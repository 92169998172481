import { Locale } from 'core/helpers/dateUtils';
import { IAppState } from 'core/interfaces/IAppState';
import { AnyObject } from 'library/types';
import { DateTime } from 'luxon';
import moment from 'moment';
import qs from 'query-string';
import { QueryClient } from 'react-query';
import { generatePath } from 'react-router-dom';
import { queryClient } from 'views';
import { INCIDENT_DETAILS_PATH } from 'views/main/routes/routes';

import { clearIncidentObj } from '../create/temp-store';
import { clearIncidentFilterObj } from '../filters/form/temp-store';
import { DateFilterChoice, IncidentStatusType } from '../graphql/generated/types';
import { resetCanAutoRefreshIL } from '../store/persistent-storage';
import { INCIDENT_STATUS, INCIDENTS_TAB } from './enums';

export const toDate = (date: Date) => {
  const momentTime = moment(date);
  if (momentTime.isAfter(moment().subtract(4, 'weeks'))) {
    return Locale.toRelative(momentTime.toDate(), 'narrow');
  }
  return Locale.toShortDate(date);
};

export const getCurrentDateTime = (): string => {
  return Locale.toFullDateTime(new Date());
};

export const toSentenceCase = (str: string) => {
  // eslint-disable-next-line
  return str?.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, char => char.toUpperCase());
};

export const nFormatter = (num: number) => {
  const format = (val: number) => val.toFixed(2).replace(/\.00$/, '');

  if (num >= 1000000000) {
    return format(num / 1000000000) + 'G';
  }
  if (num >= 1000000) {
    return format(num / 1000000) + 'M';
  }
  if (num >= 1000) {
    return format(num / 1000) + 'K';
  }

  return `${num}`;
};

export const copyURL = (url: string) => {
  navigator.clipboard.writeText(`${window.location.host}${url}`);
};

export const truncate = (input: string, maxLength = 25) =>
  input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

export const getIncidentStatusType = (status: INCIDENT_STATUS): Array<IncidentStatusType> => {
  switch (status) {
    case INCIDENT_STATUS.OPEN:
      return [IncidentStatusType.Acknowledged, IncidentStatusType.Triggered];
    case INCIDENT_STATUS.ACKNOWLEDGED:
      return [IncidentStatusType.Acknowledged];
    case INCIDENT_STATUS.TRIGGERED:
      return [IncidentStatusType.Triggered];
    case INCIDENT_STATUS.RESOLVED:
      return [IncidentStatusType.Resolved];
    case INCIDENT_STATUS.SUPPRESSED:
      return [IncidentStatusType.Suppressed];
    default:
      //default case statisifes 'all' status
      return [
        IncidentStatusType.Acknowledged,
        IncidentStatusType.Resolved,
        IncidentStatusType.Suppressed,
        IncidentStatusType.Triggered,
      ];
  }
};

export const getUIIncidentStatus = (status: IncidentStatusType): INCIDENT_STATUS => {
  switch (status) {
    case IncidentStatusType.Acknowledged:
      return INCIDENT_STATUS.ACKNOWLEDGED;
    case IncidentStatusType.Triggered:
      return INCIDENT_STATUS.TRIGGERED;
    case IncidentStatusType.Resolved:
      return INCIDENT_STATUS.RESOLVED;
    default:
      return INCIDENT_STATUS.SUPPRESSED;
  }
};

export const getIncidentDetailsPath = (id: string, queryParams?: AnyObject) => {
  let route = generatePath(INCIDENT_DETAILS_PATH, { id });
  if (queryParams) {
    route = `${route}?${qs.stringify(queryParams)}`;
  }
  return route;
};

export const invalIncidentsListingOnly = (queryClient: QueryClient) => {
  queryClient.invalidateQueries('listIncidents');
};

export const invalIncidentsListData = (queryClient: QueryClient) => {
  queryClient.invalidateQueries('countIncidents');
  invalIncidentsListingOnly(queryClient);
};

export const invalActiveTags = (queryClient: QueryClient) => {
  queryClient.invalidateQueries('getactiveTags');
};

export const queryUpdate = (
  query: URLSearchParams,
  data: string | null | undefined,
  type: string,
) => {
  if (data) {
    query.get(type) ? query.set(type, data) : query.append(type, data);
  } else {
    query.has(type) && query.delete(type);
  }
};

export const getNoOfDays = (type: DateFilterChoice.Week | DateFilterChoice.Month) => {
  const start = moment().startOf(type);
  const today = moment().endOf('day');
  // returning + 1 bcoz last day (today) is not getting counted
  return today.diff(start, 'days') + 1;
};

export const getTabIndex = (tabName: string) => {
  const tabs = Object.values(INCIDENTS_TAB);
  let index = tabs.findIndex(tab => tab === tabName);
  // if tab not found, defaults to Open tab
  if (index === -1) {
    index = tabs.findIndex(tab => tab === INCIDENTS_TAB.OPEN);
  }
  // add 1, as tab indexes (in tabs molecules) starts from 1st index
  return index + 1;
};

export const getTabName = (index: number) => {
  const tabs = Object.values(INCIDENTS_TAB);
  return index > 0 && index <= tabs.length ? tabs[index - 1] : INCIDENTS_TAB.OPEN;
};

export const getTimeZone = (organization: IAppState['organization'] | null) => {
  return organization?.currentUser.u?.time_zone ?? 'utc';
};

export const applyTimeZone = (
  date: string,
  time: string,
  timeZone: string,
  isEndDate?: boolean,
) => {
  const value = new Date(date);
  const [hrs, mins] = time.split(':');
  const dateTime = DateTime.fromObject(
    {
      year: value.getFullYear(),
      month: value.getMonth() + 1,
      day: value.getDate(),
      hour: parseInt(hrs),
      minute: parseInt(mins),
      second: isEndDate ? 59 : 0,
      millisecond: isEndDate ? 999 : 0,
    },
    { zone: timeZone },
  )
    .toUTC()
    .toISO();
  return dateTime;
};

const getLocalDateTime = (utcTimeString: string, timeZone: string) => {
  // Parse the UTC string into a Luxon DateTime object
  return DateTime.fromISO(utcTimeString, { zone: 'utc' }).setZone(timeZone);
};

export const getFormattedTimeString = (utcTimeString: string, timeZone: string) => {
  const localDateTime = getLocalDateTime(utcTimeString, timeZone);

  // Extract hours and minutes
  const hours = localDateTime.hour;
  const minutes = localDateTime.minute;

  // Format the time as "HH:mm"
  const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;

  return formattedTime;
};

export const getLocalDateString = (utcTimeString: string | null | undefined, timeZone: string) => {
  if (!utcTimeString) {
    return '';
  }
  const localDateTime = getLocalDateTime(utcTimeString, timeZone);
  const date = localDateTime.toISODate();

  return moment(date).toISOString();
};

export const clearILSessionStore = () => {
  resetCanAutoRefreshIL();
  clearIncidentFilterObj();
  clearIncidentObj();
};
