import { array, object, string, boolean, number, mixed } from 'yup';
import { FilterTypes } from '../types';
import { WorkflowTriggerTypes } from '../types/triggers';

export const TagsValidation = array().of(
  object()
    .shape(
      {
        key: string()
          .trim()
          .when('value', {
            is: (value: string) => value && value.trim().length > 0, // Only apply when key is present and not empty
            then: string().trim().required('Tag is required').min(1, 'Tag cannot be empty'),
            otherwise: string().trim(),
          }),
        value: string()
          .trim()
          .when('key', {
            is: (key: string) => key && key.trim().length > 0, // Only apply when key is present and not empty
            then: string().trim().required('Value is required').min(1, 'Value cannot be empty'),
            otherwise: string().trim(), // No specific validation when key is not present or empty
          }),
      },
      [['key', 'value']],
    )
    .test('tag-required', 'Tag is required', function (tag) {
      // Check if both key and value are missing
      return tag.key || tag.value ? true : this.createError({ message: 'Tag is required' });
    })
    .test('value-required', 'Value is required', function (tag) {
      // Check if both key and value are missing
      return tag.key || tag.value ? true : this.createError({ message: 'Value is required' });
    })
    .test('duplicate', 'Duplicate tags are not allowed', function (tag, context) {
      if (!tag.key || !tag.value) {
        // Skip duplicate check if either key or value is not provided
        return true;
      }

      return !(
        context.parent.filter(
          (t: any) => t.key?.trim() === tag.key?.trim() && t.value?.trim() === tag.value?.trim(),
        ).length > 1
      );
    }),
);

export const workflowFormValidation = object().shape({
  title: string()
    .required('Title is required')
    .max(250, 'Max characters allowed are 250')
    .trim()
    .min(1, 'Title cannot be empty'),
  description: string().max(400, 'Max characters allowed are 400').trim(),
  owner: object().shape({
    id: string().required('Owner is Required'),
    type: string(),
  }),
  tags: TagsValidation,
  trigger: object().shape({
    label: string().required('Trigger label is required!'),
    value: string()
      .oneOf(Object.values(WorkflowTriggerTypes), 'Invalid trigger value')
      .required('Trigger value is required!'),
  }),
  filterType: array().of(
    object().shape({
      label: string().required('Filter label is required!'),
      value: string()
        .oneOf(Object.values(FilterTypes), 'Invalid filter value')
        .required('Filter value is required!'),
    }),
  ),
  groupType: object()
    .shape({
      label: string(),
      value: string(),
    })
    .optional(),
  group: object()
    .shape({
      type: string(),
      fields: object().shape({
        children: mixed(),
      }),
    })
    .optional(),
  isGroup: string().optional(),
  response: object()
    .shape({
      type: string(),
      fields: array().of(mixed()),
    })
    .optional(),
});

export const communicationChannelValidation = object().shape({
  name: string(),
  data: object().shape({
    channels: array()
      .of(
        object().shape({
          channelType: object().required('Channel type is required'),
          link: string()
            .required('Channel link is required')
            .matches(
              /^(http|https):\/\/[^ "]+$/,
              'Invalid URL, please enter a valid URL starting with http:// or https://',
            ),
          displayText: string().max(100, 'Max characters allowed are 100').trim(),
        }),
      )
      .min(1, 'Atleast one communication channel required'),
  }),
});
export const statusPageValidation = object().shape({
  name: string(),
  data: object().shape({
    status_page_id: number().required('Status Page is required'),
    issue_title: string()
      .required('Name is required!')
      .trim()
      .max(250, 'Max characters allowed are 250'),
    page_status_id: number().required('Page Status is required').min(0, 'Page Status is required'),
    component_and_impact: array().test({
      message: 'Atleast one component is needed',
      test: arr => (arr || []).length > 0,
    }),
    status_and_message: array().of(
      object().shape({
        messages: array().of(
          string()
            .trim()
            .max(5000, 'Max character allowed are 5000')
            .required('Message is required')
            .notOneOf([''], 'Message cannot be empty'),
        ),
      }),
    ),
  }),
});
export const incidentNoteValidation = object().shape({
  name: string(),
  data: object().shape({
    note: string().required('Note is required'),
  }),
});

export const reassignIncidentValidation = object().shape({
  name: string(),
  data: object().required('Please select an option'),
});

export const sloAffectingValidation = object().shape({
  name: string(),
  data: object().shape({
    slo: object().required('Slo is required'),
    sli: array().min(1, 'Atleast one sli required'),
  }),
});

export const triggerWebhookValidation = object().shape({
  name: string(),
  data: object().shape({
    id: string().required('Atleast one webhook is required'),
  }),
});

export const priorityValidation = object().shape({
  name: string(),
  data: object().shape({
    priority: string().required('Atleast one priority is required'),
  }),
});

export const httpCallValidation = object().shape({
  name: string(),
  data: object().shape({
    method: string().required('Method is required'),
    url: string()
      .trim()
      .required('Link is required')
      .matches(
        /^(http|https):\/\/[^ "]+$/,
        'Invalid URL, please enter a valid URL starting with http:// or https://',
      )
      .max(1000, 'URL too long'),
    body: string().nullable(),
    headers: array().of(
      object()
        .shape(
          {
            key: string()
              .trim()
              .when('value', {
                is: (value: string) => value && value.trim().length > 0, // Only apply when key is present and not empty
                then: string()
                  .trim()
                  .required('Key is required')
                  .min(1, 'Key cannot be empty')
                  .max(100, 'Key too long'),
                otherwise: string().trim(),
              }),
            value: string()
              .trim()
              .when('key', {
                is: (key: string) => key && key.trim().length > 0, // Only apply when key is present and not empty
                then: string()
                  .trim()
                  .required('Value is required')
                  .min(1, 'Value cannot be empty')
                  .max(5000, 'Value too long'),
                otherwise: string().trim(),
              }),
          },
          [['key', 'value']],
        )
        .test('duplicate', 'Duplicate headers are not allowed', function (header, context) {
          if (!header.key || !header.value) {
            // Skip duplicate check if either key or value is not provided
            return true;
          }
          return !(
            context.parent.filter((t: any) => t.key?.trim() === header.key?.trim()).length > 1
          );
        }),
    ),
  }),
});

export const sendEmailValidation = object().shape({
  name: string(),
  data: object().shape({
    to: array().of(string().email('Invalid email format')).min(1, 'At least one email is required'),
    subject: string().trim().required('Subject is required').max(300, 'Subject too long'),
    body: string().required('Message is required').max(2000, 'Message too long'),
  }),
});

export const runbookValidation = object().shape({
  name: string(),
  data: object().shape({
    runbooks: array().of(
      object().shape({
        id: string().required('Runbook is required'),
        name: string().required('Atleast one runbook required'),
      }),
    ),
  }),
});

export const workflowFilterValidation = object().shape({
  event: object()
    .shape({
      label: string(),
      value: string(),
    })
    .nullable(),
  action: object()
    .shape({
      label: string(),
      value: string(),
    })
    .nullable(),
  tag: array().nullable(),
  tags: array().nullable(),
  owner: object()
    .shape({
      label: string(),
      value: string(),
    })
    .nullable(),
  created_by: object()
    .shape({
      label: string(),
      value: string(),
    })
    .nullable(),
  updated_by: object()
    .shape({
      label: string(),
      value: string(),
    })
    .nullable(),
  enabled: string().nullable(),
});

export const slackWorkFlowCreateChannelValidation = object().shape({
  name: string(),
  data: object().shape({
    auto_option: boolean(),
    channel_name: string()
      .max(80, 'Slack name too long')
      .matches(/^[a-zA-Z0-9 _{}.-]*$/, 'Special characters are not allowed in the channel name')
      .test('channel_name', 'Channel name is required', function (value, context) {
        if (context.parent.auto_option === false && !value) {
          throw this.createError({
            path: 'channel_name',
            message: 'Channel name is required',
          });
        }

        return true;
      })
      .test('channel_name', 'Channel name cannot consist of only blank spaces', function (value) {
        if (value && value.trim() === '') {
          throw this.createError({
            path: 'channel_name',
            message: 'Channel name cannot consist of only blank spaces',
          });
        }

        return true;
      }),
  }),
});
export const jiraTicketValidation = object().shape({
  name: string(),
  data: object().shape({
    account: string().required('Account is required'),
    project: string().required('Project is required'),
    issue_type: string().required('Issue type is required'),
    title: string().trim().min(1, 'Title is required'),
    description: string().trim().min(1, 'Description is required'),
  }),
});

export const messageTeamChannelValidation = object().shape({
  name: string(),
  data: object().shape({
    channel_id: string().required('Channel name is required'),
    message: string().required('Message is required').trim(),
  }),
});

export const messageTeamUserValidation = object().shape({
  name: string(),
  data: object().shape({
    member_id: string().required('User name is required'),
    message: string().required('Message is required').trim(),
  }),
});

export const messageSlackUserValidation = object().shape({
  name: string(),
  data: object().shape({
    to: string(),
    body: string(),
  }),
});

export const messageSlackChannelValidation = object().shape({
  name: string(),
  data: object().shape({
    channel_id: string().required('Channel name is required'),
    message: string().required('Body is required'),
  }),
});
