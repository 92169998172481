import { Flex, Box, Input } from '@chakra-ui/react';
import { FileUploadService } from 'core';
import { useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { EmailAction } from 'views/main/organization/workflow/types';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { fileUploadViewConfig } from 'views/main/organization/statuspage-v2/constants/schema';
import { MdBlock } from 'uie/components';
import FormField from 'library/molecules/FormField/FormField';

type EmailErrorObject = { message: string; type: string };

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;
const EmailForm = () => {
  const { setIsDirty } = useWorkflowFormContext();
  const {
    formState: { isDirty, errors },
    register,
    setValue,
    getValues,
  } = useFormContext<EmailAction>();
  const fileUploadService = new FileUploadService();

  function extractEmails(inputString: string) {
    // Replace all kinds of spaces with a single space
    const normalizedString = inputString.replace(/\s+/g, ' ');
    const emailsArray = normalizedString.split(' ');
    const filteredEmailsArray = emailsArray.filter(email => email.trim() !== '');
    setValue('data.to', filteredEmailsArray, { shouldDirty: true, shouldValidate: true });
  }

  const emailError = (errors: EmailErrorObject | EmailErrorObject[] | undefined) => {
    if (errors) {
      if ('message' in errors && typeof errors.message !== 'undefined') {
        return errors.message;
      } else if (Array.isArray(errors) && errors.length) {
        return 'Invalid Email format';
      }
    }

    return undefined;
  };
  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  return (
    <>
      <Flex px={6} pt={4} flexDirection="column">
        <Box mb={2}>
          <FormField label="To" error={emailError(errors?.data?.to as any)}>
            <Input
              defaultValue={getValues('data.to').join(' ')}
              placeholder="Type multiple email ids"
              onChange={e => extractEmails(e.target.value)}
            />
          </FormField>
        </Box>
        <Box mb={2}>
          <FormField label="Subject" error={errors?.data?.subject && errors.data.subject.message}>
            <Input {...register('data.subject')} placeholder="We need to look into <Incident>" />
          </FormField>
        </Box>
        <Box mb={2}>
          <FormField label="Message" error={errors?.data?.body && errors.data.body.message}>
            <MdBlock
              value={getValues('data.body')}
              onChange={content => setValue('data.body', content, { shouldDirty: true })}
              options={{
                hideIcons: ['upload-image', 'upload-file'],

                maxHeight: '130px',
                spellChecker: false,
                placeholder: 'Eg: {{event.incident_id}} triggered',
                imageUploadFunction: fileUploadService.getUploadFunctionForFeature(
                  FileUploadFeature.EMAIL,
                  OperationType.CREATE,
                ),
                imageTexts: {
                  sbInit: ``,
                  ...imageTexts,
                },
                ...fileUploadConfig,
              }}
            />
          </FormField>
        </Box>
      </Flex>
    </>
  );
};

export default EmailForm;
