import * as Types from './types';

import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from './fetcher/index';
export type GetScheduleQueryVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
  from: Types.Scalars['Time'];
  till: Types.Scalars['Time'];
  eventsFilter?: Types.InputMaybe<Types.ScheduleEventsFilters>;
}>;

export type GetScheduleQuery = {
  __typename?: 'Query';
  schedule?: {
    __typename?: 'Schedule';
    ID: number;
    name: string;
    slug: string;
    orgID: string;
    description?: string | null;
    teamID: string;
    timeZone: string;
    paused?: boolean | null;
    rotations?: Array<{
      __typename?: 'Rotation';
      ID: number;
      name: string;
      color?: string | null;
      scheduleID: number;
      startDate: any;
      period: Types.PeriodOptions;
      changeParticipantsFrequency: number;
      changeParticipantsUnit: Types.ChangeParticipantsInterval;
      endDate?: any | null;
      endsAfterIterations?: number | null;
      events?: Array<{
        __typename?: 'ScheduleEvent';
        ID: number;
        startTime: any;
        endTime: any;
        participants?: Array<{ __typename?: 'Participant'; ID: string; type: string }> | null;
      }> | null;
      shiftTimeSlot?: {
        __typename?: 'TimeSlot';
        startHour: number;
        startMin: number;
        duration: number;
        dayOfWeek?: Types.DayOfWeek | null;
      } | null;
      customPeriod?: {
        __typename?: 'CustomPeriod';
        periodFrequency: number;
        periodUnit: Types.CustomPeriodUnit;
        timeSlots: Array<{
          __typename?: 'TimeSlot';
          startHour: number;
          startMin: number;
          duration: number;
          dayOfWeek?: Types.DayOfWeek | null;
        }>;
      } | null;
      participantGroups?: Array<{
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      }> | null;
    }> | null;
    gaps?: Array<{ __typename?: 'Gap'; startTime: any; endTime: any }> | null;
    overrides?: Array<{
      __typename?: 'ScheduleOverride';
      teamID: string;
      ID: number;
      scheduleID: number;
      startTime: any;
      endTime: any;
      reason?: string | null;
      overriddenParticipant?: {
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      } | null;
      overrideWith?: {
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      } | null;
    }> | null;
    acl?: {
      __typename?: 'ACL';
      hasUpdateAccess: boolean;
      hasDeleteAccess: boolean;
      hasUpdateOwnerAccess: boolean;
    } | null;
    owner: { __typename?: 'Owner'; ID: string; type: Types.OwnerType };
    tags?: Array<{ __typename?: 'Tag'; key: string; value: string; color?: string | null }> | null;
    escalationPolicies?: Array<{
      __typename?: 'EscalationPolicy';
      ID: string;
      name: string;
    }> | null;
  } | null;
};

export type GetSchedulesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.SchedulesFilters>;
  from: Types.Scalars['Time'];
  till: Types.Scalars['Time'];
  eventsFilter?: Types.InputMaybe<Types.ScheduleEventsFilters>;
}>;

export type GetSchedulesQuery = {
  __typename?: 'Query';
  schedules?: Array<{
    __typename?: 'Schedule';
    ID: number;
    name: string;
    slug: string;
    orgID: string;
    description?: string | null;
    teamID: string;
    timeZone: string;
    paused?: boolean | null;
    rotations?: Array<{
      __typename?: 'Rotation';
      ID: number;
      name: string;
      color?: string | null;
      scheduleID: number;
      startDate: any;
      period: Types.PeriodOptions;
      changeParticipantsFrequency: number;
      changeParticipantsUnit: Types.ChangeParticipantsInterval;
      endDate?: any | null;
      endsAfterIterations?: number | null;
      events?: Array<{
        __typename?: 'ScheduleEvent';
        ID: number;
        startTime: any;
        endTime: any;
        participants?: Array<{ __typename?: 'Participant'; ID: string; type: string }> | null;
      }> | null;
      shiftTimeSlot?: {
        __typename?: 'TimeSlot';
        startHour: number;
        startMin: number;
        duration: number;
        dayOfWeek?: Types.DayOfWeek | null;
      } | null;
      customPeriod?: {
        __typename?: 'CustomPeriod';
        periodFrequency: number;
        periodUnit: Types.CustomPeriodUnit;
        timeSlots: Array<{
          __typename?: 'TimeSlot';
          startHour: number;
          startMin: number;
          duration: number;
          dayOfWeek?: Types.DayOfWeek | null;
        }>;
      } | null;
      participantGroups?: Array<{
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      }> | null;
    }> | null;
    gaps?: Array<{ __typename?: 'Gap'; startTime: any; endTime: any }> | null;
    overrides?: Array<{
      __typename?: 'ScheduleOverride';
      teamID: string;
      ID: number;
      scheduleID: number;
      startTime: any;
      endTime: any;
      reason?: string | null;
      overriddenParticipant?: {
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      } | null;
      overrideWith?: {
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      } | null;
    }> | null;
    oncallCoverage?: Array<{
      __typename?: 'OncallCoverage';
      type: string;
      startTime: any;
      endTime: any;
      rotations?: Array<{
        __typename?: 'CoverageRotations';
        ID: number;
        name: string;
        color?: string | null;
        startDate: any;
        endDate?: any | null;
        period: Types.PeriodOptions;
        customPeriodFrequency?: number | null;
        customPeriodUnit?: Types.CustomPeriodUnit | null;
        changeParticipantsFrequency: number;
        changeParticipantsUnit: Types.ChangeParticipantsInterval;
        endsAfterIterations?: number | null;
        shiftTimeSlots: Array<{
          __typename?: 'TimeSlot';
          startHour: number;
          startMin: number;
          duration: number;
          dayOfWeek?: Types.DayOfWeek | null;
        }>;
        shiftTimeSlot?: {
          __typename?: 'TimeSlot';
          startHour: number;
          startMin: number;
          duration: number;
          dayOfWeek?: Types.DayOfWeek | null;
        } | null;
        customPeriod?: {
          __typename?: 'CustomPeriod';
          periodFrequency: number;
          periodUnit: Types.CustomPeriodUnit;
        } | null;
        participantGroups?: Array<{
          __typename?: 'ParticipantGroup';
          everyone?: boolean | null;
          participants?: Array<{
            __typename?: 'Participant';
            ID: string;
            type: string;
            participant:
              | { __typename?: 'Squad'; name: string; ID: string }
              | {
                  __typename?: 'User';
                  ID: string;
                  avatarURL?: string | null;
                  usernameForDisplay?: string | null;
                  firstName: string;
                  lastName?: string | null;
                };
          } | null> | null;
        }> | null;
        events?: Array<{
          __typename?: 'ScheduleEvent';
          startTime: any;
          endTime: any;
          participants?: Array<{
            __typename?: 'Participant';
            ID: string;
            type: string;
            participant:
              | { __typename?: 'Squad'; name: string; ID: string }
              | {
                  __typename?: 'User';
                  ID: string;
                  avatarURL?: string | null;
                  usernameForDisplay?: string | null;
                  firstName: string;
                  lastName?: string | null;
                };
          }> | null;
        }> | null;
      }> | null;
      overrides?: Array<{
        __typename?: 'ScheduleOverride';
        ID: number;
        scheduleID: number;
        startTime: any;
        endTime: any;
        reason?: string | null;
        overriddenParticipant?: {
          __typename?: 'ParticipantGroup';
          everyone?: boolean | null;
          participants?: Array<{
            __typename?: 'Participant';
            ID: string;
            type: string;
            participant:
              | { __typename?: 'Squad'; ID: string; name: string }
              | {
                  __typename?: 'User';
                  ID: string;
                  usernameForDisplay?: string | null;
                  firstName: string;
                  lastName?: string | null;
                  avatarURL?: string | null;
                };
          } | null> | null;
        } | null;
        overrideWith?: {
          __typename?: 'ParticipantGroup';
          everyone?: boolean | null;
          participants?: Array<{
            __typename?: 'Participant';
            ID: string;
            type: string;
            participant:
              | { __typename?: 'Squad'; ID: string; name: string }
              | {
                  __typename?: 'User';
                  ID: string;
                  firstName: string;
                  usernameForDisplay?: string | null;
                  lastName?: string | null;
                  avatarURL?: string | null;
                };
          } | null> | null;
        } | null;
      }> | null;
    }> | null;
    acl?: {
      __typename?: 'ACL';
      hasUpdateAccess: boolean;
      hasDeleteAccess: boolean;
      hasUpdateOwnerAccess: boolean;
    } | null;
    owner: { __typename?: 'Owner'; ID: string; type: Types.OwnerType };
    tags?: Array<{ __typename?: 'Tag'; key: string; value: string; color?: string | null }> | null;
    escalationPolicies?: Array<{
      __typename?: 'EscalationPolicy';
      ID: string;
      name: string;
    }> | null;
  }> | null;
};

export type GetSchedulesForUserProfileQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.SchedulesFilters>;
  from: Types.Scalars['Time'];
  till: Types.Scalars['Time'];
  eventsFilter?: Types.InputMaybe<Types.ScheduleEventsFilters>;
  overrideFilters?: Types.InputMaybe<Types.OverridesFilters>;
}>;

export type GetSchedulesForUserProfileQuery = {
  __typename?: 'Query';
  schedules?: Array<{
    __typename?: 'Schedule';
    ID: number;
    name: string;
    rotations?: Array<{
      __typename?: 'Rotation';
      ID: number;
      name: string;
      color?: string | null;
      events?: Array<{
        __typename?: 'ScheduleEvent';
        ID: number;
        startTime: any;
        endTime: any;
        participants?: Array<{ __typename?: 'Participant'; ID: string; type: string }> | null;
      }> | null;
    }> | null;
    overrides?: Array<{
      __typename?: 'ScheduleOverride';
      teamID: string;
      ID: number;
      scheduleID: number;
      startTime: any;
      endTime: any;
      reason?: string | null;
      overriddenParticipant?: {
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      } | null;
      overrideWith?: {
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      } | null;
    }> | null;
  }> | null;
};

export type GetScheduleForEditActionQueryVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
}>;

export type GetScheduleForEditActionQuery = {
  __typename?: 'Query';
  schedule?: {
    __typename?: 'Schedule';
    ID: number;
    name: string;
    slug: string;
    orgID: string;
    description?: string | null;
    teamID: string;
    timeZone: string;
    paused?: boolean | null;
    rotations?: Array<{
      __typename?: 'Rotation';
      ID: number;
      name: string;
      color?: string | null;
      scheduleID: number;
      startDate: any;
      period: Types.PeriodOptions;
      changeParticipantsFrequency: number;
      changeParticipantsUnit: Types.ChangeParticipantsInterval;
      endDate?: any | null;
      endsAfterIterations?: number | null;
      shiftTimeSlot?: {
        __typename?: 'TimeSlot';
        startHour: number;
        startMin: number;
        duration: number;
        dayOfWeek?: Types.DayOfWeek | null;
      } | null;
      customPeriod?: {
        __typename?: 'CustomPeriod';
        periodFrequency: number;
        periodUnit: Types.CustomPeriodUnit;
        timeSlots: Array<{
          __typename?: 'TimeSlot';
          startHour: number;
          startMin: number;
          duration: number;
          dayOfWeek?: Types.DayOfWeek | null;
        }>;
      } | null;
      participantGroups?: Array<{
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      }> | null;
    }> | null;
    acl?: {
      __typename?: 'ACL';
      hasUpdateAccess: boolean;
      hasDeleteAccess: boolean;
      hasUpdateOwnerAccess: boolean;
    } | null;
    owner: { __typename?: 'Owner'; ID: string; type: Types.OwnerType };
    tags?: Array<{ __typename?: 'Tag'; key: string; value: string; color?: string | null }> | null;
  } | null;
};

export type GetScheduleForDeleteActionQueryVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
}>;

export type GetScheduleForDeleteActionQuery = {
  __typename?: 'Query';
  schedule?: {
    __typename?: 'Schedule';
    ID: number;
    name: string;
    escalationPolicies?: Array<{
      __typename?: 'EscalationPolicy';
      ID: string;
      name: string;
    }> | null;
  } | null;
};

export type GetListOfSchedulesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.SchedulesFilters>;
}>;

export type GetListOfSchedulesQuery = {
  __typename?: 'Query';
  schedules?: Array<{ __typename?: 'Schedule'; ID: number; name: string; teamID: string }> | null;
};

export type GetActiveScheduleTagsQueryVariables = Types.Exact<{
  teamID: Types.Scalars['String'];
}>;

export type GetActiveScheduleTagsQuery = {
  __typename?: 'Query';
  listActiveScheduleTags: Array<{
    __typename?: 'ScheduleActiveTags';
    key: string;
    values: Array<string>;
  } | null>;
};

export type GetSchedulesFromOverridesForProfileQueryVariables = Types.Exact<{
  from: Types.Scalars['Time'];
  till: Types.Scalars['Time'];
  overrideFilters?: Types.InputMaybe<Types.OverridesFilters>;
}>;

export type GetSchedulesFromOverridesForProfileQuery = {
  __typename?: 'Query';
  schedules?: Array<{
    __typename?: 'Schedule';
    ID: number;
    name: string;
    teamID: string;
    overrides?: Array<{ __typename?: 'ScheduleOverride'; ID: number }> | null;
  }> | null;
};

export type GetMockEventsQueryVariables = Types.Exact<{
  schedule: Types.NewSchedule;
  from: Types.Scalars['Time'];
  till: Types.Scalars['Time'];
  In?: Types.InputMaybe<Types.TimeDurationUnit>;
}>;

export type GetMockEventsQuery = {
  __typename?: 'Query';
  mockEvents: {
    __typename?: 'MockEventsData';
    gaps?: Array<{ __typename?: 'Gap'; startTime: any; endTime: any }> | null;
    mockEvents?: Array<{
      __typename?: 'MockEvents';
      rotationIndex: number;
      onCallDurationPerParticipant: number;
      events?: Array<{
        __typename?: 'MockEvent';
        startTime: any;
        endTime: any;
        mockParticipants?: Array<{
          __typename?: 'MockParticipant';
          ID: string;
          type: string;
        } | null> | null;
      }> | null;
    }> | null;
  };
};

export type GetOverrideQueryVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
}>;

export type GetOverrideQuery = {
  __typename?: 'Query';
  override?: {
    __typename?: 'ScheduleOverride';
    teamID: string;
    ID: number;
    scheduleID: number;
    startTime: any;
    endTime: any;
    reason?: string | null;
    overriddenParticipant?: {
      __typename?: 'ParticipantGroup';
      participants?: Array<{ __typename?: 'Participant'; ID: string; type: string } | null> | null;
    } | null;
    overrideWith?: {
      __typename?: 'ParticipantGroup';
      participants?: Array<{ __typename?: 'Participant'; ID: string; type: string } | null> | null;
    } | null;
  } | null;
};

export type GetICalLinksQueryVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
}>;

export type GetICalLinksQuery = {
  __typename?: 'Query';
  schedule?: {
    __typename?: 'Schedule';
    ID: number;
    iCalLink?: string | null;
    iCalLinkForMyOncalls?: string | null;
  } | null;
};

export type GetOverrideParticipantsQueryVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
  from: Types.Scalars['Time'];
  till: Types.Scalars['Time'];
}>;

export type GetOverrideParticipantsQuery = {
  __typename?: 'Query';
  schedule?: {
    __typename?: 'Schedule';
    overrides?: Array<{
      __typename?: 'ScheduleOverride';
      overriddenParticipant?: {
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      } | null;
      overrideWith?: {
        __typename?: 'ParticipantGroup';
        participants?: Array<{
          __typename?: 'Participant';
          ID: string;
          type: string;
        } | null> | null;
      } | null;
    }> | null;
  } | null;
};

export type OverrideDetailsFragment = {
  __typename?: 'ScheduleOverride';
  teamID: string;
  ID: number;
  scheduleID: number;
  startTime: any;
  endTime: any;
  reason?: string | null;
  overriddenParticipant?: {
    __typename?: 'ParticipantGroup';
    participants?: Array<{ __typename?: 'Participant'; ID: string; type: string } | null> | null;
  } | null;
  overrideWith?: {
    __typename?: 'ParticipantGroup';
    participants?: Array<{ __typename?: 'Participant'; ID: string; type: string } | null> | null;
  } | null;
};

export type EventInfoFragment = {
  __typename?: 'ScheduleEvent';
  ID: number;
  startTime: any;
  endTime: any;
  participants?: Array<{ __typename?: 'Participant'; ID: string; type: string }> | null;
};

export type ParticipantInfoFragment = { __typename?: 'Participant'; ID: string; type: string };

export type ScheduleInfoFragment = {
  __typename?: 'Schedule';
  ID: number;
  name: string;
  slug: string;
  orgID: string;
  description?: string | null;
  teamID: string;
  timeZone: string;
  paused?: boolean | null;
  acl?: {
    __typename?: 'ACL';
    hasUpdateAccess: boolean;
    hasDeleteAccess: boolean;
    hasUpdateOwnerAccess: boolean;
  } | null;
  owner: { __typename?: 'Owner'; ID: string; type: Types.OwnerType };
  tags?: Array<{ __typename?: 'Tag'; key: string; value: string; color?: string | null }> | null;
};

export type EscalationPolicyInfoFragment = {
  __typename?: 'Schedule';
  escalationPolicies?: Array<{ __typename?: 'EscalationPolicy'; ID: string; name: string }> | null;
};

export type RotationInfoFragment = {
  __typename?: 'Rotation';
  ID: number;
  name: string;
  color?: string | null;
  scheduleID: number;
  startDate: any;
  period: Types.PeriodOptions;
  changeParticipantsFrequency: number;
  changeParticipantsUnit: Types.ChangeParticipantsInterval;
  endDate?: any | null;
  endsAfterIterations?: number | null;
  shiftTimeSlot?: {
    __typename?: 'TimeSlot';
    startHour: number;
    startMin: number;
    duration: number;
    dayOfWeek?: Types.DayOfWeek | null;
  } | null;
  customPeriod?: {
    __typename?: 'CustomPeriod';
    periodFrequency: number;
    periodUnit: Types.CustomPeriodUnit;
    timeSlots: Array<{
      __typename?: 'TimeSlot';
      startHour: number;
      startMin: number;
      duration: number;
      dayOfWeek?: Types.DayOfWeek | null;
    }>;
  } | null;
  participantGroups?: Array<{
    __typename?: 'ParticipantGroup';
    participants?: Array<{ __typename?: 'Participant'; ID: string; type: string } | null> | null;
  }> | null;
};

export type OncallCoverageInfoFragment = {
  __typename?: 'OncallCoverage';
  type: string;
  startTime: any;
  endTime: any;
  rotations?: Array<{
    __typename?: 'CoverageRotations';
    ID: number;
    name: string;
    color?: string | null;
    startDate: any;
    endDate?: any | null;
    period: Types.PeriodOptions;
    customPeriodFrequency?: number | null;
    customPeriodUnit?: Types.CustomPeriodUnit | null;
    changeParticipantsFrequency: number;
    changeParticipantsUnit: Types.ChangeParticipantsInterval;
    endsAfterIterations?: number | null;
    shiftTimeSlots: Array<{
      __typename?: 'TimeSlot';
      startHour: number;
      startMin: number;
      duration: number;
      dayOfWeek?: Types.DayOfWeek | null;
    }>;
    shiftTimeSlot?: {
      __typename?: 'TimeSlot';
      startHour: number;
      startMin: number;
      duration: number;
      dayOfWeek?: Types.DayOfWeek | null;
    } | null;
    customPeriod?: {
      __typename?: 'CustomPeriod';
      periodFrequency: number;
      periodUnit: Types.CustomPeriodUnit;
    } | null;
    participantGroups?: Array<{
      __typename?: 'ParticipantGroup';
      everyone?: boolean | null;
      participants?: Array<{
        __typename?: 'Participant';
        ID: string;
        type: string;
        participant:
          | { __typename?: 'Squad'; name: string; ID: string }
          | {
              __typename?: 'User';
              ID: string;
              avatarURL?: string | null;
              usernameForDisplay?: string | null;
              firstName: string;
              lastName?: string | null;
            };
      } | null> | null;
    }> | null;
    events?: Array<{
      __typename?: 'ScheduleEvent';
      startTime: any;
      endTime: any;
      participants?: Array<{
        __typename?: 'Participant';
        ID: string;
        type: string;
        participant:
          | { __typename?: 'Squad'; name: string; ID: string }
          | {
              __typename?: 'User';
              ID: string;
              avatarURL?: string | null;
              usernameForDisplay?: string | null;
              firstName: string;
              lastName?: string | null;
            };
      }> | null;
    }> | null;
  }> | null;
  overrides?: Array<{
    __typename?: 'ScheduleOverride';
    ID: number;
    scheduleID: number;
    startTime: any;
    endTime: any;
    reason?: string | null;
    overriddenParticipant?: {
      __typename?: 'ParticipantGroup';
      everyone?: boolean | null;
      participants?: Array<{
        __typename?: 'Participant';
        ID: string;
        type: string;
        participant:
          | { __typename?: 'Squad'; ID: string; name: string }
          | {
              __typename?: 'User';
              ID: string;
              usernameForDisplay?: string | null;
              firstName: string;
              lastName?: string | null;
              avatarURL?: string | null;
            };
      } | null> | null;
    } | null;
    overrideWith?: {
      __typename?: 'ParticipantGroup';
      everyone?: boolean | null;
      participants?: Array<{
        __typename?: 'Participant';
        ID: string;
        type: string;
        participant:
          | { __typename?: 'Squad'; ID: string; name: string }
          | {
              __typename?: 'User';
              ID: string;
              firstName: string;
              usernameForDisplay?: string | null;
              lastName?: string | null;
              avatarURL?: string | null;
            };
      } | null> | null;
    } | null;
  }> | null;
};

export type WhoIsOncallQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WhoIsOncallFilters>;
}>;

export type WhoIsOncallQuery = {
  __typename?: 'Query';
  whoIsOncall?: Array<{
    __typename?: 'WhoIsOncallForSchedule';
    schedule: { __typename?: 'Schedule'; ID: number; name: string; paused?: boolean | null };
    oncallParticipants?: Array<{
      __typename?: 'Participant';
      ID: string;
      type: string;
      participant:
        | {
            __typename?: 'Squad';
            ID: string;
            username?: string;

            name: string;
            members?: Array<{
              __typename?: 'User';
              ID: string;
              name: string;
              firstName: string;
              lastName?: string | null;
            }> | null;
          }
        | {
            __typename?: 'User';
            ID: string;
            username?: string;
            name: string;
            usernameForDisplay?: string | null;
            firstName: string;
            lastName?: string | null;
          };
    }> | null;
  }> | null;
};

export const ParticipantInfoFragmentDoc = `
    fragment participantInfo on Participant {
  ID
  type
}
    `;
export const OverrideDetailsFragmentDoc = `
    fragment overrideDetails on ScheduleOverride {
  teamID
  ID
  scheduleID
  startTime
  endTime
  reason
  overriddenParticipant {
    participants {
      ...participantInfo
    }
  }
  overrideWith {
    participants {
      ...participantInfo
    }
  }
}
    `;
export const EventInfoFragmentDoc = `
    fragment eventInfo on ScheduleEvent {
  ID
  startTime
  endTime
  participants {
    ...participantInfo
  }
}
    `;
export const ScheduleInfoFragmentDoc = `
    fragment scheduleInfo on Schedule {
  ID
  name
  acl {
    hasUpdateAccess
    hasDeleteAccess
    hasUpdateOwnerAccess
  }
  slug
  orgID
  owner {
    ID
    type
  }
  tags {
    key
    value
    color
  }
  description
  teamID
  timeZone
  paused
}
    `;
export const EscalationPolicyInfoFragmentDoc = `
    fragment escalationPolicyInfo on Schedule {
  escalationPolicies {
    ID
    name
  }
}
    `;
export const RotationInfoFragmentDoc = `
    fragment rotationInfo on Rotation {
  ID
  name
  color
  scheduleID
  startDate
  period
  shiftTimeSlot {
    startHour
    startMin
    duration
    dayOfWeek
  }
  customPeriod {
    periodFrequency
    periodUnit
    timeSlots {
      startHour
      startMin
      duration
      dayOfWeek
    }
  }
  changeParticipantsFrequency
  changeParticipantsUnit
  endDate
  endsAfterIterations
  participantGroups {
    participants {
      ...participantInfo
    }
  }
}
    `;
export const OncallCoverageInfoFragmentDoc = `
    fragment oncallCoverageInfo on OncallCoverage {
  type
  startTime
  endTime
  rotations {
    ID
    name
    color
    startDate
    endDate
    period
    shiftTimeSlots {
      startHour
      startMin
      duration
      dayOfWeek
    }
    customPeriodFrequency
    customPeriodUnit
    shiftTimeSlot {
      startHour
      startMin
      duration
      dayOfWeek
    }
    customPeriod {
      periodFrequency
      periodUnit
    }
    changeParticipantsFrequency
    changeParticipantsUnit
    endsAfterIterations
    participantGroups {
      everyone
      participants {
        ID
        type
        participant {
          ... on User {
            ID
            avatarURL
            usernameForDisplay
            firstName
            lastName
          }
          ... on Squad {
            name
            ID
          }
        }
      }
    }
    events {
      startTime
      endTime
      participants {
        ID
        type
        participant {
          ... on User {
            ID
            avatarURL
            usernameForDisplay
            firstName
            lastName
          }
          ... on Squad {
            name
            ID
          }
        }
      }
    }
  }
  overrides {
    ID
    scheduleID
    startTime
    endTime
    reason
    overriddenParticipant {
      participants {
        ID
        type
        participant {
          ... on User {
            ID
            usernameForDisplay
            firstName
            lastName
            avatarURL
          }
          ... on Squad {
            ID
            name
          }
        }
      }
      everyone
    }
    overrideWith {
      participants {
        ID
        type
        participant {
          ... on User {
            ID
            firstName
            usernameForDisplay
            lastName
            avatarURL
          }
          ... on Squad {
            ID
            name
          }
        }
      }
      everyone
    }
  }
}
    `;
export const GetScheduleDocument = `
    query getSchedule($ID: Int!, $from: Time!, $till: Time!, $eventsFilter: ScheduleEventsFilters) {
  schedule(ID: $ID) {
    ...scheduleInfo
    ...escalationPolicyInfo
    rotations {
      ...rotationInfo
      events(filters: $eventsFilter) {
        ...eventInfo
      }
    }
    gaps(from: $from, till: $till) {
      startTime
      endTime
    }
    overrides(from: $from, till: $till) {
      ...overrideDetails
    }
  }
}
    ${ScheduleInfoFragmentDoc}
${EscalationPolicyInfoFragmentDoc}
${RotationInfoFragmentDoc}
${ParticipantInfoFragmentDoc}
${EventInfoFragmentDoc}
${OverrideDetailsFragmentDoc}`;
export const useGetScheduleQuery = <TData = GetScheduleQuery, TError = unknown>(
  variables: GetScheduleQueryVariables,
  options?: UseQueryOptions<GetScheduleQuery, TError, TData>,
) =>
  useQuery<GetScheduleQuery, TError, TData>(
    ['getSchedule', variables],
    fetcher<GetScheduleQuery, GetScheduleQueryVariables>(GetScheduleDocument, variables),
    options,
  );
export const GetSchedulesDocument = `
    query getSchedules($filters: SchedulesFilters, $from: Time!, $till: Time!, $eventsFilter: ScheduleEventsFilters) {
  schedules(filters: $filters) {
    ...scheduleInfo
    ...escalationPolicyInfo
    rotations {
      ...rotationInfo
      events(filters: $eventsFilter) {
        ...eventInfo
      }
    }
    gaps(from: $from, till: $till) {
      startTime
      endTime
    }
    overrides(from: $from, till: $till) {
      ...overrideDetails
    }
    oncallCoverage(from: $from, till: $till) {
      ...oncallCoverageInfo
    }
  }
}
    ${ScheduleInfoFragmentDoc}
${EscalationPolicyInfoFragmentDoc}
${RotationInfoFragmentDoc}
${ParticipantInfoFragmentDoc}
${EventInfoFragmentDoc}
${OverrideDetailsFragmentDoc}
${OncallCoverageInfoFragmentDoc}`;
export const useGetSchedulesQuery = <TData = GetSchedulesQuery, TError = unknown>(
  variables: GetSchedulesQueryVariables,
  options?: UseQueryOptions<GetSchedulesQuery, TError, TData>,
) =>
  useQuery<GetSchedulesQuery, TError, TData>(
    ['getSchedules', variables],
    fetcher<GetSchedulesQuery, GetSchedulesQueryVariables>(GetSchedulesDocument, variables),
    options,
  );
export const GetSchedulesForUserProfileDocument = `
    query getSchedulesForUserProfile($filters: SchedulesFilters, $from: Time!, $till: Time!, $eventsFilter: ScheduleEventsFilters, $overrideFilters: OverridesFilters) {
  schedules(filters: $filters) {
    ID
    name
    rotations {
      ID
      name
      color
      events(filters: $eventsFilter) {
        ...eventInfo
      }
    }
    overrides(from: $from, till: $till, filters: $overrideFilters) {
      ...overrideDetails
    }
  }
}
    ${EventInfoFragmentDoc}
${ParticipantInfoFragmentDoc}
${OverrideDetailsFragmentDoc}`;
export const useGetSchedulesForUserProfileQuery = <
  TData = GetSchedulesForUserProfileQuery,
  TError = unknown,
>(
  variables: GetSchedulesForUserProfileQueryVariables,
  options?: UseQueryOptions<GetSchedulesForUserProfileQuery, TError, TData>,
) =>
  useQuery<GetSchedulesForUserProfileQuery, TError, TData>(
    ['getSchedulesForUserProfile', variables],
    fetcher<GetSchedulesForUserProfileQuery, GetSchedulesForUserProfileQueryVariables>(
      GetSchedulesForUserProfileDocument,
      variables,
    ),
    options,
  );
export const GetScheduleForEditActionDocument = `
    query getScheduleForEditAction($ID: Int!) {
  schedule(ID: $ID) {
    ...scheduleInfo
    rotations {
      ...rotationInfo
    }
  }
}
    ${ScheduleInfoFragmentDoc}
${RotationInfoFragmentDoc}
${ParticipantInfoFragmentDoc}`;
export const useGetScheduleForEditActionQuery = <
  TData = GetScheduleForEditActionQuery,
  TError = unknown,
>(
  variables: GetScheduleForEditActionQueryVariables,
  options?: UseQueryOptions<GetScheduleForEditActionQuery, TError, TData>,
) =>
  useQuery<GetScheduleForEditActionQuery, TError, TData>(
    ['getScheduleForEditAction', variables],
    fetcher<GetScheduleForEditActionQuery, GetScheduleForEditActionQueryVariables>(
      GetScheduleForEditActionDocument,
      variables,
    ),
    options,
  );
export const GetScheduleForDeleteActionDocument = `
    query getScheduleForDeleteAction($ID: Int!) {
  schedule(ID: $ID) {
    ID
    name
    ...escalationPolicyInfo
  }
}
    ${EscalationPolicyInfoFragmentDoc}`;
export const useGetScheduleForDeleteActionQuery = <
  TData = GetScheduleForDeleteActionQuery,
  TError = unknown,
>(
  variables: GetScheduleForDeleteActionQueryVariables,
  options?: UseQueryOptions<GetScheduleForDeleteActionQuery, TError, TData>,
) =>
  useQuery<GetScheduleForDeleteActionQuery, TError, TData>(
    ['getScheduleForDeleteAction', variables],
    fetcher<GetScheduleForDeleteActionQuery, GetScheduleForDeleteActionQueryVariables>(
      GetScheduleForDeleteActionDocument,
      variables,
    ),
    options,
  );
export const GetListOfSchedulesDocument = `
    query getListOfSchedules($filters: SchedulesFilters) {
  schedules(filters: $filters) {
    ID
    name
    teamID
  }
}
    `;
export const useGetListOfSchedulesQuery = <TData = GetListOfSchedulesQuery, TError = unknown>(
  variables?: GetListOfSchedulesQueryVariables,
  options?: UseQueryOptions<GetListOfSchedulesQuery, TError, TData>,
) =>
  useQuery<GetListOfSchedulesQuery, TError, TData>(
    variables === undefined ? ['getListOfSchedules'] : ['getListOfSchedules', variables],
    fetcher<GetListOfSchedulesQuery, GetListOfSchedulesQueryVariables>(
      GetListOfSchedulesDocument,
      variables,
    ),
    options,
  );
export const GetActiveScheduleTagsDocument = `
    query getActiveScheduleTags($teamID: String!) {
  listActiveScheduleTags(teamID: $teamID) {
    key
    values
  }
}
    `;
export const useGetActiveScheduleTagsQuery = <TData = GetActiveScheduleTagsQuery, TError = unknown>(
  variables: GetActiveScheduleTagsQueryVariables,
  options?: UseQueryOptions<GetActiveScheduleTagsQuery, TError, TData>,
) =>
  useQuery<GetActiveScheduleTagsQuery, TError, TData>(
    ['getActiveScheduleTags', variables],
    fetcher<GetActiveScheduleTagsQuery, GetActiveScheduleTagsQueryVariables>(
      GetActiveScheduleTagsDocument,
      variables,
    ),

    {
      ...options,
      refetchOnMount: true, // Refetch on mount
    },
  );
export const GetSchedulesFromOverridesForProfileDocument = `
    query getSchedulesFromOverridesForProfile($from: Time!, $till: Time!, $overrideFilters: OverridesFilters) {
  schedules {
    ID
    name
    teamID
    overrides(from: $from, till: $till, filters: $overrideFilters) {
      ID
    }
  }
}
    `;
export const useGetSchedulesFromOverridesForProfileQuery = <
  TData = GetSchedulesFromOverridesForProfileQuery,
  TError = unknown,
>(
  variables: GetSchedulesFromOverridesForProfileQueryVariables,
  options?: UseQueryOptions<GetSchedulesFromOverridesForProfileQuery, TError, TData>,
) =>
  useQuery<GetSchedulesFromOverridesForProfileQuery, TError, TData>(
    ['getSchedulesFromOverridesForProfile', variables],
    fetcher<
      GetSchedulesFromOverridesForProfileQuery,
      GetSchedulesFromOverridesForProfileQueryVariables
    >(GetSchedulesFromOverridesForProfileDocument, variables),
    options,
  );
export const GetMockEventsDocument = `
    query getMockEvents($schedule: NewSchedule!, $from: Time!, $till: Time!, $In: TimeDurationUnit) {
  mockEvents(schedule: $schedule, from: $from, till: $till) {
    gaps {
      startTime
      endTime
    }
    mockEvents {
      rotationIndex
      onCallDurationPerParticipant(In: $In)
      events {
        startTime
        endTime
        mockParticipants {
          ID
          type
        }
      }
    }
  }
}
    `;
export const useGetMockEventsQuery = <TData = GetMockEventsQuery, TError = unknown>(
  variables: GetMockEventsQueryVariables,
  options?: UseQueryOptions<GetMockEventsQuery, TError, TData>,
) =>
  useQuery<GetMockEventsQuery, TError, TData>(
    ['getMockEvents', variables],
    fetcher<GetMockEventsQuery, GetMockEventsQueryVariables>(GetMockEventsDocument, variables),
    options,
  );
export const GetOverrideDocument = `
    query getOverride($ID: Int!) {
  override(ID: $ID) {
    ...overrideDetails
  }
}
    ${OverrideDetailsFragmentDoc}
${ParticipantInfoFragmentDoc}`;
export const useGetOverrideQuery = <TData = GetOverrideQuery, TError = unknown>(
  variables: GetOverrideQueryVariables,
  options?: UseQueryOptions<GetOverrideQuery, TError, TData>,
) =>
  useQuery<GetOverrideQuery, TError, TData>(
    ['getOverride', variables],
    fetcher<GetOverrideQuery, GetOverrideQueryVariables>(GetOverrideDocument, variables),
    options,
  );
export const GetICalLinksDocument = `
    query getICalLinks($ID: Int!) {
  schedule(ID: $ID) {
    ID
    iCalLink
    iCalLinkForMyOncalls
  }
}
    `;
export const useGetICalLinksQuery = <TData = GetICalLinksQuery, TError = unknown>(
  variables: GetICalLinksQueryVariables,
  options?: UseQueryOptions<GetICalLinksQuery, TError, TData>,
) =>
  useQuery<GetICalLinksQuery, TError, TData>(
    ['getICalLinks', variables],
    fetcher<GetICalLinksQuery, GetICalLinksQueryVariables>(GetICalLinksDocument, variables),
    options,
  );
export const GetOverrideParticipantsDocument = `
    query getOverrideParticipants($ID: Int!, $from: Time!, $till: Time!) {
  schedule(ID: $ID) {
    overrides(from: $from, till: $till) {
      overriddenParticipant {
        participants {
          ...participantInfo
        }
      }
      overrideWith {
        participants {
          ...participantInfo
        }
      }
    }
  }
}
    ${ParticipantInfoFragmentDoc}`;
export const useGetOverrideParticipantsQuery = <
  TData = GetOverrideParticipantsQuery,
  TError = unknown,
>(
  variables: GetOverrideParticipantsQueryVariables,
  options?: UseQueryOptions<GetOverrideParticipantsQuery, TError, TData>,
) =>
  useQuery<GetOverrideParticipantsQuery, TError, TData>(
    ['getOverrideParticipants', variables],
    fetcher<GetOverrideParticipantsQuery, GetOverrideParticipantsQueryVariables>(
      GetOverrideParticipantsDocument,
      variables,
    ),
    options,
  );
export const WhoIsOncallDocument = `
    query whoIsOncall($filters: WhoIsOncallFilters) {
  whoIsOncall(filters: $filters) {
    schedule {
      ID
      name
      paused
    }
    oncallParticipants {
      ID
      type
      participant {
        ... on User {
          ID
          name
          firstName
          lastName
        }
        ... on Squad {
          ID
          name
          members {
            ID
            name
            firstName
            lastName
          }
        }
      }
    }
  }
}
    `;
export const useWhoIsOncallQuery = <TData = WhoIsOncallQuery, TError = unknown>(
  variables?: WhoIsOncallQueryVariables,
  options?: UseQueryOptions<WhoIsOncallQuery, TError, TData>,
) =>
  useQuery<WhoIsOncallQuery, TError, TData>(
    variables === undefined ? ['whoIsOncall'] : ['whoIsOncall', variables],
    fetcher<WhoIsOncallQuery, WhoIsOncallQueryVariables>(WhoIsOncallDocument, variables),
    {
      ...options,
      refetchOnMount: true,
    },
  );
