import { PRIORITY_OPTIONS } from 'library/common';
import { QueryClient } from 'react-query';

import { filterManager } from '.';
import { queryUpdate } from '../../common/util';
import { DateFilterChoice } from '../../graphql/generated/types';
import { ListIncidentGroupsQuery } from '../../graphql/query';
import { filterDropdownOptions } from '../../interfaces/common';
import { CREATED_OPTIONS } from '../form/options';
import { buildSavedFilterObj } from './get-saved-filter-list';
import { History } from 'history';

export const createFilterFromQuery = (
  filterDatalist: {
    serviceList: filterDropdownOptions[];
    alertSourceList: filterDropdownOptions[];
    slolist: filterDropdownOptions[];
    assignedTo:
      | {
          meAndMySquads: boolean;
          users: filterDropdownOptions[];
          squads: filterDropdownOptions[];
          myUsers: filterDropdownOptions[];
          mySquads: filterDropdownOptions[];
        }
      | filterDropdownOptions[];
    serviceOwner?: {
      meAndMySquads: boolean;
      users: filterDropdownOptions[];
      squads: filterDropdownOptions[];
      myUsers: filterDropdownOptions[];
      mySquads: filterDropdownOptions[];
    };

    dropdownOptions: {
      label: string;
      options: { label: string; value: string; type: string; username?: string }[];
    }[];
    tagsData: { label: string; value: string; child: string[] }[];
    isLoading: boolean;
  },
  queryClient: QueryClient,
  query: URLSearchParams,
  history: History,
  timeZone: string,
  filtersList?: ListIncidentGroupsQuery,
): void => {
  const id = query.get('filterId') ?? '';
  // if savedFilter is applied it showul have filterId in query params
  if (id) {
    if (filtersList?.listIncidentGroups?.length) {
      const apiFilterObj = filtersList.listIncidentGroups.find(
        item => item.incidentGroupID === +id,
      );

      if (apiFilterObj) {
        const [savedFilter] = buildSavedFilterObj([apiFilterObj], filterDatalist, timeZone);
        const filterObj = {
          ...savedFilter.filters,
          id: savedFilter.id,
          name: savedFilter.name,
        };
        // remove filterId from url

        filterManager.applyFilters(filterObj, queryClient);
      }
    }
    return;
  }

  const getList = (key: string) => query.get(key)?.split(',') ?? [];
  let entityList: (string | null)[];

  // if filters are applied then in should have filter=true in query
  if (!filterDatalist.isLoading && query.get('filter') === 'true') {
    entityList = getList('services');
    const services = filterDatalist.serviceList.filter(item => entityList.includes(item.value));

    entityList = getList('assignedTo');
    let assignedTo: {
      meAndMySquads: boolean;
      users: filterDropdownOptions[];
      squads: filterDropdownOptions[];
      myUsers: filterDropdownOptions[];
      mySquads: filterDropdownOptions[];
    } = { meAndMySquads: false, mySquads: [], users: [], squads: [], myUsers: [] };
    if (!Array.isArray(filterDatalist?.assignedTo)) {
      assignedTo = {
        users:
          filterDatalist?.assignedTo?.users?.filter(item => entityList.includes(item.value)) ?? [],
        squads:
          filterDatalist?.assignedTo?.squads?.filter(item => entityList.includes(item.value)) ?? [],
        myUsers:
          filterDatalist?.assignedTo?.myUsers?.filter(item => entityList.includes(item.value)) ??
          [],
        mySquads:
          filterDatalist?.assignedTo?.mySquads?.filter(item => entityList.includes(item.value)) ??
          [],
        meAndMySquads: filterDatalist?.assignedTo?.meAndMySquads ?? false,
      };
    }
    entityList = getList('serviceOwner');
    const serviceOwner = {
      users:
        filterDatalist?.serviceOwner?.users?.filter(item => entityList.includes(item.value)) ?? [],
      squads:
        filterDatalist?.serviceOwner?.squads?.filter(item => entityList.includes(item.value)) ?? [],
      myUsers:
        filterDatalist?.serviceOwner?.myUsers?.filter(item => entityList.includes(item.value)) ??
        [],
      mySquads:
        filterDatalist?.serviceOwner?.mySquads?.filter(item => entityList.includes(item.value)) ??
        [],
      meAndMySquads: filterDatalist?.serviceOwner?.meAndMySquads ?? false,
    };

    entityList = getList('alert');
    const alert = filterDatalist.alertSourceList.filter(item => entityList.includes(item.value));

    entityList = getList('sloList');
    const sloList = filterDatalist.slolist.filter(item => entityList.includes(item.value));

    entityList = getList('priority');
    const priority = PRIORITY_OPTIONS.filter(item => entityList.includes(item.value));

    const customOption = CREATED_OPTIONS.find(opt => opt.value === DateFilterChoice.Range) ?? null;
    const created = query.get('dateFilter')
      ? CREATED_OPTIONS.filter(obj => {
          return obj.value === query.get('dateFilter');
        })[0]
      : query.get('startDate') && query.get('endDate')
      ? customOption
      : null;

    const isSlo = query.get('isSlo');
    const withRetro = query.get('withRetro');
    const withNotes = query.get('withNotes');
    const isStarred = query.get('isStarred');

    const startDate = query.get('startDate') ?? null;
    const endDate = query.get('endDate') ?? null;

    const tagsValueString = query.get('tagsValue');
    const tagsValue =
      typeof tagsValueString === 'string'
        ? (tagsValueString.split(',').map(tag => {
            return {
              label: tag,
              value: tag,
            };
          }) as filterDropdownOptions[])
        : [];

    const tagKeyString = query.get('tagsKey');
    const tagsKey =
      typeof tagKeyString === 'string'
        ? {
            label: tagKeyString,
            value: tagKeyString,
            child: tagsValue.map(tag => tag.value),
          }
        : null;
    const tags = [] as { key: filterDropdownOptions[]; value: filterDropdownOptions[] }[];
    const keyValue: filterDropdownOptions[] =
      query
        .get('keyValue')
        ?.split(',')
        .map(value => {
          return { label: value, value: value.split(':')[1] };
        }) || [];

    const lastNumberOfDays = query.get('lastNumberOfDays')
      ? parseInt(query.get('lastNumberOfDays') || '')
      : undefined;

    // remove all filters from URL
    queryUpdate(query, null, 'filter');
    queryUpdate(query, null, 'services');
    queryUpdate(query, null, 'assignedTo');
    queryUpdate(query, null, 'alert');
    queryUpdate(query, null, 'isSlo');
    queryUpdate(query, null, 'sloList');
    queryUpdate(query, null, 'created');
    queryUpdate(query, null, 'lastNumberOfDays');
    queryUpdate(query, null, 'startDate');
    queryUpdate(query, null, 'endDate');
    queryUpdate(query, null, 'withRetro');
    queryUpdate(query, null, 'withNotes');
    queryUpdate(query, null, 'tagsKey');
    queryUpdate(query, null, 'tagsValue');
    queryUpdate(query, null, 'keyValue');
    queryUpdate(query, null, 'isStarred');
    queryUpdate(query, null, 'priority');

    const filter = {
      assignedTo,
      created,
      services,
      alert,
      isSlo,
      withRetro,
      withNotes,
      isStarred,
      sloList,
      startDate,
      endDate,
      retroStatus: [],
      tagsKey,
      tagsValue,
      keyValue,
      lastNumberOfDays,
      priority,
      serviceOwner,
      temprorayServices: [],
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filterManager.applyFilters(filter, queryClient);
  }
};
