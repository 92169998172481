import {
  allOperators,
  booleanComparators,
  numberComparators,
  stringComparators,
} from 'core/const/comparators';
import { T_WA_GS_DEDUPLICATION_RULES_MODIFIED } from 'core/const/tracker';
import { deepCopy, findGracefully, pick } from 'core/helpers';
import { IAppState } from 'core/interfaces/IAppState';
import { IAlertSource } from 'core/interfaces/IIntegration';
import { BillingService, DeduplicationRulesService, ServiceService } from 'core/services';
import equal from 'fast-deep-equal/es6/react';
import React from 'react';
import { connect } from 'react-redux';
import { AppTracker } from 'shared/analytics/tracker';
import {
  generateExpression,
  getLHSType,
} from 'views/main/organization/service-catalog/helpers/helper.automation-rule';
import {
  IDeduplicationRule,
  IRuleExpressionOperation,
  IRuleTimeUnit,
} from 'views/main/organization/service-catalog/Interfaces/automation-rule';
import { RoutingRules } from '..';
import { AutomationRuleContext, AutomationRuleContextProps } from '../context';

import render from './render.index';

interface IIndexedDeduplicationRule extends IDeduplicationRule {
  id: number;
  rule_id: string;
  existing: boolean;
}

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  hide: () => void;
  serviceId: string;
  checkAndSetDirty: () => void;
  refetchRule: () => void;
  editRuleId?: string;
  rules?: IDeduplicationRule[];
}

interface IState {
  rules: IIndexedDeduplicationRule[];
  saving: boolean;
  loading: boolean;
  networkError: string;
  eventState: 'loading' | 'noEvents' | 'idle' | 'default';
  event: any;
  alertSourceSearch: string;
  alertSource: IAlertSource | null;
  alertSourcesLoadState: 'loading' | 'success' | 'error';
  alertSourceErrorMessage: string;
  warningIndex: number | null;
  errors: string[];
  searchString: { [key: string]: string };
  swappedRuleIndexes: number;
  globalSearch: string;
  showUpgradeModal: boolean;
  planRuleLimitExceeded: boolean;
  orgDeduplicationRulesCount: number;
  existingDeleted: number;
  editRuleId: string | undefined;
}
export class DeduplicationRulesModal extends React.Component<
  IProps,
  IState,
  AutomationRuleContextProps
> {
  static contextType = AutomationRuleContext;
  // replaced context! with declare context, please verify this in PR
  declare context: React.ContextType<typeof AutomationRuleContext>;

  private ServiceService = new ServiceService(this.props.serviceId);
  private DeduplicationRulesService = new DeduplicationRulesService(this.props.serviceId);

  public _service = this.props.organization.services.s.find(s => s.id === this.props.serviceId);

  public alertSources: IAlertSource[] = this.props.integrations.i
    .filter(as => !as.hideAutomation && !as.deprecated)
    .map((as: any) => ({
      isActive: false,
      ...as,
    }));

  public _comparators: any = {
    string: stringComparators,
    number: numberComparators,
    boolean: booleanComparators,
    all: allOperators,
  };

  private initialState?: IState;

  constructor(props: IProps) {
    super(props);
    this.state = {
      rules: [],
      saving: false,
      loading: true,
      alertSourcesLoadState: 'loading',
      alertSourceErrorMessage: '',
      networkError: '',
      eventState: 'default',
      event: {},
      alertSourceSearch: '',
      alertSource: null,
      warningIndex: null,
      errors: [],
      searchString: {},
      swappedRuleIndexes: -1,
      globalSearch: '',
      planRuleLimitExceeded: false,
      showUpgradeModal: false,
      orgDeduplicationRulesCount: 0,
      existingDeleted: 0,
      editRuleId: props.editRuleId,
    };
  }

  public render = render;

  public async componentDidMount() {
    this.getActiveAlertSources();
    await this.getDeduplicationRules();
    await this.updateOrgDedupRulesCount();

    if (!this.props.editRuleId) {
      this.checkPlanLimitsAndAddNewRule();
    }
  }

  updatePlanLimitExceeded = () => {
    this.setState({
      planRuleLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'deduplication-rules',
        () =>
          this.state.rules.filter(r => r.existing === false).length +
          this.state.orgDeduplicationRulesCount -
          this.state.existingDeleted,
      ),
    });
  };

  public updateOrgDedupRulesCount = async () => {
    try {
      const {
        data: {
          data: { count: orgCount },
        },
      } = await this.DeduplicationRulesService.getOrgCount();
      await this.setState({ orgDeduplicationRulesCount: orgCount });
      this.updatePlanLimitExceeded();
    } catch (err: any) {
      console.log(err);
    }
  };

  public getDeduplicationRules = async () => {
    try {
      const responseRules = this.props.rules ?? [];
      this.setState(
        {
          rules: responseRules.map((r: any, id: number) => {
            return {
              ...r,
              id: id,
              rule_id: r.rule_id,
              existing: true,
              basic_expression: r.is_basic ? r.basic_expression : [],
            };
          }),
          loading: false,
          networkError: '',
        },
        () => (this.initialState = deepCopy(this.state)),
      );
    } catch (err: any) {
      this.setState({
        networkError: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }
  };

  public getActiveAlertSources = async () => {
    try {
      const { data } = await this.ServiceService.getActiveAlertSources();
      const activeAlertSources = data.data as string[];

      this.alertSources.forEach((as: IAlertSource) => {
        if (activeAlertSources.includes(as._id)) {
          as.isActive = true;
        }
      });

      // sorting according to type
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        const firstAlertSourceName = as1.type ?? '';
        const secondAlertSourceName = as2.type ?? '';
        if (!firstAlertSourceName || !secondAlertSourceName) return 0;
        if (firstAlertSourceName.toLowerCase() < secondAlertSourceName.toLowerCase()) {
          return -1;
        }
        if (firstAlertSourceName.toLowerCase() > secondAlertSourceName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      // sorting according to active status
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        if (as1.isActive && !as2.isActive) {
          return -1;
        }
        if (!as1.isActive && as2.isActive) {
          return 1;
        }
        return 0;
      });
      const as = this.alertSources.filter((a: IAlertSource) => a.isActive);
      if (as.length === 1) {
        this.setState({ alertSourcesLoadState: 'success' });
        this.getAlertSourceConfig(as[0]);
        return;
      }
      this.setState({ alertSourcesLoadState: 'success' });
    } catch (err: any) {
      this.setState({
        alertSourceErrorMessage: `Network Error: ${
          err?.response?.data?.meta?.error_message ?? 'Network Error'
        }`,
        alertSourcesLoadState: 'error',
      });
    }
  };

  public getAlertSourceConfig = async (alertSource: IAlertSource) => {
    this.setState({
      alertSource,
      eventState: 'loading',
    });
    try {
      const eventResponse = await this.ServiceService.getAlertSourceLatestEvent(alertSource._id);
      const pastTags = eventResponse.data.data.tags || {};
      Object.keys(pastTags).forEach(k => (pastTags[k] = pastTags[k].value));
      this.setState({
        eventState: 'idle',
        event: {
          event_count: 5,
          past: eventResponse.data.data.payload,
          past_source: alertSource.shortName,
          past_tags: pastTags,
          past_incident: {
            message: eventResponse.data.data.message,
            is_triggered: false,
            is_suppressed: false,
            is_acknowledged: true,
            is_dependency: true,
            service: findGracefully(
              this.props.organization.services.s,
              'id',
              'unknown-service',
            )(eventResponse.data.data.service_id, 'slug'),
          },
        },
      });
    } catch (err: any) {
      this.setState({
        eventState: 'noEvents',
        event: {},
      });
    }
  };

  public checkPlanLimitsAndAddNewRule = async () => {
    if (this.state.planRuleLimitExceeded) {
      this.setState({ showUpgradeModal: true });
    } else {
      await this.addNewRule();
      this.updatePlanLimitExceeded();
    }
  };

  public addNewRule = async () => {
    const rules = this.state.rules;
    rules.push({
      id: rules.length,
      rule_id: '',
      expression: '',
      time_window: 1,
      time_unit: IRuleTimeUnit.Hour,
      is_basic: true,
      basic_expression: [
        {
          lhs: '',
          op: IRuleExpressionOperation.EOpNoOp,
          rhs: '',
        },
      ],
      dependency_deduplication: false,
      description: '',
      existing: false,
    });
    this.setState({
      rules,
    });
  };

  public verify = () => {
    const errors: string[] = [];
    const { rules } = this.state;
    let firstErrIndex = -1;

    rules
      .filter(rule =>
        this.props.editRuleId ? rule.rule_id === this.props.editRuleId : !rule.existing,
      )
      .forEach((rule, index) => {
        if (!rule.is_basic) {
          if (rule.expression.trim() === '') {
            errors.push(`rules[${index}].expression`);
          }
        } else {
          rule.basic_expression.forEach((be, beIndex) => {
            if (be.lhs.trim() === '') {
              errors.push(`rules[${index}].basic_expression[${beIndex}].lhs`);
            }

            if (be.op === IRuleExpressionOperation.EOpNoOp) {
              errors.push(`rules[${index}].basic_expression[${beIndex}].op`);
            }

            if (be.rhs === '') {
              errors.push(`rules[${index}].basic_expression[${beIndex}].rhs`);
            }
          });
        }

        if (Number.isNaN(rule.time_window) || rule.time_window <= 0) {
          errors.push(`rules[${index}].time_window`);
        }

        if (errors.length !== 0 && firstErrIndex === -1) {
          firstErrIndex = index;
        }
      });

    this.setState({ errors });
    return errors;
  };

  public save = async () => {
    this.setState({ errors: [], saving: true });
    const errors = this.verify();
    if (errors.length > 0) {
      this.setState({ saving: false });
      return;
    }

    const { rules } = this.state;
    const { editRuleId } = this.props;
    const rule = this.state.rules.find((r, i, arr) =>
      editRuleId ? r.rule_id === editRuleId : i === arr.length - 1,
    );

    try {
      // await this.DeduplicationRulesService.save(rules);

      if (!rule) {
        throw new Error('Rule not defined');
      }

      if (editRuleId && rule) {
        await this.context?.updateRule.mutateAsync({
          ruleType: RoutingRules.deduplication,
          ruleId: rule.rule_id,
          ruleDetail: rule,
        });
      } else {
        await this.context?.createRule.mutateAsync({
          ruleType: RoutingRules.deduplication,
          ruleDetail: rule,
        });
      }

      this.setState({
        rules: rules.map(r => {
          return { ...r, existing: true };
        }),
        existingDeleted: 0,
      });
      this.setState({ saving: false, networkError: '' });
      this.props.hide();

      this.props.refetchRule();
    } catch (err: any) {
      this.setState({
        saving: false,
        networkError: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }

    AppTracker.track(T_WA_GS_DEDUPLICATION_RULES_MODIFIED, {
      'Deduplication Alert Source': this.state.alertSource?.type,
    });
  };

  onSelectChange = (_: any, alertSource: IAlertSource) => {
    this.getAlertSourceConfig(alertSource);
  };

  onTextChange = (type: 'alertSourceSearch') => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [type]: event.target.value });
  };

  openWarning = (ruleIndex: number) => () => this.setState({ warningIndex: ruleIndex });

  closeWarning = (flag: boolean) => () => {
    if (!flag) {
      this.setState({ warningIndex: null });
    } else {
      this.setState(({ rules, warningIndex }) => {
        const rule = rules[warningIndex as number];
        rule.is_basic = false;
        rule.expression = generateExpression(rule.basic_expression, this.getCurrentEvent());
        rule.basic_expression = [];
        return { rules, warningIndex: null };
      });
    }
  };

  public onRuleExpressionChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      this.setState(({ rules }) => {
        rules[index].expression = value;
        return { rules };
      });
    };

  public onDescriptionChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(({ rules }) => {
      rules[index].description = value;
      return { rules };
    });
  };

  changeBasicExpressionLHS = (ruleIndex: number, beIndex: number) => (_: any, value: any) => {
    this.setState(({ rules }) => {
      const be = rules[ruleIndex].basic_expression[beIndex];
      be.lhs = value;
      be.op = IRuleExpressionOperation.EOpNoOp;
      be.rhs = '';
      return { rules };
    });
  };

  changeBasicExpressionOperator = (ruleIndex: number, beIndex: number) => (_: any, value: any) => {
    this.setState(({ rules }) => {
      const be = rules[ruleIndex].basic_expression[beIndex];
      if (
        be.op !== IRuleExpressionOperation.EOpFieldIs &&
        value === IRuleExpressionOperation.EOpFieldIs
      ) {
        switch (true) {
          case be.lhs === 'source':
            be.rhs = 'past_source';
            break;
          case be.lhs.startsWith('tags'):
            be.rhs = 'past_' + be.lhs;
            break;
          default:
            be.rhs = be.lhs.replace(/^current/, 'past');
        }
      } else if (
        be.op === IRuleExpressionOperation.EOpFieldIs &&
        value !== IRuleExpressionOperation.EOpFieldIs
      ) {
        be.rhs = '';
      }
      be.op = value;
      return { rules };
    });
  };

  changeBasicExpressionRHS = (ruleIndex: number, beIndex: number) => (_: any, value: any) => {
    this.setState(({ rules }) => {
      const be = rules[ruleIndex].basic_expression[beIndex];
      be.rhs = value;
      return { rules };
    });
  };

  changeRuleSelectBox = (ruleIndex: number, field: 'time_unit') => (_: any, value: any) => {
    this.setState(({ rules }) => {
      rules[ruleIndex][field] = value;
      return { rules };
    });
  };

  changeConditionInputBox =
    (ruleIndex: number, beIndex: number, field: 'rhs') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value: any = event.target.value;

      this.setState(({ rules }) => {
        const lhs: string = rules[ruleIndex].basic_expression[beIndex].lhs;
        value =
          getLHSType(lhs, this.getCurrentEvent()) === 'number' ? parseFloat(value) || '' : value;
        rules[ruleIndex].basic_expression[beIndex][field] = value;
        return { rules };
      });
    };

  changeRuleInputBox =
    (ruleIndex: number, field: 'time_window', type: 'number') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value: any = event.target.value;
      if (type === 'number') {
        value = Math.abs(parseInt(value, 10));
      }

      this.setState(({ rules }) => {
        rules[ruleIndex][field] = value;
        return { rules };
      });
    };

  toggleDependencyDeduplication = (ruleIndex: number) => () => {
    this.setState(({ rules }) => {
      rules[ruleIndex].dependency_deduplication = !rules[ruleIndex].dependency_deduplication;
      return { rules };
    });
  };

  setEntitySearch =
    (entitiyType: string, ruleIndex: number, beIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      this.setState(({ searchString }) => {
        searchString[`${entitiyType}_${ruleIndex}_${beIndex}`] = value;
        return { searchString };
      });
    };

  addCondition = (ruleIndex: number) => () => {
    this.setState(({ rules }) => {
      rules[ruleIndex].basic_expression.push({
        lhs: '',
        op: IRuleExpressionOperation.EOpNoOp,
        rhs: '',
      });

      return { rules };
    });
  };

  removeCondition = (ruleIndex: number, beIndex: number) => () => {
    this.setState(({ rules }) => {
      rules[ruleIndex].basic_expression.splice(beIndex, 1);
      return { rules };
    });
  };

  getCurrentEvent = () => {
    const event = { ...this.state.event };
    if (event) {
      event.current = event.past;
      delete event.past;

      event.source = event.past_source;
      delete event.past_source;

      event.tags = event.past_tags;
      delete event.past_tags;
    }

    return event;
  };

  getComparators = (lhs: string) => {
    let comparators = this._comparators[getLHSType(lhs, this.getCurrentEvent())];
    if (lhs === 'source' || lhs.startsWith('tags') || lhs.startsWith('current')) {
      comparators = {
        ...comparators,
        ...{
          [IRuleExpressionOperation.EOpFieldIs]: 'is same as',
        },
      };
    }
    return comparators;
  };

  swapRules = (i: number, j: number) => () => {
    this.setState(({ rules }) => {
      [rules[i], rules[j]] = [rules[j], rules[i]];
      return { rules, swappedRuleIndexes: j };
    });

    setTimeout(() => {
      this.setState({ swappedRuleIndexes: -1 });
    }, 1000);
  };

  componentDidUpdate() {
    if (this.initialState && !equal(pick(this.state, 'rules'), pick(this.initialState, 'rules')))
      this.props.checkAndSetDirty();
  }
}

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(DeduplicationRulesModal);
