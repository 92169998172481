import { API } from 'core/api';
import {
  ExportParams,
  GetAuditLogsHistoryParamsType,
  GetAuditLogsParamsType,
} from 'views/main/organization/settings/audit-logs/types/index.auditLogs';
import axios from 'axios';

class AuditLogsService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/audit-logs`;

  public getAuditLogs = (auditLogsParams: GetAuditLogsParamsType) =>
    axios.get(`${this._api}`, { params: auditLogsParams });

  public getAuditLogsById = (auditLogsId: string) => axios.get(`${this._api}/${auditLogsId}`, {});

  public getExportHistory = (exportHistoryParams: GetAuditLogsParamsType) =>
    axios.get(`${this._api}/export/history`, { params: exportHistoryParams });

  public exportLogsData = (exportFiltersParams: ExportParams) =>
    axios.post(`${this._api}/export`, {
      ...exportFiltersParams,
    });

  public getFilters = () => axios.get(`${this._api}/filters`);
}

export default AuditLogsService;
