import { Box } from '@chakra-ui/layout';
import { useMemo } from 'react';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';

import { SQ_ACTIONS } from '../../../constant';
import { SubActionTypes } from '../../../types';
import ActionItem from './ActionItem';

const SqActionList = () => {
  const { handleSubActionClick, actions, triggerType } = useWorkflowFormContext();
  const addedActionTypes: Array<SubActionTypes> = useMemo(
    () => actions.map(action => action.name),
    [actions],
  );

  const availableActions = useMemo(
    () =>
      SQ_ACTIONS.filter(
        action => !action.disabledForTriggers?.includes(triggerType) && !action.disabled,
      ),
    [triggerType],
  );
  return (
    <Box>
      {availableActions.map(action => (
        <ActionItem
          action={action}
          onClick={handleSubActionClick}
          key={action.name}
          isAdded={addedActionTypes.includes(action.name)}
        />
      ))}
    </Box>
  );
};

export default SqActionList;
