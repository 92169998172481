import React from 'react';
import { OrganizationSettingsTeamsRenderRoles } from '.';
import { DefaultIcon, MoreVerticalIcon } from '../../../../../../../icons';
import { hasProperty, sortOperationRole } from 'core/helpers';
import cx from 'classnames';
import {
  CardBlock,
  CellBlock,
  Checkbox,
  DialogModalFrame,
  DropDown,
  DropDownOptionShell,
  ErrorBlock,
  FormBlock,
  Grid,
  Heading,
  IconButton,
  InputBlock,
  Label,
  Para,
  SpinLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import ConfirmModal from '../../../../../../../components/confirm.modal';
import { ReduceEntity } from '../../../../../../../core/interfaces/IHelpers';
import { ITeamRoles } from '../../../../../../../core/interfaces/ITeams';
import { defaultTeamAbilities } from './const';
import RouteLeavingGuard from 'components/UnsavedChangeNavigation';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { Note } from '../../../shared/note';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { IOrgConfig } from 'core/interfaces/IOrgConfig';
import { IAppState } from 'core/interfaces/IAppState';

const ABILITY_NAME: Record<string, string> = {
  slos: 'SLO(s)',
  'status pages': 'Status Page',
  ger: 'Global Event Rules',
};

function EditRole(this: OrganizationSettingsTeamsRenderRoles, role: Partial<ITeamRoles>) {
  const { theme } = Theme;
  const { networkState, errors } = this.state;
  const orgConfig = this.props.organization.currentOrg.o?.config || {};

  return (
    <CardBlock
      key={role.id || -1}
      className="mb-10"
      padding="8px"
      borderStyle="sharp"
      animateOnHover
    >
      <Grid type="column" width="700px">
        <Heading fontSize={16} height={24}>
          {role.id ? 'Edit' : 'New role'}
        </Heading>

        <FormBlock onFormSubmit={role.id ? this.onUpdateRole : this.onCreateNewRole}>
          <div className="mt-10">
            <Label fontSize={14}>Name</Label>
            <div className="mt-5">
              <InputBlock
                height="32px"
                width="240px"
                padding="4px"
                placeholder="Role name"
                value={role.name}
                onChange={role.id ? this.onEditRoleNameChange : this.onNewRoleNameChange}
                error={!!(errors.new_role__name || errors.edit_role__name)}
              />
              <ErrorBlock>{errors.new_role__name || errors.edit_role__name}</ErrorBlock>
            </div>
          </div>

          <div className="mt-10">
            <Label fontSize={14}>Entities</Label>
            {Object.entries(defaultTeamAbilities(orgConfig as IOrgConfig)).map(([e, o], i) => {
              const entity = e.replace('_', ' ');
              return (
                <Grid
                  flexWidth={10}
                  key={i}
                  className="organization_settings_team_roles__ability"
                  alignItems="center"
                >
                  <Grid flexWidth={4} alignItems="center">
                    <Para fontSize={14} className="global--capitalize-first-letter">
                      {ABILITY_NAME[entity] ?? entity}
                    </Para>
                  </Grid>
                  <Grid flexWidth={8} alignItems="center" flexWrap="wrap">
                    {Object.entries(o)
                      .sort(sortOperationRole)
                      .map(([op, _], j: number) => {
                        const operations = op.split('-')[0];
                        return (
                          <CellBlock key={j} className="mr-10" color={theme.shades.cement}>
                            <label>
                              <Grid alignItems="center">
                                <Para fontSize={14} className="mr-10">
                                  {operations}
                                </Para>
                                <Checkbox
                                  base="14px"
                                  style={{ marginTop: 4 }}
                                  checked={hasProperty((role.abilities as any)[e], op)}
                                  onChange={
                                    role.id
                                      ? this.onEditRoleOperationChange(e, op)
                                      : this.onNewRoleOperationChange(e, op)
                                  }
                                />
                              </Grid>
                            </label>
                          </CellBlock>
                        );
                      })}
                  </Grid>
                </Grid>
              );
            })}
            <ErrorBlock>{errors.new_role__abilities || errors.edit_role__abilities}</ErrorBlock>
          </div>
        </FormBlock>

        <div className="mt-10">
          <ErrorBlock>{errors.network_err}</ErrorBlock>
          <Grid alignItems="center">
            <TextButton
              onClick={role.id ? this.onUpdateRole : this.onCreateNewRole}
              color={theme.primary.default}
              disabled={networkState === 'save'}
              height="26px"
            >
              <Grid alignItems="center">
                <Para color={theme.font.white}>Save</Para>
                {networkState === 'save' && (
                  <SpinLoader className="ml-10" color={theme.shades.white} base="8px" />
                )}
              </Grid>
            </TextButton>
            <TextButton
              onClick={this.onCancelEditOrSaveRole}
              buttonType="ghost"
              className="ml-10"
              disabled={networkState === 'save'}
            >
              <Para color={theme.font.default}>Cancel</Para>
            </TextButton>
          </Grid>
        </div>
      </Grid>
    </CardBlock>
  );
}

export function render(this: OrganizationSettingsTeamsRenderRoles) {
  const { theme } = Theme;
  const { team, search } = this.props;
  const orgConfig = this.props.organization.currentOrg.o?.config || {};
  const { editRoleId, deleteRoleId, newRole, errors, networkState, editRole } = this.state;

  const _uA = this.props.userAccess;
  const hasRead = _uA.hasReadAccess('teams');
  const hasUpdate = _uA.hasUpdateAccess('teams');
  const hasDelete = _uA.hasDeleteAccess('teams');
  const hasCreate = _uA.hasCreateAccess('teams');

  const _tRoles = team.roles.reduce((c: ReduceEntity<ITeamRoles, 'id' | 'name' | 'default'>, r) => {
    c[r.id] = {
      id: r.id,
      name: r.name,
      default: r.default,
    };
    return c;
  }, {});

  const filteredRoles = team.roles.filter(r => r.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <>
      <div className="organization_settings_team_roles__container mb-20">
        {filteredRoles.length === 0 && (
          <div>
            <Para fontSize={16} color={theme.font.label}>
              Search did not match any role
            </Para>
          </div>
        )}
        {filteredRoles.length > 0 && (
          <div>
            <Para style={{ marginBottom: '10px', fontSize: '12px', color: '#808080' }}>
              Roles help you assign permissions for features within Squadcast. It is team-specific.
              Learn more about roles{' '}
              <a
                href="https://support.squadcast.com/manage-teams/role-based-access-control"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </Para>
            <Para
              fontSize={12}
              color={theme.font.label}
              style={{ fontStyle: 'italic', paddingBottom: 10 }}
            >
              {`${filteredRoles.length} Roles in this team`}
            </Para>
          </div>
        )}
        {filteredRoles.map(r => {
          if (editRoleId === r.id && editRole !== null) {
            return EditRole.call(this, editRole);
          }

          return (
            <CardBlock
              key={r.id}
              className={cx('mb-10', { organization_settings_team_roles__disabled: r.default })}
              padding="8px"
              borderStyle="sharp"
              animateOnHover
            >
              <Grid type="column" width="700px">
                <Grid flexWidth={12} alignItems="center" justifyContent="space-between">
                  <Tooltip
                    label="Default role, cannot be assigned to stakeholders"
                    offset={{ top: '-50px', left: '150px' }}
                    disabled={!r.default}
                  >
                    <Grid type="column">
                      <Grid alignItems="center">
                        <Para fontSize={16} fontWeight={400}>
                          {r.name}
                        </Para>
                        {r.default && (
                          <DefaultIcon
                            className="ml-10"
                            height={12}
                            width={12}
                            color={theme.shades.grey}
                          />
                        )}
                      </Grid>
                      {r.default && (
                        <Para fontSize={12} className="mt-5 mb-5" color={theme.shades.cement}>
                          This role will allow you to manage just this team
                        </Para>
                      )}
                    </Grid>
                  </Tooltip>

                  <div>
                    {!r.default && editRoleId === '' && !newRole && (
                      <DropDown
                        hook={
                          <IconButton>
                            <MoreVerticalIcon
                              height="14px"
                              width="14px"
                              stroke={theme.shades.cement}
                            />
                          </IconButton>
                        }
                        width="100px"
                        maxWidth="100px"
                        height="auto"
                      >
                        <div>
                          <NoPermissionTooltip isDisabled={hasUpdate}>
                            <DropDownOptionShell
                              onClick={this.onEditRole(r.id)}
                              padding="8px"
                              disabled={!hasUpdate}
                            >
                              <Para>Edit</Para>
                            </DropDownOptionShell>
                          </NoPermissionTooltip>
                          <NoPermissionTooltip isDisabled={hasDelete}>
                            <DropDownOptionShell
                              padding="8px"
                              onClick={this.onToggleDelete(r.id)}
                              disabled={!hasDelete}
                            >
                              <Para color={theme.danger.default}>Delete</Para>
                            </DropDownOptionShell>
                          </NoPermissionTooltip>
                        </div>
                      </DropDown>
                    )}
                  </div>
                </Grid>
                <div>
                  {Object.entries(r.abilities).map(([e, o], i) => {
                    if (
                      e === 'team_analytics' &&
                      !(orgConfig as IOrgConfig)?.['analyticsv2.enabled']
                    ) {
                      return null;
                    }
                    const entity = e.replace(/_/g, ' ');
                    const operations = Object.entries(o).sort(sortOperationRole);
                    return (
                      <Grid
                        flexWidth={10}
                        key={i}
                        className="organization_settings_team_roles__ability"
                        alignItems="center"
                      >
                        <Grid flexWidth={4} alignItems="center">
                          <Para fontSize={14} className="global--capitalize-first-letter">
                            {ABILITY_NAME[entity] ?? entity}
                          </Para>
                        </Grid>
                        <Grid flexWidth={8} alignItems="center" flexWrap="wrap">
                          {operations.map(([op, _], j: number) => {
                            const operations = op.split('-')[0];
                            return (
                              <CellBlock key={`${j}`} className="mr-10" color={theme.shades.cement}>
                                <Para fontSize={14}>{operations}</Para>
                              </CellBlock>
                            );
                          })}
                          {operations.length === 0 && (
                            <Para fontSize={14} height="24px" color={theme.font.disabled}>
                              No operations assigned
                            </Para>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </Grid>
            </CardBlock>
          );
        })}
        {newRole !== null && EditRole.call(this, newRole)}
        <div className="mt-20 pb-200">
          {editRoleId === '' && newRole === null && hasUpdate && (
            <TextButton onClick={this.onAddNewRole}>
              <Para color={theme.font.white}>Add new team role</Para>
            </TextButton>
          )}
        </div>
      </div>
      <DialogModalFrame
        id="organization_team_settings__delete_role"
        onClose={this.onToggleDelete('')}
        width="400px"
      >
        {deleteRoleId.length > 0 && (
          <ConfirmModal
            displayText={
              <div>
                <Para>
                  Delete{' '}
                  <span className="font-bold">{_tRoles[deleteRoleId]?.name ?? 'Deleted'}</span> Role
                  from <span className="font-bold">{team.name}</span> team?
                </Para>
                {_tRoles[deleteRoleId]?.name !== 'stakeholder' && <Note isConfirmationModal />}
                <div className="mt-5">
                  <ErrorBlock>{errors.delete_role}</ErrorBlock>
                </div>
              </div>
            }
            buttonText={`Delete ${_tRoles[deleteRoleId]?.name ?? ''} Role`}
            primaryButtonColor={theme.danger.default}
            isBusy={networkState === 'save'}
            cancelText="No"
            hide={this.onHideConfirmDelete}
          />
        )}
      </DialogModalFrame>
      <UpgradePlanModal
        showModal={this.state.showDefaultRBACUpgradeModal || this.state.showCustomRbacUpgradeModal}
        message={
          this.state.showDefaultRBACUpgradeModal
            ? BillingService.getMessage(0, 'modify-default-rbac', this.props)
            : BillingService.getMessage(0, 'custom-rbac', this.props)
        }
        onCancel={() => {
          this.setState({ showDefaultRBACUpgradeModal: false, showCustomRbacUpgradeModal: false });
        }}
        header={
          this.state.showDefaultRBACUpgradeModal
            ? BillingService.getHeader(0, 'modify-default-rbac', this.props)
            : BillingService.getHeader(0, 'custom-rbac', this.props)
        }
        onUpgrade={() =>
          this.setState({ showDefaultRBACUpgradeModal: false, showCustomRbacUpgradeModal: false })
        }
        hasBillingPermission={BillingService.hasManageBillingPermission({
          organization: this.props.organization,
        })}
      />
      <RouteLeavingGuard
        when={this.state.hasUnsavedChanged}
        shouldBlockNavigation={location => {
          if (this.state.hasUnsavedChanged) return true;
          return false;
        }}
        navigate={path => this.props.history.push(path)}
      />
    </>
  );
}
