import { SubActionTypes, WorkflowTriggerTypes } from '../types';

export const ANALYTICS_TRIGGER_MAP = {
  [WorkflowTriggerTypes.INCIDENT_ACKNOWLEDGED]: 'Incident Acknowoedged',
  [WorkflowTriggerTypes.INCIDENT_REASSIGNED]: 'Incident Reassigned',
  [WorkflowTriggerTypes.INCIDENT_TRIGGERED]: 'Incident Triggered',
  [WorkflowTriggerTypes.INCIDENT_RESOLVED]: 'Incident Resolved',
  [WorkflowTriggerTypes.INCIDENT_TAGS_UPDATED]: 'Incident Tags Updated',
  [WorkflowTriggerTypes.INCIDENT_PRIORITY_UPDATED]: 'Incident Priority Updated',
  [WorkflowTriggerTypes.INCIDENT_NOTE_ADDED]: 'Incident Note Added',
};

export const ANALYTICS_ACTION_MAP = {
  [SubActionTypes.COMMUNICATION_CHANNEL]: 'Add Communication Channel',
  [SubActionTypes.INCIDENT_NOTE]: 'Add Incident Notes',
  [SubActionTypes.RESOLVE_INCIDENT]: 'Resolve Incident',
  [SubActionTypes.SLO_AFFECTED]: 'Mark Incident as SLO Affecting',
  [SubActionTypes.HTTP_CALL]: 'Make a HTTP Call',
  [SubActionTypes.RUNBOOKS]: 'Attach Runbook',
  [SubActionTypes.REASSIGN_INCIDENT]: 'Reassign Incident',
  [SubActionTypes.SEND_EMAIL]: 'Send Email',
  [SubActionTypes.TRIGGER_MANUAL_WEBHOOK]: 'Trigger Manual Webhook',
  [SubActionTypes.PRIORITY]: 'Update Priority',
  [SubActionTypes.STATUS_PAGE]: 'Add Status Page Issue',
  [SubActionTypes.SLACK_CHANNEL_ARCHIEVE]: 'Archive Incident Specific Slack Channel',
  [SubActionTypes.SLACK_CHANNEL_CREATE]: 'Create Incident Specific Slack Channel',
  [SubActionTypes.SLACK_MESSAGE_CHANNEL]: 'Send Slack Message to Specific Slack Channel',
  [SubActionTypes.SLACK_MESSAGE_USER]: 'Send Slack DM to User',
  [SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL]: 'Send MS Teams Message to Channel',
  [SubActionTypes.MS_TEAMS_MESSAGE_USER]: 'Send MS Teams Message to User',
  [SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK]: 'Create MS Teams meeting link',
  [SubActionTypes.JIRA_CREATE_TICKET]: 'Create Jira Ticket',
};
