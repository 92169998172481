import { FormikValues } from 'formik';
import { AssigneeType } from 'library/enums';
import { Owner } from '../../owner-filters/hooks/useEntityOwnerFilter';

import { INCIDENT_TAGS } from '../common/types';
import {
  GetIncidentActiveTagsQuery,
  GetIncidentAssignableEntitiesQuery,
  ListIncidentGroupsQuery,
} from '../graphql/query';

export interface filterDropdownOptions {
  label: string;
  value: string;
  assigneeType?: AssigneeType;
  username?: string | null;
  imageUrl?: string;
  subFeilds?: any;
}

export interface Option {
  label: string;
  value: string;
  type: Owner;
  username?: string;
  members?: string[];
}

export interface FormValues extends FormikValues {
  title: string;
  description?: string;
  services: filterDropdownOptions | null;
  assignedkey: filterDropdownOptions | null;
  assigned: filterDropdownOptions | null;
  tags?: INCIDENT_TAGS[];
  priority: filterDropdownOptions | null;
}

export interface IncidentParamsType {
  isLoading: boolean;
  tagsData: {
    label: string;
    value: string;
    child: string[];
  }[];
  users: filterDropdownOptions[];
  squads: filterDropdownOptions[];
  escalationPolicy: filterDropdownOptions[];
  slolist: filterDropdownOptions[];
  alertSourceList: filterDropdownOptions[];
  serviceListForFilters: filterDropdownOptions[];
  serviceList: filterDropdownOptions[];
  assigneeList: GetIncidentAssignableEntitiesQuery | undefined;
  tagData: GetIncidentActiveTagsQuery | undefined;
  filterList: ListIncidentGroupsQuery | undefined;
  serviceOwner?: {
    meAndMySquads: boolean;
    users: any;
    squads: any;
    mySquad: any;
    myUsers: any;
  };
}

export const InitParams = {
  isLoading: false,
  tagsData: [],
  users: [],
  squads: [],
  escalationPolicy: [],
  slolist: [],
  alertSourceList: [],
  serviceListForFilters: [],
  serviceList: [],
  assigneeList: {
    getIncidentAssignableEntities: {
      meAndMySquads: false,
      users: [],
      squads: [],
      myUsers: [],
      mySquads: [],
      escalationPolicies: [],
    },
  },

  tagData: undefined,
  filterList: undefined,
  serviceOwner: {
    meAndMySquads: false,
    users: [],
    squads: [],
    mySquad: [],
    myUsers: [],
  },
};

export interface IncidentParamsContextType {
  params: IncidentParamsType;
}
