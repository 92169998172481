import { FormikProvider, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { Divider, Grid, HStack, Icon, ScaleFade, Slide, Text, VStack } from '@chakra-ui/react';
import * as Yup from 'yup';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { API } from 'core';

import Layout from 'components/chakra/Layout';
import Stepper from 'components/chakra/Stepper';
import { ScheduleActionTypes, schedulesTextCopy } from '../constants/schedules.copy';
import { errorMessages } from '../constants/errormessage';
import { INewSchedule, ISchedule } from '../interface/schedule';
import SchedulesCreate from './schedules.create';
import CustomizeRotationPattern from './schedules.customizerotations';
import SchedulesTemplate from './schedules.template';
import RouteGuard from 'components/chakra/RouteGuard';
import { SCHEDULES_V2_DETAIL_PATH, SCHEDULES_V2_PATH } from 'views/main/routes/routes';
import { useCreateSchedule } from '../hooks/useSaveSchedule';
import {
  checkDuplicateTags,
  defaultReactQueryConfig,
  formatScheduleRequest,
  mapFetchScheduleToNewSchedule,
} from '../helpers/helpers.schedule';
import { scheduleProperties } from '../constants/schedules.properties';
import { rotationProperties } from '../constants/rotations.properties';
import { rotationTemplates, schedulesColorPalette } from '../constants/schedules.rotation-template';
import produce from 'immer';
import {
  NewSchedule,
  NewTag,
  OwnerType,
  Tag,
  UpdateSchedule,
} from 'views/main/organization/schedules/graphql/types';
import { useScheduleActionsContext } from '../schedules.actions/context';
import {
  useGetActiveScheduleTagsQuery,
  useGetScheduleForEditActionQuery,
  useGetListOfSchedulesQuery,
} from 'views/main/organization/schedules/graphql/query';
import { reactQueryConfig, reactQueryConfigError } from '../helpers/helpers.schedule';
import { useUpdateScheduleMutation } from 'views/main/organization/schedules/graphql/mutation';
import Loader from 'components/chakra/Loader';
import { useGetAllPatternParticipants } from '../helpers/helpers.customrotations';
import { AppTracker } from 'shared/analytics/tracker';
import {
  T_WA_UP_SCHEDULE_V2_CREATION_STEP_2_PAGE_VIEWED,
  T_WA_UP_SCHEDULE_V2_CREATION_STEP_3_PAGE_VIEWED,
} from 'core/const/tracker';
import { participantGroupsValidationSchema } from '../validations/participantGroupsSchema';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { OverlayModal } from '../../service-catalog/common';
import { InfoIcon } from '@chakra-ui/icons';

function ScheduleAdd() {
  const history = useHistory();
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const isScheduleEditMode = !!scheduleId;
  const {
    scheduleAction,
    onActionClick,
    userHasUpdatePermission,
    reFetchSchedulesData,
    reFetchOncallUsersData,
  } = useScheduleActionsContext();
  const { mutateAsync: createSchedule } = useCreateSchedule();
  const allParticipants = useGetAllPatternParticipants();
  const queryClient = useQueryClient();
  const [steps, setSteps] = useState(
    isScheduleEditMode
      ? [
          { name: schedulesTextCopy.update.stepper.step1, active: true, toggable: true, index: 0 },
          { name: schedulesTextCopy.update.stepper.step2, active: true, toggable: true, index: 1 },
          { name: schedulesTextCopy.update.stepper.step3, active: true, toggable: true, index: 2 },
        ]
      : [
          { name: schedulesTextCopy.stepper.step1, active: true, toggable: true, index: 0 },
          { name: schedulesTextCopy.stepper.step2, active: false, toggable: false, index: 1 },
          { name: schedulesTextCopy.stepper.step3, active: false, toggable: false, index: 2 },
        ],
  );
  const { resetScheduleAction } = useScheduleActionsContext();
  const activeStep = useMemo(() => steps.findIndex(step => step.active), [steps]);
  const disabledSteps = useMemo(
    () => steps.filter(step => !step.toggable).map(step => step.index),
    [steps],
  );
  const organization = useSelector((state: IAppState) => state.organization);
  const [showInvalidTeamModal, setShowInvalidTeamModal] = useState<boolean>(false);

  useEffect(() => {
    !isScheduleEditMode && resetScheduleAction();
  }, [isScheduleEditMode]);

  const { mutateAsync: updateSchedule } = useUpdateScheduleMutation({
    ...reactQueryConfig.mutation.update,
    onSuccess: () => {
      reFetchSchedulesData();
      reFetchOncallUsersData();
      reactQueryConfig.mutation.update.onSuccess();
    },
  });

  const {
    data: { schedule } = {},
    isLoading,
    isSuccess,
  } = useGetScheduleForEditActionQuery(
    {
      ID: Number.parseInt(scheduleId),
    },
    {
      ...defaultReactQueryConfig,
      onError: isScheduleEditMode ? reactQueryConfigError('Get Schedule for Edit') : () => {},
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (
      isScheduleEditMode &&
      !!schedule?.teamID &&
      organization.selectedTeam.teamId !== schedule?.teamID
    ) {
      setShowInvalidTeamModal(true);
    }
  }, [organization.selectedTeam, schedule?.teamID]);

  const { data: allSchedules } = useGetListOfSchedulesQuery(
    {
      filters: { teamID: API.config.teamId },
    },
    {
      ...defaultReactQueryConfig,
      onError: reactQueryConfigError('Get Schedules'),
    },
  );

  const { data: allTagsData } = useGetActiveScheduleTagsQuery({ teamID: API.config.teamId });

  const allScheduleNames = useMemo(
    () => (allSchedules?.schedules ? allSchedules.schedules.map(sch => sch.name) : []),
    [allSchedules],
  );

  const allSchedulesTags = useMemo(() => {
    if (!allTagsData) return {};

    const result: Record<string, string[]> = {};

    allTagsData['listActiveScheduleTags'].forEach(item => {
      if (item) {
        result[item.key] = [...item.values];
      }
    });

    return result;
  }, [allTagsData]);

  const currentUser = useSelector((state: IAppState) => state.organization.currentUser.u);

  const defaultValuesWithTags = useMemo(() => {
    return {
      id: '',
      slug: '',
      orgId: '',
      name: '',
      timeZone: '',
      description: '',
      owner: { ID: currentUser?.id ?? '', type: OwnerType.User },
      rotations: [],
      tags: [
        { key: 'Environment', value: '', color: schedulesColorPalette[0].value },
        { key: 'Type', value: '', color: schedulesColorPalette[1].value },
      ],
      showGaps: false,
      tagsSource: allSchedulesTags,
    };
  }, [allSchedulesTags, currentUser]);

  useEffect(() => {
    if (schedule && !scheduleAction) {
      /*If the edit schedule was loaded directly via URL, this code block will
      set the schedule action to edit schedule*/
      onActionClick({
        type: ScheduleActionTypes.EDIT_SCHEDULE,
        params: {
          scheduleId: schedule.ID,
          scheduleName: schedule.name,
        },
      });
    }
  }, [schedule]);
  const scheduleToEdit = useMemo(() => {
    return {
      ...(mapFetchScheduleToNewSchedule(schedule, allParticipants) as ISchedule),
      tagsSource: allSchedulesTags,
    };
  }, [schedule, allSchedulesTags]);

  let validationSchema = {};

  switch (activeStep) {
    case 0:
      {
        validationSchema = {
          name: Yup.string()
            .required(errorMessages.schedule.name)
            .test({
              test: value =>
                value?.length ? value.length < scheduleProperties.name.maxLength : true,
              message: errorMessages.schedule.nameLength.replace(
                'NUM',
                `${scheduleProperties.name.maxLength}`,
              ),
            })
            .test({
              test: value =>
                scheduleToEdit && scheduleToEdit.name === value
                  ? true
                  : !allScheduleNames.includes(value ?? ''),
              message: errorMessages.schedule.duplicateName,
            }),
          timeZone: Yup.string().required(errorMessages.schedule.timezone),
          description: Yup.string().test({
            test: value =>
              value?.length ? value.length < scheduleProperties.description.maxLength : true,
            message: errorMessages.schedule.descriptionLength.replace(
              'NUM',
              `${scheduleProperties.description.maxLength}`,
            ),
          }),
          owner: Yup.object().shape({
            ID: Yup.string().required(errorMessages.schedule.owner),
          }),
          tags: Yup.array()
            .notRequired()
            .of(
              Yup.object().shape({
                key: Yup.string(),
                value: Yup.string(),
              }),
            )
            .test({
              test: arr => (arr?.length ? arr.every(tag => !!tag.key && !!tag.value) : true),
              message: errorMessages.schedule.tags,
            })
            .test({
              test: arr => !(arr ? checkDuplicateTags(arr as NewTag[]) : false),
              message: errorMessages.schedule.duplicateTags,
            }),
        };
      }
      break;
    case 2:
      {
        validationSchema = {
          rotations: Yup.array()
            .test({
              test: arr => {
                const isValidEndDate = arr?.every(rotation =>
                  !rotation.endsAfterIterations && rotation.endDate
                    ? rotation.startDate < rotation.endDate
                    : true,
                );
                return isValidEndDate ? isValidEndDate : false;
              },
              message: errorMessages.rotations.startEndDate,
            })
            .test({
              test: arr => {
                const rotationNames = arr?.map(r => r.name);
                const uniqueRotationNames = Array.from(new Set(rotationNames));
                return rotationNames?.length === uniqueRotationNames.length;
              },
              message: errorMessages.rotations.duplicateNames,
            })
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .required(errorMessages.rotations.name)
                  .test({
                    test: value =>
                      value?.length ? value.length < rotationProperties.name.maxLength : true,
                    message: errorMessages.rotations.nameLength.replace(
                      'NUM',
                      `${rotationProperties.name.maxLength}`,
                    ),
                  }),
                color: Yup.string().required(errorMessages.schedule.colorScheme),
                participantGroups: participantGroupsValidationSchema,
                startDate: Yup.date().required(),
              }),
            ),
        };
      }
      break;
  }

  const formik = useFormik<INewSchedule>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: isScheduleEditMode
      ? scheduleToEdit ?? defaultValuesWithTags
      : defaultValuesWithTags,
    onSubmit: async (values, { resetForm, setSubmitting, setFieldValue }) => {
      if (activeStep === 0) {
        if (!values.rotations.length) {
          setFieldValue('rotations', [rotationTemplates[0]]);
        }
        const updatedSteps = produce(steps, draft => {
          draft[0].active = false;
          draft[1].toggable = true;
          draft[1].active = true;
        });
        setSteps(updatedSteps);
        !isScheduleEditMode && AppTracker.track(T_WA_UP_SCHEDULE_V2_CREATION_STEP_2_PAGE_VIEWED);
      } else if (activeStep === 1) {
        const updatedSteps = produce(steps, draft => {
          draft[1].active = false;
          draft[2].toggable = true;
          draft[2].active = true;
        });
        setSteps(updatedSteps);
        !isScheduleEditMode && AppTracker.track(T_WA_UP_SCHEDULE_V2_CREATION_STEP_3_PAGE_VIEWED);
      } else {
        try {
          setSubmitting(true);
          if (isScheduleEditMode) {
            await updateSchedule({
              ID: Number.parseInt(scheduleId),
              input: formatScheduleRequest(values, false) as UpdateSchedule,
            });
          } else {
            await createSchedule({
              input: formatScheduleRequest(values, true) as NewSchedule,
            });
          }

          setSubmitting(false);
          resetForm({ values });
          queryClient.invalidateQueries(['getListOfSchedules']);
          queryClient.invalidateQueries(['getScheduleForEditAction']);

          if (isScheduleEditMode) {
            const editedScheduleLink = generatePath(SCHEDULES_V2_DETAIL_PATH, {
              scheduleId,
            });
            history.push(editedScheduleLink);
          } else {
            history.push(SCHEDULES_V2_PATH);
          }
        } catch (error: any) {
          setSubmitting(false);
          console.log(error);
        }
      }
    },
    validationSchema: Yup.object().shape(validationSchema),
  });

  const onStepClick = (step: number) => {
    const updatedSteps = produce(steps, draft => {
      draft[activeStep].active = false;
      draft[step].active = true;
    });
    setSteps(updatedSteps);
  };

  if (isScheduleEditMode && !userHasUpdatePermission) {
    return (
      <HStack justifyContent="center" mt={5}>
        <Text>You do not have permission to edit Schedules!</Text>
      </HStack>
    );
  }

  if (isScheduleEditMode && !isLoading && !isSuccess) {
    return (
      <HStack justifyContent="center" mt={5}>
        <Text>The schedule information could not be displayed for editing!</Text>
      </HStack>
    );
  }

  return (
    <Loader.Spinner
      isLoading={isScheduleEditMode ? isLoading : false}
      loadingMessage="Initializing Schedules ..."
      centered
      spinnerProps={{ size: 'lg' }}
    >
      {(!isScheduleEditMode || (isScheduleEditMode && !isLoading)) && (
        <Layout overflow="hidden">
          <Stepper
            steps={steps}
            activeStep={activeStep}
            onStepClick={onStepClick}
            disabledSteps={disabledSteps}
          />
          <Divider />
          <OverlayModal
            isOpen={showInvalidTeamModal}
            enableOverlay
            content={
              <VStack justifyContent="center" mt={8}>
                <Icon as={InfoIcon} boxSize={8} color="default" mb={5} />
                <Text fontSize={20}>
                  Schedule not found on <strong>{organization.selectedTeam.team?.name}</strong>!
                </Text>
              </VStack>
            }
            onClose={() => {
              setShowInvalidTeamModal(false);
              history.replace(SCHEDULES_V2_PATH);
            }}
          />
          <ScaleFade initialScale={0.9} in={isScheduleEditMode ? isSuccess : true}>
            <Grid templateColumns="2.5fr 1.5fr" gap={4} bg="gray.200" h="calc(100vh - 190px)">
              <RouteGuard
                title="You have unsaved changes. Are you sure you want to leave this page without saving your changes?"
                checkDirty={() => formik.dirty}
              />

              <FormikProvider value={formik}>
                {activeStep === 0 && <SchedulesCreate isScheduleEditMode={isScheduleEditMode} />}
                {activeStep === 1 && <SchedulesTemplate />}
                {activeStep === 2 && <CustomizeRotationPattern />}
              </FormikProvider>
            </Grid>
          </ScaleFade>
        </Layout>
      )}
    </Loader.Spinner>
  );
}

export default ScheduleAdd;
