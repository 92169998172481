import React, { Component } from 'react';
import Theme from '../Theme';
import InputBlock from '../InputBlock';
import Grid from '../Grid';
import { parseObjectWithSearch, keyNav, CloseIcon } from '../../utils';
import { Shell, IShell, PreBlock } from './helper';

interface IProps {
  code: Record<string, unknown>;
  shellProps?: IShell;
  enableSearch?: boolean;
}

interface IState {
  search: string;
  canScroll: boolean;
  currentIndex: number;
}

export class JSONCodeBlock extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      search: '',
      canScroll: false,
      currentIndex: 0,
    };
  }

  clearSearch = () =>
    this.setState({
      search: '',
      currentIndex: 0,
    });

  onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState(({ currentIndex }) => ({
      search: value,
      currentIndex: value === '' ? 0 : currentIndex,
    }));
  };

  onSearchValueSelect = (e: any, _: any, value: string) =>
    this.setState(({ search }) => {
      const parsedSearch = (search || '').replaceAll('..', '.').split('.');
      const lastSearch = parsedSearch[parsedSearch.length - 1];

      if (lastSearch === '' || value.toLowerCase().includes(lastSearch.toLowerCase())) {
        parsedSearch[parsedSearch.length - 1] = value;
      } else {
        parsedSearch[parsedSearch.length] = value;
      }

      return {
        search: parsedSearch.join('.'),
        currentIndex: 0,
      };
    });

  onKeyDown = (keys: string[]) => (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (keys.length === 0) {
      return;
    }

    const { currentIndex } = this.state;

    this.setState({ canScroll: true });
    let index = currentIndex;
    let preventDefault = false;
    let cancelRoutine = false;

    const onEsc = () => {
      cancelRoutine = true;
      return;
    };

    keyNav(event)({
      onEsc,
      onTab: () => {
        preventDefault = true;

        if (keys[currentIndex]) {
          const newValue = keys[currentIndex];
          this.onSearchValueSelect(event, currentIndex, newValue);
        }
      },
      onEnter: () => {
        if (keys[currentIndex]) {
          const newValue = keys[currentIndex];
          this.onSearchValueSelect(event, currentIndex, newValue);
        }
      },
      onKeyUp: () => {
        if (currentIndex - 1 >= 0) {
          index -= 1;
        } else {
          index = keys.length;
        }
        preventDefault = true;
      },
      onKeyDown: () => {
        if (currentIndex + 1 <= keys.length) {
          index += 1;
        } else {
          index = 0;
        }
        preventDefault = true;
      },
    });

    if (cancelRoutine) return;
    if (preventDefault) event.preventDefault();
    this.setState({ currentIndex: index });
  };

  onKeyUp = () => {
    this.setState({ canScroll: false });
  };

  public render() {
    const { theme } = Theme;
    const { search, currentIndex, canScroll } = this.state;
    const { code, shellProps, enableSearch } = this.props;

    const searchedJson = parseObjectWithSearch(code, search);

    return (
      <Shell {...shellProps}>
        {enableSearch && (
          <Grid alignItems="center" justifyContent="flex-end">
            <InputBlock
              placeholder="Search"
              value={search}
              onChange={this.onSearchChange}
              style={{
                background: `${theme.shades.white}33`,
                boxShadow: 'none',
                height: '24px',
                padding: '4px 8px',
                color: theme.shades.white,
                marginTop: '14px',
                fontFamily: theme.monoFontFamily,
                borderRadius: 3,
              }}
              onKeyDown={this.onKeyDown(searchedJson.keys || [])}
              onKeyUp={this.onKeyUp}
            />
            {search.length > 0 && (
              <button
                onClick={this.clearSearch}
                style={{
                  height: '24px',
                  width: '24px',
                  background: `${theme.shades.white}33`,
                  marginLeft: 2,
                  border: 0,
                  marginTop: 14,
                  borderRadius: 3,
                }}
              >
                <CloseIcon height="12px" width="12px" fill={theme.shades.white} />
              </button>
            )}
          </Grid>
        )}

        <div id="code-renders">
          {enableSearch ? (
            <div>
              {typeof searchedJson.data === 'object' ? (
                Object.entries(searchedJson.data).map(([key, value], i: number) => {
                  const focus = i === currentIndex;
                  return (
                    <PreBlock
                      tabIndex={focus ? 0 : -1}
                      key={i}
                      index={i}
                      value={key}
                      scrollTo={focus && canScroll}
                      focus={focus}
                      code={value as Record<string, unknown>}
                      onSelectValue={this.onSearchValueSelect}
                    />
                  );
                })
              ) : (
                <PreBlock key={0} code={searchedJson.data} blockTop="-5px" value="" />
              )}
            </div>
          ) : (
            <PreBlock disabled={true} code={code} value="" />
          )}
        </div>
      </Shell>
    );
  }
}

export default JSONCodeBlock;
