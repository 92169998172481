import { usePaginationParams } from 'library/molecules/Tablev2/Table';
import moment from 'moment';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { replaceValues } from '../helpers';
import { useAuditLogs } from '../hooks';
import { AUDITLOGS_TABS } from '../index.auditLogs';
import {
  AuditLogsData,
  AuditLogsExportHistoryData,
  AuditLogsIdSpecficData,
  ExportParams,
  GetAuditLogsParamsType,
} from '../types/index.auditLogs';

interface IProps {
  children: ReactNode;
}

const AuditLogsContext = createContext({
  auditLogsData: { data: [], metadata: { totalCount: 0 } } as AuditLogsData,
  exportHistoryData: { data: [], metadata: { totalCount: 0 } } as AuditLogsExportHistoryData,
  setPagination: (() => {}) as Dispatch<
    SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >,
  paginationInfo: {
    pageIndex: 0,
    pageSize: 10,
  },
  dataLoading: false,
  showDetailsDrawer: false,
  toggleLogsDrawer: (id?: string) => {},
  auditLogsIdSpecficData: { data: {} } as AuditLogsIdSpecficData,
  activeTab: AUDITLOGS_TABS.LIVE_LOGS,
  toggleActiveTab: (tab: AUDITLOGS_TABS) => {},
  applyFilters: (filter: GetAuditLogsParamsType, cb: () => void) => {},
  clearAllFilters: (filter: GetAuditLogsParamsType, cb: () => void) => {},
  auditLogsFilterDrowDownValues: {
    clients: [],
    resourceToActionsMap: {},
    resources: [],
  },
  exportLogsData: (
    params: { fileName: string; description: string; type: string },
    cb: () => void,
  ) => {},
  exportingData: false,
  appliedFilters: {
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    endDate: moment(Date.now()).format('YYYY-MM-DD'),
    isExplicitlySet: false,
    team: [],
    client: [],
    actor: [],
    resource: [],
    action: [],
  },
  setAppliedFilters: (filter: any) => {},
  logDetailsLoading: false,
  dateFilters: {
    lastNumberOfDays: null,
    created: null,
  },
  setDateFilters: (filter: any) => {},
  showDateFilters: false,
  setShowDateFilters: (flag: boolean) => {},
  loadingHistory: true,
});

const AuditLogsProvider = ({ children }: IProps) => {
  const { setPagination, paginationInfo } = usePaginationParams({
    enabled: true,
  });
  const [showDetailsDrawer, setShowDetailsDrawer] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<AUDITLOGS_TABS>(AUDITLOGS_TABS.LIVE_LOGS);
  const [auditLogsIdSpecficData, setAuditLogsIdSpecficData] = useState<AuditLogsIdSpecficData>({
    data: {},
  } as AuditLogsIdSpecficData);
  const [showDateFilters, setShowDateFilters] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<any>({
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    endDate: moment(Date.now()).format('YYYY-MM-DD'),
    isExplicitlySet: false,
    team: [],
    client: [],
    actor: [],
    resource: [],
    action: [],
  });
  const [dateFilters, setDateFilters] = useState({
    lastNumberOfDays: null,
    created: null,
  });
  const {
    auditLogs,
    getAuditLog,
    isLoading,
    getAuditLogById,
    loadingLogs,
    auditLogsExportHistory,
    getAuditLogsHistory,
    getAuditLogFilters,
    auditLogsFilterDrowDownValues,
    exportLogsData,
    exportingData,
    loadingHistory,
  } = useAuditLogs({
    pageNumber: paginationInfo.pageIndex + 1,
    pageSize: paginationInfo.pageSize,
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    endDate: moment(Date.now()).format('YYYY-MM-DD'),
  });

  const setFiltersFun = (filters: any) => {
    setAppliedFilters(filters);
  };

  useEffect(() => {
    if (activeTab === AUDITLOGS_TABS.LIVE_LOGS) {
      const mappedFilters = replaceValues(appliedFilters, {
        team: appliedFilters?.team?.map((team: any) => team?.value),
        actor: appliedFilters?.actor?.map((actor: any) => actor?.value),
        resource: appliedFilters?.resource?.map((resource: any) => resource?.value),
        client: appliedFilters?.client?.map((client: any) => client?.value),
        action: appliedFilters?.action?.map((action: any) => action?.value),
        startDate: appliedFilters?.startDate ?? moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: appliedFilters?.endDate ?? moment(Date.now()).format('YYYY-MM-DD'),
        pageNumber: paginationInfo.pageIndex + 1,
        pageSize: paginationInfo.pageSize,
      });
      delete mappedFilters['isExplicitlySet'];
      getAuditLog(() => {}, {
        ...mappedFilters,
      });
    }
    if (activeTab === AUDITLOGS_TABS.EXPORT_HISTORY) {
      getAuditLogsHistory({
        pageNumber: paginationInfo.pageIndex + 1,
        pageSize: paginationInfo.pageSize,
      });
    }
  }, [paginationInfo]);

  useEffect(() => {
    if (activeTab === AUDITLOGS_TABS.LIVE_LOGS) {
      getAuditLog(() => {}, {
        pageNumber: 1,
        pageSize: 10,
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment(Date.now()).format('YYYY-MM-DD'),
      });
    }
    if (activeTab === AUDITLOGS_TABS.EXPORT_HISTORY) {
      getAuditLogsHistory({
        pageNumber: 1,
        pageSize: 10,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    getAuditLogFilters();
  }, []);

  const toggleLogsDrawer = async (id?: string) => {
    setShowDetailsDrawer(!showDetailsDrawer);
    if (!showDetailsDrawer && id) {
      const res = await getAuditLogById(id);
      if (res?.data) {
        setAuditLogsIdSpecficData({ data: res.data });
      }
    }
  };

  const toggleActiveTab = (tab: AUDITLOGS_TABS) => {
    setActiveTab(tab);
  };

  const applyFilters = async (filter: GetAuditLogsParamsType, cb: () => void) => {
    const mappedFilters = replaceValues(filter, {
      team: filter?.team?.map((team: any) => team?.value),
      actor: filter?.actor?.map((actor: any) => actor?.value),
      resource: filter?.resource?.map((resource: any) => resource?.value),
      client: filter?.client?.map((client: any) => client?.value),
      action: filter?.action?.map((action: any) => action?.value),
      startDate: filter?.startDate,
      endDate: filter?.endDate,
    });

    delete mappedFilters['isExplicitlySet'];

    getAuditLog(() => {
      setAppliedFilters({
        ...filter,
        isExplicitlySet: true,
        team: filter?.team ? filter?.team : [],
        actor: filter?.actor ? filter?.actor : [],
        resource: filter?.resource ? filter?.resource : [],
        client: filter?.client ? filter?.client : [],
        action: filter?.action ? filter?.action : [],
        startDate: filter?.startDate,
        endDate: filter?.endDate,
      });
      cb();
    }, mappedFilters);
  };

  const clearAllFilters = async (filter: GetAuditLogsParamsType, cb: () => void) => {
    const mappedFilters = replaceValues(filter, {
      team: filter?.team?.map((team: any) => team?.value),
      actor: filter?.actor?.map((actor: any) => actor?.value),
      resource: filter?.resource?.map((resource: any) => resource?.value),
      client: filter?.client?.map((client: any) => client?.value),
    });

    delete mappedFilters['isExplicitlySet'];

    getAuditLog(() => {
      setAppliedFilters({
        ...filter,
        isExplicitlySet: false,
        team: filter?.team ? filter?.team : [],
        actor: filter?.actor ? filter?.actor : [],
        resource: filter?.resource ? filter?.resource : [],
        client: filter?.client ? filter?.client : [],
        action: filter?.action ? filter?.action : [],
        startDate: filter?.startDate ? filter?.startDate : null,
        endDate: filter?.endDate ? filter?.endDate : null,
      });
      cb();
    }, mappedFilters);
  };

  const exportLogsDataFun = async (
    params: {
      fileName: string;
      description: string;
      type: string;
    },
    cb: () => void,
  ) => {
    const exportParams: ExportParams = {
      filters: {
        startDate: appliedFilters?.startDate ?? moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: appliedFilters?.endDate ?? moment(Date.now()).format('YYYY-MM-DD'),
        resource: appliedFilters?.resource.map((ele: any) => ele?.value),
        action: appliedFilters?.action.map((ele: any) => ele?.value),
        actor: appliedFilters?.actor.map((ele: any) => ele?.value),
        team: appliedFilters?.team.map((ele: any) => ele?.value),
        client: appliedFilters?.client.map((ele: any) => ele?.value),
      },
      name: params.fileName,
      description: params.description,
      exportType: params.type,
    };
    exportLogsData(exportParams, cb);
  };
  const value = {
    auditLogsData: auditLogs ?? { data: [], metadata: { totalCount: 0 } },
    setPagination,
    paginationInfo,
    toggleLogsDrawer,
    showDetailsDrawer,
    dataLoading: isLoading,
    logDetailsLoading: loadingLogs,
    auditLogsIdSpecficData: auditLogsIdSpecficData,
    exportHistoryData: auditLogsExportHistory ?? { data: [], metadata: { totalCount: 0 } },
    activeTab,
    toggleActiveTab,
    auditLogsFilterDrowDownValues: auditLogsFilterDrowDownValues,
    applyFilters,
    exportLogsData: exportLogsDataFun,
    exportingData,
    appliedFilters,
    setAppliedFilters: setFiltersFun,
    clearAllFilters,
    dateFilters,
    setDateFilters,
    showDateFilters,
    setShowDateFilters,
    loadingHistory,
  };

  return <AuditLogsContext.Provider value={value}>{children}</AuditLogsContext.Provider>;
};

const useAuditLogsListContext = () => useContext(AuditLogsContext);
const AuditLogsListConsumer = AuditLogsContext.Consumer;

export { AuditLogsProvider, AuditLogsListConsumer, useAuditLogsListContext };
