import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { PriorityAction } from 'views/main/organization/workflow/types';
import PriorityOptions from './PriorityOptions';
import FormField from 'library/molecules/FormField/FormField';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';

const PriorityForm = () => {
  const { setIsDirty } = useWorkflowFormContext();
  const { formState, setValue, watch } = useFormContext<PriorityAction>();

  const prioritiesOption = useMemo(() => {
    return PriorityOptions();
  }, []);

  const selectedPriorities = watch('data.priority')
    ? {
        id: watch('data.priority'),
        name:
          prioritiesOption.find(option => option.id === (watch('data.priority') as string))?.name ??
          '',
      }
    : null;

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Flex px={6} pt={4} flexDirection="column">
      <FormField label="Priority">
        <FrameWorkDropdown
          options={prioritiesOption}
          getOptionLabel={option => option.name as unknown as string}
          getOptionValue={option => option.id}
          onChange={o => setValue('data.priority', o!.id, { shouldDirty: true })}
          value={selectedPriorities}
          placeholder="Select Priority"
        />
      </FormField>
    </Flex>
  );
};

export default PriorityForm;
