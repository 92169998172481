import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { useState } from 'react';

import {
  ISSUE_FILTER_TYPES,
  issueFilterDetails,
  reactQueryConfig,
} from '../constants/status.constants';
import { useGetStatusPageDetailsQuery } from '../graphql/query';
import { getComponentOptions } from '../helpers/helper.details';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { IStatusPageDetails } from '../Interface';

export const usePageDetails = (pageId: number, computeFilterOptions = false) => {
  const [drawerOption, setDrawerOption] = useState({ isOpen: false, drawerType: '' });
  const [isEditFlow, setIsEditFlow] = useState(false);

  const statusPageData = useGetStatusPageDetailsQuery(
    { id: pageId },
    {
      ...reactQueryConfig,
      enabled: !!pageId && pageId !== -1,
      onSuccess: data => {
        if (data) {
          const details = data.getStatusPageDetails;
          const acl: Record<string, EntityACLMeta> = {};
          acl[data?.getStatusPageDetails?.id ?? ''] = {
            has_delete_access: data.getStatusPageDetails?.acl?.hasDeleteAccess ?? false,
            has_update_access: data.getStatusPageDetails?.acl?.hasUpdateAccess ?? false,
            has_update_owner_access: data.getStatusPageDetails?.acl?.hasUpdateOwnerAccess ?? false,
          };
          setUserAccessStore({
            entityACLMap: {
              ...getUserAccessStore().entityACLMap,
              status_pages: {
                ...getUserAccessStore().entityACLMap?.status_pages,
                ...acl,
              },
            },
          });
          if (computeFilterOptions) {
            const { componentOptions, groupOptions } = getComponentOptions(
              details.components as any,
            );
            issueFilterDetails[ISSUE_FILTER_TYPES.COMPONENT].filterOptions = componentOptions;
            issueFilterDetails[ISSUE_FILTER_TYPES.COMPONENT_GROUP].filterOptions = groupOptions;
            issueFilterDetails[ISSUE_FILTER_TYPES.ISSUE_STATUS].filterOptions = (
              details.messageTemplates || []
            ).map((temp: any) => {
              return {
                value: temp.id,
                label: temp.name,
              };
            });
          }
        }
      },
      onError: reactQueryConfigError('Get page details'),
    },
  );

  const setCloseDrawer = () => {
    setDrawerOption({ isOpen: false, drawerType: '' });
    setIsEditFlow(false);
  };

  const setOpenDrawer = (type: string) => {
    setDrawerOption({ isOpen: true, drawerType: type });
  };

  return {
    details: (statusPageData?.data?.getStatusPageDetails as unknown as IStatusPageDetails) || null,
    isSuccess: statusPageData.isSuccess,
    isLoading: statusPageData.isLoading,
    isRefetchingDetails: statusPageData.isRefetching,
    isError: statusPageData.isError,
    error: statusPageData.error,
    refetch: statusPageData.refetch,
    setCloseDrawer,
    setOpenDrawer,
    drawerOption,
    setIsEditFlow,
    isEditFlow,
  };
};
