import { Divider, Grid } from '@chakra-ui/react';
import { API } from 'core';
import { FormikProvider } from 'formik';
import { useContext, useEffect, useState } from 'react';
import React from 'react';

import { StatusPageContext } from '../';
import Layout from '../components/Layout';
import Stepper from '../components/Stepper';
import { statusPageTextCopy } from '../constants/statuspage.copy';
import { getComponentData } from '../helpers/helper.details';
import { reactQueryConfigSuccess } from '../helpers/helper.toast';
import { useCreatePage } from '../hooks/useCreatePage';
import { usePageConfig } from '../hooks/usePageConfig';
import { usePageTheme } from '../hooks/usePageTheme';
import { IStatusPageList, IStatusTheme } from '../Interface';
import StatusPageAddComponent from './statuspage.addComponent';
import StatuPageCustomizePage from './statuspage.customizePage';
import StatusPageDetail from './statuspage.detail';
import StatuPageAddIssue from './statuspage.issue';
import { NewStatusPageOwnerType, OwnerType } from '../graphql/types';

const steps = [
  { name: statusPageTextCopy.stepper.step1 },
  { name: statusPageTextCopy.stepper.step2 },
  { name: statusPageTextCopy.stepper.step3 },
  { name: statusPageTextCopy.stepper.step4 },
];

function StatusPageAdd() {
  const { activeStep, setActiveStep } = useContext(StatusPageContext);
  const [components, setComponents] = useState([]);
  const [pageId, setPageId] = useState<number>(0);
  const { mutateAsync: createStatusPage, isLoading } = useCreatePage((data: IStatusPageList) => {
    reactQueryConfigSuccess('Status page created');
    setPageId(data.id);
    setActiveStep(3);
  });

  const { formik: detailsPageFormik } = usePageConfig(() => setActiveStep(1));

  const onCreatePage = (themePageValues: IStatusTheme) => {
    const { components: comps } = getComponentData(components);
    createStatusPage({
      input: {
        teamID: API.config.teamId,
        name: detailsPageFormik.values.statusPageName || '',
        description: detailsPageFormik.values.description,
        domainName: detailsPageFormik.values.squadcastDomainName || '',
        components: comps,
        isPublic: !detailsPageFormik.values.privatePage,
        timezone:
          typeof detailsPageFormik.values.timezone === 'object' &&
          'value' in detailsPageFormik.values.timezone
            ? detailsPageFormik.values.timezone?.value
            : '',
        logoUrl: themePageValues.logoUrl,
        contactEmail: detailsPageFormik.values.contactEmail || '',
        themeColor: {
          primary: themePageValues.primaryColor,
          secondary: themePageValues.secondaryColor,
        },
        ownerID: detailsPageFormik.values.owner?.id || '',
        ownerType: detailsPageFormik.values.owner?.type || NewStatusPageOwnerType.User,
      },
    });
  };
  const { formik: themePageFormik, isLogoUploading } = usePageTheme(onCreatePage);

  useEffect(() => setActiveStep(0), []);

  return (
    <Layout>
      <Stepper steps={steps} activeStep={activeStep} />
      <Divider />

      <Grid
        templateColumns="2.25fr 1fr"
        gap={6}
        bg="gray.200"
        h="calc(100vh - 181px)"
        marginLeft={6}
        marginRight={6}
        overflowY="auto"
      >
        {activeStep === 0 && (
          <FormikProvider value={detailsPageFormik}>
            <StatusPageDetail />
          </FormikProvider>
        )}
        {activeStep === 1 && (
          <StatusPageAddComponent
            isAdvanceSettingHidden={true}
            init={components}
            onSubmit={(comps: any) => {
              setComponents(comps);
              setActiveStep(2);
            }}
          />
        )}
        {activeStep === 2 && (
          <FormikProvider value={themePageFormik}>
            <StatuPageCustomizePage
              isMutationProgress={isLoading}
              isLogoUploading={isLogoUploading}
            />
          </FormikProvider>
        )}
        {activeStep === 3 && <StatuPageAddIssue pageId={pageId} />}
      </Grid>
    </Layout>
  );
}

export default StatusPageAdd;
