import axios from 'axios';
import qs from 'query-string';
import { API } from '../api';
import { IPostmortem, IPostmortemResponse } from '../interfaces/IPostmortems';

class PostmortemService {
  private api = '';
  private apiList = '';
  private orgPostmortemCount = '';

  constructor(incidentId: string) {
    this.api = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/postmortem`;
    this.apiList = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/postmortem`;
    this.orgPostmortemCount = `${API.config.batman}/organizations/${API.config.organizationId}/postmortems/count`;
  }

  public getOrgCount = async () => axios.get<{ data: { count: number } }>(this.orgPostmortemCount);
  public get = () => axios.get<{ data: IPostmortem }>(this.api);

  public create = (postmortem: IPostmortem) =>
    axios.post(this.api, {
      owner_id: API.config.teamId,
      ...postmortem,
    });

  public update = (postmortem: IPostmortem) => axios.put(this.api, postmortem);

  public delete = () => axios.delete(this.api);

  public download = (fileType: 'pdf') => axios.get(`${this.api}/download/${fileType}`);

  public getPostmortemsWithinTimeRange = (props: {
    fromDate: string;
    toDate: string;
    alertSources: string[];
    services: string[];
    status: string[];
    skip: number;
    limit: number;
  }) =>
    axios.get<IPostmortemResponse>(
      `${this.apiList}?${qs.stringify({
        fromDate: props.fromDate,
        toDate: props.toDate,
        owner_id: API.config.teamId,
        alert_sources: props.alertSources,
        services: props.services,
        status: props.status,
        skip: props.skip,
        limit: props.limit,
      })}`,
    );
}

export default PostmortemService;
