import { DateTime } from 'luxon';
import { API } from 'core';

import { createStandaloneToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import chakraTheme from '../theme';

import {
  ICustomPeriod,
  INewSchedule,
  IRotation,
  IRotationEventParticipants,
  ISchedule,
  ITimeSlot,
} from '../interface/schedule';
import {
  CustomPeriodUnit,
  PeriodOptions,
  ChangeParticipantsInterval,
  CustomPeriod,
  NewSchedule,
  UpdateSchedule,
  NewTag,
} from 'views/main/organization/schedules/graphql/types';
import {
  schedulesTextCopy,
  ScheduleActionTypes,
  initialAlertDialogParameters,
} from '../constants/schedules.copy';
import { IScheduleDetail } from '../interface/schedule';
import {
  initialCustomPeriodValue,
  rotationsCustomizePattern,
} from '../constants/rotations.customize';
import {
  getDurationForTimeStamp,
  getEndTimeTimeStamp,
  IPatternParticipant,
} from './helpers.customrotations';
import { UseQueryOptions } from 'react-query';
import {
  rotationGapID,
  rotationGapLabel,
  rotationOverrideID,
  rotationOverridesLabel,
} from '../constants/schedules.view';
import {
  onCallCoverageColor,
  onCallCoverageRowID,
  onCallCoverageRowName,
  schedulesGapColor,
  schedulesOverrideColor,
} from '../constants/schedules.rotation-template';
import { getPaddedValue } from './helpers.event';
import { getDateTime } from './helpers.date';
import { AppTracker } from 'shared/analytics/tracker';
import {
  T_WA_UP_SCHEDULES_V2_PAUSED,
  T_WA_UP_SCHEDULES_V2_RESUMED,
  T_WA_UP_SCHEDULES_V2_CLONED,
  T_WA_UP_SCHEDULES_V2_DELETED,
  T_WA_UP_SCHEDULES_V2_EDITED,
  T_WA_UP_SCHEDULES_V2_EXPORTED,
} from 'core/const/tracker';

export const toast = createStandaloneToast({ theme: chakraTheme });

export type queryOperationType =
  | 'Create Schedule'
  | 'Pause Schedule'
  | 'Resume Schedule'
  | 'Clone Schedule'
  | 'Delete Schedule'
  | 'Update Schedule'
  | 'Generate Link'
  | 'Refresh Link'
  | 'Get Schedules'
  | 'Get Schedule'
  | 'Get Schedule for Edit'
  | 'Get On call users'
  | 'Create Override'
  | 'Update Override'
  | 'Delete Override';

const formatScheduleRequest = (values: INewSchedule, includeTeamID: boolean) => {
  const result = {
    name: values.name,
    timeZone: values.timeZone,
    description: values.description,
    owner: values.owner,
    tags: values.tags,
    rotations: values.rotations.map(r => {
      const [startHour, startMin] = r.shiftTimeSlot.startTime.split(':');
      let customTimeSlots = r.customPeriod?.timeSlots;
      if (
        r.period === PeriodOptions.Custom &&
        r.customPeriod?.periodUnit !== CustomPeriodUnit.Week
      ) {
        customTimeSlots = customTimeSlots?.filter(ts => !ts.dayOfWeek);
      }
      if (
        r.period === PeriodOptions.Custom &&
        r.customPeriod?.periodUnit === CustomPeriodUnit.Week
      ) {
        customTimeSlots = customTimeSlots?.filter(ts => !!ts.dayOfWeek);
      }
      /**
        We have 2 conditions to send the rotation Id in the payload -
        it should be the payload for the update query (in which case we dont include the team ID)
        and the id of the rotation parsed to int should be a number (which is not in case of newly added rotations)
       */
      const result = {
        ...(!includeTeamID && !isNaN(Number.parseInt(r.id)) ? { id: Number.parseInt(r.id) } : {}),
        name: r.name,
        color: r.color,
        participantGroups:
          r.participantGroups
            ?.filter(pg => pg?.participants?.length)
            .map(pg => ({
              participants: pg?.participants
                ?.filter(p => p?.type && p?.id)
                ?.map(p => ({ type: p?.type, ID: p?.id })),
            })) ?? [],
        startDate: r.startDate,
        period: r.period,
        shiftTimeSlot:
          r.period !== PeriodOptions.Custom
            ? {
                startHour: Number(startHour),
                startMin: Number(startMin),
                duration: r.shiftTimeSlot.duration,
              }
            : undefined,
        changeParticipantsFrequency: r.changeParticipantsFrequency,
        changeParticipantsUnit: r.changeParticipantsUnit,
        endDate:
          r.ends && !r.endsAfterIterations && r.endDate
            ? DateTime.fromObject(
                {
                  year: r.endDate.getFullYear(),
                  month: r.endDate.getMonth() + 1,
                  day: r.endDate.getDate(),
                  hour: Number(startHour),
                  minute: Number(startMin),
                },
                { zone: values.timeZone },
              ).toUTC()
            : undefined,
        endsAfterIterations: r.ends && r.endsAfterIterations ? r.endsAfterIterations : undefined,
        customPeriod:
          r.period === PeriodOptions.Custom
            ? {
                periodFrequency: r.customPeriod?.periodFrequency as number,
                periodUnit: r.customPeriod?.periodUnit as CustomPeriodUnit,
                timeSlots: (customTimeSlots as ITimeSlot[]).map(slot => {
                  const [slotStartHour, slotStartMin] = slot.startTime.split(':');
                  return {
                    startHour: Number(slotStartHour),
                    startMin: Number(slotStartMin),
                    duration: slot.duration,
                    dayOfWeek: slot.dayOfWeek ? slot.dayOfWeek : undefined,
                  };
                }),
              }
            : undefined,
      };
      return result;
    }),
  };
  if (!includeTeamID) {
    return result as UpdateSchedule;
  } else {
    return { ...result, teamID: API.config.teamId } as NewSchedule;
  }
};

const getCustomPeriodConfiguration = (
  customPeriod: CustomPeriod | null | undefined,
): ICustomPeriod => {
  //Function to get the ICustomPeriod required for formik
  //Initialize with a default value from constants
  const result = { ...initialCustomPeriodValue };
  if (
    customPeriod?.periodUnit &&
    Object.values(CustomPeriodUnit).includes(customPeriod?.periodUnit)
  ) {
    //Assign the period unit
    result.periodUnit = customPeriod.periodUnit;
  }
  if (customPeriod?.periodFrequency && customPeriod.periodFrequency > 0) {
    //Assign the period frequency
    result.periodFrequency = customPeriod.periodFrequency;
  }
  if (customPeriod?.timeSlots) {
    let customTimeSlots = customPeriod?.timeSlots;
    if (customPeriod?.periodUnit !== CustomPeriodUnit.Week) {
      //For custom period day and month, select slots which have blank day of week
      customTimeSlots = customTimeSlots?.filter(ts => !ts.dayOfWeek);
    } else {
      //For custom period week, select slots which have a non-blank day of week
      customTimeSlots = customTimeSlots?.filter(ts => !!ts.dayOfWeek);
    }
    result.timeSlots = customTimeSlots.map((timeSlot, index) => {
      //Get start time, end time and their durations
      const startHour = timeSlot.startHour ? getPaddedValue(timeSlot.startHour) : '00';
      const startMin = timeSlot?.startMin ? getPaddedValue(timeSlot.startMin) : '00';

      const startTime = [startHour, startMin].join(':');
      const startTimeDuration = getDurationForTimeStamp(startTime);
      const endTime = getEndTimeTimeStamp(startTime, timeSlot.duration);
      const endTimeDuration = getDurationForTimeStamp(endTime);
      return {
        startTime: startTime,
        endTime: endTime,
        duration: timeSlot.duration,
        dayOfWeek: timeSlot.dayOfWeek,
        isSameDay: endTimeDuration > startTimeDuration,
        id: index + 1,
      };
    });
  }
  if (customPeriod?.periodUnit === CustomPeriodUnit.Week && customPeriod.timeSlots) {
    //For the custom period week
    //Get the unique weekdays from the slots - Sunday, Monday, etc.
    const activeWeekDays = Array.from(
      new Set(customPeriod.timeSlots.map(timeSlot => timeSlot.dayOfWeek)),
    );
    //Set a boolean array - days of week filter, i.e. Sunday, Monday will be converted to [true, true, false, ...]
    const daysOfWeekFilter = rotationsCustomizePattern.customRepeat.selectOptions.daysOfWeek.map(
      weekDay => activeWeekDays.includes(weekDay),
    );
    result.daysOfWeekFilter = daysOfWeekFilter;
  }
  return result;
};

const mapFetchScheduleToNewSchedule = (
  schedule: IScheduleDetail | undefined,
  allParticipants: IPatternParticipant[],
): ISchedule | undefined => {
  if (!schedule) return;

  return {
    id: `${schedule.ID}`,
    slug: schedule.slug,
    orgId: schedule.orgID,
    name: schedule.name,
    timeZone: schedule.timeZone,
    description: schedule.description,
    owner: schedule.owner,
    tags: schedule.tags?.map(tag => ({ ...tag, color: tag.color?.toUpperCase() })),
    showGaps: false,
    rotations: schedule.rotations?.map(r => {
      const startHour = r.shiftTimeSlot?.startHour
        ? getPaddedValue(r.shiftTimeSlot.startHour)
        : '00';
      const startMin = r.shiftTimeSlot?.startMin ? getPaddedValue(r.shiftTimeSlot.startMin) : '00';

      const endDateTz = DateTime.fromJSDate(new Date(r.endDate));
      return {
        id: `${r.ID}`,
        name: r.name,
        showOnCalendar: true,
        color: (r.color ?? 'white').toUpperCase(),
        scheduleId: `${r.scheduleID}`,
        participantGroups: r.participantGroups?.map((pg, index) => {
          const participants = (pg.participants as IRotationEventParticipants)
            ?.map(p => {
              if (
                allParticipants.find(participant => participant.id === p.ID)?.label !== undefined
              ) {
                return {
                  type: p.type,
                  id: p.ID,
                  name: allParticipants.find(participant => participant.id === p.ID)?.label ?? '',
                  value: p.ID,
                  label: allParticipants.find(participant => participant.id === p.ID)?.label ?? '',
                  timeZone:
                    allParticipants.find(participant => participant.id === p.ID)?.timeZone ?? '',
                  username:
                    allParticipants.find(participant => participant.id === p.ID)?.username ?? '',
                };
              }
            })
            .filter(p => p);
          if (participants?.length !== 0) {
            return {
              groupId: `Group_${index}`,
              participants,
            };
          }
        }),
        startDate: new Date(r.startDate),
        period: r.period,
        shiftTimeSlot: {
          startTime: [startHour, startMin].join(':'),
          duration: r.shiftTimeSlot?.duration ?? 30,
          dayOfWeek: r.shiftTimeSlot?.dayOfWeek,
          id: 1,
          isSameDay: true,
        },
        customPeriod: getCustomPeriodConfiguration(r.customPeriod),
        changeParticipantsFrequency:
          r.changeParticipantsFrequency > 0 ? r.changeParticipantsFrequency : 1,
        changeParticipantsUnit: Object.values(ChangeParticipantsInterval).includes(
          r.changeParticipantsUnit,
        )
          ? r.changeParticipantsUnit
          : ChangeParticipantsInterval.Rotation,
        ends: !!r.endDate || (r.endsAfterIterations ?? 0) > 0 ? true : false,
        endDate: r.endDate
          ? new Date(endDateTz.year, endDateTz.month - 1, endDateTz.day)
          : undefined,
        endsAfterIterations: r.endsAfterIterations ?? undefined,
      };
    }) as Array<IRotation>,
  };
};

const reactQueryConfigError = (operationType: queryOperationType) => (err: unknown) => {
  const error = err as any;
  const title = `The operation ${operationType} could not be completed`;

  toast.closeAll();
  toast({
    title,
    status: 'error',
    variant: 'subtle',
    isClosable: true,
    description: error.message,
    containerStyle: {
      fontSize: 14,
      lineHeight: 10,
    },
  });
};

const reactQueryConfigSuccess = (message: string) => {
  toast({
    title: message,
    status: 'success',
    duration: 9000,
    isClosable: true,
  });
};

const defaultReactQueryConfig = {
  enabled: true,
  retry: 1,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
};

const reactQueryConfig = {
  query: {
    mocks: {
      ...defaultReactQueryConfig,
    },
  } as { [x: string]: UseQueryOptions },
  mutation: {
    pause: {
      onSuccess: () => {
        reactQueryConfigSuccess(schedulesTextCopy.toasts.paused);
        AppTracker.track(T_WA_UP_SCHEDULES_V2_PAUSED);
      },
      onError: reactQueryConfigError('Pause Schedule'),
    },
    resume: {
      onSuccess: () => {
        reactQueryConfigSuccess(schedulesTextCopy.toasts.resumed);
        AppTracker.track(T_WA_UP_SCHEDULES_V2_RESUMED);
      },
      onError: reactQueryConfigError('Resume Schedule'),
    },
    clone: {
      onSuccess: () => {
        reactQueryConfigSuccess(schedulesTextCopy.toasts.cloned);
        AppTracker.track(T_WA_UP_SCHEDULES_V2_CLONED);
      },
      onError: reactQueryConfigError('Clone Schedule'),
    },
    delete: {
      onSuccess: () => {
        reactQueryConfigSuccess(schedulesTextCopy.toasts.deleted);
        AppTracker.track(T_WA_UP_SCHEDULES_V2_DELETED);
      },
      onError: reactQueryConfigError('Delete Schedule'),
    },
    update: {
      onSuccess: () => {
        reactQueryConfigSuccess(schedulesTextCopy.toasts.updated);
        AppTracker.track(T_WA_UP_SCHEDULES_V2_EDITED);
      },
      onError: reactQueryConfigError('Update Schedule'),
    },
    generateICalLink: {
      onSuccess: () => {},
      onError: reactQueryConfigError('Generate Link'),
    },
    refreshICalLink: {
      onSuccess: () => {},
      onError: reactQueryConfigError('Refresh Link'),
    },
    createOverride: {
      onSuccess: () => {
        reactQueryConfigSuccess(schedulesTextCopy.toasts.overrideCreated);
      },
      onError: reactQueryConfigError('Create Override'),
    },
    updateOverride: {
      onSuccess: () => {
        reactQueryConfigSuccess(schedulesTextCopy.toasts.overrideUpdated);
      },
      onError: reactQueryConfigError('Update Override'),
    },
    deleteOverride: {
      onSuccess: () => {
        reactQueryConfigSuccess(schedulesTextCopy.toasts.overrideDeleted);
      },
      onError: reactQueryConfigError('Delete Override'),
    },
  },
};

type TScheduleActionParams = {
  scheduleId: number;
  scheduleName: string;
  rotationId?: string;
};

export type TScheduleAction = {
  type: ScheduleActionTypes;
  params: TScheduleActionParams;
};

export type TAlertDialogParameters = {
  title: string;
  body: string | JSX.Element;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm: () => Promise<any>;
  isLoading?: boolean;
};

const getAlertDialogParameters = (action: TScheduleAction | null) => {
  if (!action) return initialAlertDialogParameters;

  const alertDialogParameters = initialAlertDialogParameters;
  switch (action.type) {
    case ScheduleActionTypes.PAUSE: {
      alertDialogParameters.title = schedulesTextCopy.alertDialogs.pause.header;
      alertDialogParameters.body = schedulesTextCopy.alertDialogs.pause.body.replace(
        'SCHEDULENAME',
        action.params.scheduleName,
      );
      alertDialogParameters.confirmButtonText =
        schedulesTextCopy.alertDialogs.pause.confirmButtonText;
      alertDialogParameters.cancelButtonText =
        schedulesTextCopy.alertDialogs.pause.cancelButtonText;
      break;
    }
    case ScheduleActionTypes.RESUME: {
      alertDialogParameters.title = schedulesTextCopy.alertDialogs.resume.header;
      alertDialogParameters.body = schedulesTextCopy.alertDialogs.resume.body.replace(
        'SCHEDULENAME',
        action.params.scheduleName,
      );
      alertDialogParameters.confirmButtonText =
        schedulesTextCopy.alertDialogs.resume.confirmButtonText;
      alertDialogParameters.cancelButtonText =
        schedulesTextCopy.alertDialogs.resume.cancelButtonText;
      break;
    }
    case ScheduleActionTypes.CLONE: {
      alertDialogParameters.title = schedulesTextCopy.alertDialogs.clone.header;
      alertDialogParameters.body = schedulesTextCopy.alertDialogs.clone.body.replace(
        'SCHEDULENAME',
        action.params.scheduleName,
      );
      alertDialogParameters.confirmButtonText =
        schedulesTextCopy.alertDialogs.clone.confirmButtonText;
      alertDialogParameters.cancelButtonText =
        schedulesTextCopy.alertDialogs.clone.cancelButtonText;
      break;
    }
    case ScheduleActionTypes.DELETE: {
      alertDialogParameters.title = schedulesTextCopy.alertDialogs.delete.header;
      break;
    }
    case ScheduleActionTypes.EXPORT: {
      alertDialogParameters.title = schedulesTextCopy.alertDialogs.export.header;
      alertDialogParameters.confirmButtonText =
        schedulesTextCopy.alertDialogs.export.confirmButtonText;
      alertDialogParameters.cancelButtonText =
        schedulesTextCopy.alertDialogs.export.cancelButtonText;
      break;
    }
  }
  return alertDialogParameters;
};

/**
 * Create a gap rotation
 * @param scheduleID Schedule ID
 * @param scheduleName Schedule Name
 * @param scheduleTimeZone Schedule Timezone
 * @returns
 */
const getGapAsRotation = (scheduleID: number, scheduleName: string, scheduleTimeZone: string) => ({
  ID: rotationGapID,
  name: rotationGapLabel,
  color: schedulesGapColor,
  scheduleID: scheduleID,
  startDate: new Date(),
  period: PeriodOptions.Daily,
  changeParticipantsFrequency: 0,
  changeParticipantsUnit: ChangeParticipantsInterval.Day,
  scheduleName: scheduleName,
  scheduleTimeZone: scheduleTimeZone,
  isSchedulePaused: false,
  events: [],
});

/**
 * Create a overrides rotation
 * @param scheduleID Schedule ID
 * @param scheduleName Schedule Name
 * @param scheduleTimeZone Schedule Timezone
 * @returns
 */
const getOverridesAsRotation = (
  scheduleID: number,
  scheduleName: string,
  scheduleTimeZone: string,
) => ({
  ID: rotationOverrideID,
  name: rotationOverridesLabel,
  color: schedulesOverrideColor,
  scheduleID: scheduleID,
  startDate: new Date().toISOString(),
  period: PeriodOptions.Daily,
  changeParticipantsFrequency: 0,
  changeParticipantsUnit: ChangeParticipantsInterval.Day,
  scheduleName: scheduleName,
  scheduleTimeZone: scheduleTimeZone,
  isSchedulePaused: false,
  events: [],
});

export const getOnCallCoverageAsRotation = (
  scheduleID: number,
  scheduleName: string,
  scheduleTimeZone: string,
) => ({
  ID: onCallCoverageRowID,
  name: onCallCoverageRowName,
  color: onCallCoverageColor,
  scheduleID: scheduleID,
  startDate: new Date().toISOString(),
  period: PeriodOptions.Daily,
  changeParticipantsFrequency: 0,
  changeParticipantsUnit: ChangeParticipantsInterval.Day,
  scheduleName: scheduleName,
  scheduleTimeZone: scheduleTimeZone,
  isSchedulePaused: false,
  events: [],
});

/**
 * Checks and updates the tags source based on the current tags.
 * @param tagsSource - The source object containing tags.
 * @param currentTags - An array of key-value tags.
 * @returns Object containing information about whether a new tag was added and the updated tags source.
 */
const checkAndUpdateTags = (tagsSource: Record<string, string[]>, currentTags: NewTag[]) => {
  const updatedTags = { ...tagsSource };
  let newTagAdded = false;
  currentTags.forEach(tag => {
    if (tag && tag.key && tag.value) {
      if (!updatedTags[tag.key]) {
        updatedTags[tag.key] = [];
      }
      if (!updatedTags[tag.key].includes(tag.value.trim())) {
        updatedTags[tag.key].push(tag.value.trim());
        newTagAdded = true;
      }
    }
  });
  return { newTagAdded, updatedTags };
};

/**
 * Checks if there are duplicate tags in the input array.
 * @param tags - An array of tags.
 * @returns true, if duplicate tags exist, otherwise false.
 */
const checkDuplicateTags = (tags: NewTag[]) => {
  const duplicateTagsExist = tags.some((tag, index) => {
    if (!tag || !tag.key || !tag.value) {
      return false;
    }

    const lowercaseKey = tag.key.trim().toLowerCase();
    const lowercaseValue = tag.value.trim().toLowerCase();

    return tags.some(
      (t, i) =>
        i !== index &&
        t &&
        t.key &&
        t.value &&
        t.key.trim().toLowerCase() === lowercaseKey &&
        t.value.trim().toLowerCase() === lowercaseValue,
    );
  });
  return duplicateTagsExist;
};

export {
  checkAndUpdateTags,
  checkDuplicateTags,
  formatScheduleRequest,
  mapFetchScheduleToNewSchedule,
  reactQueryConfigSuccess,
  reactQueryConfigError,
  defaultReactQueryConfig,
  reactQueryConfig,
  getAlertDialogParameters,
  getGapAsRotation,
  getOverridesAsRotation,
};
