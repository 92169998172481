import { Box, HStack, Input, Text, useBoolean, VStack } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

interface IProps {
  color: string;
  label: string;
  hoursValue: number;
  minutesValue: number;
  disabled?: boolean;
  hoursOnChange?: (value: number) => void;
  minutesOnChange?: (value: number) => void;
  showOriginalTime?: boolean;
  originalTimeInMinutes?: number; // Keeping for display purposes
}

export function TimeInput(props: IProps) {
  const [isHrsAutoFocus, setHrsAutoFocus] = useBoolean();
  const [isMinsAutoFocus, setMinsAutoFocus] = useBoolean();

  return (
    <VStack align="start">
      {(props.label || props.color) && (
        <HStack>
          <Box
            bg={props.color}
            w="12px"
            h="12px"
            borderRadius="50%"
            position="relative"
            top="-2px"
          ></Box>
          <Text fontSize="16px" fontWeight={800} color={THEME_COLORS.secondary[700]}>
            {props.label}
          </Text>
        </HStack>
      )}
      <HStack
        alignSelf="end"
        justifyContent="start"
        border={`1px solid ${THEME_COLORS.secondary[200]}`}
        width="153px"
        justify="start"
        gap="0px"
        padding="2px 9px"
        borderRadius="2px"
        fontWeight={400}
        color={props.disabled ? THEME_COLORS.secondary[400] : THEME_COLORS.secondary[1000]}
        bg={props.disabled ? THEME_COLORS.secondary[150] : 'white'}
        onFocusCapture={setHrsAutoFocus.on}
        onBlur={setHrsAutoFocus.off}
      >
        {/* Hours Input */}
        <Input
          type="number"
          value={
            props.hoursValue < 10 ? '0' + props.hoursValue.toString() : props.hoursValue.toString()
          }
          variant="unstyled"
          width="19px"
          min={0}
          max={23}
          borderWidth={0}
          disabled={props.disabled}
          autoFocus={isHrsAutoFocus}
          onChange={e => {
            const newHours = Math.min(Number(e.target.value), 23);
            if (props.hoursOnChange) {
              props.hoursOnChange(newHours);
            }
            if (newHours >= 10) {
              setMinsAutoFocus.on();
            }
          }}
        />
        <Text fontSize="12px"> hrs</Text>

        {/* Minutes Input */}
        <Input
          type="number"
          value={
            props.minutesValue < 10
              ? '0' + props.minutesValue.toString()
              : props.minutesValue.toString()
          }
          variant="unstyled"
          width="19px"
          min={0}
          max={59}
          borderWidth={0}
          disabled={props.disabled}
          autoFocus={isMinsAutoFocus}
          onChange={e => {
            const newMinutes = Math.min(Number(e.target.value), 59);
            if (props.minutesOnChange) {
              props.minutesOnChange(newMinutes);
            }
          }}
        />
        <Text fontSize="12px"> mins</Text>
      </HStack>

      {/* Original time display */}
      {props.showOriginalTime && props.originalTimeInMinutes && (
        <Text
          alignSelf="end"
          fontSize="12px"
          fontWeight={700}
          color={THEME_COLORS.secondary[700]}
          width="calc(100% - 20px)"
        >
          <span
            style={{
              backgroundColor: THEME_COLORS.secondary[100],
              padding: '2px 8px',
              borderRadius: '4px',
            }}
          >
            Original : {Math.floor(props.originalTimeInMinutes / 60)} hrs{' '}
            {props.originalTimeInMinutes % 60} mins
          </span>
        </Text>
      )}
    </VStack>
  );
}
