export enum AUDITLOGS_TABS {
  LIVE_LOGS = 'live logs',
  EXPORT_HISTORY = 'export history',
}

export enum EXPORT_TYPE {
  CSV = 'csv',
  JSON = 'json',
}

export enum DRAWER_TYPE {
  LINK = 'link',
  ASSIGNEE = 'assignee',
  DROPDOWN = 'dropdown',
  GENERIC = 'generic',
  TIMESTAMP = 'timeStamp',
  META = 'meta',
}
export enum DateFilterChoice {
  Days = 'days',
  Month = 'month',
  Range = 'range',
  Week = 'week',
  Last7Days = 'last7days',
  Last14Days = 'last14days',
  Last30Days = 'last30days',
}

export enum DayOfWeek {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
}

export enum EXPORT_HISTORY_ENUM {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
}

export enum DROPDOWN_TYPE_ENUM {
  RESOURCE = 'Resource',
  ACTION = 'Action',
  ACTOR = 'Actor',
  TEAM = 'Team',
  CLIENT = 'Client',
  DATE_RANGE = 'Date Range',
}
interface Option {
  label: string;
  value: string;
}
export interface GetAuditLogsParamsType {
  pageSize?: number;
  pageNumber?: number;
  startDate?: string;
  endDate?: string;
  resource?: Option[];
  actor?: Option[];
  team?: Option[];
  client?: Option[];
  action?: Option[];
  created?: any;
  lastNumberOfDays?: any;
}

export interface GetAuditLogsHistoryParamsType {
  pageSize: number;
  pageNumber: number;
}

export interface ExportParams {
  filters: {
    startDate: string;
    endDate: string;
    resource: string[];
    action: string[];
    actor: string[];
    team: string[];
    client: string[];
  };
  name: string;
  description: string;
  exportType: string;
}

export interface AuditLogsData {
  data: {
    id: string;
    resource: string;
    action: string;
    actor: {
      userID: string;
      userEmail: string;
      userName: string;
      fullName: string;
    };
    client: string;
    team: {
      id: string;
      name: string;
    };
    timeStamp: string;
  }[];
  metadata: {
    totalCount: number;
  };
}
export interface AuditLogsExportHistoryData {
  data: {
    description: string;
    downloadLink: string;
    exportedAt: string;
    filters: {
      action: string[];
      actor: string[];
      client: string[];
      endDate: string[];
      resource: string[];
      startDate: string;
      team: string[];
    };
    id: string;
    name: string;
    requestedBy: string;
    status: '';
  }[];
  metadata: {
    totalCount: number;
  };
}

export interface AuditLogsIdSpecficData {
  data: {
    id: string;
    resource: string;
    action: string;
    actor: {
      userID: string;
      userEmail: string;
      userName: string;
      fullName: string;
    };
    additionalInfo: string;
    details: any;
    client: string;
    ipAddress: string;
    team: {
      id: string;
      name: string;
    };
    meta: any;
    timestamp: string;
    timezone: string;
    tokenType: string;
    userAgent: string;
  };
}

export interface AuditLogsTableProps {
  status: AUDITLOGS_TABS;
}
export interface DrawerCellProps {
  title: string;
  body: any;
  type: DRAWER_TYPE;
}

export interface Filters {
  startDate: string;
  endDate: string;
  resource: string[];
  action: string[];
  actor: string[];
  team: string[];
  client: string[];
}
