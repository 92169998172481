import {
  Box,
  CircularProgress,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  VStack,
  Flex,
} from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMultiStyleConfig } from '@chakra-ui/system';

import {
  LCRListState,
  MappingTableTypes,
  ROUTING_BEHAVIOR,
  ROUTING_TYPE,
} from 'views/main/organization/lcr/types/LCR.types';
import MappingTable from '../MappingTable/MappingTable';
import { useLCRCreate } from 'views/main/organization/lcr/hooks/lcrCreate';
import { useLCRStore } from 'views/main/organization/lcr/hooks/lcr.state';
import { FormButton, IconButton, Input, Textarea, useToast } from 'library/atoms';
import { API } from 'core';
import { ReloadIcon, TooltipIcon, CopyIcon } from 'icons';
import { generateKeyWordMap, genrateFormErrorEntityObject } from '../../helper';
import { useHistory, useParams } from 'react-router-dom';
import EntityDurationDropDown from 'library/molecules/Form/EntityDurationDropDown/EntityDurationDropDown';
import { useLCRUpdate } from 'views/main/organization/lcr/hooks/lcrUpdate';
import EntityOwnerDropdown from 'library/molecules/Form/EntityOwnerDropdown/EntityOwnerDropdown';
import { Modal } from 'library/molecules';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_LCR_RELOAD_BUTTON_CLICKED } from 'core/const/tracker';
import { useQueryClient } from 'react-query';
import FormField from 'library/molecules/FormField/FormField';
import { CountryType } from 'library/molecules/Form/EntityCountryDropDown/type';
import EntityCountryDropdown from 'library/molecules/Form/EntityCountryDropDown';
import { Text } from 'library/atoms/Text';

function LCRForm(props: { editMode: boolean }) {
  const { getUniqueRoutingNumber, loadinNumber, loadinNumberError, createLCR, creatingLCR } =
    useLCRCreate();

  const params = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const { getRoutingNumberData, updateLCRData, updatingLCR, loading, extraDurationMinutes } =
    useLCRUpdate(Number(params?.id));
  const setFormData = useLCRStore((state: any) => state.setFormData);
  const formState = useLCRStore((state: LCRListState) => state.formState);

  const [numberCopied, setNumberCopied] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<any>({});
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showConfirmationModalCopy, setShowConfirmationModalCopy] = useState<string>('');
  const [countryTempData, setCountryTempData] = useState<any>(undefined);
  const [routingTypeTempData, setRoutingTypeTempData] = useState<any>(undefined);
  const [routingBehavTempData, setRoutingBehavTempData] = useState<any>(undefined);
  const [isCancelMode, setIsCancelMode] = useState<boolean>(false);
  const [hasAnythingChnged, setHasAnythingChanged] = useState<boolean>(false);
  const firstRender = useRef<boolean>(true);

  const [changeType, setChangeType] = useState<'country' | 'behaviour' | 'type' | ''>('');

  const toast = useToast();
  const history = useHistory();
  const routingNumber = formState.routingNumber;
  const variant = 'floating';
  const formStyles = useMultiStyleConfig('FormField', {
    variant,
  });
  const isEditMode = props.editMode;

  useEffect(() => {
    if (params?.id && isEditMode) {
      getRoutingNumberData();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (firstRender?.current) {
      firstRender.current = false;
    } else {
      setHasAnythingChanged(true);
    }
  }, [formState]);

  useEffect(() => {
    if (numberCopied) {
      toast({
        status: 'success',
        text: `Routing number ‘${routingNumber}’ copied to clipboard `,
      });
    }
  }, [numberCopied]);

  useEffect(() => {
    setErrorState({});
  }, [formState]);

  useEffect(() => {
    return () => {
      setFormData({
        name: '',
        description: '',
        country: undefined,
        routingType: '',
        routingBehavior: undefined,
        greetingMessage: '',
        keywordMapping: [],
        notifyUser: false,
        routingNumber: undefined,
        recordCall: true,
        messageStructure: '',
        callDuration: {
          label: '',
          value: 0,
        },
        owner: undefined,
      });
    };
  }, []);

  const toggleConfirmationModal = useCallback(() => {
    setShowConfirmationModal(!showConfirmationModal);
  }, [showConfirmationModal]);

  const renderRoutingNumberRadioGroup = useMemo(() => {
    return (
      <>
        <Radio
          disabled={isEditMode}
          key={ROUTING_TYPE.Local}
          value={ROUTING_TYPE.Local}
          isChecked={formState?.routingType === ROUTING_TYPE.Local}
        >
          <Text variant="h4"> Local</Text>
        </Radio>
        <Radio
          disabled={isEditMode}
          key={ROUTING_TYPE.TollFree}
          value={ROUTING_TYPE.TollFree}
          isChecked={formState?.routingType === ROUTING_TYPE.TollFree}
        >
          <Text variant="h4"> Toll free</Text>
        </Radio>
        {(formState?.country?.id === 'IN' || formState?.country?.id === 'BE') && (
          <Text variant="formInput" mt={2} lineHeight={1.25}>
            Reach out to{' '}
            <a
              href="mailto:support@squadcast.com"
              style={{
                color: '#0F61DD',
                fontFamily: 'Mulish',
                fontWeight: 600,
                fontStyle: 'normal',
                lineHeight: '16px',
                fontSize: '12px',
              }}
              target="_blank"
              rel="noreferrer"
            >
              support
            </a>{' '}
            to procure a number for {formState?.country?.name}. You can still proceed and finish the
            rest of the configuration while we add the number for you from our backend.
          </Text>
        )}
      </>
    );
  }, [formState?.routingType, formState?.country]);

  const renderRoutingBehaviorRadioGroup = useMemo(() => {
    return Object.values(ROUTING_BEHAVIOR).map(element => {
      if (element === ROUTING_BEHAVIOR.CALL)
        return (
          <Radio
            key={element}
            value={element}
            isChecked={formState?.routingBehavior === ROUTING_BEHAVIOR.CALL}
          >
            <Text variant="h4">Play greeting and call the current on-call responder</Text>
          </Radio>
        );
      return (
        <Radio
          key={element}
          value={element}
          isChecked={formState?.routingBehavior === ROUTING_BEHAVIOR.VOICEMAIL}
        >
          <Text variant="h4">
            {' '}
            Play greeting and go to voicemail. Send the voicemail recording to the current on-call
            responder
          </Text>
        </Radio>
      );
    });
  }, []);

  const getRoutingNumber = useCallback(() => {
    const country = formState?.country;
    const phoneNumberType = formState?.routingType;
    setNumberCopied(false);
    if (country && phoneNumberType) {
      getUniqueRoutingNumber(country?.id, phoneNumberType);
    }
  }, [formState]);

  const hasErrors = Boolean(
    Object.values(genrateFormErrorEntityObject(formState)).find(ele => ele.length !== 0)?.length,
  );

  const createLCRFun = useCallback(async () => {
    const createLCRBody = {
      name: formState?.name,
      description: formState?.description,
      country: formState?.country?.id,
      type: formState?.routingType,
      number: formState?.routingNumber ?? '',
      owner_id: formState?.owner?.id,
      owner_type: formState?.owner?.type,
      routing_type: formState?.routingBehavior,
      record_call: formState?.recordCall,
      greeting_message: formState?.greetingMessage,
      keypad_mappings: generateKeyWordMap(formState?.keywordMapping),
      team_id: API.config.teamId,
      message_structure: formState?.messageStructure,
      max_duration: formState?.callDuration?.label.split(' ').join(''),
    };
    if (!hasErrors) {
      if (isEditMode) {
        await updateLCRData(createLCRBody, () => history?.go(-1));
        queryClient.refetchQueries('lcr-fetch');
        queryClient.refetchQueries('lcr-details');
      } else {
        await createLCR(createLCRBody, () => history.go(-1));
        queryClient.refetchQueries('lcr-fetch');
      }
    } else {
      setErrorState(genrateFormErrorEntityObject(formState));
    }
  }, [formState, genrateFormErrorEntityObject]);

  const getModalHeader = useCallback(() => {
    if (isCancelMode && hasAnythingChnged) {
      return {
        header: 'Unsaved Changes',
        body: 'You haven’t saved any changes. Are you sure you want to discard the changes?',
        button: ['Keep Editing', 'Discard Changes'],
      };
    } else if (isEditMode) {
      if (changeType === 'country') {
        return {
          header: 'Are you sure ?',
          body: 'You can’t change the country. To have a different number, please create a new routing number',
          button: ['Okay'],
        };
      } else if (changeType === 'behaviour') {
        return {
          header: 'Are you sure ?',
          body: 'You can’t change the number type. To have a different number, please create a new routing number',
          button: ['Okay'],
        };
      } else if (changeType === 'type') {
        return {
          header: `Do you want to switch the LCR behaviour to  ${
            formState?.routingBehavior === ROUTING_BEHAVIOR.CALL ? 'Voicemail' : 'Oncall'
          }`,
          body: `${
            formState?.routingBehavior === ROUTING_BEHAVIOR.VOICEMAIL
              ? 'Please attach schedules to each service to notify the on-call responders'
              : 'Switching to voicemail will remove the schedules configured for each service'
          } `,
          button: ['Cancel', 'Switch'],
        };
      }
    } else {
      if (changeType === 'country') {
        return {
          header: 'Are you sure ?',
          body: 'By changing the country, you will lose the generated routing number',
          button: ['Cancel', 'Yes'],
        };
      } else if (changeType === 'behaviour') {
        return {
          header: 'Are you sure ?',
          body: 'By changing the routing number type, you will lose the generated routing number',
          button: ['Cancel', 'Yes'],
        };
      } else if (changeType === 'type') {
        return {
          header: `Do you want to switch the LCR behaviour to  ${
            formState?.routingBehavior === ROUTING_BEHAVIOR.CALL ? 'Voicemail' : 'Oncall'
          }`,
          body: `${
            formState?.routingBehavior === ROUTING_BEHAVIOR.VOICEMAIL
              ? 'Please attach schedules to each service to notify the on-call responde'
              : 'Switching to voicemail will remove the schedules configured for each service'
          } `,
          button: ['Cancel', 'Yes'],
        };
      }
    }
  }, [isEditMode, changeType, isCancelMode]);

  const onModalConfirm = () => {
    if (isCancelMode) {
      history.go(-1);
    } else if (routingTypeTempData) {
      setFormData({
        ...formState,
        routingType: routingTypeTempData,
        routingNumber: undefined,
      });
    } else if (countryTempData) {
      setFormData({
        ...formState,
        country: countryTempData,
        routingNumber: undefined,
      });
    } else if (routingBehavTempData) {
      setFormData({
        ...formState,
        callDuration: null,
        routingBehavior: routingBehavTempData,
        keywordMapping: formState?.keywordMapping.map((ele: MappingTableTypes) => {
          return {
            ...ele,
            schedule: [],
          };
        }),
      });
    }
    toggleConfirmationModal();
    setRoutingTypeTempData(undefined);
    setCountryTempData(undefined);
  };

  const getDisabledStatus = () => {
    let isDisabled = false;
    if (formState?.keywordMapping.length === 0) {
      isDisabled = true;
    } else {
      if (formState?.routingBehavior === ROUTING_BEHAVIOR.VOICEMAIL) {
        formState?.keywordMapping.map((ele: MappingTableTypes) => {
          if (ele?.alias.length === 0 || ele?.service?.length === 0) {
            isDisabled = true;
            return;
          }
        });
      } else {
        formState?.keywordMapping.map((ele: MappingTableTypes) => {
          if (ele?.alias.length === 0 || ele?.service?.length === 0 || ele?.schedule.length === 0) {
            isDisabled = true;
            return;
          }
        });
      }
    }
    return isDisabled;
  };

  const getICconForGenerateNumberField = useCallback(() => {
    if (loadinNumber) {
      return (
        <Tooltip hasArrow placement="bottom" label={'Generating Routing Number'} zIndex={'inherit'}>
          <span>
            <CircularProgress size={5} isIndeterminate />
          </span>
        </Tooltip>
      );
    } else if (routingNumber) {
      return (
        <HStack style={{ width: '100%' }} mr={2}>
          {!isEditMode && (
            <IconButton
              aria-label="Filter"
              variant="header"
              style={{ height: '24px', width: '24px' }}
              icon={<ReloadIcon style={{ width: 10, height: 10, margin: 'auto' }} />}
              onClick={() => {
                AppTracker.track(T_WA_UP_LCR_RELOAD_BUTTON_CLICKED);
                getRoutingNumber();
              }}
              isDisabled={false}
            />
          )}
          <CopyToClipboard
            text={routingNumber}
            onCopy={() => {
              setNumberCopied(true);
            }}
          >
            <IconButton
              aria-label="Filter"
              variant="header"
              style={{ height: '24px', width: '24px' }}
              icon={<CopyIcon style={{ width: 16, height: 16, margin: 'auto' }} />}
              onClick={() => {}}
              isDisabled={false}
            />
          </CopyToClipboard>
        </HStack>
      );
    } else {
      return (
        <FormButton
          bg={'transparent'}
          variant={'ghost'}
          style={{ cursor: 'pointer' }}
          onClick={getRoutingNumber}
          disabled={formState?.routingType === undefined || formState?.country === undefined}
          title="Generate Number"
        />
      );
    }
  }, [loadinNumber, routingNumber, formState, getRoutingNumber]);

  if (loading)
    return (
      <VStack h="inherit" justifyContent="center">
        <CircularProgress isIndeterminate />
      </VStack>
    );

  return (
    <VStack gap={3}>
      <FormField label="Name *" error={errorState?.name}>
        <Input
          value={formState?.name}
          placeholder="Enter Name"
          maxLength={30}
          onChange={(e: any) => {
            setFormData({
              ...formState,
              name: e.target.value,
            });
          }}
        />
      </FormField>

      <FormField label="Description" error={''}>
        <Textarea
          value={formState?.description}
          placeholder="Enter Description"
          maxLength={120}
          onChange={(e: any) => {
            setFormData({
              ...formState,
              description: e.target.value,
            });
          }}
        />
      </FormField>

      <EntityOwnerDropdown
        name="owner"
        error={''}
        source={'LCR'}
        value={formState?.owner}
        onChange={(val: any) => {
          setFormData({
            ...formState,
            owner: val,
          });
        }}
        isRequired
      />

      <EntityCountryDropdown
        name="Country"
        error={''}
        disabled={isEditMode}
        value={formState.country}
        onChange={(val: CountryType) => {
          setChangeType('country');
          setShowConfirmationModalCopy('country');
          if (isEditMode || formState?.routingNumber) {
            toggleConfirmationModal();
            setCountryTempData(val);
          } else {
            setFormData({
              ...formState,
              country: val,
              routingNumber: undefined,
            });
          }
        }}
        isRequired
      />

      <FormField label="Select the type of Number*" error={errorState?.routingType}>
        <RadioGroup
          value={formState?.routingType}
          onChange={(val: ROUTING_TYPE) => {
            setChangeType('behaviour');
            setShowConfirmationModalCopy('routing number type');
            if (isEditMode || formState?.routingNumber) {
              setRoutingTypeTempData(val);
              toggleConfirmationModal();
            } else {
              setFormData({
                ...formState,
                routingType: val,
                routingNumber: undefined,
              });
            }
          }}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {renderRoutingNumberRadioGroup}
        </RadioGroup>
      </FormField>

      <FormField label="Routing Number" error={errorState?.routingNumber}>
        <HStack
          style={{
            width: '100%',
            height: 30,
            border: '1px solid',
            borderColor: 'var(--chakra-colors-gray-200',
            justifyContent: 'space-between',
            borderRadius: '0.1875rem',
            paddingLeft: 5,
            backgroundColor: '#F1F3F6',
          }}
        >
          <Box>
            <Text color={'secondary.400'}>{routingNumber ? routingNumber : '-'}</Text>
          </Box>
          <Box>
            {(formState?.country?.id === 'US' ||
              formState?.country?.id === 'CA' ||
              formState?.country?.id === 'AU' ||
              formState?.country?.id === 'GB') &&
              getICconForGenerateNumberField()}
          </Box>
        </HStack>
      </FormField>

      <FormField label="Preferred Flow of Call*" error={errorState?.routingBehavior}>
        <RadioGroup
          value={formState?.routingBehavior}
          onChange={(val: ROUTING_BEHAVIOR) => {
            if (isEditMode) {
              setRoutingBehavTempData(val);
              setChangeType('type');
              toggleConfirmationModal();
            } else {
              setFormData({
                ...formState,
                callDuration: null,
                routingBehavior: val,
              });
            }
          }}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {renderRoutingBehaviorRadioGroup}
        </RadioGroup>
      </FormField>
      <FormField label="Total Allowed Duration of Call*" error={errorState?.duration}>
        <EntityDurationDropDown
          name="Duration"
          type={formState?.routingBehavior ?? ROUTING_BEHAVIOR.VOICEMAIL}
          onChange={(val: CountryType) => {
            setFormData({
              ...formState,
              callDuration: val,
            });
          }}
          value={formState?.callDuration}
          isRequired
          extraDurationMinutes={extraDurationMinutes}
        />
      </FormField>

      {formState?.routingBehavior === ROUTING_BEHAVIOR.CALL && (
        <Box display={'flex'} flexDirection="row" width={'100%'} justifyContent={'flex-start'}>
          <VStack style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <HStack>
              <FormLabel sx={formStyles.label} marginBottom={0} marginRight={0}>
                Record Call
              </FormLabel>
              <Tooltip
                placement="top"
                label={
                  'The conversation between the caller and the oncall engineer will be recorded and will be attached to the incident'
                }
                style={{ marginBottom: '5px !important', marginLeft: '0px !important' }}
              >
                <Box>
                  <TooltipIcon
                    cursor="pointer"
                    height={13}
                    width={13}
                    style={{ marginBottom: '5px !important', marginLeft: '0px !important' }}
                  />
                </Box>
              </Tooltip>
            </HStack>
          </VStack>
          <Box marginLeft={2}>
            <Switch
              size="sm"
              isChecked={formState?.recordCall}
              onChange={e => {
                setFormData({
                  ...formState,
                  recordCall: !formState?.recordCall,
                });
              }}
            />
          </Box>
        </Box>
      )}
      <FormField
        label="How would you like to greet your caller*"
        error={errorState?.greetingMessage}
        tooltipText="This message will be converted to text-to-speech and played when users call the number. Think of this as a welcome message for the caller"
      >
        <Flex direction="column" gap={2}>
          <Textarea
            value={formState?.greetingMessage}
            maxLength={150}
            onChange={(e: any) => {
              if (/^[a-zA-Z0-9 ,.\-?]+$/.test(e.target.value) || e.target.value.length <= 0) {
                setFormData({
                  ...formState,
                  greetingMessage: e.target.value,
                });
              }
            }}
            placeholder="Type greeting message"
          />
          <Text variant="body" color="secondary.700">
            {150 - (formState?.greetingMessage?.length ?? 0)} character(s) remaining
          </Text>
        </Flex>
      </FormField>
      <FormField
        label="How you would like to structure your message*"
        tooltipText="This will be converted to text-to-speech. Ex: Report issues related to"
        error={errorState?.messageStructure}
      >
        <Flex gap={2} direction="column">
          <HStack gap={0}>
            <Box>
              <Text variant="formInput" color="secondary.1000">
                Press 1 to
              </Text>
            </Box>
            <Input
              value={formState?.messageStructure}
              placeholder="Enter Message"
              maxLength={20}
              style={{ width: '30%' }}
              onChange={(e: any) => {
                if (/^[a-zA-Z0-9 ,.\-?]+$/.test(e.target.value) || e.target.value.length <= 0) {
                  setFormData({
                    ...formState,
                    messageStructure: e.target.value,
                  });
                }
              }}
            />
          </HStack>
          <Text variant="body" color="secondary.700" fontStyle="italic">
            This applies to all the numbers
          </Text>

          <Text variant="body" color="secondary.700">
            {20 - (formState?.messageStructure?.length ?? 0)} character(s) remaining
          </Text>
        </Flex>
      </FormField>

      <FormField label="Map Keypad*">
        {formState?.messageStructure && (
          <Box width={'100%'} bgColor={THEME_COLORS.primary[100]} p={'9px'}>
            <Text variant="h3">
              Eg: For the below setup the IVR will sound as follows
              <span style={{ fontWeight: 600 }}>
                {` Press 1 to ${formState?.messageStructure} < ${
                  formState?.keywordMapping[0]?.alias ?? ''
                } >`}{' '}
              </span>
            </Text>
          </Box>
        )}
      </FormField>
      <MappingTable />

      <HStack
        gap={2}
        style={{ width: '100%', borderTop: '1px solid #E1E6EB', paddingTop: 10, paddingBottom: 10 }}
      >
        <FormButton
          type="submit"
          title="Save"
          onClick={createLCRFun}
          disabled={creatingLCR || hasErrors || updatingLCR || getDisabledStatus()}
          isLoading={creatingLCR}
        />
        <FormButton
          variant="secondary"
          title="Cancel"
          onClick={() => {
            if (!hasAnythingChnged) {
              history.go(-1);
            } else {
              setIsCancelMode(true);
              toggleConfirmationModal();
            }
          }}
        />
      </HStack>
      <Modal
        isOpen={showConfirmationModal}
        onClose={toggleConfirmationModal}
        title={getModalHeader()?.header as string}
        body={
          <Text variant="modal" color="secondary.700">
            {getModalHeader()?.body}
          </Text>
        }
        primaryButton={
          getModalHeader()?.button.length === 2 ? (
            <FormButton
              variant="danger"
              isLoading={loadinNumber}
              onClick={onModalConfirm}
              title={getModalHeader()?.button[1] ?? ''}
            />
          ) : (
            <></>
          )
        }
        secondaryButton={
          <FormButton
            variant="secondary"
            onClick={toggleConfirmationModal}
            title={getModalHeader()?.button[0] ?? ''}
          />
        }
      />
    </VStack>
  );
}

export default React.memo(LCRForm);
