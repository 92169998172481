import React, { useEffect, useState } from 'react';
import {
  Grid,
  Para,
  TextButton,
  Theme,
  Heading,
  DialogModalFrame,
  Select,
  SpinLoader,
  SnackContext,
} from 'uie/components';
import { ThemeProvider } from 'styled-components';
import { API } from '../../../../core/api';
import { IIncident, SloViolatingIncident } from '../../../../core/interfaces/IIncidents';
import { IOrganization } from '../../../../core/interfaces/IOrganization';

import SnackBarComp from '../runbooks/common/snackbar.component';
import moment from 'moment';
import { IncidentService } from 'core/services';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { T_WA_UP_INCIDENT_SLO_AFFECTED } from '../../../../core/const/tracker';
const { theme } = Theme;

export const snackMsg = {
  default: 'Something went wrong please try again',
  default_SUCCESS: 'Something went wrong please try again',
  ADD_TASK_SUCCESS: 'Affects SLO has been added Successfully!',
  SLO_DETAILS_SUCCESS: 'Slos not found',
};
interface forSliData {
  value: string;
  label: string;
}

interface forSloArray {
  value: number;
  label: string;
}
interface forSelectedSloData {
  label: string;
  organization_id: string;
  owner_id: string;
  value: number;
}
interface IProps {
  incident: IIncident | null;
  teamId: string;
  sloData: { slo_violating_incident: SloViolatingIncident };
  getIncidentDetails: () => void;
  closeSloModel: () => void;
  opendSloModal: boolean;
}
const AffectsSlo = (props: IProps) => {
  const __incidentService = new IncidentService();
  const _createSnack = SnackContext();
  const [sloDropDownField, setSloDropDownField] = useState<boolean>(false);
  const [isSaveSlo, setIsSaveSlo] = useState<boolean>(false);
  const [sliData, setSliData] = useState<forSliData[]>([]);
  const [sloData, setSloData] = useState<forSloArray[]>([]);
  const [selectedSliArray, setSelectedSliArray] = useState<forSliData[]>([]);
  const [selectedSloData, setSelectedSloData] = useState<forSelectedSloData>();
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [background, setBackground] = useState<string>('');
  const [slo, setSlo] = useState<any>();

  const hideSnackBar = () => {
    setShowSnack(false);
    setMessage('');
  };
  const showSnackBar = (msg: string, bg: string, timeout: number) => {
    setMessage(msg);
    setBackground(bg);
    setShowSnack(true);
    setTimeout(hideSnackBar, timeout);
  };

  const selectSLO = async (data: forSelectedSloData) => {
    setSloDropDownField(true);
    setSelectedSliArray([]);
    try {
      const res = (await __incidentService.selectSoData(data.value, props.teamId)).data;
      if (res) {
        const SLOoptions: forSliData[] = res.data.slo.slis
          .filter((item: string) => {
            return item;
          })
          .map(item => {
            return {
              value: item,
              label: item,
            };
          });
        setSliData(SLOoptions);
        data.organization_id = API.config.organizationId;
        data.owner_id = res.data.slo.owner_id;
        setSelectedSloData(data);
        AppTracker.track(T_WA_UP_INCIDENT_SLO_AFFECTED);
      }
    } catch (err: any) {
      showSnackBar(
        err?.response?.data?.meta?.error_message || 'Something went wrong',
        theme.danger.default,
        3000,
      );
    }
  };

  const errorBudgetCalc = (): number => {
    if (props?.incident?.status === 'resolved') {
      const updatedTime = moment(props.incident?.updated_at);
      const creationTime = moment(props.incident?.timeOfCreation);
      const error_budget = updatedTime.diff(creationTime, 'seconds');
      const error_budget_in_number = (error_budget / 60).toFixed(3);
      return parseFloat(error_budget_in_number);
    }
    return 0;
  };

  const getSloDetails = async () => {
    try {
      if (props.incident && props.incident.id) {
        const {
          data: { data: sloViolatingIncident },
        } = await __incidentService.sloAffected(props.incident.id, props.teamId);

        if (sloViolatingIncident) {
          setSlo(sloViolatingIncident);
          setSelectedSloData({
            label: sloViolatingIncident.slo_violating_incident.slo_name,
            value: sloViolatingIncident.slo_violating_incident.slo_id,
            organization_id: API.config.organizationId,
            owner_id: API.config.teamId,
          });
          const SLOoptions: forSliData[] = sloViolatingIncident.slo_violating_incident.slis
            .filter((item: string) => {
              return item;
            })
            .map(item => {
              return {
                value: item,
                label: item,
              };
            });
          setSelectedSliArray(SLOoptions);
          setIsSaveSlo(true);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props?.incident?.service_id) {
      sloArray();
    }
  }, [props?.incident?.service_id]);

  useEffect(() => {
    if (!showSnack) {
      _createSnack(null);
    } else {
      _createSnack(
        <SnackBarComp message={message} background={background} hideSnackBar={hideSnackBar} />,
      );
    }
  }, [showSnack]);

  useEffect(() => {
    getSloDetails();
  }, [props?.incident?.id, props.sloData]);

  const sloArray = async () => {
    try {
      const slos = (await __incidentService.sloArrayData(props.teamId)).data;
      if (slos) {
        const SLOoptions: forSloArray[] = slos?.data?.slos
          .filter(items => {
            if (props?.incident?.service_id) {
              return (
                items.is_active === true && items.service_ids.includes(props.incident.service_id)
              );
            }
          })
          .map(item => {
            return {
              value: item.id,
              label: item.name,
            };
          });

        setSloData(SLOoptions);
      }
    } catch (err: any) {
      showSnackBar(
        err?.response?.data?.meta?.error_message || 'Something went wrong',
        theme.danger.default,
        3000,
      );
    }
  };

  const selectSli = (e: forSliData[]) => {
    setIsSaveSlo(true);
    const selectedArray: forSliData[] = [];
    e.map((item: forSliData) => {
      if (item.label !== '' && item.value !== '') {
        return selectedArray.push(item);
      }
    });

    setSelectedSliArray(selectedArray);
  };

  const saveAffectedSlo = async () => {
    setUpdateLoader(true);
    try {
      const slis: string[] = selectedSliArray.map((item: forSliData) => {
        return item.value;
      });

      const errBdgt = errorBudgetCalc();

      if (slis && slis.length > 0 && selectedSloData && props?.incident?.id) {
        const response = await __incidentService.affectedSloSaved(
          selectedSloData?.value,
          props.incident.id,
          slis,
          errBdgt,
          props.teamId,
        );
        if (response) {
          showSnackBar(snackMsg.ADD_TASK_SUCCESS, theme.success.default, 3000);
          props.getIncidentDetails();
          setUpdateLoader(false);
          props.closeSloModel();
        }
      } else {
        props.closeSloModel();
        setUpdateLoader(false);
        showSnackBar(snackMsg.default, theme.danger.default, 3000);
      }
    } catch (err: any) {
      props.closeSloModel();
      setUpdateLoader(false);
      setSloDropDownField(false);
      setIsSaveSlo(false);
      showSnackBar(
        err?.response?.data?.meta?.error_message || 'Something went wrong',
        theme.danger.default,
        3000,
      );
    }
  };

  return (
    <div>
      {
        <DialogModalFrame
          id="errorBudgetUpdate"
          onClose={() => {
            getSloDetails();
            props.closeSloModel();
          }}
          padding="16px"
          width="310px"
        >
          {props.opendSloModal && (
            <Grid flexWidth={12} flexWrap="wrap">
              <Grid flexWidth={12}>
                <Heading fontSize={24} color="#2D3964" fontWeight={500}>
                  Affects SLO
                </Heading>
              </Grid>
              <Grid flexWidth={12}>
                <Para color="#2D3964">Incident affects SLO</Para>
              </Grid>
              {slo && (
                <Grid flexWidth={12}>
                  <Para
                    style={{ fontSize: '14px', color: Theme.theme.danger.default }}
                    className="float-right"
                  >
                    {`Incident is associated with & marked as false positive for SLO: ${
                      slo.slo_violating_incident.slo_name
                    } and SLI: ${slo.slo_violating_incident.slis.join(
                      ',',
                    )}. Selecting a different SLO/SLI will remove the previous mapping.`}
                  </Para>
                </Grid>
              )}
              <Grid
                flexWidth={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'space-between',
                }}
                className="mt-10"
              >
                <Grid
                  className="mt-10"
                  flexWidth={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Grid className="mb-10">
                    <Para style={{ fontWeight: 400, fontSize: '14px', color: '#8690A4' }}>SLO</Para>
                  </Grid>
                  <Grid>
                    <Select
                      options={sloData}
                      value={selectedSloData}
                      placeholder="Select SLO"
                      onChange={(e: any) => selectSLO(e)}
                    />
                  </Grid>
                </Grid>
                {(sloDropDownField || selectedSliArray.length != 0) && (
                  <Grid
                    className="mt-10"
                    flexWidth={12}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Grid className="mb-10">
                      <Para style={{ fontWeight: 400, fontSize: '14px', color: '#8690A4' }}>
                        SLI
                      </Para>
                    </Grid>
                    <Grid>
                      <Select
                        isMulti={true}
                        options={sliData}
                        placeholder="Select SLIs"
                        onChange={(e: any) => selectSli(e)}
                        value={selectedSliArray}
                        isClearable={true}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {isSaveSlo && (
                <Grid type="row" className="mt-10" style={{ flexWrap: 'wrap' }}>
                  {selectedSliArray &&
                    selectedSliArray.map((item: forSliData, inx: number) => {
                      return (
                        <Grid
                          style={{
                            width: 'auto',
                            backgroundColor: theme.shades.whiteSmoke,
                            borderRadius: 2,
                            padding: ' 5px 15px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          className="ml-5 mb-5"
                          alignItems="center"
                          justifyContent="center"
                          key={inx}
                        >
                          <Para
                            fontSize={12}
                            color={theme.shades.cement}
                            style={{ textAlign: 'center' }}
                          >
                            {item.value}
                          </Para>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
              {isSaveSlo && (
                <Grid
                  className="mt-20"
                  style={{ width: '302px', display: 'flex', justifyContent: 'end' }}
                >
                  <TextButton
                    style={{ borderRadius: '2px', fontSize: '16px', color: '#fff' }}
                    className="ml-10 py-10 px-20"
                    onClick={saveAffectedSlo}
                  >
                    {updateLoader && (
                      <ThemeProvider theme={{ ...Theme.theme, revision: 0 }}>
                        <SpinLoader color={theme.primary.light} />
                      </ThemeProvider>
                    )}
                    Save
                  </TextButton>
                </Grid>
              )}
            </Grid>
          )}
        </DialogModalFrame>
      }
    </div>
  );
};

export default AffectsSlo;
