import { HStack, Spacer } from '@chakra-ui/react';
import { useSecondaryFilters } from 'components/SecondaryFilter/context';
import { FormButton, useToast } from 'library/atoms';
import { CustomDrawerComponent, FormSelect } from 'library/molecules';
import FormControlWrapper from 'library/molecules/FormControlWrapper';
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FilterFormTags } from '../../incident-list/filters/form/FilterFormTags';
import { filterDropdownOptions } from '../../incident-list/interfaces/common';
import { removeElementFromArray, uniqueArrayObjects } from './helper';
import { isEmpty } from 'lodash';
import { useGetactiveTagsQuery } from '../../incident-list/graphql/query';
import { QUERY_CONFIG } from '../../incident-list/common/constants';
import { PRIORITY_OPTIONS } from 'library/common/constant';
import { SquadFilterDropdown } from '../../owner-filters/Dropdown/AssigneeDropdown';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_ADD_FILTERS_IN_ANALYTICS_PAGE_V2_USED } from 'core/const/tracker';
import { getDropdownOptionDetails } from '../../owner-filters/helpers';
import { useOrganizationConfig } from '../../schedules';
import { useGetSqauds } from './hooks';
import { useGetServiceListForMaintainer } from '../hooks';

interface FilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  tagsKey: any;
  setTagsKey: (tags: any) => void;
  tagsValue: any;
  setTagsValue: (tags: any) => void;
  keyValue: any;
  setKeysValue: (keys: any) => void;
  priority: any;
  setPriority: (priority: any) => void;
  serviceOwner: any;
  setServiceOwner: (serviceOwner: any) => void;
  services: any;
  setServices: (services: any) => void;
  selectedAssigne: any;
  setSelectedAssigne: (selected: any) => void;
  selectedTeamId: string;
  editMode: boolean;
}

function FilterDrawer(props: FilterDrawerProps) {
  const {
    isOpen,
    onClose,
    serviceOwner,
    setServiceOwner,
    services,
    setServices,
    tagsKey,
    setTagsKey,
    tagsValue,
    setTagsValue,
    keyValue,
    setKeysValue,
    priority,
    setPriority,
    selectedAssigne,
    setSelectedAssigne,
    selectedTeamId,
    editMode,
  } = props;
  const { filters, updateAllFilterAtOnce } = useSecondaryFilters();
  const config = { ...QUERY_CONFIG, staleTime: 1000 };

  const selectedTeam = useMemo(() => filters.teams?.[0], [filters.teams]);

  const toast = useToast();

  const [selectedFilterTags, setSelctedFilterTags] = useState<any>([]);
  const [selectedAssigneLocal, setSelectedAssigneLocal] = useState({
    myUsers: [] as filterDropdownOptions[],
    mySquads: [] as filterDropdownOptions[],
    users: [] as filterDropdownOptions[],
    squads: [] as filterDropdownOptions[],
    meAndMySquads: false,
  });
  const [tagsKeyLocal, setTagsKeyLocal] = useState<any>(null);
  const [tagsValueLocal, setTagsValueLocal] = useState<any>([]);
  const [keyValueLocal, setKeysValueLocal] = useState<any>([]);
  const [priorityLocal, setPriorityLocal] = useState<any>([]);

  const [serviceOwnerLocal, setServiceOwnerLocal] = useState<{
    users: filterDropdownOptions[];
    squads: filterDropdownOptions[];
    myUsers: filterDropdownOptions[];
    mySquads: filterDropdownOptions[];
    meAndMySquads: boolean;
  }>({
    myUsers: [],
    mySquads: [],
    users: [],
    squads: [],
    meAndMySquads: false,
  });
  const [servicesLocal, setServicesLocal] = useState([]);

  useEffect(() => {
    setSelctedFilterTags([
      ...serviceOwnerLocal?.myUsers,
      ...serviceOwnerLocal?.mySquads,
      ...serviceOwnerLocal?.users,
      ...serviceOwnerLocal?.squads,
    ]);
  }, [serviceOwnerLocal]);

  useEffect(() => {
    setServiceOwnerLocal(serviceOwner);
    setServicesLocal(services);
    setTagsKeyLocal(tagsKey);
    setSelectedAssigneLocal(selectedAssigne);
    setTagsValueLocal(tagsValue);
    setKeysValueLocal(keyValue);
    setPriorityLocal(priority);
    setServiceOwnerLocal(serviceOwner);
  }, [
    tagsKey,
    serviceOwner,
    services,
    selectedAssigne,
    tagsValue,
    keyValue,
    priority,
    serviceOwner,
  ]);

  let squadList = [];
  const { squads } = useGetSqauds(selectedTeamId);
  if (selectedTeamId && selectedTeamId?.length) {
    squadList = squads;
  }

  const { tagsList: tagsData } = useGetactiveTagsQuery({ teamID: selectedTeamId }, config);

  const fields = [
    ...serviceOwnerLocal?.myUsers,
    ...serviceOwnerLocal?.mySquads,
    ...serviceOwnerLocal?.users,
    ...serviceOwnerLocal?.squads,
  ];

  const serviceList = useGetServiceListForMaintainer(
    fields?.map((val: any) => {
      return val.value;
    }),
    true,
    selectedTeamId,
  ).serviceList;

  const organization = useOrganizationConfig().organization;

  const { currentUserOption, squadsOfCurrentUser } = getDropdownOptionDetails(
    organization,
    selectedTeamId,
    squadList.length ? squadList : undefined,
  );

  const getUsersForFilter = useCallback((field: any) => {
    return [...field?.myUsers, ...field?.users];
  }, []);

  const getServiceOwnersForFilter = useCallback((field: any) => {
    return [...field?.myUsers, ...field?.users, ...field?.mySquads, ...field?.squads];
  }, []);

  const getServciesForFilter: any = useMemo(() => {
    if (servicesLocal && servicesLocal?.length) return servicesLocal;
    else if (
      (serviceOwnerLocal?.mySquads.length ||
        serviceOwnerLocal?.users.length ||
        serviceOwnerLocal?.squads?.length ||
        serviceOwnerLocal?.myUsers?.length) &&
      !services?.length
    ) {
      return serviceList;
    }
    return [];
  }, [serviceOwnerLocal, servicesLocal, serviceList]);

  const handleFormSubmit = () => {
    const filterData = {
      priority: [...priorityLocal.map((item: any) => item.value)],
      services: [...getServciesForFilter.map((item: any) => item.value)],
      tags: [...keyValueLocal.map((item: any) => item.label)],
      teams: [...(filters?.teams ?? [])],
      users: [
        ...getUsersForFilter(selectedAssigneLocal).map((item: any) => item.value),
        ...selectedAssigneLocal?.squads.map((item: any) => item.value),
        ...selectedAssigneLocal?.mySquads.map((item: any) => item.value),
      ],
      squads: [],
      serviceOwner: [
        ...getServiceOwnersForFilter(serviceOwnerLocal).map((item: any) => item.value),
      ],
      showServices: servicesLocal && servicesLocal?.length ? ['true'] : ['false'],
    };

    const getAnalyticsForm = (field: any) => {
      let obj: any = {
        'Me and My Squads': field?.meAndMySquads,
      };
      if (field?.users.length)
        obj = {
          ...obj,
          Users: field?.users.map((user: any) => user?.value),
        };
      if (field?.squads.length)
        obj = {
          ...obj,
          Squads: field?.squads.map((user: any) => user?.value),
        };
      return obj;
    };

    let trackedAppliedFilter: any = {
      Assignee: getAnalyticsForm(selectedAssigneLocal),
      'Service Owner': getAnalyticsForm(serviceOwnerLocal),
    };
    if (filterData?.services.length) {
      trackedAppliedFilter = {
        ...trackedAppliedFilter,
        Services: filterData?.services,
      };
    }
    if (filterData?.priority.length) {
      trackedAppliedFilter = {
        ...trackedAppliedFilter,
        Services: filterData?.services,
      };
    }
    if (filterData?.services.length && servicesLocal) {
      trackedAppliedFilter = {
        ...trackedAppliedFilter,
        Priority: filterData?.priority,
      };
    }
    if (filterData?.tags.length) {
      trackedAppliedFilter = {
        ...trackedAppliedFilter,
        Priority: filterData?.tags,
      };
    }
    if (filterData?.serviceOwner.length && filterData?.services?.length === 0) {
      toast({
        status: 'error',
        text: `No Service Selected / No Service associated with the selected service owner`,
      });
    } else {
      AppTracker.track(T_WA_UP_ADD_FILTERS_IN_ANALYTICS_PAGE_V2_USED, trackedAppliedFilter);
      updateAllFilterAtOnce(filterData);
      setServiceOwner(serviceOwnerLocal);
      setServices(servicesLocal);
      setTagsKey(tagsKeyLocal);
      setTagsValue(tagsValueLocal);
      setKeysValue(keyValueLocal);
      setPriority(priorityLocal);
      setSelectedAssigne(selectedAssigneLocal);
      onClose();
    }
  };

  const clearFilter = () => {
    setSelectedAssigne({
      users: [],
      squads: [],
      mySquads: [],
      myUsers: [],
      meAndMySquads: false,
    });

    setServiceOwner({
      users: [],
      squads: [],
      mySquads: [],
      myUsers: [],
      meAndMySquads: false,
    });
    setServices([]);
    setTagsKey(null);
    setTagsValue([]);
    setKeysValue([]);
    setPriority([]);
    updateAllFilterAtOnce({
      priority: [],
      services: [],
      tags: [],
      teams: [...(filters?.teams ?? [])],
      users: [],
      squads: [],
      serviceOwner: [],
      showServices: ['false'],
    });
    onClose();
  };

  const isApplyDisabled =
    !priorityLocal.length &&
    !servicesLocal.length &&
    !keyValueLocal.length &&
    !selectedAssigneLocal.users.length &&
    !selectedAssigneLocal.squads.length &&
    !selectedAssigneLocal.myUsers.length &&
    !selectedAssigneLocal?.mySquads.length &&
    !serviceOwnerLocal.users.length &&
    !serviceOwnerLocal.squads.length &&
    !serviceOwnerLocal.myUsers.length &&
    !serviceOwnerLocal?.mySquads.length;

  return (
    <CustomDrawerComponent
      size="md"
      renderHeader
      isOpen={isOpen}
      onClose={onClose}
      title="Filter"
      disableBodyPadding
      footer={
        <HStack spacing={3}>
          <FormButton
            title="Apply"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={handleFormSubmit}
            isDisabled={isApplyDisabled}
          />
          <FormButton
            title="Clear All"
            variant="ghost"
            onClick={() => {
              clearFilter();
            }}
          />
        </HStack>
      }
    >
      <form onSubmit={handleFormSubmit}>
        <SquadFilterDropdown
          label="Assignee"
          description="View incidents where the selected entity has been assigned, either currently or historically"
          value={selectedAssigneLocal}
          placeholder="Select Assignee"
          onClear={() => {
            setSelectedAssigneLocal({
              users: [],
              squads: [],
              mySquads: [],
              myUsers: [],
              meAndMySquads: false,
            });
          }}
          type="assignee"
          onChange={(value: any) => {
            if (value?.type === 'select-all') {
              setSelectedAssigneLocal({
                meAndMySquads: true,
                myUsers: currentUserOption,
                mySquads: squadsOfCurrentUser,
                users: removeElementFromArray(
                  currentUserOption[0].value,
                  selectedAssigneLocal.users,
                ),
                squads: [...selectedAssigneLocal.squads].filter(
                  el => ![...squadsOfCurrentUser].map(sq => sq.value).includes(el.value),
                ),
              });
            } else if (value?.type === 'user') {
              if (!selectedAssigneLocal.mySquads.find(s => s.value === value.value)) {
                setSelectedAssigneLocal({
                  meAndMySquads: selectedAssigneLocal?.meAndMySquads,
                  users: uniqueArrayObjects(
                    selectedAssigneLocal.users.length ? selectedAssigneLocal?.users : [],
                    value,
                  ),
                  squads: selectedAssigneLocal.squads,
                  myUsers: selectedAssigneLocal?.myUsers,
                  mySquads: selectedAssigneLocal?.mySquads,
                });
              }
            } else if (value?.type === 'squad') {
              if (!selectedAssigneLocal.mySquads.find(s => s.value === value.value)) {
                setSelectedAssigneLocal({
                  meAndMySquads: selectedAssigneLocal?.meAndMySquads,
                  squads: uniqueArrayObjects(
                    selectedAssigneLocal.squads?.length ? selectedAssigneLocal.squads : [],
                    value,
                  ),
                  users: selectedAssigneLocal.users,
                  myUsers: selectedAssigneLocal?.myUsers,
                  mySquads: selectedAssigneLocal?.mySquads,
                });
              }
            }
          }}
          meAndMySqaudField={selectedAssigneLocal}
          setterFunction={setSelectedAssigneLocal}
          selectedTeam={selectedTeam}
          onRemove={(type: string, id: string) => {
            if (id === 'meAndMySquads') {
              setSelectedAssigneLocal({
                meAndMySquads: false,
                users: selectedAssigneLocal.users,
                squads: selectedAssigneLocal.squads,
                myUsers: [],
                mySquads: [],
              });
            } else if (type === 'user') {
              setSelectedAssigneLocal({
                meAndMySquads: selectedAssigneLocal?.meAndMySquads,
                users: removeElementFromArray(id, selectedAssigneLocal.users),
                squads: selectedAssigneLocal.squads,
                myUsers: removeElementFromArray(id, selectedAssigneLocal.myUsers),
                mySquads: selectedAssigneLocal?.mySquads,
              });
            } else if (type === 'squad') {
              setSelectedAssigneLocal({
                meAndMySquads: selectedAssigneLocal?.meAndMySquads,
                squads: removeElementFromArray(id, selectedAssigneLocal.squads),
                users: selectedAssigneLocal.users,
                myUsers: selectedAssigneLocal?.myUsers,
                mySquads: removeElementFromArray(id, selectedAssigneLocal.mySquads),
              });
            }
          }}
        />

        <SquadFilterDropdown
          type="serviceOwner"
          label="Service Owner"
          description="Find incidents for services owned by specific squad or users"
          value={serviceOwnerLocal}
          onClear={() => {
            setServiceOwnerLocal({
              users: [],
              squads: [],
              mySquads: [],
              myUsers: [],
              meAndMySquads: false,
            });
          }}
          placeholder="Select Service Owner"
          setSelctedFilterTags={(val: any) => setSelctedFilterTags(val)}
          selectedTeam={selectedTeam}
          onChange={(value: any) => {
            if (value?.type === 'select-all') {
              setServiceOwnerLocal({
                meAndMySquads: true,
                users: removeElementFromArray(currentUserOption[0].value, serviceOwnerLocal.users),
                squads: serviceOwnerLocal.squads.filter(
                  el => ![...squadsOfCurrentUser].map(sq => sq.value).includes(el.value),
                ),
                myUsers: currentUserOption,
                mySquads: squadsOfCurrentUser,
              });
            } else if (value?.type === 'user') {
              if (!serviceOwnerLocal.myUsers.find(s => s.value === value.value)) {
                setServiceOwnerLocal({
                  meAndMySquads: serviceOwnerLocal?.meAndMySquads,
                  users: uniqueArrayObjects(serviceOwnerLocal.users, value),
                  squads: serviceOwnerLocal.squads,
                  myUsers: serviceOwnerLocal?.myUsers,
                  mySquads: serviceOwnerLocal?.mySquads,
                });
              }
            } else if (value?.type === 'squad') {
              if (!serviceOwnerLocal.mySquads.find(s => s.value === value.value)) {
                setServiceOwnerLocal({
                  meAndMySquads: serviceOwnerLocal?.meAndMySquads,
                  squads: uniqueArrayObjects(serviceOwnerLocal.squads, value),
                  users: serviceOwnerLocal.users,
                  myUsers: serviceOwnerLocal?.myUsers,
                  mySquads: serviceOwnerLocal?.mySquads,
                });
                setServicesLocal([]);
              }
            }
            setServicesLocal([]);
          }}
          meAndMySqaudField={serviceOwnerLocal}
          setterFunction={setServiceOwnerLocal}
          onRemove={(type: string, id: string) => {
            if (id === 'meAndMySquads') {
              setServiceOwnerLocal({
                meAndMySquads: false,
                users: serviceOwnerLocal.users,
                squads: serviceOwnerLocal.squads,
                myUsers: [],
                mySquads: [],
              });
            } else if (type === 'user') {
              setServiceOwnerLocal({
                meAndMySquads: serviceOwnerLocal?.meAndMySquads,
                users: removeElementFromArray(id, serviceOwnerLocal.users),
                squads: serviceOwnerLocal.squads,
                myUsers: removeElementFromArray(id, serviceOwnerLocal.myUsers),
                mySquads: serviceOwnerLocal?.mySquads,
              });
            } else if (type === 'squad') {
              setServiceOwnerLocal({
                meAndMySquads: serviceOwnerLocal?.meAndMySquads,
                squads: removeElementFromArray(id, serviceOwnerLocal.squads),
                users: serviceOwnerLocal.users,
                myUsers: serviceOwnerLocal?.myUsers,
                mySquads: removeElementFromArray(id, serviceOwnerLocal.mySquads),
              });
            }
            setServicesLocal([]);
          }}
        />
        <FormControlWrapper
          label="Services"
          value={servicesLocal}
          onClear={() => {
            setServicesLocal([]);
          }}
        >
          <FormSelect
            options={serviceList}
            value={servicesLocal}
            label="Services"
            hideLabel
            onChange={(type, value) => {
              setServicesLocal(value as SetStateAction<never[]>);
            }}
            error={''}
            isMulti={true}
            fieldName="services"
            isClearable={false}
          />
          <FilterFormTags
            values={servicesLocal}
            setFieldValue={(type, value) => {
              setServicesLocal(value as SetStateAction<never[]>);
            }}
            type="services"
          />
        </FormControlWrapper>
        <FormControlWrapper
          label="Tags"
          value={tagsKeyLocal}
          onClear={() => {
            setTagsKeyLocal(null);
            setTagsValueLocal([]);
            setKeysValueLocal([]);
          }}
        >
          <FormSelect
            options={tagsData}
            value={tagsKeyLocal}
            label="Tags Key"
            hideLabel
            controlShouldRenderValue
            onChange={(fieldName, value) => {
              if (fieldName === 'tagsKey') setTagsKeyLocal(value);
              if (fieldName === 'tagsValue') setTagsValueLocal(value);
              if (value && Array.isArray(value)) setKeysValueLocal([...keyValue, ...value]);
            }}
            error={''}
            isMulti={false}
            fieldName="tagsKey"
            isClearable={false}
          />
          <Spacer mb={3} />
          <FormSelect
            options={
              tagsKeyLocal
                ? Array.isArray(tagsKeyLocal.child)
                  ? tagsKeyLocal.child.map((child: any) => {
                      return { label: `${tagsKeyLocal.label}:${child}`, value: child };
                    })
                  : []
                : []
            }
            value={tagsValueLocal}
            label="Tags Value"
            hideLabel
            onChange={(fieldName, value) => {
              if (fieldName === 'tagsKey') setTagsKeyLocal(value);
              if (fieldName === 'tagsValue') setTagsValueLocal(value);
              setKeysValueLocal(value);
            }}
            error={''}
            isMulti={true}
            fieldName="tagsValue"
            isDisabled={!tagsKeyLocal || isEmpty(tagsKeyLocal)}
            isClearable={false}
          />
          <FilterFormTags
            values={keyValueLocal}
            valueSecondary={tagsValueLocal}
            typeSecondary={'tagsValue'}
            setFieldValue={(type, value) => {
              setKeysValueLocal(value);
              setTagsValueLocal(value);
            }}
            type="keyValue"
          />
        </FormControlWrapper>
        <FormControlWrapper
          label="Priority"
          value={priorityLocal}
          onClear={() => {
            setPriorityLocal([]);
          }}
        >
          <FormSelect
            options={PRIORITY_OPTIONS}
            value={priorityLocal}
            onChange={(type, value) => setPriorityLocal(value)}
            error={''}
            isMulti={true}
            label="Priority"
            hideLabel
            fieldName="priority"
            isClearable={false}
            variant="priority"
          />
          <FilterFormTags
            values={priorityLocal}
            setFieldValue={(type, value) => setPriorityLocal(value)}
            type="priority"
          />
        </FormControlWrapper>
      </form>
    </CustomDrawerComponent>
  );
}

export default React.memo(FilterDrawer);
