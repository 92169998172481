import { Flex, Text, Circle } from '@chakra-ui/layout';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FILTER_TYPE_LABEL } from '../../constant';
import { FilterTypes, IGroupType, WorkflowForm } from '../../types';
import ServiceFilter from './service/ServiceFilter';
import AlertSourceFilter from './alertSource/AlertSourceFilter';
import PriorityFilter from './priority/PriorityFilter';
import { DeleteIcon } from '@chakra-ui/icons';

import TagFilter from './tags/TagFilter';
import { IconButton } from 'library/atoms';

interface Props {
  type: keyof typeof FILTER_TYPE_LABEL | string;
  index: number;
  isGroup: IGroupType;
  removeField: (index: number) => void;
  subIndex?: number;
  filterFieldName: string;
}

const FilterContent = ({ type, filterFieldName }: Pick<Props, 'type' | 'filterFieldName'>) => {
  switch (type) {
    case FilterTypes.SERVICE:
      return (
        <ServiceFilter<WorkflowForm> filterFieldName={filterFieldName} filterName={'service'} />
      );
    case FilterTypes.ALERT_SOURCE:
      return (
        <AlertSourceFilter<WorkflowForm>
          filterFieldName={filterFieldName}
          filterName={'alert_source'}
        />
      );
    case FilterTypes.TAGS:
      return <TagFilter filterFieldName={filterFieldName} filterName={'tag'} />;

    case FilterTypes.PRIORITY:
      return (
        <PriorityFilter<WorkflowForm> filterFieldName={filterFieldName} filterName={'priority'} />
      );
    default:
      return <></>;
  }
};

const FilterTypeContainer = ({
  type,
  index,
  isGroup,
  subIndex,
  removeField,
}: Omit<Props, 'filterFieldName'>) => {
  const { remove: removeGroupField } = useFieldArray<WorkflowForm>({
    name: `group.fields.children.${index}.fields.children`,
  });
  const { watch, setValue, getValues } = useFormContext<WorkflowForm>();

  // prefix name of filter
  const name = `group.fields.children.${index}`;
  // postfix name based on if active is filter or group
  const filterFieldName = isGroup === 'filter' ? `${name}` : `${name}.fields.children.${subIndex}`;
  return (
    <Flex direction="column" pb={4} pt={2}>
      <Flex align="center">
        <Circle size="3" mr="2" backgroundColor="secondary.900" color="secondary.900" />
        <Flex flex={1}>
          <Text textStyle="bold_800" variant="h3">
            {FILTER_TYPE_LABEL[type as keyof typeof FILTER_TYPE_LABEL]}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <FilterContent filterFieldName={filterFieldName} type={type} />

        <IconButton
          icon={<DeleteIcon />}
          aria-label="delete"
          onClick={() => {
            if (
              isGroup === 'filter' ||
              getValues(`group.fields.children.${index}.fields.children`).length === 1
            ) {
              if (watch('group.fields.children').length === 1) {
                setValue('group.type', 'empty');
              }
              removeField(index);
            } else {
              removeGroupField(subIndex);
            }
          }}
        />
      </Flex>
    </Flex>
  );
};

export default FilterTypeContainer;
