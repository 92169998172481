import { IIntegration } from 'core/interfaces/IIntegration';
import { AnyObject } from 'library/types';
import qs from 'query-string';
import { generatePath } from 'react-router-dom';
import { GER_DETAILS_PATH, GER_EDIT_PATH } from 'views/main/routes/routes';
import { EntityType } from '../../navigation-flows/helpers';

import useUINavigationFunctions from '../../navigation-flows/useUINavigationFunctionV2';
import { ALERT_INFO, IntegrationType } from '../types';

export const getGerPath = (id: string, queryParams?: AnyObject) => {
  let route = generatePath(GER_DETAILS_PATH, { id });
  if (queryParams) {
    route = `${route}?${qs.stringify(queryParams)}`;
  }
  return route;
};

export const getGerEditPath = (id: string) => {
  return generatePath(GER_EDIT_PATH, { id });
};

export const useGetEntityName = (type: EntityType, id: string) => {
  const { getEntityName } = useUINavigationFunctions();
  return getEntityName(type, id);
};

export const useGetEntityUsername = (type: EntityType, id: string) => {
  const { getEntityUsername } = useUINavigationFunctions();
  return getEntityUsername(type, id);
};

export const useHasEntityObject = (type: EntityType, id: string) => {
  const { getEntityObject } = useUINavigationFunctions();
  return !!getEntityObject(type, id);
};

export const useGetEntityLink = (type: EntityType, id: string) => {
  const { getEntityLink } = useUINavigationFunctions();
  return getEntityLink(type, id);
};

export const getAlertsList = (integrations?: IntegrationType): ALERT_INFO[] => {
  const alerts: IIntegration[] = integrations?.i ?? [];
  return alerts
    .filter(alert => !alert.isPrivate)
    .map(alert => {
      return {
        name: alert.type,
        shortName: alert.shortName,
        version: alert.version,
        iconURL: alert.iconURL?.svg,
        id: alert._id,
        categories: alert.categories ?? [],
      };
    });
};
