import { FormikValues } from 'formik';
import moment from 'moment';
import { DateFilterChoice } from '../../graphql/generated/types';
import { Filters } from '../../interfaces/filters';
import { getDefaultTimeStr } from '../manager/util';

export type FilterFormError = Partial<{ created: string; tagsValue: string; name: string }>;

export const validateFilterForm = (values: Filters | FormikValues) => {
  const errors: FilterFormError = {};

  if (values.created?.value === DateFilterChoice.Range) {
    if (values?.startDate && values?.endDate) {
      const start = moment(values?.startDate);
      const end = moment(values?.endDate);

      const [startHrs, startMins] = (values?.startTime ?? getDefaultTimeStr(false)).split(':');
      const [endHrs, endMins] = (values?.endTime ?? getDefaultTimeStr(true)).split(':');

      const dateDiff = start.diff(end);
      if (
        dateDiff > 0 ||
        (dateDiff === 0 && (startHrs > endHrs || (startHrs === endHrs && startMins > endMins)))
      ) {
        errors.created = 'From cannot be greater than To';
      }
    } else {
      errors.created = 'For custom date range From & To is required';
    }
  }
  if (values.tagsKey && !values.tagsValue.length) {
    errors.tagsValue = 'Please select tags value along with tags key';
  }
  if (values.id && !values.name) {
    errors.name = 'Name is required for save filter';
  }
  return errors;
};
