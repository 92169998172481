import { SetStateAction, Dispatch } from 'react';
import {
  BE_FieldType,
  FilterTypeOption,
  WorkflowForm,
  WorkFlowGroup,
  WorkFlowGroupRecur,
} from 'views/main/organization/workflow/types';
import { FILTER_TYPE_OPTIONS } from 'views/main/organization/workflow/constant';
import { UseFieldArrayAppend, useFormContext } from 'react-hook-form';
import { Circle, Flex } from '@chakra-ui/layout';
import { DeleteIcon } from '@chakra-ui/icons';
import FormField from 'library/molecules/FormField/FormField';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';
import { IconButton } from 'library/atoms';
import { Tag } from 'library/molecules/Form/TagInput/useFormTag';

interface IFilterProps {
  setAddFilter: Dispatch<SetStateAction<boolean>>;
  setAddGroup: Dispatch<SetStateAction<boolean>>;
  addGroup: UseFieldArrayAppend<WorkflowForm>;
  addFilter: UseFieldArrayAppend<WorkflowForm>;
  setShowButton: (v: boolean) => void;
}
type OptionType =
  | Tag
  | WorkFlowGroup
  | {
      type: string;
      filterType?: string;
      fields: FilterTypeOption | WorkFlowGroupRecur | BE_FieldType;
      value?: string;
    }
  | Array<
      | Tag
      | WorkFlowGroup
      | {
          type: string;
          filterType?: string;
          fields: FilterTypeOption | WorkFlowGroupRecur | BE_FieldType;
          value?: string;
        }
    >;

const FilterSelection = ({
  setAddFilter,
  setAddGroup,
  addGroup,
  addFilter,
  setShowButton,
}: IFilterProps) => {
  const { watch } = useFormContext<WorkflowForm>();

  function handleOption(option: OptionType) {
    if (watch('isGroup') === 'filter' || watch('isGroup') === 'group') {
      addGroup(option);
    } else if (watch('isGroup') === 'subgroup') {
      addFilter(option);
    }
  }
  return (
    <Flex>
      <Circle size="3" mr="2" mt="1" backgroundColor="secondary.900" color="secondary.900" />
      <Flex width="full" gap="2" alignItems="center">
        <FormField label="Select Filter">
          <FrameWorkDropdown<FilterTypeOption>
            options={FILTER_TYPE_OPTIONS}
            value={null}
            placeholder="Select filter"
            onChange={option => {
              if (option) {
                handleOption(option);
              }
              setAddFilter(false);
              setShowButton(true);
              setAddGroup(false);
            }}
            isMulti={false}
            isClearable={false}
          />
        </FormField>
        <IconButton
          mt={4}
          icon={<DeleteIcon />}
          aria-label="delete"
          onClick={() => {
            setAddFilter(false);
            setAddGroup(false);
          }}
        />
      </Flex>
    </Flex>
  );
};

export default FilterSelection;
