import { ResultSet } from '@cubejs-client/core';
import { DatumValue } from '@nivo/core';
import { DefaultRawDatum } from '@nivo/pie';
import {
  BarsChart,
  CalendarChart,
  LineData,
  LinesChart,
  PieChart,
  Stat,
  StatsChart,
} from '../types';

export const round = (num: number) => +num.toFixed(2);

export const deltaPercent = (a: number, b: number | undefined) =>
  b ? round(((a - b) / b) * 100) : 0;

const resultSetToNumber = (resultSet: ResultSet) => {
  if (resultSet?.series) {
    return resultSet?.series()[0]?.series[0].value;
  }
  return 0;
};

export function resultSetToStat(resultSet: ResultSet, chart: StatsChart): Stat {
  const valueFn = chart.valueFn;

  let value = 0,
    previousValue;

  // TODO: Fix ResultSet types instead.
  if ((resultSet as any).queryType === 'regularQuery') {
    value = resultSetToNumber(resultSet);
  } else if ((resultSet as any).queryType === 'compareDateRangeQuery') {
    const [a, b] = resultSet.decompose();
    [value, previousValue] = [resultSetToNumber(a), resultSetToNumber(b)];
  }

  return {
    value: valueFn ? valueFn(value) : value,
    previousValue: previousValue && valueFn ? valueFn(previousValue) : previousValue,
  };
}

type CubeDataPoint = {
  x: DatumValue;
  value: number;
};

export function resultSetToLines(
  resultSet: ResultSet,
  chart: LinesChart,
  isCompare?: boolean,
): LineData[] {
  const { xValueFn, yValueFn, seriesNames } = chart;
  let ID = 0;

  if (resultSet?.series) {
    return resultSet?.series<CubeDataPoint>()?.map((series, i) => {
      if (i % 2 === 0) {
        ID = ID + 1;
      }
      return {
        id:
          (seriesNames?.[series.key] ||
            seriesNames?.[Object.keys(seriesNames).find(k => series.key.includes(k)) || ''] ||
            series.key) + (isCompare ? `(${ID})` : ''),
        color: '#000',
        data: series?.series?.map(serie => ({
          x: xValueFn ? xValueFn(serie.x) : serie.x,
          y: yValueFn ? yValueFn(serie.value) : serie.value,
        })),
      };
    });
  } else {
    return [];
  }
}

export function resultSetToBars(resultSet: ResultSet, chart: BarsChart) {
  return resultSet
    .chartPivot({
      x: ['Services.name', 'Teams.name'],
    })
    .map(pivot => {
      if (['[Empty string]', '∅'].includes(pivot.x)) {
        pivot.x = 'None';
        pivot.xValues = ['None'];
      }
      return pivot;
    })
    .reverse(); // Show result in descending count order
}

export function resultSetToPie(resultSet: ResultSet, chart: PieChart): DefaultRawDatum[] {
  return resultSet
    .chartPivot({
      x: ['Services.name', 'Teams.name'],
    })
    .map(pivot => ({ id: pivot.xValues[1], value: pivot[chart.measures[0]] })) as any;
}

export function resultSetToCalendar(resultSet: ResultSet, chart: CalendarChart) {
  return resultSet
    .chartPivot()
    .map(pivot => {
      return {
        // Remove timestamp
        day: pivot.x.split('T')[0],
        value: pivot[chart.measures[0]] ?? 0,
      };
    })
    .filter(pivot => pivot.value > 0);
}
