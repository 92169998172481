import { CloseIcon } from '@chakra-ui/icons';
import { Box, FormLabel, HStack, Text, VStack } from '@chakra-ui/react';
import { FormButton, IconButton } from 'library/atoms';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';
import { ColorSelection } from 'library/molecules';

import { TERTIARY_COLORS } from 'library/theme/colors';
import { FC } from 'react';
import useTagInput from './useFormTag';

export type onTagUpdateParams = Parameters<typeof useTagInput>[number]['onChange'];

type Props = {
  isSingleTag?: boolean;
  isVerticalAlign?: boolean;
  tagsList?: Parameters<typeof useTagInput>[number]['availableTags'];
  onTagUpdate?: onTagUpdateParams;
  initialValue?: Parameters<typeof useTagInput>[number]['initialValue'];
  isTagCreatable?: boolean;
  width?: {
    key: number | string;
    value: number | string;
    color: number | string;
  };
  error?: Array<{
    key?: string;
    value?: string;
  }>;
};

const removeDuplicates = (arr: any[]) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const FormTag: FC<Props> = ({
  isSingleTag,
  tagsList,
  isVerticalAlign,
  onTagUpdate,
  initialValue,
  isTagCreatable = false,
  width,
  error,
}) => {
  const tagGroupData = useTagInput({
    initialValue,
    availableTags: tagsList || [],
    onChange: onTagUpdate,
  });

  return (
    <VStack alignItems="start" w="full">
      {tagGroupData.tags.map((tag, index) => {
        const selectedKeyValues = tag.key
          ? (tagGroupData.keyValueOptions[tag.key] || []).map(value => ({
              label: value,
              value,
            }))
          : [];

        let keyOptions = tagGroupData.keyOptions;
        let keyOption = keyOptions.find(key => key.label === tag.key) || null;

        let valueOptions = selectedKeyValues;
        valueOptions = removeDuplicates(valueOptions);
        let valueOption = selectedKeyValues.find(keyValue => keyValue.value === tag.value) || null;

        if (isTagCreatable) {
          if (!keyOption && tag.key) {
            keyOption = { label: tag.key, value: tag.key, valuesCount: tag.value ? 1 : 0 };
            keyOptions = keyOptions.concat([keyOption]);
          }
          if (!valueOption && tag.value) {
            valueOption = { label: tag.value, value: tag.value };
            valueOptions = valueOptions.concat([valueOption]);
          }
        }

        const style =
          error?.[index]?.key || error?.[index]?.value ? { marginBottom: '20px' } : undefined;

        return (
          <HStack
            key={index}
            spacing={0}
            gap={2}
            w="full"
            flexDir={isVerticalAlign ? 'column' : 'row'}
            style={style}
          >
            <Box w={isVerticalAlign ? 'inherit' : width?.key ?? 299}>
              <FormLabel htmlFor={`key${index}`} visibility="hidden" m={0} height={0}>
                Key {index}
              </FormLabel>
              <FrameWorkDropdown
                inputId={`key${index}`}
                options={keyOptions}
                value={keyOption}
                onChange={option => {
                  if (option) {
                    tagGroupData.onTagKeyChange(index)(option.value);
                  }
                }}
                placeholder={isVerticalAlign ? 'Type or Select Tag Key' : 'Key'}
                isCreatable={isTagCreatable}
                formatCreateLabel={label => (
                  <Text color="brand.blue" variant="formInput">{`+ ${label}`}</Text>
                )}
              />
              {error?.[index]?.key && (
                <Text color="red.500" fontSize="sm" position="absolute">
                  {error?.[index]?.key}
                </Text>
              )}
            </Box>
            <Box w={isVerticalAlign ? 'inherit' : width?.value ?? 249}>
              <FormLabel htmlFor={`value${index}`} visibility="hidden" m={0} height={0}>
                Value {index}
              </FormLabel>
              <FrameWorkDropdown
                inputId={`value${index}`}
                options={valueOptions}
                value={valueOption}
                onChange={option => {
                  if (option) {
                    tagGroupData.onTagValueChange(index)(option.value);
                  }
                }}
                placeholder={isVerticalAlign ? 'Type or Select Tag Value' : 'Value'}
                isCreatable={isTagCreatable}
                formatCreateLabel={label => (
                  <Text color="brand.blue" variant="formInput">{`+ ${label}`}</Text>
                )}
                isDisabled={isVerticalAlign ? !keyOption : false}
              />
              {error?.[index]?.value && (
                <Text color="red.500" fontSize="sm" position="absolute">
                  {error?.[index]?.value}
                </Text>
              )}
            </Box>
            {!isSingleTag && (
              <>
                <Box w={width?.color ?? 136}>
                  <ColorSelection
                    options={TERTIARY_COLORS}
                    onChange={tagGroupData.onTagColorChange(index)}
                  />
                </Box>
                <IconButton
                  aria-label="Delete tag"
                  icon={<CloseIcon />}
                  variant="ghost"
                  onClick={tagGroupData.onTagDelete(index)}
                />
              </>
            )}
          </HStack>
        );
      })}

      {!isSingleTag && (
        <FormButton
          onClick={tagGroupData.onTagAdd}
          data-testid="add-another-tag"
          variant="ghost"
          title="Add Another Tag"
        />
      )}
    </VStack>
  );
};
