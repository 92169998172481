import { TRIGGER_OPTIONS_LABEL_MAP } from '../constant';
import {
  communicationChannelInitValues,
  incidentNoteInitValues,
  sloAffectingInitValues,
  reassignIncidentInitValues,
  runbookInitValues,
  tagFilterInitValue,
  webhookInitValues,
  httpCallInitValues,
  emailInitValues,
  messageTeamChannelInitValues,
  messageTeamUserInitValues,
  createSlackInitValues,
  messageUserInitValues,
  messageChannelInitValues,
  jiraTicketInitValues,
  priorityInitValues,
  statusPageInitValues,
} from '../constant/values';
import {
  ActionTypeDetails,
  SloAffectingAction,
  SqCommunicationChannelAction,
  SubActionTypes,
  WorkflowForm,
  RunbookAction,
  FilterTypeOption,
} from '../types';
import {
  SloAffectingActionResponse,
  SqCommunicationChannelActionResponse,
  WorkFlowTagsResponse,
  Workflow,
  AttachRunbookActionResponse,
} from '../types/response/workflow';

import { GetIncidentActiveTagsQuery } from '../../incident-list/graphql/query';
import { KeyValueTag } from 'library/atoms/Tag/Tag';
import { OwnerType } from 'library/molecules/Form/EntityOwnerDropdown/helper';

export const getActionFormInitValue = (type: SubActionTypes) => {
  switch (type) {
    case SubActionTypes.RUNBOOKS:
      return runbookInitValues;
    case SubActionTypes.COMMUNICATION_CHANNEL:
      return communicationChannelInitValues;
    case SubActionTypes.INCIDENT_NOTE:
      return incidentNoteInitValues;
    case SubActionTypes.SLO_AFFECTED:
      return sloAffectingInitValues;
    case SubActionTypes.RESOLVE_INCIDENT:
      return null;
    case SubActionTypes.REASSIGN_INCIDENT:
      return reassignIncidentInitValues;
    case SubActionTypes.TRIGGER_MANUAL_WEBHOOK:
      return webhookInitValues;
    case SubActionTypes.HTTP_CALL:
      return httpCallInitValues;
    case SubActionTypes.SEND_EMAIL:
      return emailInitValues;
    case SubActionTypes.PRIORITY:
      return priorityInitValues;
    case SubActionTypes.STATUS_PAGE:
      return statusPageInitValues;
    case SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK:
      return null;
    case SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL:
      return messageTeamChannelInitValues;
    case SubActionTypes.MS_TEAMS_MESSAGE_USER:
      return messageTeamUserInitValues;
    case SubActionTypes.SLACK_CHANNEL_ARCHIEVE:
      return null;
    case SubActionTypes.SLACK_CHANNEL_CREATE:
      return createSlackInitValues;
    case SubActionTypes.SLACK_MESSAGE_USER:
      return messageUserInitValues;
    case SubActionTypes.SLACK_MESSAGE_CHANNEL:
      return messageChannelInitValues;
    case SubActionTypes.JIRA_CREATE:
      return jiraTicketInitValues;
    case SubActionTypes.JIRA_CREATE_TICKET:
      return jiraTicketInitValues;
    default:
      const _exhaustiveCheck = type;
      return _exhaustiveCheck;
  }
};

const getActionsRequestData = (action: ActionTypeDetails) => {
  switch (action.name) {
    case SubActionTypes.COMMUNICATION_CHANNEL:
      return {
        ...action,
        data: {
          channels: action.data.channels.map(link => ({
            type: link.channelType.value,
            link: link.link,
            display_text: link.displayText || '',
          })),
        },
      };
    case SubActionTypes.SLO_AFFECTED:
      return {
        ...action,
        data: {
          slo: action.data.slo?.id,
          slis: action.data.sli.map(sli => sli.value),
        },
      };
    case SubActionTypes.RUNBOOKS:
      return {
        name: SubActionTypes.RUNBOOKS,
        data: {
          runbooks: action.data.runbooks.map(v => v.id),
        },
      };
    case SubActionTypes.REASSIGN_INCIDENT:
      return {
        name: action.name,
        data: {
          id: action.data?.id,
          type: action.data?.type,
        },
      };
    case SubActionTypes.STATUS_PAGE:
      return {
        name: action.name,
        data: {
          ...action.data,
          component_and_impact: action.data.component_and_impact.map(comp => ({
            component_id: comp.component_id,
            impact_status_id: comp.impact_status_id,
          })),
          status_and_message: action.data.status_and_message
            .filter(status => status.messages.length !== 0)
            .map(status => ({ status_id: status.status_id, messages: status.messages })),
        },
      };

    default:
      return action;
  }
};

export const getWorkflowRequestData = (
  formData: WorkflowForm & { actions: ActionTypeDetails[] },
) => {
  const actionData = formData.actions.map(getActionsRequestData);
  const filterData = {
    fields: {
      value: (formData.group?.fields?.children[0]?.fields as FilterTypeOption)?.value ?? '',
      key: (formData.group?.fields?.children[0]?.fields as FilterTypeOption)?.key ?? '',
    },
    type: formData.group?.fields?.children[0]?.type ?? 'empty',
  };

  return {
    title: formData.title,
    description: formData.description,
    owner_id: formData.owner.id,
    owner_type: formData.owner.type,
    tags: formData.tags,
    trigger: formData.trigger.value,
    filters:
      formData.group?.fields.children &&
      formData.group?.fields.children.length <= 1 &&
      formData.group?.fields?.children[0]?.filterType !== 'group'
        ? filterData
        : formData.group,
    actions: actionData,
  };
};

export const getNewTagFilter = () => {
  return tagFilterInitValue;
};

interface Tags {
  [key: string]: string[];
}

export const transformTags = (params: GetIncidentActiveTagsQuery): Tags => {
  if (!params.getIncidentActiveTags.length) return {};
  const transformedTags: Tags = {};
  params.getIncidentActiveTags.forEach(tag => {
    if (!tag) return;
    if (transformedTags[tag.key]) {
      transformedTags[tag.key] = [...transformedTags[tag.key], ...tag.values];
    } else {
      transformedTags[tag.key] = [...tag.values];
    }
  });

  return transformedTags;
};

export const mapWorkFlowTagResponseToTagProp = (tags: WorkFlowTagsResponse[]): KeyValueTag[] =>
  tags.map(tag => ({
    keyStr: tag.key,
    value: tag.value,
    color: tag.color,
  }));

export const mapTagPropToWorkFlowTagResponse = (tags: KeyValueTag[]): WorkFlowTagsResponse[] =>
  tags.map(tag => ({
    key: tag.keyStr,
    value: tag.value,
    color: tag.color,
  }));

export const mapResponseToSqCommunicationChannelAction = (
  action: SqCommunicationChannelActionResponse,
): SqCommunicationChannelAction => {
  return {
    name: action.name,
    data: {
      channels: action.data.channels.map(link => ({
        channelType: {
          label: link.type,
          value: link.type,
        },
        link: link.link,
        displayText: link.display_text,
      })),
    },
  };
};

export const mapResponseToSloAffectingAction = (
  action: SloAffectingActionResponse,
): SloAffectingAction => {
  return {
    name: action.name,
    data: {
      slo: {
        id: Number.parseInt(action.data.id),
        name: action.data.name,
      },
      sli:
        action.data?.slis?.map(sli => ({
          label: sli,
          value: sli,
        })) ?? [],
    },
  };
};

export const mapResponseToAttachRunbookAction = (
  action: AttachRunbookActionResponse,
): RunbookAction => {
  return {
    name: action.name,
    data: {
      runbooks: action.data.runbooks.map(runbook => ({
        id: runbook.id,
        name: runbook.name,
      })),
    },
  };
};

export const mapWorkflowResponseToWorkflowForm = (workflow: Workflow): WorkflowForm => {
  return {
    title: workflow.title,
    description: workflow.description,
    owner: {
      id: workflow.owner_id,
      type: workflow.owner_type as OwnerType,
    },
    response: {
      type: workflow.filters.type,
      fields: workflow.filters?.fields?.children
        ? workflow.filters?.fields?.children
        : workflow.filters.fields && [workflow.filters],
    },

    tags: workflow.tags,
    trigger: {
      label: TRIGGER_OPTIONS_LABEL_MAP[workflow.trigger as keyof typeof TRIGGER_OPTIONS_LABEL_MAP],
      value: workflow.trigger,
    },
  };
};

export const getActionsFromWorkflow = (workflow: Workflow): ActionTypeDetails[] => {
  return (
    workflow.actions?.map(action => {
      switch (action.name) {
        case SubActionTypes.COMMUNICATION_CHANNEL:
          return mapResponseToSqCommunicationChannelAction(action);
        case SubActionTypes.SLO_AFFECTED:
          return mapResponseToSloAffectingAction(action);
        default:
          return action;
      }
    }) ?? []
  );
};

export const removeDeletedEntities = (payload: ActionTypeDetails) => {
  switch (payload.name) {
    case SubActionTypes.RUNBOOKS:
      const data = payload?.data.runbooks;
      const filteredData = data?.filter(item => item.name !== 'deleted entity');
      return {
        ...payload,
        data: { runbooks: filteredData },
      };
    default:
      return payload;
  }
};
export function extractIndexFromNestedData(str: string) {
  const match = str.match(/group\.fields\.children\.(\d+)\.type/);
  return match && parseInt(match[1]);
}

export function determineFilterType(input: string) {
  if (input === 'and_filter' || input === 'or_filter') {
    return 'group';
  } else return 'filter';
}

export function determineFilterDropdownType(input: string) {
  // Check if the input contains a word followed by "_"
  const regex = /^(.*?)_\w+$/;
  const match = input.match(regex);

  if (match) {
    return match[1]; // Return the part before the word followed by "_"
  } else {
    return null; // Return null if the input doesn't match the pattern
  }
}
