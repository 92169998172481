import React, { memo, useMemo, useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { IconButton, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import useQueryParams from 'core/hooks/useQueryParams';
import { debounce } from 'core';
import { useHistory } from 'react-router';
import { SCHEDULES_V2_PATH } from 'views/main/routes/routes';

export const Search = memo(
  ({
    searchEnabled,
    setSearchEnabled,
    isDisabled,
  }: {
    searchEnabled: boolean;
    setSearchEnabled: (isEnable: boolean) => void;
    isDisabled: boolean;
  }) => {
    const query = useQueryParams();
    const searchQueryParam = query.get('search');

    const [searchTerm, setSearchTerm] = useState('');
    const history = useHistory();

    const debouncedSetSearchTerm = useMemo(
      () =>
        debounce(term => {
          if (term) {
            query.set('search', term);
          } else {
            query.delete('search');
          }
          history.push(`${SCHEDULES_V2_PATH}?${query.toString()}`);
        }, 1500),
      [history, query],
    );

    // Update the search term and use the debounced function to set the query params
    const handleChange = ({ target: { value } }: { target: { value: string } }) => {
      setSearchTerm(value);
      if (value) {
        debouncedSetSearchTerm(value);
      } else {
        query.delete('search');
        history.push(`${SCHEDULES_V2_PATH}?${query.toString()}`);
      }
    };
    return (
      <>
        {searchEnabled ? (
          <InputGroup width={'auto'}>
            <InputLeftElement height={8} pointerEvents="none">
              <SearchIcon color="blue.900" />
            </InputLeftElement>

            <Input
              autoFocus
              height={8}
              fontSize={14}
              type="text"
              value={searchTerm || searchQueryParam || ''}
              placeholder="Search for keywords"
              onChange={handleChange}
              onBlur={({ target: { value } }) => {
                if (!value) {
                  setSearchEnabled(false);
                }
              }}
              isDisabled={isDisabled}
            />
          </InputGroup>
        ) : (
          <IconButton
            aria-label="Search"
            variant={'icon'}
            icon={<SearchIcon color="blue.900" />}
            onClick={() => setSearchEnabled(true)}
          />
        )}
      </>
    );
  },
);
