import './index.css';

import { FocusBlock, Grid, Heading, Para, Theme } from 'uie/components';
import cx from 'classnames';
import { CrownIcon } from 'icons';
import { Component, PureComponent, useMemo } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import LibraryTheme from 'library/theme';
import { connect } from 'react-redux';
import { Route, RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';

import { BillingService, setSidebarState } from '../../../../core';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { SettingsInternalTabs, SettingTab } from './const';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { IUserAbilityMappingSlug } from 'core/interfaces/IUserData';
import { IPricingEntity } from 'core/interfaces/IBillingPlan';

type IMix = Pick<IAppState, 'organization' | 'APP_CONFIG'> & RouteChildrenProps<'/settings'>;
interface IProps extends IMix {
  setSidebarState: typeof setSidebarState;
}

interface AppState {
  showUpgradeModal: boolean;
  selectedEntity: IPricingEntity | undefined;
}
class OrganizationSettings extends Component<IProps, AppState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showUpgradeModal: false,
      selectedEntity: undefined,
    };
  }

  componentDidMount() {
    this.props.setSidebarState('collapsed');
  }

  render() {
    const { theme } = Theme;
    const { organization } = this.props;
    const currentUser = organization.currentUser.u;
    const currentUserAbilitiesSlug = currentUser?.abilities.map(a => a.slug) || [];
    const isOrgPlanActive = organization.plan.p?.active;
    const filteredTabs = SettingTab.filter(t => {
      return currentUser?.role === 'account_owner'
        ? isOrgPlanActive
          ? true
          : !t.requiresActiveOrg
        : t.role === 'user'
        ? true
        : currentUserAbilitiesSlug.includes(t.requires as any);
    }).map(t => {
      switch (t.pricingEntity) {
        case 'postmortem-templates':
          BillingService.isFeatureDisabled(this.props, 'postmortem-templates') ||
          BillingService.isSomeProductTrialPlan(this.props)
            ? (t.requiresPro = true)
            : (t.requiresPro = false);
          break;
        case 'event-hooks':
          BillingService.isFeatureDisabled(this.props, 'event-hooks') ||
          BillingService.isSomeProductTrialPlan(this.props)
            ? (t.requiresPro = true)
            : (t.requiresPro = false);

          break;
        case 'audit-logs':
          BillingService.isFeatureDisabled(this.props, 'audit-logs')
            ? (t.requiresPro = true)
            : (t.requiresPro = false);
      }
      return t;
    });

    const toggleUpgradePlanModal = (type?: IPricingEntity) => {
      this.setState({
        showUpgradeModal: !this.state.showUpgradeModal,
        selectedEntity: type ?? undefined,
      });
    };
    const getFiltersTab = () => {
      return filteredTabs.map((t, i) => {
        if (t.requiresPro) {
          return (
            <FocusBlock
              value={t.accessor}
              padding="8px"
              className={cx('org_settings__focus_tab', {
                org_settings__in_focus: this.props.location.pathname.includes(t.accessor),
              })}
            >
              <Grid
                onClick={() => {
                  toggleUpgradePlanModal(t.accessor as IPricingEntity);
                }}
              >
                <Para fontSize={14} fontWeight={400} color={theme.shades.cement}>
                  {t.name}
                </Para>
                {t.requiresPro && (
                  <span style={{ marginLeft: 5, marginTop: 1 }}>
                    <CrownIcon />
                  </span>
                )}
              </Grid>
            </FocusBlock>
          );
        }
        return (
          <Link to={`/settings/${t.accessor}`} key={i} className="no_underline">
            <Grid
              className={cx({
                org_settings__in_focus_wrapper: this.props.location.pathname.includes(t.accessor),
              })}
            >
              <FocusBlock
                value={t.accessor}
                padding="8px"
                className={cx('org_settings__focus_tab', {
                  org_settings__in_focus: this.props.location.pathname.includes(t.accessor),
                })}
              >
                <Grid justifyContent="center">
                  <Para fontSize={14} fontWeight={400} color={theme.shades.cement}>
                    {t.name}
                  </Para>
                  {t.requiresPro && (
                    <span style={{ marginLeft: 5, marginTop: 1 }}>
                      <CrownIcon />
                    </span>
                  )}
                </Grid>
              </FocusBlock>
            </Grid>
          </Link>
        );
      });
    };

    const routeTabs = [...filteredTabs, ...SettingsInternalTabs];

    const getRouteTabs = () => {
      return routeTabs.map(t => {
        return (
          t.component !== null && (
            <Route
              key={t.accessor}
              path={`/settings/${t.accessor}`}
              exact={true}
              component={t.component}
            />
          )
        );
      });
    };

    return (
      <ChakraProvider resetCSS={false} theme={LibraryTheme}>
        <div id="org_settings__container">
          <div id="org_settings__sidebar">
            <Grid type="column" className="org_settings__sidebar_container">
              <Heading
                fontSize={20}
                height={32}
                fontWeight={400}
                color={theme.shades.black}
                style={{ marginTop: 2 }}
              >
                Settings
              </Heading>
              <Grid flexWidth={12} type="column" className="mt-10">
                {getFiltersTab()}
              </Grid>
            </Grid>
          </div>
          <div id="org_settings__routes">{getRouteTabs()}</div>
          <UpgradePlanModal
            showModal={this.state.showUpgradeModal}
            onCancel={toggleUpgradePlanModal}
            message={BillingService.getMessage(
              0,
              this.state.selectedEntity as IPricingEntity,
              {
                organization,
              } as unknown as Pick<IAppState, 'organization'>,
            )}
            header={BillingService.getHeader(
              0,
              this.state.selectedEntity as IPricingEntity,
              {
                organization,
              } as unknown as Pick<IAppState, 'organization'>,
            )}
            onUpgrade={toggleUpgradePlanModal}
            hasBillingPermission={true}
          />
        </div>
      </ChakraProvider>
    );
  }
}

export default connect(
  ({ organization, APP_CONFIG }: IAppState) => ({
    organization,
    APP_CONFIG,
  }),
  { setSidebarState },
)(OrganizationSettings);
