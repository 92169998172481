import { debounce } from 'core';
import { T_WA_WP_LIST_FILTER_APPLIED, T_WA_WP_LIST_SEARCH_APPLIED } from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import { getAllOwnerOptions } from 'library/molecules/Form/EntityOwnerDropdown/helper';
import qs, { ParsedQuery } from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppTracker } from 'shared/analytics/tracker';
import { ACTION_OPTIONS, EVENT_OPTIONS } from '../../constant';
import { WorkflowFilterForm } from '../../types';
import { WorkflowTriggerTypes } from '../../types/triggers';

const encodeTag = (t: string) => {
  try {
    return encodeURIComponent(t);
  } catch (error) {
    return '';
  }
};
const decodeTag = (t: string) => {
  try {
    return decodeURIComponent(t);
  } catch (error) {
    return '';
  }
};

export const tagQuery = {
  stringify: (tags: WorkflowFilterForm['tags']) =>
    (tags || []).map(t => {
      const key = t.key ? encodeTag(t.key) : '';
      const value = t.value ? encodeTag(t.value) : '';
      return JSON.stringify([key, value]);
    }),

  parse: (tags: string[]) => {
    return tags.length
      ? (tags
          .map(tag => {
            try {
              const [key, value] = JSON.parse(tag);
              return { key: decodeTag(key), value: decodeTag(value) };
            } catch (error) {
              return null;
            }
          })
          .filter(t => t) as NonNullable<WorkflowFilterForm['tags']>)
      : null;
  },
};

export const useSearchFilterParams = () => {
  const state = useSelector((state: IAppState) => state);
  const ownerOptions = useMemo(() => getAllOwnerOptions(state), [state.organization.selectedTeam]);

  const history = useHistory();
  const query = qs.parse(history.location.search, { arrayFormat: 'index' }) as ParsedQuery<string>;
  const searchTerm = query['search'];
  const { event, action, owner, created_by, updated_by, enabled, tags } = query;

  const [searchValue, setSearchValue] = useState('');

  const filters = useMemo(() => {
    const filterEvent = event as WorkflowTriggerTypes;
    const filterParams = {
      event: Object.values(WorkflowTriggerTypes).includes(filterEvent) ? filterEvent : null,
      action: typeof action === 'string' ? action : null,
      tags: tags ? tagQuery.parse(tags instanceof Array ? tags : [tags]) : null,
      owner: typeof owner === 'string' ? owner : null,
      created_by: typeof created_by === 'string' ? created_by : null,
      updated_by: typeof updated_by === 'string' ? updated_by : null,
      enabled:
        typeof enabled === 'string' && ['yes', 'no'].includes(enabled)
          ? (enabled as WorkflowFilterForm['enabled'])
          : null,
    };

    return {
      params: filterParams,
      form: {
        event: EVENT_OPTIONS.find(event => event.value === filterParams.event) || null,
        action: ACTION_OPTIONS.find(action => action.value === filterParams.action) || null,
        tag: [],
        tags: filterParams.tags,
        owner: ownerOptions.find(owner => owner.id === filterParams.owner) || null,
        created_by: ownerOptions.find(owner => owner.id === filterParams.created_by) || null,
        updated_by: ownerOptions.find(owner => owner.id === filterParams.updated_by) || null,
        enabled: filterParams.enabled as NonNullable<WorkflowFilterForm['enabled']>,
      },
    };
  }, [event, action, owner, created_by, updated_by, enabled, tags?.toString()]);

  const updateSearchParam = useCallback(
    debounce((value: string) => {
      const routeConfig = {
        pathname: history.location.pathname,
        search: value.length
          ? qs.stringifyUrl({
              url: history.location.search,
              query: {
                search: value || undefined,
              },
            })
          : qs.exclude(history.location.search, ['search']),
      };
      history.push(routeConfig);
    }, 1000),
    [],
  );

  const updateSearch = (value: string) => {
    setSearchValue(value);
    AppTracker.track(T_WA_WP_LIST_SEARCH_APPLIED);
    updateSearchParam(value);
  };

  const updateFilters = (filters: WorkflowFilterForm) => {
    const query = qs.parse(window.location.search, { arrayFormat: 'index' }) as ParsedQuery<string>;
    const searchTerm = query['search'];
    const routeConfig = {
      pathname: history.location.pathname,
      search: qs.stringifyUrl(
        {
          url: '',
          query: {
            event: filters.event?.value,
            action: filters.action?.value,
            tags: filters.tags?.length ? tagQuery.stringify(filters.tags) : undefined,
            owner: filters.owner?.id,
            created_by: filters.created_by?.id,
            updated_by: filters.updated_by?.id,
            enabled: typeof filters.enabled === 'string' ? filters.enabled : undefined,
            search: searchTerm,
          },
        },
        { arrayFormat: 'index' },
      ),
    };
    AppTracker.track(T_WA_WP_LIST_FILTER_APPLIED);
    history.push(routeConfig);
  };

  useEffect(() => {
    setSearchValue(typeof searchTerm === 'string' ? searchTerm : '');
  }, [searchTerm]);

  return {
    filters,
    querySearchValue: typeof searchTerm === 'string' ? searchTerm : '',
    searchValue,
    updateSearch,
    updateFilters,
  };
};
