import { API } from 'core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { UseMutationOptions } from 'react-query';

import { PAGE_TYPES } from '../constants/schema';
import {
  UpdateStatusPageConfigurationMutation,
  UpdateStatusPageConfigurationMutationVariables,
  useUpdateStatusPageConfigurationMutation,
  useVerifyStatusPageCustomDomainMutation,
  VerifyStatusPageCustomDomainMutation,
  VerifyStatusPageCustomDomainMutationVariables,
} from '../graphql/mutation';
import { getPageInputDetails } from '../helpers/helper.service';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { IVerifyStatusPageCustomDomain } from '../Interface';
import { AppTracker } from 'shared/analytics/tracker';
import {
  T_WA_UP_STATUS_PAGE_V2_CNAME_AND_TXT_VERIFIED,
  T_WA_UP_STATUS_PAGE_V2_EDITED,
} from 'core/const/tracker';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { NewStatusPageOwnerType } from '../graphql/types';

type onSubmit = () => void;

const { initValues, schema } = getPageInputDetails(PAGE_TYPES.DETAILS);

export const useUpdateConfig = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    UpdateStatusPageConfigurationMutation,
    TError,
    UpdateStatusPageConfigurationMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageConfigurationMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Page Config Updated',
      });
    },
    onError: reactQueryConfigError('Update page config'),
  });
};

const useVerifyCustomDomain = <TError = unknown, TContext = unknown>(
  onSuccess: (data: IVerifyStatusPageCustomDomain) => void,
  options?: UseMutationOptions<
    VerifyStatusPageCustomDomainMutation,
    TError,
    VerifyStatusPageCustomDomainMutationVariables,
    TContext
  >,
) => {
  return useVerifyStatusPageCustomDomainMutation({
    ...options,
    onSuccess: data => {
      onSuccess(data.verifyStatusPageCustomDomain as IVerifyStatusPageCustomDomain);
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Custom Domain Verified',
      });
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_CNAME_AND_TXT_VERIFIED);
    },
    onError: reactQueryConfigError('Verify custom domain'),
  });
};

export const usePageConfig = (
  onSubmitHandler: onSubmit,
  initValue = initValues,
  pageId = -1,
  refetchPageDetails?: () => void,
) => {
  const [domainStatus, setDomainStatus] = useState<IVerifyStatusPageCustomDomain>({
    isCustomARecordVerified: {
      message: 'Verification pending',
      isVerified: initValue.isCustomARecordVerified || false,
    },
    isCustomTxtVerified: {
      message: 'Verification pending',
      isVerified: initValue.isCustomTxtVerified || false,
    },
  });
  const { mutateAsync: updateConfig, isLoading } = useUpdateConfig(() => {
    onSubmitHandler();
  });
  const currentUser = useSelector((state: IAppState) => state.organization.currentUser.u);

  if (typeof initValue.owner === 'undefined' || initValue.owner.id === '') {
    initValue.owner = {
      id: currentUser?.id || '',
      type: NewStatusPageOwnerType.User,
    };
  }

  const { mutateAsync: verifyDomain, isLoading: isDomainVerifying } = useVerifyCustomDomain(
    (da: IVerifyStatusPageCustomDomain) => {
      setDomainStatus(da);
      refetchPageDetails?.();
    },
  );

  const onVerifyDomain = () => {
    verifyDomain({ pageID: pageId });
  };

  const onFormSubmit = (values: any) => {
    if (pageId !== -1) {
      updateConfig({
        input: {
          id: pageId || -1,
          name: values.statusPageName,
          description: values.description,
          domainName: values.squadcastDomainName,
          timezone: values.timezone?.value,
          customDomainName: values.domainSettings === '1' ? '' : values.customPageHostName,
          ownerID: values.owner.id,
          ownerType: values.owner.type,
          hideFromSearchEngines: values.hideFromSearchEngines,
        },
      });
    } else {
      onSubmitHandler();
    }
  };

  const formik = useFormik({
    initialValues: initValue,
    validationSchema: schema,
    onSubmit: onFormSubmit,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
  });

  return {
    formik,
    isLoading: isLoading,
    onVerifyDomain,
    domainStatus,
    isDomainVerifying,
  };
};
