import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react';

import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { components } from 'react-select';

import {
  DropdownSection,
  FilterEntity,
  FilterObjectKeys,
  Option,
  Owner,
  useEntityOwnerFilter,
} from '../../owner-filters/hooks/useEntityOwnerFilter';
import { SelectOwnerDropdown } from '.';
import useQueryParams from '../../service-catalog/helpers/helper.query-params';
import { FilterFormTags } from '../../incident-list/filters/form/FilterFormTags';
import { useEffect, useMemo, useRef } from 'react';
import FormControlWrapper from 'library/molecules/FormControlWrapper';
import { getDropdownOptionDetails } from '../helpers';
import { useOrganizationConfig } from '../../schedules';
import { useGetSqauds } from '../../analytics-v2/Filters/hooks';

interface SquadFilterDropdown {
  type: 'assignee' | 'serviceOwner';
  onChange: any;
  selectedTeam?: any;
  meAndMySqaudField: {
    meAndMySquads: boolean;
    users: any[];
    squads: any[];
    myUsers: any[];
    mySquads: any[];
  };
  setterFunction: any;
  onRemove: any;
  setSelctedFilterTags?: any;
  placeholder: string;
  label: string;
  description: string;
  value: any;
  onClear: any;
}

export function SquadFilterDropdown(props: SquadFilterDropdown) {
  const {
    selectedTeam,
    onChange,
    meAndMySqaudField,
    setterFunction,
    onRemove,
    setSelctedFilterTags,
    placeholder,
    label,
    description,
    value,
    onClear,
  } = props;
  const query = useQueryParams();
  const org = useOrganizationConfig().organization;
  const { squads } = useGetSqauds(selectedTeam || '');
  const squadList = squads || [];

  const allSelectedUserIds = [...value.users, ...value.myUsers].map(el => el.value);
  const allSelectedSquadIds = [...value.squads, ...value.mySquads].map(el => el.value);

  const filterQueryParam = query.get('entity_owner') ?? '';
  const {
    dropdownOptions,
    onChangeHandler,
    handleSelectedFilterTagClick,
    selectedFilterTags,
    clearSelectedFilterValues,
  } = useEntityOwnerFilter({
    queryParamsString: filterQueryParam,
    entity: FilterEntity.ESCALATION_POLICY,
    selectedTeamId: selectedTeam,
    initialFilterValues: {
      [FilterObjectKeys.USER]: allSelectedUserIds,
      [FilterObjectKeys.SQUAD]: allSelectedSquadIds,
      [FilterObjectKeys.CURRENT_TEAM]: false,
    },
  });
  const { currentUserOption, squadsOfCurrentUser } = getDropdownOptionDetails(
    org,
    selectedTeam,
    squadList.length ? squadList : undefined,
  );

  const isFirstRender = useRef<boolean>(true);
  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false;
    else {
      if (setSelctedFilterTags?.length) setSelctedFilterTags(selectedFilterTags);
    }
  }, [selectedFilterTags]);

  const Group = (props: any) => {
    const {
      children,
      label,
      selectProps: { inputValue },
    } = props;

    return (
      <Accordion
        allowToggle={true}
        defaultIndex={label === DropdownSection.YOU_AND_YOUR_SQUADS ? 0 : -1}
        {...(inputValue ? { index: 0 } : {})}
      >
        <AccordionItem>
          {({ isExpanded = false }) => (
            <>
              <AccordionButton
                _expanded={{ background: 'white !important' }}
                padding="8px 12px"
                sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}
              >
                <HStack p={0}>
                  <Text color="#646464" fontWeight={700}>
                    {label}
                  </Text>
                  {isExpanded ? (
                    <ChevronDownIcon width={6} height={6} color="#646464" />
                  ) : (
                    <ChevronRightIcon width={6} height={6} color="#646464" />
                  )}
                </HStack>
              </AccordionButton>
              <AccordionPanel p={0}>{children}</AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    );
  };

  const Menu = (props: any) => {
    const { children } = props;
    return (
      <components.Menu {...props}>
        <Flex
          sx={{
            ...{
              boxShadow: '0px 4px 10px 0px #0000000D',
            },
            ...{
              boxShadow: '2px 0px 10px 0px #0000000D',
            },
            width: '100%',
          }}
        >
          {children}
        </Flex>
      </components.Menu>
    );
  };

  const dropdownValue = useMemo(() => {
    let val: any = [];
    if (value?.mySquads) val = [...val, ...value.mySquads];
    if (value?.myUsers) val = [...val, ...value.myUsers];
    if (value?.squads) val = [...val, ...value.squads];
    if (value?.users) val = [...val, ...value.users];

    if (val.length) return val;
    return null;
  }, [value]);

  return (
    <FormControlWrapper
      label={label}
      description={description}
      value={dropdownValue}
      onClear={() => {
        onClear();
        clearSelectedFilterValues();
      }}
    >
      <SelectOwnerDropdown
        variant="avatar"
        teamOptionLabelStyles={{
          color: '#646464',
          fontSize: 'md',
          fontWeight: 700,
        }}
        options={dropdownOptions}
        value={[]}
        placeholder={placeholder}
        components={{ Group, Menu }}
        onChange={value => {
          onChange(value);
          onChangeHandler(value as Option);
        }}
        applyChakraStyle={false}
        closeMenuOnSelect
      />
      <FilterFormTags
        values={
          meAndMySqaudField.meAndMySquads
            ? [...meAndMySqaudField.users, ...meAndMySqaudField.squads]
            : [
                ...meAndMySqaudField.users,
                ...meAndMySqaudField.squads,
                ...meAndMySqaudField.mySquads,
                ...meAndMySqaudField.myUsers,
              ]
        }
        type="assignedTo"
        fieldType="service_owner"
        ownServiceData={
          meAndMySqaudField.meAndMySquads
            ? {
                squads: squadsOfCurrentUser,
                users: currentUserOption,
              }
            : undefined
        }
        setFieldValue={setterFunction}
        onClose={(type, id) => {
          onRemove(type, id);
          handleSelectedFilterTagClick(type as Owner, id);
        }}
      />
    </FormControlWrapper>
  );
}
