import Tippy from '@tippy.js/react';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import { API, BillingService } from 'core';
import { T_WA_UP_WHO_IS_ON_CALL_VIEWED } from 'core/const/tracker';
import { ISCalendarOnCallUser } from 'core/interfaces/ICalendar';
import { THEME_COLORS } from 'library/theme/colors';
import { matchSorter } from 'match-sorter';
import moment from 'moment';
import React from 'react';
import { generatePath, Link, Redirect } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import {
  Avatar,
  DialogModalFrame,
  DropDown,
  DropDownContainer,
  DropDownHook,
  FocusBlock,
  Grid,
  IconButton,
  InputBlock,
  Label,
  Para,
  SelectBox,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';

import { TopBar } from '.';
import {
  ErrorIcon,
  HelpCircleIcon,
  ProfileIcon,
  SearchIcon,
  TeamIcon,
  TrialIcon,
  WhatsNewIcon,
} from '../../../icons';
import idcard from '../../../icons/idcard.svg';
import { supportUrls } from '../../../store/supportUrls';
import { INCIDENTS_TAB } from '../organization/incident-list/common/enums';
import { INCIDENT_LIST_TAB } from '../routes/routes';
import Search from '../search';
import Banner from './banner';
import ExperimentsModal from './experiments.modal';
import { Logout } from './logout';
import OnCallSwitch from './onCallSwitch';

export function render(this: TopBar) {
  const { theme } = Theme;
  const {
    incidentData,
    openModal,
    redirectKey,
    teamSearch,
    onCallWidgetSearch,
    onCallSchedules,
    onCallUsers,
  } = this.state;
  const {
    organization,
    APP_PREVIEW,
    pathName,
    visibleRoutes,
    isRefresh,
    whoIsOnCallInfo,
    isWhoIsOnCallLoading,
  } = this.props;

  const { teams, selectedTeam, users, calendar, currentUser, currentOrg } = organization;
  const onCallUsersSet = new Set<string>();
  const orgConfig = this.props.organization.currentOrg.o?.config;
  const hideLegacyShifts = !!orgConfig?.show_migrated_schedules;
  !hideLegacyShifts &&
    Object.values(organization.calendar.onCall).forEach(users =>
      users.forEach(u => onCallUsersSet.add(u.id)),
    );
  whoIsOnCallInfo?.forEach(whoIsOnCall => {
    whoIsOnCall.oncallParticipants?.forEach(p => onCallUsersSet.add(p.ID));
  });
  const onCallUserIds = Array.from(onCallUsersSet);

  const teamSelectEnabled = !['/analytics-v2', '/settings'].find(route =>
    pathName.startsWith(route),
  );

  const _oUsers = users.u.reduce((c: any, u) => {
    c[u.id] = {
      name: `${u.first_name}`,
      _id: u.id,
      type: 'users',
    };
    return c;
  }, {});

  const _oSchedules = calendar.c.reduce((c: any, s) => {
    c[s.id] = {
      id: s.id,
      name: s.name,
    };
    return c;
  }, {});

  if (redirectKey.group !== '' && redirectKey.key !== '') {
    const redirectTo = visibleRoutes.find(
      r => r.group === redirectKey.group && r.key === redirectKey.key,
    );
    this.setState({ redirectKey: { group: '', key: '' } });
    if (redirectTo) {
      return <Redirect to={redirectTo.route} />;
    }
  }

  const myTeams = teams.t.filter(t => t.members.some(m => m.user_id === currentUser.u?.id || ''));
  const filteredTeams = myTeams.filter(t =>
    t.name.toLowerCase().includes(teamSearch.toLowerCase()),
  );

  const getTotalDays = () => {
    const trialStartDate = this.props.organization.plan.p?.metadata.chargebee.trial_start;
    const trialEndDate = this.props.organization.plan.p?.metadata.chargebee.trial_end;
    return moment(trialEndDate).diff(trialStartDate, 'days');
  };

  const getTrialUsageDays = () => {
    const totalDays = getTotalDays();
    if (this.state.trialExpired) {
      return totalDays;
    }
    const trialStartDate = new Date(
      this.props.organization.plan.p?.metadata.chargebee.trial_start as string,
    );
    const today = new Date(Date.now());
    return Math.min(Math.floor(moment(today).diff(moment(trialStartDate), 'days')), totalDays);
  };

  const trialMessage = () => {
    const onNormalTrial = BillingService.isOnNormalProductTrialPlan(this.props);
    let notificationsLeft = '';

    if (onNormalTrial) {
      const usage = this.props.organization.plan.p?.notification_usage.find(
        n => n.type === 'phone-and-sms',
      );
      notificationsLeft = `Notification Usage : ${usage?.count}/${usage?.limit} (Phone + SMS combined)`;
    }

    const daysLeft = getTotalDays() - getTrialUsageDays();
    if (this.state.trialExpired || daysLeft == 0) {
      return (
        `You have 0 days left on the Trial Plan which includes our full set of features.` +
        (onNormalTrial ? `\n\n${notificationsLeft}` : '')
      );
    }

    return (
      'You have ' +
      (daysLeft == 1 ? '1 more day ' : `${daysLeft} more days `) +
      'left on the Trial Plan which includes our full set of features.' +
      (onNormalTrial ? `\n\n${notificationsLeft}` : '')
    );
  };

  const onSearch = (val: string) => {
    const filteredOnCallUsers: {
      [key: string]: ISCalendarOnCallUser[];
    } = {};
    const onCallUsersList = organization.calendar.onCall;
    this.setState({
      onCallWidgetSearch: val,
    });
    Object.entries(onCallUsersList).map(([cal, users]) => {
      if (users.length) {
        if (_oSchedules[cal]?.name.toLowerCase().includes(val.toLowerCase())) {
          filteredOnCallUsers[cal] = users;
        }
      }
    });
    const filteredSchedules =
      whoIsOnCallInfo &&
      matchSorter(whoIsOnCallInfo, val, {
        keys: ['schedule.name'],
        threshold: matchSorter.rankings.CONTAINS,
      });
    if (filteredSchedules) {
      this.setState({
        onCallSchedules: filteredSchedules,
      });
    }
    if (filteredOnCallUsers) {
      this.setState({
        onCallUsers: filteredOnCallUsers,
      });
    }
  };

  const shouldRenderNoDataView = () => {
    return (
      (!whoIsOnCallInfo?.length && // no schedules to render
        (!hideLegacyShifts ? !Object.keys(organization.calendar.onCall)?.length : true)) || // no legacy schedules to render
      (onCallWidgetSearch && // search in progress but no search results
        !onCallSchedules?.length &&
        (!hideLegacyShifts ? !Object.keys(onCallUsers)?.length : true))
    );
  };

  const getNoDataLabel = () => {
    if (
      !whoIsOnCallInfo?.length && // no schedules to render
      (!hideLegacyShifts ? !Object.keys(organization.calendar.onCall)?.length : true) // no legacy schedules to render
    ) {
      return 'No schedules available';
    }
    return 'No results found';
  };

  const switchOnCallView = () => {
    onSearch('');
    this.props.onToggleOnCallView();
  };

  const getOnCallUser = () => {
    const oncallUsers: Array<string> = [];
    let widgetSummary = '';
    if (isWhoIsOnCallLoading) {
      widgetSummary = 'Loading';
    } else if (onCallUserIds.length > 0 && (whoIsOnCallInfo?.length || 0) > 0) {
      if (!hideLegacyShifts) {
        onCallUserIds.forEach(user => {
          if (_oUsers[user]?.name && !oncallUsers.includes(_oUsers[user]?.name)) {
            oncallUsers.push(_oUsers[user]?.name);
          }
        });
      }
      whoIsOnCallInfo?.forEach(oncall => {
        oncall.oncallParticipants?.forEach(participantGroup => {
          if (participantGroup?.participant?.name) {
            const name = participantGroup?.participant?.name?.split(' ')[0];
            if (!oncallUsers.includes(name)) oncallUsers.push(name);
          }
        });
      });
      widgetSummary = oncallUsers[0] || 'Deleted User';
    } else {
      widgetSummary = 'None';
    }
    return { count: oncallUsers.length - 1 || '', displayText: widgetSummary };
  };

  const { count, displayText } = getOnCallUser();

  return (
    <>
      <Grid type="column" id="main_app_interface__header" className="top_bar">
        <Grid alignItems="center" justifyContent="space-between">
          <Grid alignItems="center" justifyContent="flex-start" className="top_bar__left">
            {teamSelectEnabled && (
              <>
                <div className="top_bar__team_select">
                  <Label fontSize={10}>Team</Label>
                  <SelectBox
                    hook={
                      <Grid alignItems="center">
                        <TeamIcon height={12} width={12} />
                        <Para
                          className="ml-5"
                          fontSize={14}
                          style={{
                            width: '100px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {selectedTeam.team?.name || 'Not assigned'}
                        </Para>
                      </Grid>
                    }
                    height="auto"
                    maxHeight="200px"
                    width="150px"
                    maxWidth="150px"
                    onValueChange={this.onSelectTeam}
                    hookRef={this.onAssignHookRef}
                    {...(myTeams.length > 0
                      ? {
                          searchHookProps: {
                            placeholder: 'Search',
                            value: teamSearch,
                            onChange: this.onTeamSearch,
                            padding: '4px',
                            fontSize: '14px',
                            height: '24px',
                          },
                        }
                      : {})}
                  >
                    {filteredTeams.map(t => {
                      return (
                        <FocusBlock
                          key={t.id}
                          value={t.id}
                          isSelected={t.id === selectedTeam.teamId}
                        >
                          <Para
                            fontSize={14}
                            style={{
                              maxWidth: '100px',
                            }}
                          >
                            {t.name}
                          </Para>
                        </FocusBlock>
                      );
                    })}
                    {filteredTeams.length === 0 ? (
                      <FocusBlock key={-1} value="-1" disabled>
                        <Para fontSize={14}>No matches</Para>
                      </FocusBlock>
                    ) : (
                      <></>
                    )}
                    {myTeams.length === 0 ? (
                      <FocusBlock key={-1} value="-1" disabled>
                        <Para fontSize={14}>
                          Please contact your account owner to assign to a team
                        </Para>
                      </FocusBlock>
                    ) : (
                      <></>
                    )}
                  </SelectBox>
                </div>
                <div className="top_bar__search_divider" />
              </>
            )}
            <div className="top_bar__search_container">
              <Tooltip
                label={`Search (${this._keyMod} + K)`}
                padding="4px"
                offset={{ top: '-24px', left: '40px' }}
              >
                <IconButton
                  className="top_bar__search"
                  onClick={this.toggleModal('search')}
                  tabIndex={0}
                  style={{
                    width: teamSelectEnabled ? 'auto' : '197px',
                    justifyContent: teamSelectEnabled ? 'center' : 'flex-start',
                  }}
                >
                  <SearchIcon height={16} width={16} stroke={theme.shades.lightGrey} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Banner organization={organization} />
          <div className="top_bar__center">
            {!currentOrg.o!.sandbox && incidentData.triggerCount > 0 && (
              <Grid
                justifyContent="space-between"
                alignItems="center"
                className="top_bar__incident_alert"
              >
                {incidentData.triggerCount === 1 ? (
                  <>
                    <Para fontSize={14} color={theme.shades.white}>
                      Incident
                    </Para>
                    <Para fontSize={14} color={theme.shades.black}>
                      {incidentData.message}
                    </Para>
                    {pathName !== `/incident/${incidentData.id}` && (
                      <Link
                        to={`/incident/${incidentData.id}`}
                        onClick={this.dismissIncidentNotification}
                      >
                        <Para fontSize={14} color={theme.primary.default}>
                          Open
                        </Para>
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    <Para fontSize={14} color={theme.shades.white}>
                      {incidentData.triggerCount} unacknowledged incidents
                    </Para>
                    {pathName !== '/incident' && (
                      <Link
                        to={generatePath(INCIDENT_LIST_TAB, { tabNum: INCIDENTS_TAB.TRIGGERED })}
                      >
                        <Para fontSize={14} color={theme.primary.default}>
                          Open
                        </Para>
                      </Link>
                    )}
                  </>
                )}
              </Grid>
            )}
          </div>

          <Grid alignItems="center" justifyContent="flex-end" className="top_bar__right">
            {this.state.productTrial ? (
              <Tippy
                content={
                  <UpgradeOnlyModal
                    hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
                    align="left"
                    showModal={true}
                    message={trialMessage()}
                    header={'Trial Period'}
                    hideLearnMore={true}
                  />
                }
                interactive={true}
                animation={'perspective'}
                placement="bottom"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <TrialIcon />
                  </div>
                  <div>
                    {this.state.trialExpired ? (
                      <Para> Trial Expired </Para>
                    ) : (
                      <Para>
                        Used: {getTrialUsageDays()} / {getTotalDays()} days
                      </Para>
                    )}
                  </div>
                </div>
              </Tippy>
            ) : (
              ''
            )}
            <div className="top_bar__whats_new">
              <div className="top_bar__new-release">
                <WhatsNewIcon stroke={theme.shades.grey} fill="none" height={20} width={20} />
              </div>
            </div>
            <DropDown
              offset="-130px"
              hook={
                <HelpCircleIcon
                  stroke={theme.shades.grey}
                  height={20}
                  width={20}
                  className="cursor-pointer top_bar__help"
                />
              }
              width="200px"
              maxWidth="200px"
              padding="0"
            >
              {Object.values(supportUrls.headerUrl)
                .filter(urlData => !('hidden' in urlData) || !urlData.hidden)
                .map(({ name, url }, i) => (
                  <FocusBlock
                    value={i}
                    className="top_bar__profile_link"
                    key={i}
                    onSelectValue={this.openUrl(url)}
                  >
                    <Para fontSize={14}>{name}</Para>
                  </FocusBlock>
                ))}
            </DropDown>
            <DropDownHook>
              <Grid
                type="column"
                alignItems="flex-start"
                className="top_bar__on_call_tab"
                onClick={() => {
                  AppTracker.track(T_WA_UP_WHO_IS_ON_CALL_VIEWED);
                }}
              >
                <Label fontSize={10}>On-call</Label>
                <Para fontSize={12}>{`${displayText}${count > 0 ? ` + ${count}` : ''}`}</Para>
              </Grid>
              <DropDownContainer
                offset={`calc(100% - 210px)`}
                width="200px"
                maxWidth="200px"
                maxHeight="400px"
              >
                <div className="top_bar__on_call_tab_drop_container">
                  <OnCallSwitch
                    applyChakraStyle={false}
                    onChange={switchOnCallView}
                    showMyOnCall={this.props.showMyOnCall}
                  />
                  {(whoIsOnCallInfo?.length ||
                    (!hideLegacyShifts &&
                      Object.keys(organization.calendar.onCall)?.length > 0)) && (
                    <div className="top_bar__on_call_tab_drop_cell">
                      <InputBlock
                        style={{
                          background:
                            'url("/icons/greys/search copy.svg") 8px 7px / 14px no-repeat scroll',
                          padding: '0 10px 0 30px',
                        }}
                        value={onCallWidgetSearch}
                        height="30px"
                        fontSize="14px"
                        onChange={e => onSearch(e.target.value)}
                        placeholder="Search"
                      />
                    </div>
                  )}
                  <Label fontSize={12}>On-call schedules</Label>
                  {!hideLegacyShifts &&
                    Object.entries(onCallUsers).map(([cal, users], i) => {
                      return (
                        users.length > 0 &&
                        _oSchedules[cal]?.name && (
                          <div key={i} className="top_bar__on_call_tab_drop_cell">
                            <Para fontSize={14}>{_oSchedules[cal].name}</Para>
                            {users.map((u, ind) => {
                              return (
                                <>
                                  {u.first_name ? (
                                    <div className="top_bar__on_call_div" key={ind}>
                                      <Link
                                        to={`/user/${u.id}`}
                                        className="top_bar__on_call_link"
                                        ref={this._onCallUserLinkRef}
                                        onClick={this.blurDropdownReferences}
                                      >
                                        <Para color={theme.primary.default} fontSize={14}>
                                          {`${u.first_name} ${u.last_name}`}
                                        </Para>
                                      </Link>
                                      {u.username_for_display && (
                                        <Grid
                                          type="row"
                                          style={{ display: 'flex', alignItems: 'flex-start' }}
                                        >
                                          <img
                                            src={idcard}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              marginRight: 5,
                                              marginTop: 4,
                                            }}
                                            alt="idcard"
                                          />
                                          <Para
                                            fontSize={14}
                                            style={{
                                              color: THEME_COLORS.secondary[1200],
                                              maxWidth: '17ch',
                                            }}
                                          >
                                            {u.username_for_display}
                                          </Para>
                                        </Grid>
                                      )}
                                    </div>
                                  ) : (
                                    <Para color={theme.primary.default} fontSize={14}>
                                      Deleted User
                                    </Para>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        )
                      );
                    })}
                  {onCallSchedules?.map(({ schedule, oncallParticipants }, i) => {
                    return (
                      <div key={i} className="top_bar__on_call_tab_drop_cell">
                        <Para fontSize={14}>{`${schedule.name || 'No Schedule name'}`}</Para>
                        {oncallParticipants?.map((p, ind) => {
                          return (
                            <React.Fragment key={ind}>
                              {p?.participant?.name ? (
                                <div className="top_bar__on_call_div">
                                  <Link
                                    to={
                                      p.type === 'user'
                                        ? `/user/${p.ID}`
                                        : `/settings/teams/${API.config.teamId}/squads`
                                    }
                                    className="top_bar__on_call_link"
                                    ref={this._onCallUserLinkRef}
                                    onClick={this.blurDropdownReferences}
                                  >
                                    <Para
                                      color={theme.primary.default}
                                      fontSize={14}
                                      style={{ lineHeight: '21px' }}
                                    >
                                      {`${p?.participant?.name}`}
                                    </Para>
                                  </Link>
                                  {p.type === 'user' && p?.participant?.username && (
                                    <Grid
                                      type="row"
                                      style={{ display: 'flex', alignItems: 'flex-start' }}
                                    >
                                      <img
                                        src={idcard}
                                        style={{
                                          width: 16,
                                          height: 16,
                                          marginRight: 5,
                                          marginTop: 4,
                                        }}
                                        alt="idcard"
                                      />
                                      <Para
                                        fontSize={14}
                                        style={{
                                          color: THEME_COLORS.secondary[1200],
                                          maxWidth: '17ch',
                                        }}
                                      >
                                        {p?.participant?.username}
                                      </Para>{' '}
                                    </Grid>
                                  )}
                                </div>
                              ) : (
                                <Para color={theme.primary.default} fontSize={14}>
                                  Deleted User
                                </Para>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  })}
                  {shouldRenderNoDataView() && (
                    <Grid type="column" alignItems="center">
                      <img src="/icons/no_data.svg" style={{ marginTop: '40px' }} />
                      <Para fontSize={15} style={{ marginTop: '20px' }}>
                        {getNoDataLabel()}
                      </Para>
                    </Grid>
                  )}
                </div>
              </DropDownContainer>
            </DropDownHook>

            <DropDownHook>
              <div
                className={`top_bar__profile_avatar ${
                  !currentUser.u?.phone_verified ? 'top_bar__profile_avatar-unverified' : ''
                }`}
                tabIndex={0}
              >
                <Avatar
                  base={17}
                  reduceColorString={currentUser.u!.id}
                  attributeHook={
                    !currentUser.u?.phone_verified ? (
                      <ErrorIcon />
                    ) : (
                      onCallUsersSet.has(currentUser.u!.id)
                    )
                  }
                  fontSize={10}
                  attributeProps={
                    !currentUser.u?.phone_verified
                      ? {}
                      : {
                          backgroundColor: theme.success.default,
                          base: 4,
                        }
                  }
                >
                  {`${currentUser.u!.first_name} ${currentUser.u!.last_name}`}
                </Avatar>
              </div>

              <DropDownContainer
                offset="-164px"
                width="300px"
                maxWidth="200px"
                maxHeight="200px"
                padding="0"
              >
                <div>
                  <Grid type="column" alignItems="flex-end" className="top_bar__avatar_user">
                    <Para fontSize={16}>
                      {currentUser.u!.first_name} {currentUser.u!.last_name}
                    </Para>
                    {currentUser.u?.username_for_display && (
                      <Grid
                        type="row"
                        style={{ marginBottom: 5, marginTop: 4, alignItems: 'flex-start' }}
                      >
                        <img
                          src={idcard}
                          style={{ width: 16, height: 16, marginRight: 5, marginTop: 2 }}
                          alt="idcard"
                        />
                        <Para
                          fontSize={12}
                          style={{ color: THEME_COLORS.secondary[1200], maxWidth: '17ch' }}
                        >
                          {currentUser.u?.username_for_display}
                        </Para>
                      </Grid>
                    )}
                    <Grid type="row" style={{ alignItems: 'flex-start' }}>
                      <ProfileIcon
                        width={16}
                        height={16}
                        color={THEME_COLORS.secondary[1200]}
                        style={{ marginRight: 5 }}
                      />
                      <Para fontSize={12} color={theme.font.label}>
                        {currentUser.u?.role.replace('_', ' ')}{' '}
                      </Para>
                    </Grid>
                    {onCallUsersSet.has(currentUser.u!.id) && (
                      <Link
                        className="top_bar__profile__schedule_link"
                        to={
                          currentOrg.o?.config.disable_old_schedules
                            ? '/schedules-v2'
                            : '/schedules'
                        }
                        ref={this._profileUserLinkRef}
                      >
                        <TextButton color={theme.primary.light}>
                          <Para fontWeight={400} fontSize={12} color={theme.primary.default}>
                            You are on-call
                          </Para>
                        </TextButton>
                      </Link>
                    )}
                  </Grid>
                  <Link
                    to={`/user/${currentUser.u!.id}`}
                    className="top_bar__profile_link tb-profile"
                    ref={this._profileUserLinkRef}
                    onClick={this.blurDropdownReferences}
                  >
                    <Grid type="column">
                      <Para fontSize={14} style={{ textAlign: 'right' }}>
                        Profile
                      </Para>
                      {!currentUser.u?.email_verified ? (
                        <Para fontSize={11} color={theme.danger.default}>
                          Verify Email
                        </Para>
                      ) : (
                        !currentUser.u?.phone_verified && (
                          <Para fontSize={11} color={theme.danger.default}>
                            Verify Phone Number
                          </Para>
                        )
                      )}
                    </Grid>
                  </Link>
                  {APP_PREVIEW.appPreviews.length > 0 && (
                    <FocusBlock
                      value={2}
                      onSelectValue={this.toggleModal('experiment')}
                      className="top_bar__profile_link"
                    >
                      <Para fontSize={14}>Previews</Para>
                    </FocusBlock>
                  )}
                  <Logout isRefresh={isRefresh} onLogout={this.props.requestUserLogout} />
                </div>
              </DropDownContainer>
            </DropDownHook>
          </Grid>
        </Grid>
      </Grid>
      <DialogModalFrame id="experimental_modal_frame" onClose={this.toggleModal('')} padding="16px">
        {openModal === 'experiment' && <ExperimentsModal />}
      </DialogModalFrame>
      <DialogModalFrame
        onKeyDown={el => {
          if (el.key === 'Escape' && openModal === 'search') {
            this.onSearchSwitcherClose();
          }
        }}
        id="main_search_modal"
        onClose={this.onSearchSwitcherClose}
        padding="0"
        style={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
        hideClose={true}
        width="1000px"
        skipFocusTrap={false}
      >
        {openModal === 'search' && <Search hide={this.onSearchSwitcherClose} />}
      </DialogModalFrame>
    </>
  );
}
