import Axios from 'axios';
import { API } from 'core';
import { useToast } from 'library/atoms';
import { useQuery } from 'react-query';
import { queryKeys } from 'views/main/organization/service-catalog/contants/service.contant';
import { BE_FieldType } from '../../../types';

type sort_by = 'name' | 'mtta' | 'mttr' | 'status' | 'open_incidents';

type sort_order = 'asc' | 'des';

export interface ServiceObj {
  id: string;
  name: string;
}

export interface ServiceOption {
  label: string;
  value: string;
}
export interface IServiceList {
  data: { services: Array<ServiceObj> };
}

const getServiceList = async (
  pageOffset: number,
  pageLimit: number,
  teamId: string | unknown,
  sort_by: sort_by | string,
  sort_order: sort_order | string,
) => {
  const { data } = await Axios.get<IServiceList>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services?page_limit=${pageLimit}&offset=${pageOffset}&owner_id=${teamId}&sort_by=${sort_by}&sort_order=${sort_order}`,
  );
  return data.data.services;
};

const useServiceList = (services?: BE_FieldType) => {
  const toast = useToast();
  const teamId = API.config.teamId;
  return useQuery(
    [queryKeys.SERVICELIST, teamId],
    () => getServiceList(1, 1000, teamId, 'name', 'asc'),
    {
      onError: () => {
        toast({ text: 'Fetch service list failed', status: 'error' });
      },
      enabled: !!services || true,
    },
  );
};

export default useServiceList;
