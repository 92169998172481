import React, { useEffect, useRef, useState } from 'react';
import { DropDownContainer, DropDownHook, Grid, Para, TextButton, Theme } from 'uie/components';
import { DateRangePicker } from 'react-date-range';
import { TimerIcon } from '../../icons';
import { Locale } from 'core/helpers/dateUtils';

const { theme } = Theme;

const dateDisplayFormat = Locale.shortDateFormat.replace(/L/g, 'M');

interface IProps {
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
  onUpdateRange: (startDate: Date, endDate: Date) => void;
  isFuture?: boolean;
  offset?: string;
  isScrollable?: boolean;
  className?: string;
}

const CalendarRangePicker: React.FC<IProps> = ({
  startDate,
  endDate,
  isScrollable = false,
  ...props
}) => {
  const [selectedRange, setSelectedRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate,
    endDate,
  });

  const [mountDropdown, setMountDropdown] = useState(false);
  const [isFirstInitalized, setIsFirstInitalized] = useState(false);

  setTimeout(() => {
    setMountDropdown(true);
  }, 200);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dateRangeRef = useRef<any>();

  const handleChangeTimeRange = (range: { selection: { startDate: Date; endDate: Date } }) => {
    let selectedEndDate = range.selection.endDate;

    if (selectedEndDate > new Date() && !props.isFuture) {
      selectedEndDate = new Date();
    }

    setSelectedRange({
      endDate: selectedEndDate,
      startDate: range.selection.startDate,
    });
  };

  const handleApplyRange = () => {
    props.onUpdateRange(selectedRange.startDate, selectedRange.endDate);
    closeDropdown();
  };

  const onCancel = () => {
    setSelectedRange({
      startDate,
      endDate,
    });

    closeDropdown();
  };

  const closeDropdown = () => {
    if (dropdownRef.current) {
      dropdownRef.current?.focus();
      dropdownRef.current?.blur();
    }
  };

  useEffect(() => {
    // Scroll to bottom
    if (dateRangeRef.current && isFirstInitalized && isScrollable) {
      try {
        // Scrollable Element
        const calendar = dateRangeRef.current.dateRange.calendar.list.scrollParent;
        calendar.scrollIntoView({ behavior: 'smooth', block: 'end' });
      } catch (error) {
        console.log(error);
      }
    }
  }, [isFirstInitalized, isScrollable]);

  useEffect(() => {
    setSelectedRange({
      endDate: endDate,
      startDate: startDate,
    });
  }, [startDate, endDate]);

  return (
    <Grid alignItems="center">
      <DropDownHook ref={dropdownRef} onClick={() => setIsFirstInitalized(true)}>
        <Para fontSize={14} className="analytics__time_range-para">
          <TimerIcon /> From{' '}
          <span className="font-bold">
            {Locale.toShortDateWoYear(startDate, { disableTimezone: true })}
          </span>{' '}
          to{' '}
          <span className="font-bold">
            {Locale.toShortDateWoYear(endDate, { disableTimezone: true })}
          </span>
          <span className="font-bold"> ({Locale.namedOffset})</span>
        </Para>

        <DropDownContainer offset={props.offset || '-390px'} style={{ padding: '0' }}>
          {mountDropdown && (
            <Grid type="column">
              <DateRangePicker
                minDate={props.minDate || new Date()}
                maxDate={props.maxDate || new Date()}
                direction="vertical"
                color={theme.primary.default}
                inputRanges={[]}
                ranges={[
                  {
                    startDate: selectedRange.startDate,
                    endDate: selectedRange.endDate,
                    key: 'selection',
                  },
                ]}
                onChange={handleChangeTimeRange as any}
                dateDisplayFormat={dateDisplayFormat}
                scroll={{ enabled: isScrollable }}
                ref={dateRangeRef}
                className={props.className ? props.className : ''}
              />

              <Grid justifyContent="flex-end">
                <TextButton buttonType="ghost" style={{ margin: '12px' }} onClick={onCancel}>
                  <Para fontSize={14}>Cancel</Para>
                </TextButton>
                <TextButton
                  onClick={handleApplyRange}
                  style={{ margin: '12px', float: 'left', fontSize: '14px' }}
                >
                  <Para fontSize={14} color={theme.shades.white}>
                    Apply Time Range
                  </Para>
                </TextButton>
              </Grid>
            </Grid>
          )}
        </DropDownContainer>
      </DropDownHook>
    </Grid>
  );
};

export default CalendarRangePicker;
