import { ActionTypes, DrawerType, SubActionTypes } from '../../types';
import SqActionList from './list/SqActionList';
import CommunicationChannelFormContainer from './squadcast/communication-channel/CommunicationChannelFormContainer';
import IncidentNoteContainer from './squadcast/incident-note/IncidentNoteContainer';
import SloAffectingContainer from './squadcast/slo-affected/SloAffectingContainer';
import ReassignIncidentContainer from './squadcast/reassign-incident/ReassignIncidentContainer';
import RunbookContainer from './squadcast/runbook/RunbookContainer';
import TriggeredWebhook from './squadcast/trigger-webhook/TriggeredWebhookContainer';
import HttpCallContainer from './squadcast/http-call/HttpCallContainer';
import EmailContainer from './squadcast/send-email/EmailContainer';
import MessageTeamsChannelContainer from './microsoft/messageChannel/MessageTeamChannelContainer';
import MessageTeamsUserContainer from './microsoft/messageTeam/messageTeamUserContainer';
import MSTeamActionList from './list/MSTeamActionList';
import SlackActionList from './list/SlackActionList';
import ChannelCreateContainer from './slack/channelCreate/ChannelCreateContainer';
import MessageUserContainer from './slack/messageUser/MessageUserContainer';
import MessageChannelContainer from './slack/messageChannel/MessagChannelContainer';
import JiraActionList from './list/JiraActionList';
import JiraTicketContainer from './jira/JiraTicketContainer';
import PriorityContainer from './squadcast/priority/PriorityContainer';
import StatusPageContainer from './squadcast/statuspage/StatusPageContainer';

type Props = {
  type: DrawerType;
};

const ActionWrapper = (props: Props) => {
  switch (props.type) {
    case ActionTypes.SQUADCAST:
      return <SqActionList />;
    case ActionTypes.MS_TEAMS:
      return <MSTeamActionList />;
    case ActionTypes.SLACK:
      return <SlackActionList />;
    case SubActionTypes.RUNBOOKS:
      return <RunbookContainer />;
    case SubActionTypes.COMMUNICATION_CHANNEL:
      return <CommunicationChannelFormContainer />;
    case SubActionTypes.INCIDENT_NOTE:
      return <IncidentNoteContainer />;
    case SubActionTypes.SLO_AFFECTED:
      return <SloAffectingContainer />;
    case SubActionTypes.RESOLVE_INCIDENT:
      return null;
    case SubActionTypes.REASSIGN_INCIDENT:
      return <ReassignIncidentContainer />;
    case SubActionTypes.TRIGGER_MANUAL_WEBHOOK:
      return <TriggeredWebhook />;
    case SubActionTypes.HTTP_CALL:
      return <HttpCallContainer />;
    case SubActionTypes.SEND_EMAIL:
      return <EmailContainer />;
    case SubActionTypes.PRIORITY:
      return <PriorityContainer />;
    case SubActionTypes.STATUS_PAGE:
      return <StatusPageContainer />;
    case SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL:
      return <MessageTeamsChannelContainer />;
    case SubActionTypes.MS_TEAMS_MESSAGE_USER:
      return <MessageTeamsUserContainer />;
    case SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK:
      return null;
    case SubActionTypes.SLACK_CHANNEL_CREATE:
      return <ChannelCreateContainer />;
    case SubActionTypes.SLACK_MESSAGE_USER:
      return <MessageUserContainer />;
    case SubActionTypes.SLACK_MESSAGE_CHANNEL:
      return <MessageChannelContainer />;
    case ActionTypes.JIRA:
      return <JiraActionList />;
    case SubActionTypes.JIRA_CREATE_TICKET:
      return <JiraTicketContainer />;
    case SubActionTypes.SLACK_CHANNEL_ARCHIEVE:
      return <></>;
    case SubActionTypes.JIRA_CREATE:
      return <></>;
    default:
      const _exhaustiveCheck: never = props.type;
      return _exhaustiveCheck;
  }
};

export default ActionWrapper;
