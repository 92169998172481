import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SlackUserAction, SlackOption } from 'views/main/organization/workflow/types';
import { MdBlock } from 'uie/components';

import { useGetSlackChannelUsers } from 'views/main/organization/settings/integrations/slack-v2/hooks/getSlackChannelsUsers';
import FormField from 'library/molecules/FormField/FormField';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';

const MessageUserForm = () => {
  const { setIsDirty } = useWorkflowFormContext();
  const {
    formState: { isDirty, errors },
    setValue,
    getValues,
    watch,
    trigger,
  } = useFormContext<SlackUserAction>();

  const { data: slackUsers } = useGetSlackChannelUsers();

  const allSlackUsers = useMemo(() => {
    return slackUsers?.map(user => ({
      value: user.id,
      label:
        user.profile.display_name_normalized === '' ? '-' : user.profile.display_name_normalized,
    }));
  }, [slackUsers]);

  const slackUser = watch('data.member_id');

  const selectedUser = useMemo(() => {
    return slackUsers
      ?.filter(user => user.id === slackUser)
      .map(user => ({ label: user.profile.display_name_normalized, value: user.id }));
  }, [slackUsers, slackUser]);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  return (
    <Flex px={6} pt={4} flexDirection="column">
      <Box mb={3}>
        <FormField label="User" error={errors?.data?.member_id && errors.data.member_id.message}>
          <FrameWorkDropdown<SlackOption>
            options={allSlackUsers}
            isLoading={false}
            value={selectedUser}
            onChange={o => {
              setValue('data.member_id', o!.value, { shouldDirty: true });
              trigger(`data.member_id`);
            }}
            placeholder={'Select'}
          />
        </FormField>
      </Box>
      <Box mb={2}>
        <FormField
          label="Message"
          error={
            typeof errors?.data?.message === 'object' && 'message' in errors.data.message
              ? errors.data.message.message
              : ''
          }
        >
          <MdBlock
            value={getValues('data.message')}
            onChange={content => setValue('data.message', content, { shouldDirty: true })}
            options={{
              hideIcons: ['upload-image', 'upload-file'],
              maxHeight: '130px',
              spellChecker: false,
              placeholder: 'Eg: {{event.incident_id}} triggered',
            }}
          />
        </FormField>
      </Box>
    </Flex>
  );
};

export default MessageUserForm;
