import { HStack } from '@chakra-ui/layout';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import TagStack from 'library/atoms/Tag/TagStack';
import { useCallback, useMemo } from 'react';
import { useWorkflowListContext } from '../../context/workflowListContext';

const FilterPreview = () => {
  const workflowContext = useWorkflowListContext();
  interface Filter {
    key: string;
    identifier: number | null;
    label: string;
    username?: string | null;
    value: string | boolean | null | undefined;
  }

  const filterLabels: Filter[] = useMemo(
    () =>
      [
        {
          key: 'event',
          identifier: null,
          label: 'Event',
          value: workflowContext.filterFormValues.event?.label,
        },
        {
          key: 'action',
          identifier: null,
          label: 'Action',
          value: workflowContext.filterFormValues.action?.label,
        },
        {
          key: 'owner',
          identifier: null,
          label: 'Owner',
          username: workflowContext.filterFormValues.owner?.username,
          value: workflowContext.filterFormValues.owner?.name,
        },
        {
          key: 'created_by',
          identifier: null,
          username: workflowContext.filterFormValues.created_by?.username,
          label: 'Created By',
          value: workflowContext.filterFormValues.created_by?.name,
        },
        {
          key: 'updated_by',
          identifier: null,
          username: workflowContext.filterFormValues.updated_by?.username,
          label: 'Updated By',
          value: workflowContext.filterFormValues.updated_by?.name,
        },
        {
          key: 'enabled',
          identifier: null,
          label: 'Enabled',
          value: workflowContext.filterFormValues.enabled,
        },
        ...(workflowContext.filterFormValues.tags || []).map((tag, index) => ({
          key: 'tags',
          identifier: index,
          label: 'Tag',
          value: [tag.key, tag.value].join(' ').trim(),
        })),
      ].filter(filter => filter.value),
    [workflowContext.filterFormValues],
  );

  const onRemoveFilter = useCallback(
    (index: number) => {
      const appliedFilters = Object.assign({}, workflowContext.filterFormValues);
      const filterKey = filterLabels[index].key as keyof typeof appliedFilters;
      const filterIdentifier = filterLabels[index].identifier;

      if (
        filterKey === 'tags' &&
        appliedFilters['tags']?.length &&
        typeof filterIdentifier === 'number'
      ) {
        appliedFilters['tags'] =
          appliedFilters['tags']?.filter((_tag, i) => i !== filterIdentifier) || null;
      } else {
        appliedFilters[filterKey] = null;
      }
      workflowContext.onApplyFilter(appliedFilters);
    },
    [filterLabels],
  );

  if (!filterLabels.length) {
    return null;
  }

  const transformTags = (filterLabels: any) => {
    return filterLabels.map((filter: Filter) => ({
      label: `${filter.label}: ${filter.value}`,
      username: filter.username,
    }));
  };

  return (
    <HStack alignSelf="start" sx={{ '& *': { fontSize: 10.5 } }}>
      <TagStack
        variant={TAG_VARIANTS.PLAIN}
        tags={transformTags(filterLabels)}
        defaultLength={100}
        onClose={index => onRemoveFilter(index)}
      />
    </HStack>
  );
};

export default FilterPreview;
