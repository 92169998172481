import { Grid, Para, Theme } from 'uie/components';
import { ILog } from 'core/interfaces/IIncidents';
import React from 'react';
import { ExpandCollapse } from '../../components/ExpandCollapse';
import {
  ADDITIONAL_RESPONDERS_ACTIONS,
  APTA_ACTIONS,
  COMMUNICATION_LOG_TYPES,
  IAG_ACTIONS,
  MANUAL_MERGE_CHILD_LOG_TYPES,
  MANUAL_MERGE_PARENT_LOG_TYPES,
} from '../../constants';

type Iprops = {
  log: ILog;
  logMessageActivity: string;
  logChanges: string[];
  entityComponent: JSX.Element;
};
const ActionHasSlo: React.FC<Iprops> = ({
  log,
  logChanges,
  logMessageActivity,
  entityComponent,
}) => {
  const { theme } = Theme;

  const messageFirst = [
    'triggered',
    'tags_modified',
    'acknowledged',
    'resolved',
    'auto_resolved',
    'postmortem_created',
    'postmortem_updated',
    'postmortem_deleted',
    'postmortem_status_updated',
    'manually_marked_positive_transient_alert_feedback',
    'manually_marked_negative_transient_alert_feedback',
    'site_test_has_been_triggered_by',
  ].some(text => log.actionType.includes(text));
  return (
    <>
      {log.action.split('_').includes('slo') || COMMUNICATION_LOG_TYPES.includes(log.actionType) ? (
        <Para fontSize={14} color={theme.shades.cement}>
          {entityComponent} {logMessageActivity}
        </Para>
      ) : (
        (!log.additionalInfo ||
          Object.keys(log.additionalInfo).length === 0 ||
          [...MANUAL_MERGE_PARENT_LOG_TYPES, ...MANUAL_MERGE_CHILD_LOG_TYPES].includes(
            log.action,
          )) &&
        !(
          log.action === 'Comment' ||
          [
            ...APTA_ACTIONS,
            ...IAG_ACTIONS,
            ...ADDITIONAL_RESPONDERS_ACTIONS,
            'delayed_notification_resumed',
          ].includes(log.action)
        ) && (
          <Grid type="column">
            <Para fontSize={14} color={theme.shades.cement}>
              {messageFirst ? (
                <>
                  {logMessageActivity} {entityComponent}
                </>
              ) : (
                <>
                  {entityComponent} {logMessageActivity}
                </>
              )}
            </Para>
            {log.actionType === 'tags_modified' && (
              <ExpandCollapse>
                {logChanges?.map(item => {
                  return (
                    <Para key={item} fontSize={14} color={theme.shades.cement}>
                      {item}
                    </Para>
                  );
                })}
              </ExpandCollapse>
            )}
          </Grid>
        )
      )}
    </>
  );
};

export default ActionHasSlo;
