import { Box, FormLabel, HStack, VStack, Text } from '@chakra-ui/react';
import ChakraDatepicker from 'components/chakra/Datepicker';
import { ITeam } from 'core/interfaces/ITeams';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import MultiSelectDropdown from 'library/atoms/MultiSelectDropdown/MultiselectDrowDown';
import { FormSelect } from 'library/molecules';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useState } from 'react';
import { filterDropdownOptions } from 'views/main/organization/incident-list/interfaces/common';
import { useOrganizationConfig } from 'views/main/organization/schedules';
import { DateFilterChoice } from 'views/main/organization/statuspage-v2/graphql/types';
import {} from '../../../users/add';
import { useAuditLogsListContext } from '../../context/auditLogsContext';
import { checkIfValueExists, getActionsValue, getDropdownValues } from '../../helpers';
import { DROPDOWN_TYPE_ENUM } from '../../types/index.auditLogs';
import { FilterFormTags } from './FilterFormTags';
import FormControlWrapper from 'library/molecules/FormControlWrapper';
import TagStack from '../TagsStack/TagStack';

interface Option {
  label: string;
  value: string;
}

interface FilterFormProps {
  dateFilterValue: string;
  setDateFilterValue: React.Dispatch<React.SetStateAction<string>>;
  actor: Option[];
  setActor: React.Dispatch<React.SetStateAction<Option[]>>;
  team: Option[];
  setTeam: React.Dispatch<React.SetStateAction<Option[]>>;
  client: Option[];
  setClient: React.Dispatch<React.SetStateAction<Option[]>>;
  resources: Option[];
  setResources: React.Dispatch<React.SetStateAction<Option[]>>;
  actions: Option[];
  setActions: React.Dispatch<React.SetStateAction<Option[]>>;
  startDate: any;
  setStartDate: React.Dispatch<React.SetStateAction<string | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<string | null>>;
  endDate: any;
  lastNumberOfDays: any;
  setLastNumberOfDays: (value: any) => void;
  created: any;
  setCreated: (value: any) => void;
  userList: IUsersOfOrganization[];
  setShowDateFilterToggle: (value: any) => void;
  showDateError: boolean;
  setShowDateError: (value: any) => void;
}

function FilterForm(props: FilterFormProps) {
  const {
    dateFilterValue,
    setDateFilterValue,
    actor,
    setActor,
    team,
    setTeam,
    client,
    setClient,
    resources,
    setResources,
    actions,
    setActions,
    userList,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    lastNumberOfDays,
    setLastNumberOfDays,
    created,
    setCreated,
    setShowDateFilterToggle,
    showDateError,
    setShowDateError,
  } = props;

  const org = useOrganizationConfig();
  const getOrgUserList = () => {
    return userList.map((user: IUsersOfOrganization) => {
      return {
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      };
    });
  };

  const [todayDate] = useState(() => DateTime.utc());

  const context = useAuditLogsListContext();

  return (
    <VStack>
      <Box style={{ width: '100%', marginTop: 0 }}>
        <FormControlWrapper
          label="Resource"
          onClear={() => {
            setResources([]);
            setActions([]);
          }}
          value={getDropdownValues('Resource', context.auditLogsFilterDrowDownValues.resources)}
        >
          <MultiSelectDropdown
            options={getDropdownValues('Resource', context.auditLogsFilterDrowDownValues.resources)}
            selectedValues={resources}
            placeholder="Type or select the Resource(s)"
            onChange={(val: any) => {
              if (checkIfValueExists(resources, val[0])) {
                setResources(resources.filter(v => v.value !== val[0].value));
              } else {
                setResources([...resources, ...val]);
              }
              setActions([]);
            }}
          />
          <HStack pt={1} alignSelf="start" sx={{ '& *': { fontSize: 10.5 } }}>
            <TagStack
              showLabel={false}
              tags={resources ?? []}
              onClose={(item: any) => {
                setResources(resources.filter(val => val.value !== item.value));
                setActions([]);
              }}
            />
          </HStack>
        </FormControlWrapper>
      </Box>
      <Box style={{ width: '100%', marginTop: 0 }}>
        <FormControlWrapper
          label="Date Range"
          onClear={() => {
            setCreated(null);
            setLastNumberOfDays(null);
            setStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
            setEndDate(moment(Date.now()).format('YYYY-MM-DD'));
            context.setDateFilters({
              lastNumberOfDays: null,
              created: null,
            });
            setShowDateFilterToggle(false);
          }}
          value={getDropdownValues('Date Range', DROPDOWN_TYPE_ENUM.DATE_RANGE)}
        >
          <FormSelect
            options={getDropdownValues('Date Range', DROPDOWN_TYPE_ENUM.DATE_RANGE)}
            value={created}
            label="Date"
            hideLabel
            isClearable={false}
            onChange={(field, value) => {
              setShowDateError(false);
              const inferedValue = value as filterDropdownOptions;
              if (value && inferedValue.value) {
                if (inferedValue.value === DateFilterChoice.Range) {
                  setLastNumberOfDays(undefined);
                  setStartDate(moment(todayDate.startOf('day').toISO()).format('YYYY-MM-DD'));
                  setEndDate(moment(todayDate.toISO()).format('YYYY-MM-DD'));
                  setShowDateFilterToggle(true);
                } else {
                  const noOfDays = inferedValue.value;
                  setLastNumberOfDays(noOfDays);
                  setShowDateFilterToggle(true);
                  setStartDate(null);
                  setEndDate(null);
                }
              }
              setCreated(value);
            }}
            error={''}
            isMulti={false}
            fieldName="created"
          />
          {showDateError && (
            <Text variant="body" color="red">
              For custome date rage Start Date & End Date is required
            </Text>
          )}
          {created?.value === DateFilterChoice.Range ? (
            <>
              <HStack mt={3} spacing="auto">
                <VStack align="flex-start" spacing={2}>
                  <FormLabel fontSize="12px" color="secondary.700" fontWeight="800" mb={0}>
                    Start Date
                  </FormLabel>
                  <ChakraDatepicker
                    dateFormat="dd/MM/yyyy"
                    setDate={(value: Date) => {
                      setShowDateFilterToggle(true);
                      setStartDate(moment(value).format('YYYY-MM-DD'));
                      setShowDateError(false);
                    }}
                    minDate={moment().subtract(1, 'year').toDate()}
                    maxDate={new Date(endDate ?? Date.now())}
                    date={startDate ? new Date(Date.parse(startDate)) : (null as unknown as Date)}
                  />
                </VStack>
                <VStack align="flex-start" spacing={2}>
                  <FormLabel fontSize="12px" color="secondary.700" fontWeight="800" mb={0}>
                    End Date
                  </FormLabel>
                  <ChakraDatepicker
                    dateFormat="dd/MM/yyyy"
                    setDate={(value: Date) => {
                      setEndDate(moment(value).format('YYYY-MM-DD'));
                      setShowDateError(false);

                      setShowDateFilterToggle(true);
                    }}
                    minDate={new Date(startDate)}
                    maxDate={new Date()}
                    date={endDate ? new Date(Date.parse(endDate)) : (null as unknown as Date)}
                  />
                </VStack>
              </HStack>
              {/* start and end date filter tags */}
              <HStack align="baseline">
                {startDate && (
                  <FilterFormTags
                    values={[
                      {
                        label: `Start Date ${moment(startDate).format('DD/MM/YYYY')}`,
                        value: moment(startDate).toISOString(),
                      },
                    ]}
                    onClose={val => {
                      setStartDate(val);
                    }}
                    isSingle={true}
                  />
                )}
                {endDate && (
                  <FilterFormTags
                    values={[
                      {
                        label: `End Date ${moment(endDate).format('DD/MM/YYYY')}`,
                        value: moment(endDate).toISOString(),
                      },
                    ]}
                    onClose={val => {
                      setEndDate(val);
                    }}
                    isSingle={true}
                  />
                )}
              </HStack>
              {showDateError && (
                <Text variant="body" color="red">
                  For custome date rage Start Date & End Date is required
                </Text>
              )}
            </>
          ) : (
            <>
              {created?.value === DateFilterChoice.Month ||
              created?.value === DateFilterChoice.Week ||
              created?.value === DateFilterChoice.Days ||
              created?.value === DateFilterChoice.Range ? (
                <FilterFormTags
                  values={[
                    {
                      label: `Created: ${created.label}`,
                      value: created.value,
                    },
                  ]}
                  onClose={val => {
                    context.setDateFilters({
                      created: val,
                      lastNumberOfDays: null,
                    });
                    setCreated(val);
                    setLastNumberOfDays(null);
                  }}
                  isSingle={true}
                />
              ) : (
                <>
                  {!!lastNumberOfDays && (
                    <FilterFormTags
                      values={[
                        {
                          label: `Number of Days: Last ${
                            lastNumberOfDays?.split('last')[1].split('days')[0]
                          } days`,
                          value: `$(lastNumberOfDays ?? '')}`,
                        },
                      ]}
                      onClose={val => {
                        context.setDateFilters({
                          ...context.dateFilters,
                          lastNumberOfDays: val,
                        });
                        setLastNumberOfDays(val);
                      }}
                      isSingle={true}
                    />
                  )}
                </>
              )}
            </>
          )}
        </FormControlWrapper>
      </Box>
      <Box style={{ width: '100%', marginTop: 0 }}>
        <FormControlWrapper
          label="Actions"
          onClear={() => {
            setActions([]);
            // context.setAppliedFilters({
            //   ...context.appliedFilters,
            //   action: [],
            // });
          }}
          value={getActionsValue(
            resources,
            context.auditLogsFilterDrowDownValues.resourceToActionsMap,
          )}
        >
          <MultiSelectDropdown
            options={getActionsValue(
              resources,
              context.auditLogsFilterDrowDownValues.resourceToActionsMap,
            )}
            selectedValues={actions}
            placeholder="Type or select the action(s)"
            onChange={(val: any) => {
              if (checkIfValueExists(actions, val[0])) {
                setActions(actions.filter(v => v.value !== val[0].value));
              } else {
                setActions([...actions, ...val]);
              }
            }}
          />
          <HStack pt={1} alignSelf="start" sx={{ '& *': { fontSize: 10.5 } }}>
            <TagStack
              showLabel={false}
              tags={actions ?? []}
              onClose={(item: any) => {
                setActions(actions.filter(val => val.value !== item.value));
              }}
            />
          </HStack>
        </FormControlWrapper>
      </Box>
      <Box style={{ width: '100%', marginTop: 0 }}>
        <FormControlWrapper
          label="Actor"
          onClear={() => {
            setActor([]);
            // context.setAppliedFilters({
            //   ...context.appliedFilters,
            //   actor: [],
            // });
          }}
          value={getOrgUserList()}
        >
          <MultiSelectDropdown
            options={getOrgUserList()}
            selectedValues={actor}
            placeholder="Type or select the actor(s)"
            onChange={(val: any) => {
              if (checkIfValueExists(actor, val[0])) {
                setActor(actor.filter(v => v.value !== val[0].value));
              } else {
                setActor([...actor, ...val]);
              }
            }}
            variantType="assignee"
          />
          <HStack pt={1} alignSelf="start" sx={{ '& *': { fontSize: 10.5 } }}>
            <TagStack
              showLabel={true}
              tags={actor ?? []}
              onClose={(item: any) => {
                setActor(actor.filter(val => val.value !== item.value));
              }}
            />
          </HStack>
        </FormControlWrapper>
      </Box>
      <Box style={{ width: '100%', marginTop: 0 }}>
        <FormControlWrapper
          label="Team"
          onClear={() => {
            setTeam([]);
          }}
          value={org.organization.teams.t.map((val: ITeam) => {
            return {
              label: val.name,
              value: val.id,
            };
          })}
        >
          <MultiSelectDropdown
            options={org.organization.teams.t.map((val: ITeam) => {
              return {
                label: val.name,
                value: val.id,
              };
            })}
            selectedValues={team}
            placeholder="Type or select the team(s)"
            onChange={(val: any) => {
              if (checkIfValueExists(team, val[0])) {
                setTeam(team.filter(v => v.value !== val[0].value));
              } else {
                setTeam([...team, ...val]);
              }
            }}
            variantType="assignee"
          />{' '}
          <HStack pt={5} alignSelf="start" sx={{ '& *': { fontSize: 10.5 } }}>
            <TagStack
              showLabel={true}
              tags={team ?? []}
              onClose={(item: any) => {
                setTeam(team.filter(val => val.value !== item.value));
              }}
            />
          </HStack>
        </FormControlWrapper>
      </Box>
      <Box style={{ width: '100%', marginTop: -10 }}>
        <FormControlWrapper
          label="Client"
          onClear={() => {
            setClient([]);
          }}
          value={getDropdownValues('Client', context.auditLogsFilterDrowDownValues.clients)}
        >
          <MultiSelectDropdown
            options={getDropdownValues('Client', context.auditLogsFilterDrowDownValues.clients)}
            selectedValues={client}
            placeholder="Type or select the client(s)"
            onChange={(val: any) => {
              if (checkIfValueExists(client, val[0])) {
                setClient(client.filter(v => v.value !== val[0].value));
              } else {
                setClient([...client, ...val]);
              }
            }}
          />{' '}
          <HStack pt={1} alignSelf="start" sx={{ '& *': { fontSize: 10.5 } }}>
            <TagStack
              showLabel={false}
              tags={client ?? []}
              onClose={(item: any) => {
                setClient(client.filter(val => val.value !== item.value));
              }}
            />
          </HStack>
        </FormControlWrapper>
      </Box>
    </VStack>
  );
}

export default FilterForm;
