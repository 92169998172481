import { T_WA_WP_CREATED, T_WA_WP_EDITED } from 'core/const/tracker';
import { useEffect, useRef } from 'react';

import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';

import { workflowUpdateApi } from '../api';
import { QUERY_KEYS } from '../constant';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { generatePath, useHistory } from 'react-router';
import { AppTracker } from 'shared/analytics/tracker';
import { WORKFLOWS_DETAILS_PATH, WORKFLOWS_PATH } from 'views/main/routes/routes';

import { createWorkflowApi } from '../api';
import { ANALYTICS_ACTION_MAP, ANALYTICS_TRIGGER_MAP } from '../constant/analytics';
import { drawerInitValue, workflowFormInitValues } from '../constant/values';
import { getActionFormInitValue, removeDeletedEntities } from '../helper';
import {
  ActionListActionType,
  ActionTypeDetails,
  ActionTypes,
  DrawerStateType,
  DrawerType,
  SubActionTypes,
  WorkflowForm,
} from '../types';
import { workflowList } from './workflowListContext';
import { WorkflowTriggerTypes } from '../types/triggers';
import { Workflow } from '../types/response/workflow';
import { AxiosError } from 'axios';
import { PaymentFailedResponseError, ResponseError } from '../types/response';
import { useToast } from 'library/atoms';
import { API } from 'core';

const WorkflowFormContext = createContext({
  workflowForm: workflowFormInitValues,
  isCreateFlow: true,
  onFormSubmit: (values: WorkflowForm) => {},
  isFormSubmitting: false,
  triggerType: WorkflowTriggerTypes.INCIDENT_TRIGGERED,
  setTriggerType: (v: WorkflowTriggerTypes) => {},

  actions: [] as ActionTypeDetails[],
  actionForm: null as ActionTypeDetails | null,
  initEditActionForm: (index: number) => {},
  initActionForm: (index: number) => {},
  onActionSubmit: (actionForm: ActionTypeDetails) => {},
  actionHandler: (index: number, actionType: ActionListActionType) => {},

  drawerInfo: drawerInitValue,
  openDrawer: (type: DrawerType) => {},
  handleSubActionClick: (type: SubActionTypes) => {},
  checkDirtyAndCloseDrawer: (goPrev = false) => {},

  setIsDirty: (v: boolean) => {},
  showGuardModal: false,
  discardChanges: () => {},
  closeGuardModal: () => {},
  isDirty: false,
  isFormDirty: false,

  enableGroup: true,
  setEnableGroup: (v: boolean) => {},
});

interface IProps {
  children: ReactNode;
  isCreateFlow: boolean;
  workflowFormData?: WorkflowForm;
  initialActions?: ActionTypeDetails[];
}

const WorkflowFormProvider = ({
  children,
  isCreateFlow,
  workflowFormData,
  initialActions,
}: IProps) => {
  const [workflowForm, setWorkflowForm] = useState<WorkflowForm>(
    workflowFormData ?? workflowFormInitValues,
  );
  const [actions, setActions] = useState<ActionTypeDetails[]>([]);
  const [actionForm, setActionForm] = useState<ActionTypeDetails | null>(null);
  const [drawerInfo, setDrawerInfo] = useState<DrawerStateType>(drawerInitValue);
  const [editAction, setEditAction] = useState({ isEdit: false, editActionIndex: -1 });
  const [isDirty, setIsDirty] = useState(false);
  const [showGuardModal, setShowGuardModal] = useState({ isOpen: false, isGoPrev: false });
  const [triggerType, setTriggerType] = useState(
    workflowFormData ? workflowFormData.trigger.value : workflowFormInitValues.trigger.value,
  );
  const [enableGroup, setEnableGroup] = useState(true);
  const params = useParams<{
    id: string;
  }>();

  const initialActionsRef = useRef<string | undefined>();
  const formDirty = initialActionsRef.current !== JSON.stringify(actions);
  useEffect(() => {
    initialActionsRef.current = JSON.stringify(initialActions);
  }, []);

  useEffect(() => {
    if (isCreateFlow) {
      setWorkflowForm(workflowFormInitValues);
    } else {
      setWorkflowForm(workflowFormData ?? workflowFormInitValues);
    }
  }, []);

  // const availableActionList: ActionList[] = getActionTypeList();

  // set initial values for actions
  useEffect(() => {
    if (isCreateFlow) {
      setActions([]);
    } else {
      setActions(initialActions ?? []);
    }
  }, []);

  const toast = useToast();
  const history = useHistory();

  const { mutate: createWorkflow, isLoading } = useMutation(createWorkflowApi, {
    onSuccess: (data: Workflow) => {
      toast({
        text: 'Workflow successfully added',
        status: 'success',
        link: {
          text: 'View',
          url: `/workflows/${data.id}`,
          onClick: () => {
            history.push(generatePath(WORKFLOWS_DETAILS_PATH, { id: data.id }));
          },
        },
      });
      AppTracker.track(T_WA_WP_CREATED, {
        'Workflow Actions': data.actions?.map(action => ANALYTICS_ACTION_MAP[action.name]) ?? [],
        'Workflow Trigger': ANALYTICS_TRIGGER_MAP[data.trigger],
        workflow_id: [data.id],
      });
      queryClient.invalidateQueries({
        queryKey: [workflowList, API.config.teamId],
        refetchInactive: true,
      });
      queryClient.invalidateQueries('workflowCount');
      queryClient.invalidateQueries('workflowTags');
      history.push(generatePath(WORKFLOWS_PATH));
    },
    onError: (err: AxiosError<ResponseError | PaymentFailedResponseError>) => {
      if (err.response && err.response.data) {
        if ('meta' in err.response.data) {
          const errorMessage = err.response.data.meta.error_message;
          toast({ text: errorMessage, status: 'error' });
        } else {
          const errorMessage =
            err.response.data.error_details.description ??
            'Organization has crossed the plan limits for workflows';
          toast({ text: errorMessage, status: 'error' });
        }
      } else {
        toast({ text: 'Workflow creation failed', status: 'error' });
      }
    },
  });

  const queryClient = useQueryClient();
  const { mutate: updateWorkflow } = useMutation(workflowUpdateApi, {
    retry: 1,
    onSuccess: data => {
      toast({ text: 'Workflow updated', status: 'success' });
      queryClient.invalidateQueries([QUERY_KEYS.WORKFLOW, params.id]);
      queryClient.invalidateQueries('workflowTags');

      history.push(generatePath(WORKFLOWS_DETAILS_PATH, { id: data?.id }));
      AppTracker.track(T_WA_WP_EDITED, {
        workflow_id: [data.id],
      });
      queryClient.refetchQueries(workflowList);
    },

    onError: (err: AxiosError<ResponseError>) => {
      const errorMessage =
        err?.response?.data?.message ??
        err?.response?.data?.meta?.error_message ??
        'Workflow update failed';
      toast({ text: errorMessage, status: 'error' });
      toast({ text: errorMessage, status: 'error' });
    },
  });

  // describe what the function does
  const initActionFormData = useCallback((type: SubActionTypes) => {
    setActionForm(getActionFormInitValue(type));
  }, []);

  const openDrawer = useCallback((type: DrawerType) => {
    setDrawerInfo(info => {
      return {
        isOpen: true,
        prevDrawerType: (info?.drawerType as ActionTypes) || null,
        drawerType: type,
      };
    });
  }, []);

  const closeDrawer = useCallback((goPrev = false) => {
    setDrawerInfo(info => {
      return {
        isOpen: Boolean(goPrev && info?.prevDrawerType),
        prevDrawerType: null,
        drawerType: goPrev ? (info?.prevDrawerType as DrawerType) : null,
      };
    });
  }, []);

  const checkDirtyAndCloseDrawer = useCallback(
    (goPrev = false) => {
      if (isDirty) {
        setShowGuardModal({ isOpen: true, isGoPrev: goPrev });
      } else {
        closeDrawer(goPrev);
      }
    },
    [isDirty],
  );

  const closeGuardModal = useCallback(() => {
    setShowGuardModal({ isOpen: false, isGoPrev: false });
  }, []);

  const initActionForm = useCallback(
    (index: number) => {
      setActionForm(actions[index]);
      openDrawer(actions[index].name);
    },
    [actions],
  );

  const discardChanges = useCallback(() => {
    setIsDirty(false);
    closeDrawer(showGuardModal.isGoPrev);
    closeGuardModal();
  }, [showGuardModal.isGoPrev]);

  const openSubActionDrawer = useCallback((type: SubActionTypes) => {
    setEditAction({ isEdit: false, editActionIndex: -1 });
    initActionFormData(type);
    openDrawer(type);
  }, []);

  const handleSubActionClick = useCallback((type: SubActionTypes) => {
    if (type === SubActionTypes.RESOLVE_INCIDENT) {
      setActions(actions => [...actions, { name: type }]);
      closeDrawer();
    } else if (type === SubActionTypes.SLACK_CHANNEL_ARCHIEVE) {
      setActions(actions => [...actions, { name: type, data: {} }]);
      closeDrawer();
    } else if (type === SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK) {
      setActions(actions => [...actions, { name: type }]);
      closeDrawer();
    } else {
      openSubActionDrawer(type);
    }
  }, []);

  const initEditActionForm = useCallback(
    (index: number) => {
      setEditAction({ isEdit: true, editActionIndex: index });
      initActionForm(index);
    },
    [actions],
  );

  const onActionSubmit = useCallback(
    (values: ActionTypeDetails) => {
      const sanitizedValues = removeDeletedEntities(values);
      if (editAction.isEdit) {
        setActions(action => {
          const result = Array.from(action);
          result.splice(editAction.editActionIndex, 1, sanitizedValues);
          return result;
        });
      } else {
        setActions(actions => [...actions, sanitizedValues]);
      }

      setActionForm(null);
      setIsDirty(false);
      closeDrawer();
    },
    [actions, editAction],
  );

  const onFormSubmit = useCallback(
    (values: WorkflowForm) => {
      const tags = values.tags.map(tag => {
        return { ...tag, key: tag.key ? tag.key.trim() : '', value: tag.value.trim() };
      });

      const actionsPayload: ActionTypeDetails[] = actions.map(action => {
        if (action.name === 'sq_make_http_call') {
          if (!action.data.headers.some(header => header.value)) {
            return { ...action, data: { ...action.data, headers: [] } };
          } else {
            return {
              ...action,
              data: {
                ...action.data,
                headers: action.data.headers
                  .filter(header => header.value)
                  .map(header => {
                    return header;
                  }),
              },
            };
          }
        } else {
          return action;
        }
      });
      if (!isCreateFlow) {
        updateWorkflow({
          id: params.id,
          formDirty,
          formData: { ...values, actions: actionsPayload, tags },
        });
        return;
      }

      createWorkflow({ ...values, actions: actionsPayload, tags });
    },
    [actions, isCreateFlow],
  );

  const actionHandler = useCallback((startIndex: number, actionType: ActionListActionType) => {
    setActions(action => {
      const result = Array.from(action);
      const [removed] = result.splice(startIndex, 1);
      if ([ActionListActionType.GO_NEXT, ActionListActionType.GO_PREV].includes(actionType)) {
        const endIndex =
          actionType === ActionListActionType.GO_PREV ? startIndex - 1 : startIndex + 1;
        result.splice(endIndex, 0, removed);
      }
      return result;
    });
  }, []);
  const value = {
    workflowForm,
    isCreateFlow,
    onFormSubmit,
    isFormSubmitting: isLoading,
    triggerType,
    setTriggerType,

    actions,
    actionForm,
    onActionSubmit,
    initEditActionForm,
    initActionForm,
    actionHandler,

    drawerInfo,
    handleSubActionClick,
    openDrawer,
    checkDirtyAndCloseDrawer,

    setIsDirty,
    showGuardModal: showGuardModal.isOpen,
    discardChanges,
    closeGuardModal,
    isDirty,
    isFormDirty: formDirty,

    enableGroup,
    setEnableGroup,
  };

  return <WorkflowFormContext.Provider value={value}>{children}</WorkflowFormContext.Provider>;
};

const useWorkflowFormContext = () => useContext(WorkflowFormContext);

export { WorkflowFormProvider, useWorkflowFormContext, WorkflowFormContext };
