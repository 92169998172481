import { ICurrentUser } from 'core/interfaces/IAppState';
import { IIncidentExportParams, ServiceOwner } from 'core/interfaces/IIncidents';
import { DateTime } from 'luxon';
import moment from 'moment';
import { QueryClient } from 'react-query';

import { getNoOfDays, toSentenceCase } from '../../common/util';
import { DateFilterChoice, IncidentFilters } from '../../graphql/generated/types';
import { filterDropdownOptions } from '../../interfaces/common';
import { FilterKeysType, Filters } from '../../interfaces/filters';
import { ILCOLUMN } from '../../store';
import { getListDefaultDaysOverride, isAllTimeLimit } from '../../store/persistent-storage';

export const getFiltersTags = (filter: Filters, currentUser?: ICurrentUser | null) => {
  type tags = {
    key: string;
    value: string;
    label: string;
    username?: string | null;
    subFeilds: any;
  }[];
  const getDateTime = (dateStr: string | null, timeStr: string | null) => {
    return dateStr
      ? [{ label: `${moment(dateStr).format('DD/MM/YYYY')}, ${timeStr ?? ''}`, value: dateStr }]
      : [];
  };

  const getAssignedtoFilter = (field: any) => {
    let reqArray: any = [];
    if (field?.meAndMySquads) {
      const currentUset = (reqArray = [
        ...reqArray,
        {
          key: 'meAndMySquads',
          value: '',
          label:
            currentUser?.id === field?.myUsers[0]?.value
              ? 'Me and MySquads'
              : `${field?.myUsers[0]?.label}'s squad`,
          username: '',
          subFeilds: {
            squads: [
              ...field?.mySquads?.map((val: any) => {
                return {
                  ...val,
                  key: 'squads',
                  hideDeleteIcon: true,
                };
              }),
            ],
            users: [
              ...field?.myUsers?.map((val: any) => {
                return {
                  ...val,
                  key: 'assignedTo',
                  hideDeleteIcon: true,
                };
              }),
            ],
          },
        },
      ]);
    }
    if (field && field?.users && field?.squads)
      reqArray = [...reqArray, ...field.users, ...field?.squads];
    return reqArray;
  };
  const dateValue = filter.created?.value ?? DateFilterChoice.Days;

  const filterDataforTags: Record<string, filterDropdownOptions[]> = {
    alert: filter.alert,
    assignedTo: getAssignedtoFilter(filter.assignedTo),
    services: filter.services,
    isSlo: filter.isSlo ? [{ value: filter.isSlo, label: filter.isSlo }] : [],
    sloList: filter.sloList,
    startDate: getDateTime(filter.startDate, filter.startTime),
    endDate: getDateTime(filter.endDate, filter.endTime),
    priority: filter.priority,
    serviceOwner: getAssignedtoFilter(filter.serviceOwner),
    withRetro: filter.withRetro ? [{ value: filter.withRetro, label: filter.withRetro }] : [],
    retroStatus: filter.withRetro ? filter.retroStatus : [],
    withNotes: filter.withNotes ? [{ value: filter.withNotes, label: filter.withNotes }] : [],
    isStarred: filter.isStarred ? [{ value: filter.isStarred, label: filter.isStarred }] : [],
    name: filter.name ? [{ value: filter.name, label: filter.name }] : [],
    keyValue: filter.keyValue,
    lastNumberOfDays: filter.lastNumberOfDays
      ? [{ value: filter.lastNumberOfDays.toString(), label: filter.lastNumberOfDays.toString() }]
      : [],
    created:
      filter.created &&
      (dateValue === DateFilterChoice.Month || dateValue === DateFilterChoice.Week)
        ? [filter.created]
        : [],
  };

  const getTagsForRender = (limit = 0): { tagsList: tags; overflow: tags } => {
    const tagsList: tags = [];
    for (const key in filterDataforTags) {
      const value = filterDataforTags[key as FilterKeysType];
      if (value && value.length) {
        value.forEach(({ value, label, username, subFeilds }) => {
          tagsList.push({ key, value, label, username, subFeilds });
        });
      }
    }
    const overflow = limit > 0 ? tagsList.splice(limit) : [];
    return { tagsList, overflow };
  };

  return getTagsForRender;
};

export const getFilterKeyLabel = (key: keyof Filters): string => {
  switch (key) {
    case 'assignedTo':
      return 'Assignee';
    case 'lastNumberOfDays':
      return 'Number of Days';
    case 'keyValue':
      return 'Tag';
    case 'isStarred':
      return 'Starred';
    case 'isSlo':
      return 'SLO Affecting';
    case 'withRetro':
      return 'Postmortems';
    case 'retroStatus':
      return 'Postmortem Status';
    case 'withNotes':
      return 'Incident Notes';
    case 'serviceOwner':
      return 'Service Owner';
    case 'startDate':
      return 'From';
    case 'endDate':
      return 'To';
  }
  return toSentenceCase(key);
};

export const invalSavedFilters = (queryClient: QueryClient) => {
  queryClient.invalidateQueries('listIncidentGroups');
};

export const getFiltersForDownload = (
  filters: IncidentFilters = {},
  sortBy: ILCOLUMN,
): Partial<IIncidentExportParams> => {
  const localDate = DateTime.local();
  const today = localDate.toISO();
  let numberOfDays = getListDefaultDaysOverride();
  let queryAllTime = isAllTimeLimit();

  const dateFilter = filters.dateFilter ?? DateFilterChoice.Days;
  if (filters.lastNumberOfDays && filters.dateFilter === DateFilterChoice.Days) {
    numberOfDays = filters.lastNumberOfDays;
    queryAllTime = false;
  } else if (dateFilter === DateFilterChoice.Week || dateFilter === DateFilterChoice.Month) {
    numberOfDays = getNoOfDays(dateFilter);
    queryAllTime = false;
  }

  // API expect 01-01-197 date as start time if we need all time data
  const priorDate = (
    queryAllTime ? DateTime.local(1970) : localDate.minus({ days: numberOfDays })
  ).toISO();

  return {
    start_time: filters.customDateFrom || priorDate,
    end_time: filters.customDateTo || today,
    assignedToUserIDsAndTheirSquads: filters.assignedToUserIDsAndTheirSquads ?? [],
    service_owner: getServiceOwnerQueryParam({
      userIDsAndTheirSquads: filters.serviceOwner?.userIDsAndTheirSquads ?? [],
      userIDs: filters.serviceOwner?.userIDs ?? [],
      squadIDs: filters.serviceOwner?.squadIDs ?? [],
    }),
    sort_by: sortBy,
    ...(filters.serviceIDs ? { services: filters.serviceIDs } : []),
    ...(filters.alertSourceIDs ? { sources: filters.alertSourceIDs } : []),
    ...(filters.responderIDs ? { assigned_to: filters.responderIDs } : []),
    ...(filters.tags ? { tags: filters.tags.map(tag => `${tag?.key}=${tag?.value}`) } : []),
    ...(filters.isSLO ? { slo_affecting: filters.isSLO } : []),
    ...(filters.sloIDs ? { slos: filters.sloIDs } : []),
    ...(filters.hasNotes ? { notes: filters.hasNotes } : null),
    ...(filters.hasRetrospectives ? { retrospectives: filters.hasRetrospectives } : null),
    ...(filters.isStarred ? { is_starred: filters.isStarred } : null),
    ...(filters.priority ? { priority: filters.priority } : null),
  };
};

function getServiceOwnerQueryParam(serviceOwner: ServiceOwner) {
  const entityOwnerParts = [];
  const users = [];
  const squads = [];
  const squadsOfUser = [];
  if (serviceOwner.userIDsAndTheirSquads) {
    squadsOfUser.push(serviceOwner.userIDsAndTheirSquads);
    users.push(serviceOwner.userIDsAndTheirSquads);
  }

  if (serviceOwner.userIDs) {
    users.push(serviceOwner.userIDs);
  }

  if (serviceOwner.squadIDs) {
    squads.push(serviceOwner.squadIDs);
  }
  if (users.length > 0) {
    entityOwnerParts.push('user:' + users.join(','));
  }
  if (squads.length > 0) {
    entityOwnerParts.push('squad:' + squads.join(','));
  }
  if (squadsOfUser.length > 0) {
    entityOwnerParts.push('squadsof:' + squadsOfUser.join(','));
  }
  const result = entityOwnerParts.join('|');
  return result;
}

export function getIntersection(arr1: any, arr2: any, key: string) {
  const keySet = new Set(arr2.map((obj: any) => obj[key]));

  return arr1.filter((obj: any) => keySet.has(obj[key]));
}

export const getResponders = (
  responderIds: string[],
  dropdownOptions: {
    label: string;
    options: { label: string; value: string; type: string; username?: string }[];
  }[],
) => {
  let searchPool: any = [];
  dropdownOptions?.map(
    (val: {
      label: string;
      options: { label: string; value: string; type: string; username?: string }[];
    }) => {
      searchPool = [...searchPool, ...val.options];
    },
  );
  searchPool = searchPool.filter((poolData: any) => poolData?.type !== 'select-all');
  const usersPool = searchPool.filter((poolData: any) => poolData?.type === 'user');
  const squadsPool = searchPool.filter((poolData: any) => poolData?.type === 'squad');

  return {
    squads: getIntersection(
      squadsPool,
      responderIds.map((ele: string) => {
        return {
          value: ele,
        };
      }),
      'value',
    ),
    users: getIntersection(
      usersPool,
      responderIds.map((ele: string) => {
        return {
          value: ele,
        };
      }),
      'value',
    ),
  };
};

export const getDefaultTime = (isEndDate: boolean) => {
  return isEndDate ? ['23', '59'] : ['00', '00'];
};

export const getDefaultTimeStr = (isEndDate: boolean) => {
  return getDefaultTime(isEndDate).join(':');
};
