import { Box, VStack } from '@chakra-ui/layout';
import { useParams } from 'react-router-dom';
import { useLCRStore } from '../../../../hooks/lcr.state';
import { LCRListState, ILogsFilterDataTypes } from '../../../../types/LCR.types';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_LCR__LOGS_FILTER_APPLIED } from 'core/const/tracker';
import TagStack from 'library/atoms/Tag/TagStack';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import { CustomDrawerComponent } from 'library/molecules';
import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormButton } from 'library/atoms';
import { Text } from 'library/atoms/Text';
import EntityUserDropdownCurrent from 'library/molecules/Form/EntityUserDropdown/EntityUserDropdown';
import EntityServiceropdown from 'library/molecules/Form/EntityServiceDropDown/EntityServiceDropDown';

type IProps = {
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
};
const LCRLogsFilter = ({ isDrawerOpen, onDrawerClose }: IProps) => {
  const params = useParams<{ id: string }>();
  const lcrState = useLCRStore((state: LCRListState) => state);
  const lcrLogsFilter = lcrState.logsFilter ?? [];

  interface IMenu {
    serviceID: string;
    name: string;
  }

  interface IUserMenu {
    id: string;
    name: string;
  }

  const onChangeDropDown = (val: ILogsFilterDataTypes) => {
    const hasDuplicate = lcrLogsFilter.some(option => option.value === val.value);

    if (!hasDuplicate) {
      useLCRStore.getState().setLCRLogsFilter([
        ...lcrLogsFilter,
        {
          key: val.key,
          label: val.label,
          value: val.value,
        },
      ]);
    }
  };

  const renderUserMappings = (dropdownValue: ILogsFilterDataTypes[]) => {
    return (
      <TagStack
        variant={TAG_VARIANTS.PLAIN}
        onClose={removeIndex => {
          const updatedArr = dropdownValue.find((_t: any, i: any) => i == removeIndex);
          const removeArr = lcrLogsFilter.filter(
            (_t: any, i: any) => _t.value !== updatedArr?.value,
          );
          useLCRStore.getState().setLCRLogsFilter(removeArr);
        }}
        tags={
          dropdownValue?.length
            ? dropdownValue.map((tag: ILogsFilterDataTypes) => {
                return { label: `${tag.label}` };
              })
            : []
        }
      />
    );
  };

  const handleApplyFilter = () => {
    useLCRStore.getState().setLCRLogsApply(lcrLogsFilter);
    AppTracker.track(T_WA_UP_LCR__LOGS_FILTER_APPLIED, {
      'User IDs': lcrLogsFilter.filter(value => value.key === 'User').map(data => data.value),
      'Service IDs': lcrLogsFilter.filter(value => value.key === 'Service').map(data => data.value),
      'LCR ID': params.id,
    });
    onDrawerClose();
  };
  return (
    <CustomDrawerComponent
      title="Filter"
      size="sm"
      isOpen={isDrawerOpen}
      onClose={onDrawerClose}
      disableBodyPadding
    >
      <VStack height="100%" align="stretch" p={4} gap={4}>
        <Box>
          <Text variant="h4_800" color="secondary.700" mb={2}>
            Assigned To
          </Text>
          <EntityUserDropdownCurrent
            name="User"
            value={null}
            onChange={(val: IUserMenu) => {
              onChangeDropDown({
                key: 'User',
                label: val.name,
                value: val.id,
              });
            }}
          />
          <Box mt={2} sx={{ '& *': { fontSize: 12 } }}>
            {renderUserMappings(lcrLogsFilter.filter(value => value.key === 'User'))}
          </Box>
        </Box>

        <Box>
          <Text variant="h4_800" color="secondary.700" mb={2}>
            Affected Services
          </Text>
          <EntityServiceropdown
            name="Service"
            value={null}
            onChange={(val: IMenu) => {
              onChangeDropDown({
                key: 'Service',
                label: val.name,
                value: val.serviceID,
              });
            }}
          />
          <Box mt={2} sx={{ '& *': { fontSize: 12 } }}>
            {renderUserMappings(lcrLogsFilter.filter(value => value.key === 'Service'))}
          </Box>
        </Box>
      </VStack>
      <DrawerFooterWrapper>
        <FormButton onClick={handleApplyFilter} title="Apply" />

        <FormButton variant="secondary" onClick={onDrawerClose} title="Cancel" />
      </DrawerFooterWrapper>
    </CustomDrawerComponent>
  );
};

export default LCRLogsFilter;
