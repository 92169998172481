import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import EntityOwnerDropdown from 'library/molecules/Form/EntityOwnerDropdown/EntityOwnerDropdown';
import EntityUserDropdown from 'library/molecules/Form/EntityUserDropdown/EntityUserDropdown';
import FormDropdown from 'library/molecules/Form/FormDropdown/FormDropdown';
import { FormTag } from 'library/molecules/Form/TagInput/FormTag';
import FormField from 'library/molecules/FormField/FormField';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import TagStack from 'library/atoms/Tag/TagStack';
import { HoverBox } from 'library/molecules';
import { ComponentProps, FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ACTION_OPTIONS, EVENT_OPTIONS } from '../../constant';
import { WorkflowFilterForm } from '../../types';

const FilterForm: FC<{ tagList: ComponentProps<typeof FormTag>['tagsList'] }> = ({ tagList }) => {
  const { setValue, watch } = useFormContext<WorkflowFilterForm>();
  const selectedTags = watch('tags') || [];

  /**
   * If the field has a value, resolve the value as null or empty array for tag
   * @param field field name of the form
   * @returns void
   */
  const onClearFieldValue = (field: keyof WorkflowFilterForm) => {
    const fieldValue = watch(field);

    if (field === 'tag' && (fieldValue as WorkflowFilterForm['tag'])?.length) {
      return () => setValue('tag', []);
    } else if (fieldValue) {
      return () => setValue(field, null);
    } else {
      return undefined;
    }
  };

  const onTagKeySelect = (tags: NonNullable<WorkflowFilterForm['tag']>) => {
    const [initialTag] = tags;
    if (initialTag && initialTag.value) {
      const isDuplicateTag = selectedTags.find(
        tag => tag.key === initialTag.key && tag.value === initialTag.value,
      );

      // Add unique tag to tags list
      if (!isDuplicateTag) {
        setValue('tags', [...selectedTags, ...tags]);
      }
      // Remove the tag value and allow another tag value to be selected without tag key removal
      setValue('tag', [{ ...initialTag, value: '' }]);
    } else {
      setValue('tag', tags);
    }
  };

  const ownerValue = watch('owner') ?? undefined;
  const createdBy = watch('created_by') ?? undefined;
  const updatedBy = watch('updated_by') ?? undefined;

  return (
    <VStack>
      <HoverBox>
        <FormField
          label="Event"
          onClearField={onClearFieldValue('event')}
          textStyle={{ fontSize: 16 }}
          style={{ padding: '16px', marginTop: 0 }}
        >
          <FormDropdown
            placeholder="Type or select the Event"
            options={EVENT_OPTIONS}
            name="event"
          />
        </FormField>
      </HoverBox>

      <HoverBox>
        <FormField
          label="Actions"
          onClearField={onClearFieldValue('action')}
          textStyle={{ fontSize: 16 }}
          style={{ padding: '16px', marginTop: 0 }}
        >
          <FormDropdown
            placeholder="Type or select the Actions"
            options={ACTION_OPTIONS}
            name="action"
          />
        </FormField>
      </HoverBox>
      <HoverBox>
        <FormField
          label="Tags"
          onClearField={onClearFieldValue('tags')}
          textStyle={{ fontSize: 16 }}
          style={{ padding: '16px', marginTop: 0 }}
        >
          <FormTag
            tagsList={tagList}
            initialValue={watch('tag') || []}
            onTagUpdate={onTagKeySelect}
            isSingleTag
            isVerticalAlign
          />

          <Box mt={2} sx={{ '& *': { fontSize: 8 } }}>
            <TagStack
              variant={TAG_VARIANTS.PLAIN}
              tags={selectedTags.map(tag => ({ label: `${tag.key} ${tag.value}` }))}
              onClose={removeIndex =>
                setValue(
                  'tags',
                  selectedTags.filter((_t, i) => i !== removeIndex),
                )
              }
            />
          </Box>
        </FormField>
      </HoverBox>
      <HoverBox>
        <EntityOwnerDropdown
          style={{ padding: '16px', marginTop: 0 }}
          value={ownerValue}
          name="owner"
          placeholder="Type or select Owner"
          onClearField={onClearFieldValue('owner')}
        />
      </HoverBox>
      <HoverBox>
        <EntityOwnerDropdown
          style={{ padding: '16px', marginTop: 0 }}
          value={createdBy}
          label="Created By"
          placeholder="Type or select Create by"
          name="created_by"
          onClearField={onClearFieldValue('created_by')}
        />
      </HoverBox>
      <HoverBox>
        <EntityOwnerDropdown
          style={{ padding: '16px', marginTop: 0 }}
          value={updatedBy}
          label="Updated By"
          placeholder="Type or select Updated by"
          name="updated_by"
          onClearField={onClearFieldValue('updated_by')}
        />
      </HoverBox>
      <HoverBox>
        <FormField
          style={{ padding: '16px', marginTop: 0 }}
          label="Show Enabled"
          onClearField={onClearFieldValue('enabled')}
          textStyle={{ fontSize: 16 }}
        >
          <RadioGroup
            onChange={val => setValue('enabled', val as WorkflowFilterForm['enabled'])}
            value={watch('enabled') || ''}
          >
            <HStack>
              <Radio value="yes">
                <Text variant="formInput">Yes</Text>
              </Radio>
              <Radio value="no">
                <Text variant="formInput">No</Text>
              </Radio>
            </HStack>
          </RadioGroup>
        </FormField>
      </HoverBox>
    </VStack>
  );
};

export default FilterForm;
