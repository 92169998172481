import produce from 'immer';
import { TERTIARY_COLORS } from 'library/theme/colors';
import { useEffect, useMemo, useState } from 'react';

type TagOption = { key: string; values: string[] };
export type Tag = { key?: string; value: string; color?: string };

export const EMPTY_TAG: Tag = {
  key: '',
  value: '',
  color: TERTIARY_COLORS[0],
};

type Props = {
  initialValue?: Tag[];
  availableTags: TagOption[];
  onChange?: (tags: Tag[]) => void;
};

export default function useTagGroup({ initialValue, availableTags, onChange }: Props) {
  const [hasIncompleteTag, setHasIncompleteTag] = useState(false);
  const [selectedTags, setSelectedTags] = useState([EMPTY_TAG]);

  useEffect(() => {
    setSelectedTags(initialValue?.length ? initialValue : [EMPTY_TAG]);
  }, [initialValue]);

  const keyOptions = useMemo(
    () =>
      availableTags.map(tag => ({
        label: tag.key,
        value: tag.key,
        valuesCount: tag.values.length,
      })),
    [availableTags],
  );

  const valueOptions = useMemo(
    () =>
      availableTags.reduce((acc, tag) => {
        acc[tag.key] = Array.from(new Set(tag.values));
        return acc;
      }, {} as Record<string, string[]>),
    [availableTags],
  );

  const onTagChange = (tags: Tag[]) => {
    setSelectedTags(tags);
    onChange?.(tags);

    setHasIncompleteTag(tags.some(tag => !tag.key || !tag.value));
  };

  const onTagAdd = () => {
    onTagChange([...selectedTags, EMPTY_TAG]);
  };

  const onTagDelete = (tagIndex: number) => () => {
    const filteredTags = selectedTags.filter((_value, index) => index != tagIndex);
    onTagChange(filteredTags);
  };

  const onTagKeyChange = (index: number) => (key: string) => {
    const updatedTags = produce(selectedTags, draft => {
      draft[index].key = key;
      draft[index].value = '';
    });
    onTagChange(updatedTags);
  };

  const onTagValueChange = (index: number) => (value: string) => {
    const updatedTags = produce(selectedTags, draft => {
      draft[index].value = value;
    });
    onTagChange(updatedTags);
  };

  const onTagColorChange = (index: number) => (color: string) => {
    if (color) {
      const updatedTags = produce(selectedTags, draft => {
        draft[index].color = color;
      });
      onTagChange(updatedTags);
    }
  };

  return {
    tags: selectedTags,
    hasIncompleteTag,
    keyOptions,
    keyValueOptions: valueOptions,
    onTagAdd,
    onTagDelete,
    onTagKeyChange,
    onTagValueChange,
    onTagColorChange,
  };
}
