import { Flex } from '@chakra-ui/layout';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { StatusPageAction } from 'views/main/organization/workflow/types';
import ImpactForm from './ImpactForm';
import { API } from 'core';
import { useStausPageList } from 'views/main/organization/statuspage-v2/hooks/useStatusPageList';
import { IStatusCode } from 'views/main/organization/statuspage-v2/Interface';
import StatusForm from './StatusForm';
import { usePageDetails } from 'views/main/organization/statuspage-v2/hooks/usePageDetails';
import { debounce } from 'lodash';
import { usePageIssue } from 'views/main/organization/statuspage-v2/hooks/usePageIssue';
import FormField from 'library/molecules/FormField/FormField';
import { Input } from 'library/atoms';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';
import { GetStatusPageDetailsQuery } from 'views/main/organization/statuspage-v2/graphql/query';

const StatusPageForm = () => {
  const { setIsDirty } = useWorkflowFormContext();
  const { formState, watch, setValue, getValues, reset } = useFormContext<StatusPageAction>();

  const { list } = useStausPageList(API.config.teamId);
  const { details } = usePageDetails(watch('data.status_page_id'));
  const { formik: statusPageDetail } = usePageIssue(watch('data.status_page_id'), () => {});

  const debouncedInput = debounce((title: string) => {
    setValue('data.issue_title', title, { shouldDirty: true });
  }, 1500);

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  const statusPageList = useMemo(() => {
    if (!list) return [];

    return list.map(status => ({
      label: status?.name as string,
      value: status?.id,
    }));
  }, [list]);

  const pageStatusList = useMemo(() => {
    if (!details?.statusCodes) return [];

    return details.statusCodes.map(
      (status: GetStatusPageDetailsQuery['getStatusPageDetails']['statusCodes'][0]) => ({
        label: status?.slug as string,
        value: status?.id as number,
      }),
    );
  }, [details]);

  const selectedStatusPage =
    //-1 represents no ID or default value
    watch('data.status_page_id') === -1
      ? statusPageList[0]
      : statusPageList?.find(status => status.value === watch('data.status_page_id'));

  const selectedPageStatus = pageStatusList?.find(
    ({ value }: { value: number }) => value === watch('data.page_status_id'),
  );

  useEffect(() => {
    if (selectedStatusPage) {
      setValue('data.status_page_id', selectedStatusPage.value as number);
    }
  }, [list]);
  return (
    <Flex px={6} pt={4} flexDirection="column" gap={2} pb={16}>
      <FormField
        label="Select status page"
        error={formState.errors.data?.status_page_id?.message}
        isRequired
      >
        <FrameWorkDropdown
          options={statusPageList}
          onChange={o => {
            if (o) {
              reset({
                ...getValues(),
                data: {
                  ...getValues('data'),
                  status_page_id: o.value,
                  component_and_impact: [],
                  status_and_message: [],
                  page_status_id: -1,
                },
              });
            }
          }}
          value={selectedStatusPage}
          placeholder="Select"
        />
      </FormField>
      <FormField label="Issue Title" error={formState.errors.data?.issue_title?.message} isRequired>
        <Input
          defaultValue={getValues('data.issue_title')}
          onChange={e => debouncedInput(e.target.value)}
          placeholder="Type your issue here"
        />
      </FormField>
      <FormField
        label="Page status"
        error={formState.errors.data?.page_status_id?.message}
        isRequired
      >
        <FrameWorkDropdown
          options={pageStatusList}
          value={typeof selectedPageStatus === 'undefined' ? null : selectedPageStatus}
          placeholder="Select page status"
          onChange={o => setValue('data.page_status_id', o!.value, { shouldDirty: true })}
        />
      </FormField>
      <ImpactForm
        statusPageDetail={statusPageDetail.values}
        err={formState.errors.data?.component_and_impact?.message ?? ''}
      />
      <StatusForm values={statusPageDetail.values} />
    </Flex>
  );
};

export default StatusPageForm;
