import React, { Component } from 'react';
import './index.css';
import { connect } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { IComponentState } from 'core/interfaces/IComponentState';
import { objectIsEmpty, deepCopy, pick } from 'core/helpers';
import render from './render.index';
import { IRoutingExpressionBranch } from 'core/interfaces/IRoutingRule';
import {
  BillingService,
  RoutingRulesService,
  ServiceService,
  TaggingRulesService,
} from 'core/services';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_GS_ROUTING_RULES_MODIFIED } from 'core/const/tracker';
import { exception } from 'core/exception';
import equal from 'fast-deep-equal/es6/react';
import { getAssignableTeamMembers } from 'shared/reducers';
import { IRoutingRule } from 'views/main/organization/service-catalog/Interfaces/automation-rule';
import { RoutingRules } from '..';
import { AutomationRuleContext, AutomationRuleContextProps } from '../context';
import { ActionMeta, OnChangeValue } from 'react-select';
import { ICreatableOption } from 'views/main/organization/incidentDetails/renders/updateTags/tagGroup';

interface IProps extends Pick<IAppState, 'organization'> {
  serviceId: string;
  hide: () => void;
  checkAndSetDirty: () => void;
  setIsDirty: (isDirty: boolean) => void;
  refetchRule: () => void;
  editRuleId?: string;
  rules?: IRoutingRule[];
}

interface IState {
  componentState: IComponentState;
  rules: IRoutingRule[];
  saveState: 'idle' | 'saving' | 'saved' | 'error';
  networkError: '';
  eventState: 'loading' | 'noEvents' | 'idle';
  tags: any;
  errors: string[];
  warningIndex: number | null;
  searchString: { [key: string]: string };
  swappedRuleIndexes: number[];
  globalSearch: string;
  showUpgradeModal: boolean;
  planRoutingRulesLimitExceeded: boolean;
  orgRoutingRulesCount: number;
  existingDeleted: number;
  editRuleId: string | undefined;
}

export class RoutingRulesModal extends Component<IProps, IState, AutomationRuleContextProps> {
  static contextType = AutomationRuleContext;
  declare context: React.ContextType<typeof AutomationRuleContext>;

  public _service = this.props.organization.services.s.find(s => s.id === this.props.serviceId);
  public _searchMap: {
    type: 'user' | 'squad' | 'escalationpolicy';
    id: string;
    name: string;
  }[] = [
    ...getAssignableTeamMembers(
      this.props.organization.selectedTeam.team!,
      this.props.organization.users.u,
    ).map(u => ({
      name: `${u.first_name} ${u.last_name}`,
      id: u.id,
      type: 'user' as any,
    })),
    ...this.props.organization.squads.s.map(s => ({
      name: s.name,
      id: s.id,
      type: 'squad' as any,
    })),
    ...this.props.organization.escalations.e.map(e => ({
      name: e.name,
      id: e.id,
      type: 'escalationpolicy' as any,
    })),
  ];
  private routingService = new RoutingRulesService(this.props.serviceId);
  private serviceService = new ServiceService(this.props.serviceId);
  private taggingService = new TaggingRulesService(this.props.serviceId);
  private initialState?: IState;

  constructor(props: IProps) {
    super(props);
    this.state = {
      componentState: 'busy',
      rules: props.rules ?? [],
      eventState: 'loading',
      tags: {},
      saveState: 'idle',
      errors: [],
      networkError: '',
      warningIndex: null,
      searchString: {},
      swappedRuleIndexes: [],
      globalSearch: '',
      showUpgradeModal: false,
      planRoutingRulesLimitExceeded: false,
      orgRoutingRulesCount: 0,
      existingDeleted: 0,
      editRuleId: props.editRuleId,
    };
  }
  public render = render;
  public async componentDidMount() {
    this.getAlertSourceConfig();
    await this.getSeverityRules();
    await this.updateOrgRoutingRulesCount();

    if (!this.props.editRuleId) {
      this.checkPlanLimitsAndAddRule();
    }
  }

  updateUpgradePlanLimitExceeded = () => {
    this.setState({
      planRoutingRulesLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'routing-rules',
        () =>
          this.state.rules.filter(r => r.existing === false).length +
          this.state.orgRoutingRulesCount -
          this.state.existingDeleted,
      ),
    });
  };

  public updateOrgRoutingRulesCount = async () => {
    try {
      const {
        data: {
          data: { count: orgRoutingRulesCounts },
        },
      } = await this.routingService.getOrgCount();
      await this.setState({ orgRoutingRulesCount: orgRoutingRulesCounts });
      this.updateUpgradePlanLimitExceeded();
    } catch (err: any) {
      this.setState({ componentState: 'error' });
    }
  };

  public getAlertSourceConfig = async () => {
    this.setState({
      eventState: 'loading',
    });
    try {
      const taggingRulesResponse = await this.taggingService.get();
      let activeTags: any = {};
      const {
        data: { data: activeTagsResponse },
      } = await this.serviceService.getActiveTags();

      activeTags = activeTagsResponse.tags;

      const tags = taggingRulesResponse.data.data.rules.reduce((c: any, nr: any, i: number) => {
        Object.entries(nr.tags).forEach((t: any) => {
          if (c[t[0]]) {
            c[t[0]].push(t[1].value);
          } else {
            c[t[0]] = [t[1].value];
          }
        });
        return c;
      }, {});

      const res: any = {};
      const keys = [...Object.keys(activeTags), ...Object.keys(tags)];
      keys.forEach(key => {
        const tmp = [];

        if (tags.hasOwnProperty(key)) {
          tmp.push(...tags[key]);
        }

        if (activeTags.hasOwnProperty(key)) {
          tmp.push(...activeTags[key]);
        }

        res[key] = Array.from(new Set(tmp));
      });

      const isRulesEmpty = objectIsEmpty(res);

      this.setState({
        eventState: isRulesEmpty ? 'noEvents' : 'idle',
        tags: isRulesEmpty
          ? {}
          : {
              tags: res,
            },
      });
    } catch (err: any) {
      this.setState({
        eventState: 'noEvents',
        tags: {},
      });
      exception.handle('E_GET_SERVICE_ROUTING_RULES_ALERT_SOURCE', err);
    }
  };

  public getSeverityRules = async () => {
    this.setState({ componentState: 'busy' });
    try {
      const isRulesEmpty = this.props.rules?.length === 0;

      const rules = isRulesEmpty
        ? []
        : this.props.rules?.map((r, id: number) => ({
            id,
            rule_id: r.rule_id,
            expression: r.expression,
            searchString: '',
            route_to: {
              name:
                this._searchMap.find(e => e.id === r.route_to.entity_id)?.name ??
                `Deleted ${r.route_to.entity_type}`,
              entity_type: r.route_to.entity_type,
              entity_id: r.route_to.entity_id,
            },
            is_basic: r.is_basic,
            basic_expression: r.is_basic ? r.basic_expression : [],
            existing: true,
          })) ?? [];

      this.setState(
        {
          rules,
          componentState: 'idle',
        },
        () => (this.initialState = deepCopy(this.state)),
      );
    } catch (err: any) {
      exception.handle('E_GET_SERVICE_ROUTING_RULES', err);
      this.setState({ componentState: 'error' });
    }
  };

  public checkPlanLimitsAndAddRule = async () => {
    if (this.state.planRoutingRulesLimitExceeded) {
      return this.setState({ showUpgradeModal: true });
    }
    await this.addNewRule();
    this.updateUpgradePlanLimitExceeded();
  };

  public addNewRule = async () => {
    this.setState(({ rules }) => {
      rules.push({
        id: rules.length,
        rule_id: '',
        expression: '',
        is_basic: true,
        basic_expression: [
          {
            lhs: '',
            rhs: '',
          },
        ],
        existing: false,
        searchString: '',
        route_to: {
          name: '',
          entity_type: '',
          entity_id: '',
        },
      });

      return {
        rules,
      };
    });
  };

  public verify = () => {
    const errors: string[] = [];
    const { rules } = this.state;
    let firstErrIndex = -1;

    rules
      .filter(rule =>
        this.props.editRuleId ? rule.rule_id === this.props.editRuleId : !rule.existing,
      )
      .forEach((rule, index) => {
        const errString = `Error in rule #${index + 1} with expression ${
          rule.is_basic ? this.generateExpression(rule.basic_expression) : rule.expression
        }: `;
        if (!rule.is_basic) {
          if (rule.expression === '') {
            errors.push(errString + 'expressions cant be empty');
          }
        } else {
          rule.basic_expression.forEach((be, beIndex) => {
            if (be.lhs === '') {
              errors.push(errString + 'expressions cant be empty');
            }
            if (be.rhs === '') {
              errors.push(errString + 'expressions cant be empty');
            }
          });
        }
        if (rule.route_to.entity_id === '') {
          errors.push(errString + 'rule has to be assigned to any entity');
        }
        // check for deleted user here
        if (rule.route_to.name == 'Deleted user') {
          errors.push(errString + 'rule is assigned to a Deleted user');
        }
        if (errors.length !== 0 && firstErrIndex === -1) {
          firstErrIndex = index;
        }
      });

    this.setState({ errors });
    return errors;
  };

  public save = async () => {
    AppTracker.track(T_WA_GS_ROUTING_RULES_MODIFIED);
    this.setState({ errors: [], saveState: 'saving' });
    const errors = this.verify();
    if (errors.length > 0) {
      this.setState({ saveState: 'idle' });
      return;
    }

    const { rules } = this.state;
    const { editRuleId } = this.props;
    const rule = this.state.rules.find((r, i, arr) =>
      editRuleId ? r.rule_id === editRuleId : i === arr.length - 1,
    );

    try {
      if (!rule) {
        throw new Error('Rule not defined');
      }

      if (editRuleId) {
        await this.context?.updateRule.mutateAsync({
          ruleType: RoutingRules.routing,
          ruleId: rule.rule_id,
          ruleDetail: rule,
        });
      } else {
        await this.context?.createRule.mutateAsync({
          ruleType: RoutingRules.routing,
          ruleDetail: rule,
        });
      }

      this.setState({
        rules: rules.map(r => {
          return { ...r, existing: true };
        }),
        existingDeleted: 0,
      });
      this.setState({
        saveState: 'saved',
      });
      this.props.setIsDirty(false);

      this.props.refetchRule();
    } catch (err: any) {
      this.setState({
        saveState: 'error',
        networkError: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }
  };
  public stopPropagation = () => (e: any) => e.stopPropagation();

  public onRoutingvalueChange = (index: number) => (_: any, v: any) => {
    this.onAddRouteTo(index, v.id, v.type, v.name);
  };

  public onAddRouteTo = (
    index: number,
    id: string,
    type: 'user' | 'squad' | 'escalationpolicy',
    name: string,
  ) => {
    this.setState(({ rules }) => {
      rules[index].route_to = {
        name,
        entity_id: id,
        entity_type: type,
      };

      return { rules };
    });
  };

  setEntitySearch =
    (entityType: string, ruleIndex: number, beIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      this.setState(({ searchString }) => {
        searchString[`${entityType}_${ruleIndex}_${beIndex}`] = value;
        return { searchString };
      });
    };

  addCondition = (ruleID: number) => () => {
    this.setState(({ rules }) => {
      const selectedRuleIndex = rules.findIndex(r => r.id === ruleID);
      const newRules = [...rules];

      if (selectedRuleIndex === -1) return { rules };

      newRules[selectedRuleIndex].basic_expression.push({
        lhs: '',
        rhs: '',
      });

      return { rules };
    });
  };

  changeConditionSelectBox =
    (ruleID: number, beIndex: number, field: 'lhs' | 'rhs') =>
    (newValue: OnChangeValue<ICreatableOption, false>, _: ActionMeta<ICreatableOption>) => {
      let value = newValue?.value;
      if (!value) return;
      if (field === 'lhs' && !value.startsWith('tags.')) {
        value = 'tags.' + value;
      }
      this.setState(({ rules }) => {
        const selectedRuleIndex = rules.findIndex(r => r.id === ruleID);
        const newRules = [...rules];

        if (selectedRuleIndex === -1) return { rules };

        newRules[selectedRuleIndex].basic_expression[beIndex][field] = value;
        if (field === 'lhs') {
          newRules[selectedRuleIndex].basic_expression[beIndex].rhs = '';
        }
        return { rules: newRules };
      });
    };

  removeCondition = (ruleID: number, beIndex: number) => () => {
    this.setState(({ rules }) => {
      const selectedRuleIndex = rules.findIndex(r => r.id === ruleID);
      const newRules = [...rules];

      if (selectedRuleIndex === -1) return { rules };

      newRules[selectedRuleIndex].basic_expression.splice(beIndex, 1);
      return { rules: newRules };
    });
  };

  public onRuleExpressionChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      this.setState(({ rules }) => {
        rules[index].expression = value;
        return { rules };
      });
    };

  generateExpression = (basicExpression: IRoutingExpressionBranch[]): string => {
    return basicExpression
      .map(be => {
        if (be.lhs === '') return '';
        const fs = '{lhs} == {rhs}';

        const rhs = `"${be.rhs}"`;
        return fs.replace('{lhs}', be.lhs).replace('{rhs}', rhs);
      })
      .join(' && ');
  };

  openWarning = (ruleIndex: number) => () => this.setState({ warningIndex: ruleIndex });
  closeWarning = (flag: boolean) => () => {
    if (!flag) {
      this.setState({ warningIndex: null });
    } else {
      this.setState(({ rules, warningIndex }) => {
        const rule = rules[warningIndex as number];
        rule.is_basic = false;
        rule.expression = this.generateExpression(rule.basic_expression);
        rule.basic_expression = [];
        return { rules, warningIndex: null };
      });
    }
  };

  hide = () => this.state.warningIndex === null && this.props.hide();

  public componentDidUpdate() {
    if (
      this.initialState &&
      this.state.saveState !== 'saved' &&
      !equal(pick(this.state, 'rules'), pick(this.initialState, 'rules'))
    )
      this.props.checkAndSetDirty();
  }
}

export default connect(({ organization }: IAppState) => ({
  organization,
}))(RoutingRulesModal);
