import { HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { Tag } from 'library/atoms';
import { memo } from 'react';

import { truncate } from '../../common/util';

export const FilterFormTags = memo(
  ({
    values,
    setFieldValue,
    type,
    isSingle,
    valueSecondary,
    typeSecondary,
    fieldType,
    onClose,
    ownServiceData,
  }: {
    values: Array<{ value: string; label: string; username?: string | null }>;
    setFieldValue: (type: string, val: Array<{ value: string; label: string }> | null) => void;
    type: string;
    isSingle?: boolean;
    valueSecondary?: Array<{ value: string; label: string }>;
    typeSecondary?: string;
    fieldType?: 'service_owner' | 'others';
    onClose?: (type: string, id: string) => void;
    ownServiceData?: {
      squads: any;
      users: any;
    };
  }) => {
    const tagValue = ownServiceData
      ? [
          ...values,
          {
            value: 'meAndMySquads',
            type: 'meAndMySquads',
            label: 'Me and My Squad',
          },
        ]
      : values;

    const generateToolTipTextForOwnSqauds = () => {
      const targetArray: string[] = [];
      if (ownServiceData?.squads.length) {
        ownServiceData.squads.map((ele: any) => {
          targetArray.push(`Squad: ${ele.label}`);
        });
      }
      if (ownServiceData?.users.length) {
        ownServiceData.users.map((ele: any) => {
          targetArray.push(`User: ${ele.label}`);
        });
      }

      return targetArray.join(', ');
    };

    generateToolTipTextForOwnSqauds();
    return (
      <>
        {tagValue.length ? (
          <Wrap mt={3}>
            {tagValue.map(
              ({
                value,
                label,
                username,
                type: fieldtype,
              }: {
                value: string;
                label: string;
                username?: string | null;
                type?: string;
              }) => {
                return (
                  <WrapItem key={value?.toString() ?? ''}>
                    <Tag
                      size="md"
                      variant="filter"
                      label={label}
                      tooltipText={
                        label === 'Me and My Squad' ? generateToolTipTextForOwnSqauds() : undefined
                      }
                      title={
                        username && label !== 'Me and My Squad' ? (
                          <HStack>
                            <Text fontWeight={'bold'}>
                              {truncate(label)}
                              <Text margin={1} as="span" fontWeight="normal">
                                ({truncate(username)})
                              </Text>
                            </Text>
                          </HStack>
                        ) : (
                          <HStack>
                            <Text fontWeight={'bold'}>
                              {label === 'Me and My Squad' ? 'Me and My Squads' : label}
                            </Text>
                          </HStack>
                        )
                      }
                      onClose={() => {
                        if (fieldType && fieldType === 'service_owner') {
                          onClose && fieldtype && onClose(fieldtype, value);
                        } else {
                          setFieldValue(
                            type,
                            isSingle ? null : values.filter((obj: any) => obj.value != value),
                          );
                          if (valueSecondary && typeSecondary) {
                            setFieldValue(
                              typeSecondary,
                              isSingle
                                ? null
                                : valueSecondary.filter((obj: any) => obj.value != value),
                            );
                          }
                        }
                      }}
                    />
                  </WrapItem>
                );
              },
            )}
          </Wrap>
        ) : null}
      </>
    );
  },
);
