import Axios from 'axios';
import { API } from 'core';
import { useQuery } from 'react-query';
import { AlertSources } from './types';
import { QUERY_KEYS } from '../../../constant';
import { useToast } from 'library/atoms';
import { BE_FieldType } from '../../../types';

const alertSourceListFunc = async () => {
  const { data } = await Axios.get<AlertSources>(`${API.config.endpoint}/public/integrations`);
  return data.data;
};

const useAlertSourcesList = (alertSources?: BE_FieldType) => {
  const toast = useToast();
  return useQuery([QUERY_KEYS.ALERT_SOURCES], () => alertSourceListFunc(), {
    onError: () => {
      toast({ text: 'Fetch Alert Sources failed', status: 'error' });
    },
    enabled: !!alertSources || true,
  });
};

export default useAlertSourcesList;
