import { Flex, Box, Input } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { HttpCallAction } from 'views/main/organization/workflow/types';
import Editor from '@monaco-editor/react';
import { THEME_COLORS } from 'library/theme/colors';
import HttpCallFormHeader from './HttpCallFormHeader';
import FormField from 'library/molecules/FormField/FormField';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';

const HttpCallForm = () => {
  const { setIsDirty } = useWorkflowFormContext();

  const {
    formState: { isDirty, errors },
    register,
    setValue,
    watch,
    trigger,
  } = useFormContext<HttpCallAction>();

  const urlOptions = useMemo(
    () => [
      { label: 'Get', value: 'GET' },
      { label: 'Post', value: 'POST' },
      { label: 'Put', value: 'PUT' },
      { label: 'Patch', value: 'PATCH' },
      { label: 'Delete', value: 'DELETE' },
    ],
    [],
  );

  const method = watch('data.method');
  const methodValue = urlOptions.find(url => url.value === method);
  const payload = watch('data.body');

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  return (
    <>
      <Flex px={6} pt={4} flexDirection="column">
        <Flex flex={1} gap={2}>
          <Flex flex={0.3}>
            <FormField label="URL" error={errors?.data?.method && errors.data.method.message}>
              <FrameWorkDropdown
                options={urlOptions}
                isLoading={false}
                value={methodValue}
                onChange={o => {
                  setValue('data.method', o!.value, { shouldDirty: true });
                  trigger(`data.method`);
                }}
                placeholder={'Select'}
              />
            </FormField>
          </Flex>
          <Flex flex={1} mt={6}>
            <FormField error={errors?.data?.url && errors.data.url.message}>
              <Input {...register('data.url')} aria-label="url" />
            </FormField>
          </Flex>
        </Flex>
        <HttpCallFormHeader />
        <Box mt={4}>
          <FormField label="Payload" error={errors?.data?.body && errors.data.body.message}>
            <Box border={`1px solid ${THEME_COLORS.secondary[200]}`}>
              <Editor
                height={'250px'}
                value={payload}
                onChange={e => {
                  setValue('data.body', e?.trim() ?? '', { shouldDirty: true });
                }}
                width="100%"
              />
            </Box>
          </FormField>
        </Box>
      </Flex>
    </>
  );
};

export default HttpCallForm;
