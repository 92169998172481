import { WORKFLOW_STATUS_COLORS } from 'library/theme/colors';
import {
  OperationOption,
  OperatorTypes,
  ActionListItem,
  ActionTypes,
  CommunicationChannelTypes,
  CommunicationChannelTypesOption,
  FilterTypeOption,
  FilterTypes,
  SubActionTypes,
  TriggerOption,
  LogicalOperatorOptions,
  LogicalOperatorTypes,
} from '../types';
import { WorkflowRunningStatus } from '../types/response/workflow';
import { WorkflowTriggerTypes } from '../types/triggers';

export const TRIGGER_OPTIONS_LABEL_MAP = {
  [WorkflowTriggerTypes.INCIDENT_TRIGGERED]: 'When incident is Triggered',
  [WorkflowTriggerTypes.INCIDENT_ACKNOWLEDGED]: 'When incident is Acknowledged',
  [WorkflowTriggerTypes.INCIDENT_REASSIGNED]: 'When incident is Reassigned',
  [WorkflowTriggerTypes.INCIDENT_RESOLVED]: 'When incident is Resolved',
  [WorkflowTriggerTypes.INCIDENT_TAGS_UPDATED]: 'When incident tags are updated',
  [WorkflowTriggerTypes.INCIDENT_PRIORITY_UPDATED]: 'When incident priority is updated',
  [WorkflowTriggerTypes.INCIDENT_NOTE_ADDED]: 'When a note is added to an incident',
};

export const TRIGGER_OPTIONS: TriggerOption[] = [
  {
    label: TRIGGER_OPTIONS_LABEL_MAP[WorkflowTriggerTypes.INCIDENT_TRIGGERED],
    value: WorkflowTriggerTypes.INCIDENT_TRIGGERED,
  },
  {
    label: TRIGGER_OPTIONS_LABEL_MAP[WorkflowTriggerTypes.INCIDENT_ACKNOWLEDGED],
    value: WorkflowTriggerTypes.INCIDENT_ACKNOWLEDGED,
  },
  {
    label: TRIGGER_OPTIONS_LABEL_MAP[WorkflowTriggerTypes.INCIDENT_REASSIGNED],
    value: WorkflowTriggerTypes.INCIDENT_REASSIGNED,
    blockedForActions: [SubActionTypes.REASSIGN_INCIDENT],
  },
  {
    label: TRIGGER_OPTIONS_LABEL_MAP[WorkflowTriggerTypes.INCIDENT_RESOLVED],
    value: WorkflowTriggerTypes.INCIDENT_RESOLVED,
    blockedForActions: [SubActionTypes.RESOLVE_INCIDENT, SubActionTypes.REASSIGN_INCIDENT],
  },
  {
    label: TRIGGER_OPTIONS_LABEL_MAP[WorkflowTriggerTypes.INCIDENT_TAGS_UPDATED],
    value: WorkflowTriggerTypes.INCIDENT_TAGS_UPDATED,
  },
  {
    label: TRIGGER_OPTIONS_LABEL_MAP[WorkflowTriggerTypes.INCIDENT_PRIORITY_UPDATED],
    value: WorkflowTriggerTypes.INCIDENT_PRIORITY_UPDATED,
  },
  {
    label: TRIGGER_OPTIONS_LABEL_MAP[WorkflowTriggerTypes.INCIDENT_NOTE_ADDED],
    value: WorkflowTriggerTypes.INCIDENT_NOTE_ADDED,
  },
];

export const TRIGGER_LABELS = {
  [WorkflowTriggerTypes.INCIDENT_TRIGGERED]: 'Incident Triggered',
  [WorkflowTriggerTypes.INCIDENT_ACKNOWLEDGED]: 'Incident Acknowledged',
  [WorkflowTriggerTypes.INCIDENT_REASSIGNED]: 'Incident Reassigned',
  [WorkflowTriggerTypes.INCIDENT_RESOLVED]: 'Incident Resolved',
  [WorkflowTriggerTypes.INCIDENT_TAGS_UPDATED]: 'Incident Tags Updated',
  [WorkflowTriggerTypes.INCIDENT_PRIORITY_UPDATED]: 'Incident Priority Updated',
  [WorkflowTriggerTypes.INCIDENT_NOTE_ADDED]: 'Incident Note Added',
};

export const EVENT_OPTIONS: TriggerOption[] = [
  {
    label: 'Incident Triggered',
    value: WorkflowTriggerTypes.INCIDENT_TRIGGERED,
  },
  {
    label: 'Incident Acknowledged',
    value: WorkflowTriggerTypes.INCIDENT_ACKNOWLEDGED,
  },
  {
    label: 'Incident Reassigned',
    value: WorkflowTriggerTypes.INCIDENT_REASSIGNED,
  },
  {
    label: 'Incident Resolved',
    value: WorkflowTriggerTypes.INCIDENT_RESOLVED,
  },
  {
    label: 'Incident Tags Updated',
    value: WorkflowTriggerTypes.INCIDENT_TAGS_UPDATED,
  },
  {
    label: 'Incident Priority Updated',
    value: WorkflowTriggerTypes.INCIDENT_PRIORITY_UPDATED,
  },
  {
    label: 'Incident Note Added',
    value: WorkflowTriggerTypes.INCIDENT_NOTE_ADDED,
  },
];

export const FILTER_TYPE_LABEL = {
  [FilterTypes.SERVICE]: 'Service Name',
  [FilterTypes.ALERT_SOURCE]: 'Alert Source',
  [FilterTypes.TAGS]: 'Tags',
  [FilterTypes.PRIORITY]: 'Priority',
};

export const OPERATOR_LABEL = {
  [OperatorTypes.IS]: 'is',
  [OperatorTypes.AND]: 'and',
  [OperatorTypes.IS_NOT]: 'is not',
  [OperatorTypes.CONTAINS]: 'contains',
  [OperatorTypes.CONTAINS_NOT]: 'does not contain',
};

export const FILTER_TYPE_OPTIONS: FilterTypeOption[] = [
  {
    label: FILTER_TYPE_LABEL[FilterTypes.SERVICE],
    value: FilterTypes.SERVICE,
  },
  {
    label: FILTER_TYPE_LABEL[FilterTypes.ALERT_SOURCE],
    value: FilterTypes.ALERT_SOURCE,
  },
  {
    label: FILTER_TYPE_LABEL[FilterTypes.TAGS],
    value: FilterTypes.TAGS,
  },
  {
    label: FILTER_TYPE_LABEL[FilterTypes.PRIORITY],
    value: FilterTypes.PRIORITY,
  },
];

export const OperatorOptions: OperationOption[] = [
  {
    label: 'Is',
    value: OperatorTypes.IS,
  },
  {
    label: 'Is not',
    value: OperatorTypes.IS_NOT,
  },
  {
    label: 'Contains',
    value: OperatorTypes.CONTAINS,
  },
  {
    label: 'Does not contain',
    value: OperatorTypes.CONTAINS_NOT,
  },
];

export const TAGS_FILTER_TYPE_OPTIONS: OperationOption[] = [OperatorOptions[0], OperatorOptions[1]];
export const ALERT_SOURCE_FILTER_TYPE_OPTIONS: OperationOption[] = [
  OperatorOptions[0],
  OperatorOptions[1],
];

export const LOGICAL_OPERATOR_OPTIONS: LogicalOperatorOptions[] = [
  {
    label: 'And',
    value: LogicalOperatorTypes.AND,
  },
  {
    label: 'Or',
    value: LogicalOperatorTypes.OR,
  },
];

export const QUERY_KEYS = {
  ALERT_SOURCES: 'alertSources',
  TAGS_LIST: 'tagsList',
  WORKFLOW: 'workflow',
  WORKFLOW_LOGS: 'workflowLogs',
  WORKFLOW_ACTION_LOGS: 'workflowActionLogs',
};

export const dropdownAvailableOptions = [OperatorTypes.IS, OperatorTypes.IS_NOT];
export const ACTION_TITLE = {
  [ActionTypes.SQUADCAST]: 'Select Squadcast Action',
  [ActionTypes.SLACK]: 'Select Slack Action',
  [ActionTypes.MS_TEAMS]: 'Select Microsoft Teams Action',
  [ActionTypes.JIRA]: 'Select Jira Action',
  [SubActionTypes.RUNBOOKS]: 'Attach Runbook(s)',
  [SubActionTypes.HTTP_CALL]: 'Make an HTTP call',
  [SubActionTypes.COMMUNICATION_CHANNEL]: 'Add Communication Channel',
  [SubActionTypes.INCIDENT_NOTE]: 'Add Incident Note',
  [SubActionTypes.JIRA_CREATE_TICKET]: 'Create Jira Ticket',
  [SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL]: 'Send a message to specific Microsoft Teams Channel',
  [SubActionTypes.MS_TEAMS_MESSAGE_USER]: 'Send a message to specific Microsoft Teams User',
  [SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK]:
    'Create Microsoft Teams meeting and add it as a communication channel',
  [SubActionTypes.RESOLVE_INCIDENT]: 'Auto Resolve Incident',
  [SubActionTypes.REASSIGN_INCIDENT]: 'Reassign Incident',
  [SubActionTypes.SEND_EMAIL]: 'Send an email',
  [SubActionTypes.PRIORITY]: 'Update Priority',
  [SubActionTypes.STATUS_PAGE]: 'Add Status Page Issue',
  [SubActionTypes.SLACK_CHANNEL_ARCHIEVE]: 'Archive Incident Specific Slack Channel',
  [SubActionTypes.SLACK_CHANNEL_CREATE]: 'Create Incident Specific Slack Channel',
  [SubActionTypes.SLACK_MESSAGE_CHANNEL]: 'Send a Message to Specific Slack Channel',
  [SubActionTypes.SLACK_MESSAGE_USER]: 'Send a Direct message to User',
  [SubActionTypes.SLO_AFFECTED]: 'Mark Incident as SLO Affecting',
  [SubActionTypes.TRIGGER_MANUAL_WEBHOOK]: 'Trigger Manual Webhook',
  [SubActionTypes.JIRA_CREATE]: ' Jira Ticket',
};

export const ACTION_OPTIONS = Object.values(SubActionTypes)
  .filter(
    type => ![SubActionTypes.REASSIGN_INCIDENT, SubActionTypes.RESOLVE_INCIDENT].includes(type),
  )
  .map(type => ({
    label: ACTION_TITLE[type as keyof typeof ACTION_TITLE],
    value: type,
  }));

export const MS_TEAMS_ACTIONS: ActionListItem[] = [
  {
    name: SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL,
    title: 'Send a message to specific Microsoft Teams Channel',
    description: 'Automatically sends a message to a Microsoft Teams channel',
    allowMultiple: true,
  },
  {
    name: SubActionTypes.MS_TEAMS_MESSAGE_USER,
    title: 'Send a message to specific Microsoft Teams User',
    description: 'Automatically sends a message to a Microsoft Teams user',
    allowMultiple: true,
  },
  {
    name: SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK,
    title: 'Create a Microsoft Teams Meeting',
    description:
      'Automatically creates a Microsoft Teams meeting link and adds it to the communication channel',
    allowMultiple: false,
  },
];

export const SLACK_ACTIONS: ActionListItem[] = [
  {
    name: SubActionTypes.SLACK_CHANNEL_ARCHIEVE,
    title: 'Archive Incident Specific Slack Channel',
    description: 'Automatically archives the existing Incident Specific Slack channel',
    allowMultiple: false,
  },
  {
    name: SubActionTypes.SLACK_CHANNEL_CREATE,
    title: 'Create incident specific Slack Channel',
    description:
      'Automatically creates an Incident Specific Slack channel in the communication card',
    allowMultiple: false,
  },
  {
    name: SubActionTypes.SLACK_MESSAGE_CHANNEL,
    title: 'Send a message to Specific Slack Channel',
    description: 'Sends a message to the selected Slack channel',
    allowMultiple: true,
  },
  {
    name: SubActionTypes.SLACK_MESSAGE_USER,
    title: 'Send a Direct Message to a User',
    description: 'Sends a Direct Message to the selected Slack User',
    allowMultiple: true,
  },
];
export const SQ_ACTIONS: ActionListItem[] = [
  {
    name: SubActionTypes.RUNBOOKS,
    title: 'Attach Runbook(s)',
    description: 'Automatically attaches the selected runbooks to the incident.',
    allowMultiple: false,
  },
  {
    name: SubActionTypes.COMMUNICATION_CHANNEL,
    title: ACTION_TITLE[SubActionTypes.COMMUNICATION_CHANNEL],
    description:
      'Automatically adds the communication details to the communication card of the incident.',
    allowMultiple: false,
  },
  {
    name: SubActionTypes.INCIDENT_NOTE,
    title: ACTION_TITLE[SubActionTypes.INCIDENT_NOTE],
    description: 'Automatically adds the note to the Incident.',
    allowMultiple: true,
  },
  {
    name: SubActionTypes.SLO_AFFECTED,
    title: ACTION_TITLE[SubActionTypes.SLO_AFFECTED],
    description: 'Automatically marks the incident as SLO affecting.',
    allowMultiple: false,
  },
  {
    name: SubActionTypes.RESOLVE_INCIDENT,
    title: ACTION_TITLE[SubActionTypes.RESOLVE_INCIDENT],
    description: 'Description text will come here',
    allowMultiple: true,
    disabledForTriggers: [WorkflowTriggerTypes.INCIDENT_RESOLVED],
    disabled: true,
  },
  {
    name: SubActionTypes.REASSIGN_INCIDENT,
    title: ACTION_TITLE[SubActionTypes.REASSIGN_INCIDENT],
    description: 'Description text will come here',
    allowMultiple: true,
    disabledForTriggers: [
      WorkflowTriggerTypes.INCIDENT_RESOLVED,
      WorkflowTriggerTypes.INCIDENT_REASSIGNED,
    ],
    disabled: true,
  },
  {
    name: SubActionTypes.TRIGGER_MANUAL_WEBHOOK,
    title: ACTION_TITLE[SubActionTypes.TRIGGER_MANUAL_WEBHOOK],
    description: 'Automatically triggers a manual webhook',
    allowMultiple: true,
  },
  {
    name: SubActionTypes.HTTP_CALL,
    title: ACTION_TITLE[SubActionTypes.HTTP_CALL],
    description: 'Automatically make a custom HTTP call',
    allowMultiple: true,
  },
  {
    name: SubActionTypes.SEND_EMAIL,
    title: ACTION_TITLE[SubActionTypes.SEND_EMAIL],
    description: 'Automatically sends email',
    allowMultiple: true,
  },

  {
    name: SubActionTypes.STATUS_PAGE,
    title: ACTION_TITLE[SubActionTypes.STATUS_PAGE],
    description: 'Automatically updates Statuspage',
    allowMultiple: true,
  },
  {
    name: SubActionTypes.PRIORITY,
    title: ACTION_TITLE[SubActionTypes.PRIORITY],
    description: 'Automatically updates priority',
    allowMultiple: false,
  },
];
export const Jira_ACTIONS: ActionListItem[] = [
  {
    name: SubActionTypes.JIRA_CREATE_TICKET,
    title: 'Create a Jira Ticket',
    description: 'Automatically create a Jira ticket in Jira Cloud.',
    allowMultiple: true,
  },
];

export const CHANNEL_LABEL = {
  [CommunicationChannelTypes.VIDEO]: 'Video Conference Link',
  [CommunicationChannelTypes.CHAT]: 'Chat Link',
  [CommunicationChannelTypes.EXTERNAL]: 'Link',
};
export const COMMUNICATION_CHANNEL_OPTIONS: CommunicationChannelTypesOption[] = [
  {
    label: `Add ${CHANNEL_LABEL[CommunicationChannelTypes.VIDEO]}`,
    value: CommunicationChannelTypes.VIDEO,
  },
  {
    label: `Add ${CHANNEL_LABEL[CommunicationChannelTypes.CHAT]}`,
    value: CommunicationChannelTypes.CHAT,
  },
  {
    label: `Add ${CHANNEL_LABEL[CommunicationChannelTypes.EXTERNAL]}`,
    value: CommunicationChannelTypes.EXTERNAL,
  },
];

export const WORKFLOW_DETAILS_TABS = [
  {
    name: 'Details',
    queryKey: 'details',
    analytics_key: 'Details',
  },
  {
    name: 'Logs',
    queryKey: 'logs',
    analytics_key: 'Logs',
  },
];

export const STATUS_COLORS: Record<WorkflowRunningStatus, string> = {
  running: WORKFLOW_STATUS_COLORS.running,
  successful: WORKFLOW_STATUS_COLORS.successful,
  failed: WORKFLOW_STATUS_COLORS.failed,
  partial: WORKFLOW_STATUS_COLORS.partial,
  queued: WORKFLOW_STATUS_COLORS.queued,
};

export const STATUS_LABELS: Record<WorkflowRunningStatus, string> = {
  running: 'Running',
  successful: 'Successful',
  failed: 'Failed',
  partial: 'Partially Successful',
  queued: 'Queued',
};
