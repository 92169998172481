import React, { useCallback, useEffect } from 'react';
import { Box, FormLabel, Input, useMultiStyleConfig, VStack } from '@chakra-ui/react';
import { LCRListState, MappingTableTypes, ROUTING_BEHAVIOR } from '../../../../types/LCR.types';
import EntityServiceropdown from 'library/molecules/Form/EntityServiceDropDown/EntityServiceDropDown';
import { FormButton, IconButton } from 'library/atoms';
import { BinIcon, DownArrow, UpArrow } from 'icons';
import { useLCRStore } from 'views/main/organization/lcr/hooks/lcr.state';
import { containsObject, moveElement, updateIncides } from '../../helper';
import EntitySchduleDropDown from 'library/molecules/Form/EntitySchduleDropDown/EntitySchduleDropDown';
import TagStack from 'library/atoms/Tag/TagStack';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import { Text } from 'library/atoms/Text';
function MappingTable() {
  const mappingDataStateValues = useLCRStore((state: LCRListState) => state.mappingData);
  const setMappingData = useLCRStore((state: any) => state.setMappingData);

  const setFormData = useLCRStore((state: any) => state.setFormData);
  const formState = useLCRStore((state: LCRListState) => state.formState);
  const mappings = formState?.keywordMapping || [];
  const variant = 'floating';
  const formStyles = useMultiStyleConfig('FormField', {
    variant,
  });

  const getDisabledStatus = () => {
    let isDisabled = false;
    if (formState?.routingBehavior === ROUTING_BEHAVIOR.VOICEMAIL) {
      mappingDataStateValues.map((ele: MappingTableTypes) => {
        if (ele?.alias.length === 0 || ele?.service?.length === 0) isDisabled = true;
        return;
      });
    } else {
      mappingDataStateValues.map((ele: MappingTableTypes) => {
        if (ele?.alias.length === 0 || ele?.service?.length === 0 || ele?.schedule.length === 0)
          isDisabled = true;
        return;
      });
    }
    return isDisabled;
  };

  useEffect(() => {
    return () => {
      setMappingData([
        {
          keypadNumber: 1,
          alias: '',
          service: [],
          schedule: [],
        },
      ]);
    };
  }, []);

  const updateMappingState = useCallback(
    (key: string, value: any, keypadNumber: number) => {
      const id = mappings.findIndex(x => x.keypadNumber == keypadNumber);
      let newArr: MappingTableTypes[] = mappings ?? [];
      const found = mappings.some(el => el?.keypadNumber === keypadNumber);
      if (found) {
        if (key === 'alias') {
          newArr[id] = {
            ...mappings[id],
            alias: value,
          };
        } else if (key === 'service') {
          newArr[id] = {
            ...mappings[id],
            service: value,
          };
        } else if (key === 'schedule') {
          newArr[id] = {
            ...mappings[id],
            schedule: containsObject(value, mappings[id].schedule)
              ? mappings[id]?.schedule.filter((el: any) => el.ID !== value.ID)
              : [...mappings[id].schedule, value],
          };
        }
      } else {
        newArr = [
          ...newArr,
          {
            keypadNumber: keypadNumber,
            alias: key === 'alias' ? value : '',
            service: key === 'service' ? value : '',
            schedule: key === 'schedule' ? [value] : '',
          },
        ];
      }
      setFormData({
        ...formState,
        keywordMapping: newArr,
      });

      setMappingData(newArr);
    },
    [formState],
  );
  const addNewService = useCallback(() => {
    if (mappingDataStateValues.length <= 9) {
      setMappingData([
        ...mappingDataStateValues,
        {
          keypadNumber: mappingDataStateValues.length + 1,
          alias: '',
          service: '',
          schedule: '',
        },
      ]);
    }
  }, [mappingDataStateValues]);

  const deleteMappedValue = useCallback(
    (keypadNumber: number) => {
      const resultArr = mappingDataStateValues.filter(
        (val: MappingTableTypes) => val.keypadNumber !== keypadNumber,
      );

      const res = updateIncides(resultArr);
      setMappingData(res);
      setFormData({
        ...formState,
        keywordMapping: res,
      });
    },
    [mappingDataStateValues, updateIncides],
  );

  const renderScheduleMappings = (keypadNumber: number) => {
    const id = mappings.findIndex(x => x.keypadNumber == keypadNumber);
    return (
      <TagStack
        variant={TAG_VARIANTS.PLAIN}
        onClose={removeIndex => {
          const newArr: MappingTableTypes[] = mappings;
          const updatedArr = mappings[id]?.schedule.filter((_t: any, i: any) => i !== removeIndex);
          newArr[id] = {
            ...mappings[id],
            schedule: updatedArr,
          };
          setFormData({
            ...formState,
            keywordMapping: newArr,
          });
        }}
        tags={
          mappings[id]?.schedule?.length
            ? mappings[id]?.schedule?.map((tag: any) => {
                return { label: `${tag.name}` };
              })
            : []
        }
      />
    );
  };

  const renderActivityButtons = (row: any) => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // marginTop: 2,
          paddingTop: 0,
          marginLeft: 15,
          height: 60,
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100px',
          }}
        >
          <IconButton
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            icon={<UpArrow />}
            aria-label="filter"
            disabled={row?.keypadNumber === 1}
            onClick={() => {
              const ele = moveElement(mappingDataStateValues, row?.keypadNumber, 'up');
              setMappingData(ele);

              setFormData({
                ...formState,
                keywordMapping: ele,
              });
            }}
          />
          <IconButton
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            icon={<DownArrow />}
            aria-label="filter"
            disabled={row?.keypadNumber === mappingDataStateValues.length}
            onClick={() => {
              const ele = moveElement(mappingDataStateValues, row?.keypadNumber, 'down');
              setMappingData(ele);

              setFormData({
                ...formState,
                keywordMapping: ele,
              });
            }}
          />
          <IconButton
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            icon={<BinIcon style={{ height: '12px', width: '12px' }} />}
            disabled={mappingDataStateValues.length <= 1}
            aria-label="filter"
            onClick={() => {
              deleteMappedValue(row.keypadNumber);
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box style={{ marginTop: 0, width: '100%' }}>
      <table style={{ width: '100%' }}>
        <thead style={{ backgroundColor: '#E2E8F0', height: '40px' }}>
          <tr>
            <th className="#css-11hl124" style={{ width: '10px' }}>
              <FormLabel
                sx={{ ...formStyles.label, fontSize: 11, marginLeft: '18px' }}
                marginBottom={0}
                marginRight={0}
                marginLeft={8}
              >
                KEYPAD NUMBER
              </FormLabel>
            </th>
            <th>
              <FormLabel
                sx={{ ...formStyles.label, fontSize: 11, marginLeft: '18px' }}
                marginBottom={0}
                marginRight={0}
                marginLeft={8}
              >
                ALIAS
              </FormLabel>
            </th>

            <th>
              <FormLabel
                sx={{ ...formStyles.label, fontSize: 11, marginLeft: '18px' }}
                marginBottom={0}
                marginRight={0}
                marginLeft={8}
              >
                SERVICE
              </FormLabel>
            </th>
            {formState?.routingBehavior === ROUTING_BEHAVIOR.CALL && (
              <th>
                <FormLabel
                  sx={{ ...formStyles.label, fontSize: 11, marginLeft: '18px' }}
                  marginBottom={0}
                  marginRight={0}
                  marginLeft={8}
                >
                  SCHEDULE
                </FormLabel>
              </th>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {mappingDataStateValues.map((value: any, index) => (
            <tr key={index}>
              <td style={{ width: '150px' }}>
                <FormLabel
                  sx={{ ...formStyles.label, fontSize: 11 }}
                  marginBottom={0}
                  marginRight={0}
                  marginLeft={8}
                >
                  {value.keypadNumber}
                </FormLabel>
              </td>
              <td>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    // marginTop: 2,
                    paddingTop: 0,
                    marginLeft: 15,
                    height: 60,
                  }}
                >
                  <Input
                    maxLength={25}
                    value={mappings[value.keypadNumber - 1]?.alias}
                    onChange={e => {
                      if (/^[a-zA-Z0-9 ,.\-?]+$/.test(e.target.value) || e.target.value.length <= 0)
                        updateMappingState('alias', e.target.value, value.keypadNumber);
                    }}
                    placeholder="Enter alias"
                  />
                  <Text variant="body" color="secondary.700">
                    {25 - (mappings[value.keypadNumber - 1]?.alias?.length ?? 0)} character(s)
                    remaining
                  </Text>
                </Box>
              </td>
              <td>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    // marginTop: 2,
                    paddingTop: 0,
                    marginLeft: 15,
                    height: 60,
                  }}
                >
                  <EntityServiceropdown
                    name="Service"
                    error=""
                    value={mappings[value.keypadNumber - 1]?.service}
                    isRequired
                    menuPortalTarget
                    onChange={(val: any) => {
                      updateMappingState('service', val, value.keypadNumber);
                    }}
                  />
                </Box>
              </td>
              {formState?.routingBehavior === ROUTING_BEHAVIOR.CALL && (
                <td>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      // marginTop: 2,
                      paddingTop: 0,
                      marginLeft: 15,
                      height: 60,
                    }}
                  >
                    <Box display={'flex'} flex={'row'} width={'100%'} style={{ height: 60 }}>
                      <VStack style={{ width: '100%', justifyContent: 'flex-start' }}>
                        <Box style={{ width: '100%' }}>
                          <EntitySchduleDropDown
                            name="Service"
                            error=""
                            value={mappings[value.keypadNumber - 1]?.schedule}
                            isRequired
                            menuPortalTarget
                            onChange={(val: any) => {
                              updateMappingState('schedule', val, value.keypadNumber);
                            }}
                          />
                        </Box>
                        <Box mt={2} sx={{ '& *': { fontSize: 8 }, width: '100%' }}>
                          {renderScheduleMappings(value?.keypadNumber)}
                        </Box>
                      </VStack>
                    </Box>
                  </Box>
                </td>
              )}
              <td>{renderActivityButtons(value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box style={{ width: '100%' }} mt={2}>
        <FormButton
          variant="secondary"
          title="Add Service"
          disabled={mappingDataStateValues.length >= 9 || getDisabledStatus()}
          onClick={addNewService}
        />
      </Box>
    </Box>
  );
}

export default React.memo(MappingTable);
