import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import Select, { StylesConfig } from 'react-select';
import * as Yup from 'yup';
import { PAGE_TYPES } from '../../../constants/schema';
import { getPageInputDetails } from '../../../helpers/helper.service';
import {
  IComponentsForm,
  IComponentTreeItem,
  IfilterOption,
  IServiceOption,
} from '../../../Interface';
import { useFlag } from '@unleash/proxy-client-react';
import { colors } from '../../../theme/styles/colors';
import { THEME_COLORS } from 'library/theme/colors';
import StatusPageComponentTimelineForm from './timeline';
import { GetStatusPageDetailsQuery } from '../../../graphql/query';
import { StatuspageComponentTimelineProvider } from './timeline/context';

interface IComponent {
  components?: IComponentTreeItem[];
}
interface IAddComponentType {
  onCancel: () => void;
  groupOptions: IfilterOption[];
  init: IComponentsForm;
  onSave: (values: IComponentsForm) => void;
  isComponentProgress?: boolean;
  serviceOptions: Array<IServiceOption>;
  isAdvanceSettingHidden?: boolean;
  isComponentTimelineVisible?: boolean;
  componentData?: IComponentTreeItem[];
  statusCodes?: GetStatusPageDetailsQuery['getStatusPageDetails']['statusCodes'];
}

type Iprops = IAddComponentType & IComponent;

export const ReactSelectStyles: StylesConfig<any> = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 40,
  }),
};

const { schema } = getPageInputDetails(PAGE_TYPES.ADDCOMPONENT);

export function ComponentForm(props: Iprops) {
  const enabledServiceComponentConnector = useFlag('service-component-connector');

  const addComponentSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name of component is required')
      .trim()
      .max(250, 'Max characters allowed are 250')
      .test('unique', 'Component name already exists', function (value) {
        const { componentData, init, components } = props;
        //for edit case , initial populated name and changed name should be checked in order to pass validation
        // else ,suppose if we only change desc,it will check name validation, resulting in duplicate name

        const isEditedNameDuplicate = (componentData ?? components)?.some(
          data =>
            data.name.toLowerCase() === init.name.toLowerCase() &&
            data.name.toLowerCase() === value?.toLowerCase(),
        );

        const isNewNameDuplicate = (componentData ?? components)?.some(
          data => data.name.toLowerCase() === value?.toLowerCase(),
        );

        return isEditedNameDuplicate || !isNewNameDuplicate;
      }),
    componentToGroup: Yup.boolean(),
    groupId: Yup.string().when('componentToGroup', {
      is: true,
      then: Yup.string().required('Select a group'),
    }),
  });
  return (
    <VStack alignItems={'flex-start'} width={'100%'} justifyContent={'space-between'}>
      <Formik
        initialValues={props.init}
        onSubmit={props.onSave}
        validationSchema={addComponentSchema}
        validateOnBlur={true}
      >
        {({ values, errors, setFieldValue, submitForm, touched }) => {
          return (
            <Form style={{ width: '100%' }}>
              <StatuspageComponentTimelineProvider
                component={values}
                statusCodes={props.statusCodes!}
                renderTimeline={true}
              >
                <>
                  <VStack gap={2} width={'100%'} alignItems={'flex-start'}>
                    <FormControl isInvalid={!!errors.name && touched.name} width={'100%'}>
                      <FormLabel fontWeight={800} htmlFor={'name'} fontSize={14} color={'#627C98'}>
                        Component Name*
                      </FormLabel>
                      <Input
                        name={'name'}
                        autoFocus
                        type={'text'}
                        fontSize={14}
                        fontWeight={400}
                        value={values.name}
                        placeholder="Add component name"
                        color={'#627C98'}
                        onChange={e => {
                          e.stopPropagation();
                          setFieldValue('name', e.target.value, true);
                        }}
                      />
                      {errors.name && touched.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={!!errors.description}>
                      <FormLabel
                        htmlFor={`description`}
                        mt={3}
                        fontWeight={800}
                        fontSize={16}
                        color={'#627C98'}
                      >
                        Add Description
                      </FormLabel>
                      <Textarea
                        onChange={e => {
                          e.stopPropagation();
                          setFieldValue('description', e.target.value);
                        }}
                        value={values.description}
                        name={'description'}
                        fontWeight={400}
                        fontSize={14}
                        color={'#627C98'}
                        placeholder="Eg : We continously monitor our service. Any issues will be posted here"
                      />
                      {errors.description && (
                        <FormErrorMessage>{errors.description}</FormErrorMessage>
                      )}
                    </FormControl>

                    <Checkbox
                      name={`componentToGroup`}
                      fontWeight={800}
                      mt={3}
                      fontSize={14}
                      color={'#627C98'}
                      defaultChecked={!!values.componentToGroup}
                      onChange={evt => {
                        setFieldValue(`componentToGroup`, evt.target.checked);
                        if (!evt.target.checked) {
                          setFieldValue('groupId', undefined);
                        }
                      }}
                      mb={1}
                    >
                      Add Component to a Group
                    </Checkbox>
                    <FormControl
                      ml={17}
                      isInvalid={values.componentToGroup && !!errors.groupId && touched.groupId}
                    >
                      <Select
                        options={props.groupOptions}
                        value={props.groupOptions.filter(el => values.groupId === el.value)}
                        onChange={value => {
                          setFieldValue('groupId', value?.value);
                        }}
                        isDisabled={!values.componentToGroup}
                        isMulti={false}
                        styles={{ ...ReactSelectStyles }}
                      />
                      {errors.groupId && touched.groupId && (
                        <FormErrorMessage>{errors.groupId}</FormErrorMessage>
                      )}
                    </FormControl>
                    {props.isComponentTimelineVisible && props.init.name.length > 0 && (
                      <StatusPageComponentTimelineForm />
                    )}
                    {!props.isAdvanceSettingHidden && (
                      <Accordion width="100%" allowToggle>
                        <AccordionItem border="none" backgroundColor={'none'}>
                          <AccordionButton
                            _expanded={{ bg: 'transparent' }}
                            _focus={{ boxShadow: 'none' }}
                            style={{ padding: '8px 0px' }}
                          >
                            <Flex>
                              <Text color={colors.colors.gray[700]} fontSize={14} fontWeight={800}>
                                Advanced Settings
                              </Text>
                              <AccordionIcon ml={1.5} color={'#000'} />
                            </Flex>
                          </AccordionButton>

                          <AccordionPanel style={{ padding: '8px 0px' }}>
                            <VStack spacing="24px">
                              {enabledServiceComponentConnector && (
                                <FormControl
                                  isInvalid={
                                    values.componentToGroup && !!errors.groupId && touched.groupId
                                  }
                                >
                                  <Text
                                    color={THEME_COLORS.secondary[700]}
                                    fontSize={14}
                                    fontWeight={800}
                                    marginBottom="6px"
                                  >
                                    Select Service
                                  </Text>
                                  <Select
                                    options={props.serviceOptions}
                                    value={
                                      values?.serviceID === null
                                        ? props.serviceOptions[0]
                                        : props.serviceOptions.filter(
                                            el => values.serviceID === el.value,
                                          )
                                    }
                                    onChange={value => {
                                      setFieldValue('serviceID', value?.value);
                                    }}
                                    isMulti={false}
                                    styles={{ ...ReactSelectStyles }}
                                  />
                                  {errors.groupId && touched.groupId && (
                                    <FormErrorMessage>{errors.groupId}</FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </VStack>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    )}
                  </VStack>
                  <Box w="100%" bottom={0} position="fixed" bgColor={'white'} zIndex="2">
                    <Divider />
                    <HStack py={2} gap={4}>
                      <Button
                        onClick={submitForm}
                        variant="default"
                        isLoading={props.isComponentProgress}
                      >
                        Save
                      </Button>
                      <Button variant="invertedDefault" onClick={() => props.onCancel()}>
                        Cancel
                      </Button>
                    </HStack>
                  </Box>
                </>
              </StatuspageComponentTimelineProvider>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
}
