import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h20v20H0z"></path>
    <path
      fill="#E05420"
      fillRule="evenodd"
      d="M9.298 16.953a2 2 0 0 1-1.054-.49c-.218-.187-.371-.368-.769-.9a306.058 306.058 0 0 0-1.299-1.749l-.39-.525-.015-.02-.762-1.028c-.18-.24-.437-.587-.682-.917l-.421-.566-.572-.775.14-.187a449.985 449.985 0 0 0 1.101-1.48C5.17 7.518 5.873 6.569 6.34 5.94 7.92 3.806 8.09 3.593 8.442 3.358 8.845 3.09 9.2 3 9.843 3c.414 0 .487.005.72.068.281.077.8.309.824.37.008.023-.08.165-.219.352-.129.171-.985 1.312-1.9 2.537L7.513 8.671c-.44.575-.962 1.289-.962 1.318 0 .018.456.64 1.01 1.38 2.834 3.777 3.84 5.134 3.827 5.166-.042.113-.733.381-1.104.432a3.9 3.9 0 0 1-.985-.014m4.665.009a2 2 0 0 1-1.054-.49c-.219-.187-.371-.37-.77-.901a271.1 271.1 0 0 0-1.688-2.273l-.016-.022c-.146-.197-.482-.649-.76-1.027-.181-.24-.438-.587-.683-.917l-.422-.566L8 9.99l.14-.187c.078-.105.244-.326.366-.492l.735-.988c.595-.798 1.298-1.746 1.765-2.376 1.58-2.134 1.751-2.347 2.102-2.582.403-.268.758-.358 1.401-.358.414 0 .487.005.72.069.281.076.8.308.824.368.008.024-.08.166-.219.353-.13.172-.985 1.312-1.9 2.537-.916 1.225-1.706 2.279-1.756 2.345-.44.574-.962 1.288-.962 1.317 0 .018.456.64 1.01 1.38 2.834 3.778 3.84 5.134 3.827 5.166-.043.113-.733.382-1.104.432a3.9 3.9 0 0 1-.985-.013m1.28-5.551a1.422 1.422 0 1 0 0-2.845 1.422 1.422 0 0 0 0 2.845"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default SvgComponent;
