import { Box, Flex } from '@chakra-ui/react';
import { API } from 'core';
import { T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_FILTER_APPLIED } from 'core/const/tracker';
import { Search } from 'library/atoms';
import { Loader } from 'library/molecules';
import { forwardRef, useImperativeHandle } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';
import {
  FilterEntity as FilterEntityEnum,
  useEntityOwnerFilter,
} from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import useQueryParams from 'views/main/organization/service-catalog/helpers/helper.query-params';

import { useSession } from '../../behaviors/Session';
import { getTimeZone } from '../../common/util';
import { IncidentParamsContext } from '../../hooks/useAllParams';
import { SavedFilter } from '../../interfaces/filters';
import { filterManager } from '../manager';
import { buildSavedFilterObj } from '../manager/get-saved-filter-list';
import { EmptyList } from './EmptyList';
import { FilterEntity } from './FilterEntity';

type FiltersListProps = {
  onSelection: (id: number) => void;
  onClose: () => void;
};

export const FilterList = forwardRef(({ onSelection, onClose }: FiltersListProps, ref) => {
  const [selectedId, setSelectedId] = useState<number>(filterManager.getUIFilters()?.id ?? -1);
  const [searchTerm, setSearchTerm] = useState<string>();
  const query = useQueryParams();
  const filterQueryParam = query.get('entity_owner') ?? '';

  const queryClient = useQueryClient();
  const { dropdownOptions } = useEntityOwnerFilter({
    queryParamsString: filterQueryParam,
    entity: FilterEntityEnum.ESCALATION_POLICY,
    selectedTeamId: API.config.teamId,
  });

  const {
    session: { orgState: organization },
  } = useSession();
  const timeZone = getTimeZone(organization);

  const {
    params: {
      filterList,
      isLoading,
      serviceList,
      users,
      squads,
      escalationPolicy,
      tagsData,
      slolist,
      alertSourceList,
    },
  } = useContext(IncidentParamsContext);

  useEffect(() => {
    const assignedTo = [...users, ...squads, ...escalationPolicy];
    const savedFilters = buildSavedFilterObj(
      filterList?.listIncidentGroups,
      {
        alertSourceList,
        serviceList,
        assignedTo,
        tagsData,
        slolist,
        dropdownOptions,
      },
      timeZone,
    );
    setAPIFiltersListData(savedFilters);
    updateFiltersList(searchTerm, savedFilters);
  }, [filterList?.listIncidentGroups]);

  const [apiFiltersListData, setAPIFiltersListData] = useState<SavedFilter[]>([]);
  const [filtersListData, setFiltersListData] = useState<SavedFilter[]>([]);

  useImperativeHandle(ref, () => ({
    applyFilter(id: number) {
      const savedFilterObj = filtersListData.find(item => item.id === id);
      if (savedFilterObj) {
        const filterData = {
          ...savedFilterObj.filters,
          id: savedFilterObj.id,
          name: savedFilterObj.name,
        };
        filterManager.applyFilters(filterData, queryClient);
        const apiFilters = filterManager.buildAPIFilterModel(filterData);

        const tags: Record<string, string> = {};
        if (apiFilters.tags) {
          apiFilters.tags.forEach(pair => {
            if (pair?.key != null && pair?.value != null) {
              tags[pair?.key.toString()] = pair?.value;
            }
          });
        }
        const trackedAppliedFilter = {
          'Incident Group ID': savedFilterObj.id,
          Responders: apiFilters.responderIDs,
          'Affected Service': apiFilters.serviceIDs,
          Tags: tags,
          'Alert Sources': filterData.alert.map(alertSourceData => alertSourceData.label),
          Priority: filterData.priority.map(priorityData => priorityData.value),
          'SLO Affecting': filterData.isSlo ? (apiFilters.isSLO == 'no' ? 'No' : 'Yes') : null,
          'Inc with Postmortem': filterData.withRetro
            ? apiFilters.hasRetrospectives == 'no'
              ? 'No'
              : 'Yes'
            : null,
          'Inc with Notes': filterData.withNotes
            ? apiFilters.hasNotes == 'no'
              ? 'No'
              : 'Yes'
            : null,
          'Starred Inc': filterData.isStarred
            ? apiFilters.isStarred == 'no'
              ? 'No'
              : 'Yes'
            : null,
        };
        AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_FILTER_APPLIED, {
          'Applied Filter': trackedAppliedFilter,
        });

        onClose();
      }
    },
    resetFilterSelection() {
      setSelectedId(-1);
    },
  }));

  const updateFiltersList = (val = '', list = apiFiltersListData) => {
    setSearchTerm(val);
    if (val) {
      const filteredList = list.filter(item => item.name?.toLowerCase().includes(val));
      setFiltersListData(filteredList);
    } else {
      setFiltersListData(list);
    }
  };

  const filterData = (val: string) => {
    updateFiltersList(val.toLowerCase());
  };

  const onSelectionChange = (e: any) => {
    const id = +e.target.value;
    setSelectedId(id);
    onSelection(id);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {apiFiltersListData.length ? (
        <Box p={4} display="flex" flexDir="column" rowGap={2}>
          <Search onSearch={filterData} hasAutoFocus={false} showSearchBarOnly />
          <Flex flexDir="column">
            {filtersListData.map(filterObj => (
              <Box
                key={filterObj.id}
                borderBottom={1}
                borderStyle="solid"
                borderBottomColor="secondary.200"
              >
                <FilterEntity
                  filterData={filterObj}
                  isSelected={filterObj.id === selectedId}
                  onChange={onSelectionChange}
                  timeZone={timeZone}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      ) : (
        <EmptyList />
      )}
    </>
  );
});
