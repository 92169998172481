import { Box, HStack, RadioGroup, Text, useBoolean, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { FormButton, Input, Radio, useToast } from 'library/atoms';
import { Modal } from 'library/molecules';
import { useQueryClient } from 'react-query';

import { useCreateIncidentGroupMutation } from '../../graphql/mutation';
import { Filters } from '../../interfaces/filters';
import { filterManager } from '../manager';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_SAVED_FILTER_CREATED } from 'core/const/tracker';
import { useHistory } from 'react-router';
import useQueryParams from 'core/hooks/useQueryParams';
import { queryUpdate } from 'core/util';
import { omit } from 'lodash';
import { applyTimeZone, getTimeZone } from '../../common/util';
import { useSession } from '../../behaviors/Session';
import { getDefaultTime, getDefaultTimeStr } from '../manager/util';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  filters: Filters;
  teamID: string;
  onFilterClose: () => void;
  timeZone: string;
};

enum SAVE_TYPE {
  TEAM = 'team',
  PRIVATE = 'private',
}

export const SaveFilter = ({
  isOpen,
  onClose,
  onSuccess,
  filters,
  teamID,
  onFilterClose,
  timeZone,
}: Props) => {
  const isValid = ({ name, type }: { name: string; type: string }): boolean => {
    return !!name && !!type;
  };
  const [applyAfterSave, setApplyAfterSave] = useBoolean();
  const { mutateAsync: saveFilters, isLoading } = useCreateIncidentGroupMutation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const history = useHistory();
  const query = useQueryParams();

  const saveFilter = (name: string, type: string, doApply: boolean) => {
    const apiFilter = filterManager.getAPIFilterObj(filters, timeZone);
    (doApply ? setApplyAfterSave.on : setApplyAfterSave.off)();

    saveFilters({
      teamID,
      name,
      isPrivate: type === SAVE_TYPE.PRIVATE,
      filters: apiFilter,
    })
      .then(({ createIncidentGroup }) => {
        toast({
          status: 'success',
          text: `Success: ${name} created`,
        });
        if (doApply) {
          filterManager.applyFilters({ ...filters, id: createIncidentGroup, name }, queryClient);
          onFilterClose();
        }
        onSuccess();
        AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_SAVED_FILTER_CREATED, {
          'Saved Filter Scope': type === SAVE_TYPE.PRIVATE ? 'Save for Me' : 'Save for Team',
        });
      })
      .catch(error => {
        toast({
          status: 'error',
          text: `${error}` ?? 'Error: Unable to create filter',
        });
      });
  };

  return (
    <Modal
      title="Save Filter"
      isOpen={isOpen}
      onClose={onClose}
      body={
        <Formik
          initialValues={{ name: '', type: '' }}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          }}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <VStack align="flex-start" rowGap={1}>
                <Box w="100%" mb={4}>
                  <Text color="secondary.700" variant="formInput_800">
                    Filter Name*
                  </Text>
                  <Input
                    mt={2}
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                    placeholder="Filter Name"
                    name="name"
                  />
                  {props.errors.name && <div id="error">{props.errors.name}</div>}
                </Box>
                <Text color="secondary.700" variant="formInput_800">
                  Scope*
                </Text>
                <RadioGroup name="type" display="flex" columnGap={6} mb={4}>
                  <Radio
                    value={SAVE_TYPE.TEAM}
                    label="Save for Team"
                    onChange={props.handleChange}
                  />
                  <Radio
                    value={SAVE_TYPE.PRIVATE}
                    label="Save for Me"
                    onChange={props.handleChange}
                  />
                </RadioGroup>
                <HStack mt={4}>
                  <FormButton
                    type="button"
                    variant="secondary"
                    onClick={onClose}
                    title="Cancel"
                    isDisabled={isLoading}
                  />
                  <FormButton
                    isDisabled={!isValid(props.values) || (applyAfterSave && isLoading)}
                    variant="primary"
                    title="Save"
                    onClick={() => {
                      saveFilter(props.values.name, props.values.type, false);
                      queryUpdate(query, null, 'filterId');
                      history.replace({ search: query.toString() });
                    }}
                    isLoading={isLoading && !applyAfterSave}
                  />
                  <FormButton
                    isDisabled={!isValid(props.values) || (!applyAfterSave && isLoading)}
                    variant="primary"
                    title="Save and Apply"
                    onClick={() => {
                      saveFilter(props.values.name, props.values.type, true);
                      queryUpdate(query, null, 'filterId');
                      history.replace({ search: query.toString() });
                    }}
                    isLoading={isLoading && applyAfterSave}
                  />
                </HStack>
              </VStack>
            </form>
          )}
        </Formik>
      }
    />
  );
};
