import { Flex, FormLabel, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import ChakraDatepicker from 'components/chakra/Datepicker';
import { API } from 'core';
import { queryUpdate } from 'core/util';
import { FormikProps } from 'formik';
import { FormButton, useToast } from 'library/atoms';
import { PRIORITY_OPTIONS } from 'library/common/constant';
import { FormInput, FormRadio, FormSelect } from 'library/molecules';
import FormControlWrapper from 'library/molecules/FormControlWrapper';
import { TimeInput } from 'library/organisms';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { SquadFilterDropdown } from 'views/main/organization/owner-filters/Dropdown/AssigneeDropdown';
import { useEntityAssigneeFilter } from 'views/main/organization/owner-filters/hooks/useEnitityAssigneeFilter';
import {
  FilterEntity,
  useEntityOwnerFilter,
} from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import useQueryParams from 'views/main/organization/service-catalog/helpers/helper.query-params';

import { DEFAULT_QUERY_DAYS, IL_REACT_QUERY_CONFIG } from '../../common/constants';
import { ALERT_SOURCE } from '../../common/types';
import { applyTimeZone } from '../../common/util';
import { DateFilterChoice } from '../../graphql/generated/types';
import { useListActiveAlertSourcesQuery } from '../../graphql/query';
import { IncidentParamsContext, useGetServiceListForMaintainer } from '../../hooks/useAllParams';
import { filterDropdownOptions } from '../../interfaces/common';
import { Filters } from '../../interfaces/filters';
import { isAllTimeLimit } from '../../store/persistent-storage';
import { filterManager } from '../manager';
import { RETRO_STATUS_DROPDOWN_OPTIONS } from '../manager/constant';
import { getDefaultTime, getDefaultTimeStr } from '../manager/util';
import { FilterFormTags } from './FilterFormTags';
import { CREATED_OPTIONS, RADIO_OPTIONS } from './options';
import { getIncidentFilterObj, setIncidentFilterObj } from './temp-store';
import { validateFilterForm } from './validator';

export const FormTemplate = ({
  values: {
    assignedTo,
    created,
    lastNumberOfDays,
    startDate,
    endDate,
    startTime,
    endTime,
    services,
    serviceOwner,
    priority,
    isSlo,
    sloList,
    tagsKey,
    tagsValue,
    isStarred,
    withRetro,
    withNotes,
    retroStatus,
    alert,
    name,
    id,
    keyValue,
  },
  values,
  errors,
  handleSubmit,
  setFieldValue,
  setValues,
  setFieldTouched,
  onCancel,
  onSave,
  onCancelModel,
  onUpdate,
  isLoading,
  timeZone,
}: FormikProps<Filters> & {
  onClose: () => void;
  onCancel: () => void;
  onCancelModel: () => void;
  onSave: (filters: Filters) => void;
  onUpdate: (filters: Filters) => void;
  isLoading: boolean;
  timeZone: string;
}) => {
  const toast = useToast();
  const query = useQueryParams();
  const history = useHistory();
  const filterQueryParam = query.get('entity_owner') ?? '';

  const { selectedFilterTags, clearSelection, onChangeHandler } = useEntityOwnerFilter({
    queryParamsString: filterQueryParam,
    entity: FilterEntity.ESCALATION_POLICY,
    selectedTeamId: '',
  });

  const { currentUserOption, squadsOfCurrentUser } = useEntityAssigneeFilter({
    queryParamsString: filterQueryParam,
    entity: FilterEntity.ESCALATION_POLICY,
  });

  const queryClient = useQueryClient();
  const [alertArray, setAlertArray] = useState<filterDropdownOptions[]>([]);

  const setObjFieldValue = (field: string, value: any) => {
    if (field) {
      setIncidentFilterObj(field, value);
      setFieldValue(field, value);
    }
  };

  const [todayDate] = useState(() => DateTime.utc());

  const getFromDate = () => {
    let date: string;
    if (created?.value === DateFilterChoice.Range && startDate) {
      date = startDate;
    } else {
      const days = lastNumberOfDays ?? DEFAULT_QUERY_DAYS;
      date = todayDate.minus({ days }).toISO();
    }
    return applyTimeZone(date, getDefaultTimeStr(false), timeZone);
  };

  const getEndDate = () => {
    let date = todayDate.toISO();
    if (created?.value === DateFilterChoice.Range && endDate) {
      date = endDate;
    }
    return applyTimeZone(date, getDefaultTimeStr(true), timeZone, true);
  };

  const APIParams = {
    fromDate: getFromDate(),
    toDate: getEndDate(),
  };
  const { refetch } = useListActiveAlertSourcesQuery(
    {
      teamID: API.config.teamId,
      ...(!!created?.value || !isAllTimeLimit() ? APIParams : null),
    },
    {
      ...IL_REACT_QUERY_CONFIG,
      // enabled: false,
      onSuccess: data => {
        const alertSourceList: filterDropdownOptions[] = Array.isArray(data.listActiveAlertSources)
          ? data.listActiveAlertSources.map((alert: ALERT_SOURCE) => {
              return { label: alert.name, value: alert.id, imageUrl: alert.iconURL.svg };
            })
          : [];

        setAlertArray(alertSourceList);
      },
    },
  );

  const invalAlertSourcesList = () => {
    queryClient.invalidateQueries('listActiveAlertSources');
  };

  const getTimeStamp = (isEndDate = false) => {
    const filterObj = getIncidentFilterObj();
    const val = filterObj ? filterObj[isEndDate ? 'endTime' : 'startTime'] : null;

    return val ?? getDefaultTimeStr(isEndDate);
  };

  const getTime = (type: 'Hours' | 'Mins', isEndDate = false) => {
    const [hrs, mins] = getTimeStamp(isEndDate).split(':');
    return parseInt(type === 'Hours' ? hrs : mins);
  };

  const padTime = (val: string) => val.padStart(2, '0');

  const onTimeUpdate = (time: number, type: 'Hours' | 'Mins', isEndDate = false) => {
    const val = padTime(time.toString());
    const defaultTime = getDefaultTime(isEndDate);
    const timeField = isEndDate ? endTime : startTime;
    const [hrs, mins] = timeField ? timeField.split(':') : defaultTime;
    const timeValue = type === 'Hours' ? `${val}:${padTime(mins)}` : `${padTime(hrs)}:${val}`;

    const field = isEndDate ? 'endTime' : 'startTime';

    setObjFieldValue(field, timeValue);
  };

  const hasValue = useMemo(() => {
    return (
      name ||
      assignedTo?.meAndMySquads ||
      assignedTo?.users.length ||
      assignedTo?.squads.length ||
      !!startDate ||
      !!endDate ||
      !!startTime ||
      !!endTime ||
      services.length ||
      !!isSlo ||
      sloList.length ||
      (!!tagsKey && !!tagsValue.length) ||
      !!isStarred ||
      !!withRetro ||
      !!withNotes ||
      alert.length ||
      priority.length ||
      !!created ||
      !!lastNumberOfDays ||
      serviceOwner?.meAndMySquads ||
      serviceOwner?.squads.length ||
      serviceOwner?.users.length
    );
  }, [
    name,
    assignedTo,
    startDate,
    endDate,
    startTime,
    endTime,
    services,
    isSlo,
    sloList,
    serviceOwner,
    isStarred,
    withRetro,
    withNotes,
    alert,
    priority,
    created,
    lastNumberOfDays,
    tagsKey,
    tagsValue,
  ]);

  const uniqueArrayObjects = useCallback((sourceArray: filterDropdownOptions[], value: any) => {
    const el = sourceArray.find((val: filterDropdownOptions) => val.value === value.value);
    if (!el) return [...sourceArray, value];
    return sourceArray;
  }, []);

  const getServiceOwner = useMemo(() => {
    return [
      ...serviceOwner.mySquads,
      ...serviceOwner.myUsers,
      ...serviceOwner.squads,
      ...serviceOwner.users,
    ];
  }, [serviceOwner]);

  const {
    params: { slolist: sloArray, tagsData },
  } = useContext(IncidentParamsContext);

  return (
    <form onSubmit={handleSubmit}>
      {id && (
        <FormControlWrapper
          label="Name*"
          value={name}
          onClear={() => {
            setObjFieldValue('name', '');
            errors.name = '';
          }}
        >
          <FormInput
            value={name}
            onChange={setObjFieldValue}
            error={errors.name}
            fieldName="name"
          />
        </FormControlWrapper>
      )}
      <SquadFilterDropdown
        label="Assignee"
        description="View incidents where the selected entity has been assigned, either currently or historically"
        value={assignedTo}
        onClear={() => {
          setObjFieldValue('assignedTo', {
            users: [],
            squads: [],
            mySquads: [],
            myUsers: [],
            meAndMySquads: false,
          });
          clearSelection();
        }}
        type="assignee"
        placeholder="Select assignee"
        onChange={(value: any) => {
          if (value?.type === 'select-all') {
            setObjFieldValue('assignedTo', {
              meAndMySquads: true,
              myUsers: currentUserOption,
              mySquads: squadsOfCurrentUser,
              users: assignedTo.users.filter(el => el.value !== currentUserOption[0].value),
              squads: assignedTo.squads.filter(
                el => ![...squadsOfCurrentUser].map(sq => sq.value).includes(el.value),
              ),
            });
          } else if (value?.type === 'user') {
            setObjFieldValue('assignedTo', {
              meAndMySquads: assignedTo?.meAndMySquads,
              users: uniqueArrayObjects(assignedTo.users.length ? assignedTo?.users : [], value),
              squads: assignedTo.squads,
              myUsers: assignedTo?.myUsers,
              mySquads: assignedTo?.mySquads,
            });
          } else if (value?.type === 'squad') {
            setObjFieldValue('assignedTo', {
              meAndMySquads: assignedTo?.meAndMySquads,
              squads: uniqueArrayObjects(assignedTo.squads?.length ? assignedTo.squads : [], value),
              users: assignedTo.users,
              myUsers: assignedTo?.myUsers,
              mySquads: assignedTo?.mySquads,
            });
          }
        }}
        meAndMySqaudField={assignedTo}
        setterFunction={setObjFieldValue}
        onRemove={(type: string, id: string) => {
          if (id === 'meAndMySquads') {
            setObjFieldValue('assignedTo', {
              meAndMySquads: false,
              users: assignedTo.users,
              squads: assignedTo.squads,
              myUsers: [],
              mySquads: [],
            });
          } else if (type === 'user') {
            setObjFieldValue('assignedTo', {
              meAndMySquads: assignedTo?.meAndMySquads,
              users: assignedTo.users.filter((val: filterDropdownOptions) => val.value != id),
              squads: assignedTo.squads,
              myUsers: assignedTo?.myUsers,
              mySquads: assignedTo?.mySquads,
            });
          } else if (type === 'squad') {
            setObjFieldValue('assignedTo', {
              meAndMySquads: assignedTo?.meAndMySquads,
              squads: assignedTo?.squads?.filter((val: filterDropdownOptions) => val.value != id),
              users: assignedTo?.users,
              myUsers: assignedTo?.myUsers,
              mySquads: assignedTo?.mySquads,
            });
          }
        }}
      />
      {/* </FormControlWrapper> */}
      <FormControlWrapper
        label="Created"
        value={created}
        onClear={() => {
          setObjFieldValue('startDate', null);
          setObjFieldValue('endDate', null);
          setObjFieldValue('startTime', null);
          setObjFieldValue('endTime', null);
          setObjFieldValue('lastNumberOfDays', undefined);
          setObjFieldValue('created', null);
          errors.created = '';
          invalAlertSourcesList();
        }}
      >
        <FormSelect
          options={CREATED_OPTIONS}
          value={created}
          label="Date"
          hideLabel
          isClearable={false}
          onChange={(field, value) => {
            const inferedValue = value as filterDropdownOptions;
            if (value && inferedValue.value) {
              if (inferedValue.value === DateFilterChoice.Range) {
                setObjFieldValue('startDate', todayDate.startOf('day').toISO());
                setObjFieldValue('endDate', todayDate.toISO());
                setObjFieldValue('startTime', getDefaultTimeStr(false));
                setObjFieldValue('endTime', getDefaultTimeStr(true));
                setObjFieldValue('lastNumberOfDays', undefined);
              } else {
                const noOfDays = ['week', 'month'].includes(inferedValue.value)
                  ? undefined
                  : inferedValue.value;
                setObjFieldValue('lastNumberOfDays', noOfDays);
                setObjFieldValue('startDate', null);
                setObjFieldValue('endDate', null);
                setObjFieldValue('startTime', null);
                setObjFieldValue('endTime', null);
              }
            }
            setObjFieldValue('created', value);
            invalAlertSourcesList();
          }}
          onBlur={setFieldTouched}
          error={startDate && endDate ? null : errors.created}
          isMulti={false}
          fieldName="created"
        />
        {created?.value === DateFilterChoice.Range ? (
          <>
            <HStack mt={3} spacing="auto">
              <VStack align="flex-start" spacing={2}>
                <FormLabel fontSize="12px" color="secondary.700" fontWeight="800" mb={0}>
                  From
                </FormLabel>
                <ChakraDatepicker
                  dateFormat="dd/MM/yyyy"
                  setDate={(value: Date) => {
                    setObjFieldValue('startDate', moment(value).toDate());
                  }}
                  maxDate={new Date(endDate ?? Date.now())}
                  date={startDate ? new Date(Date.parse(startDate)) : new Date()}
                />
                <TimeInput
                  color={''}
                  label={''}
                  hoursValue={getTime('Hours')}
                  minutesValue={getTime('Mins')}
                  hoursOnChange={val => onTimeUpdate(val, 'Hours')}
                  minutesOnChange={val => onTimeUpdate(val, 'Mins')}
                />
              </VStack>
              <VStack align="flex-start" spacing={2}>
                <FormLabel fontSize="12px" color="secondary.700" fontWeight="800" mb={0}>
                  To
                </FormLabel>
                <ChakraDatepicker
                  dateFormat="dd/MM/yyyy"
                  setDate={(value: Date) => {
                    setObjFieldValue('endDate', moment(value).toDate());
                  }}
                  maxDate={new Date()}
                  date={endDate ? new Date(Date.parse(endDate)) : new Date()}
                />
                <TimeInput
                  color={''}
                  label={''}
                  hoursValue={getTime('Hours', true)}
                  minutesValue={getTime('Mins', true)}
                  hoursOnChange={val => onTimeUpdate(val, 'Hours', true)}
                  minutesOnChange={val => onTimeUpdate(val, 'Mins', true)}
                />
              </VStack>
            </HStack>
            {/* start and end date filter tags */}
            <HStack align="baseline">
              {startDate != null && (
                <FilterFormTags
                  values={[
                    {
                      label: `From: ${moment(startDate).format('DD/MM/YYYY')}, ${getTimeStamp()}`,
                      value: moment(startDate).toISOString(),
                    },
                  ]}
                  setFieldValue={setObjFieldValue}
                  type="startDate"
                  isSingle={true}
                />
              )}
              {endDate != null && (
                <FilterFormTags
                  values={[
                    {
                      label: `To: ${moment(endDate).format('DD/MM/YYYY')}, ${getTimeStamp(true)}`,
                      value: moment(endDate).toISOString(),
                    },
                  ]}
                  setFieldValue={setObjFieldValue}
                  type="endDate"
                  isSingle={true}
                />
              )}
            </HStack>
          </>
        ) : (
          <>
            {created?.value === DateFilterChoice.Month ||
            created?.value === DateFilterChoice.Week ? (
              <FilterFormTags
                values={[
                  {
                    label: `Created: ${created.label}`,
                    value: created.value,
                  },
                ]}
                setFieldValue={setObjFieldValue}
                type="created"
                isSingle={true}
              />
            ) : (
              <>
                {!!lastNumberOfDays && (
                  <FilterFormTags
                    values={[
                      {
                        label: `Number of Days: ${lastNumberOfDays}`,
                        value: `$(lastNumberOfDays ?? '')}`,
                      },
                    ]}
                    setFieldValue={setObjFieldValue}
                    type="lastNumberOfDays"
                    isSingle={true}
                  />
                )}
              </>
            )}
          </>
        )}
        {errors.created && (
          <Text p={1} color="red.500" fontSize="10px">
            {errors.created}
          </Text>
        )}
      </FormControlWrapper>
      <SquadFilterDropdown
        label="Service Owner"
        description="Find incidents for services owned by specific squad or users"
        value={serviceOwner}
        onClear={() => {
          setObjFieldValue('serviceOwner', {
            users: [],
            squads: [],
            mySquads: [],
            myUsers: [],
            meAndMySquads: false,
          });
          setObjFieldValue('services', []);
          clearSelection();
        }}
        type="serviceOwner"
        placeholder="Select service owner"
        onChange={(value: any) => {
          if (value?.type === 'select-all') {
            setObjFieldValue('serviceOwner', {
              meAndMySquads: true,
              users: serviceOwner.users.filter(el => el.value !== currentUserOption[0].value),
              squads: serviceOwner.squads.filter(
                el => ![...squadsOfCurrentUser].map(sq => sq.value).includes(el.value),
              ),
              myUsers: currentUserOption,
              mySquads: squadsOfCurrentUser,
            });
          } else if (value?.type === 'user') {
            setObjFieldValue('serviceOwner', {
              meAndMySquads: serviceOwner?.meAndMySquads,
              users: uniqueArrayObjects(serviceOwner.users, value),
              squads: serviceOwner.squads,
              myUsers: serviceOwner?.myUsers,
              mySquads: serviceOwner?.mySquads,
            });
          } else if (value?.type === 'squad') {
            setObjFieldValue('serviceOwner', {
              meAndMySquads: serviceOwner?.meAndMySquads,
              squads: uniqueArrayObjects(serviceOwner.squads, value),
              users: serviceOwner.users,
              myUsers: serviceOwner?.myUsers,
              mySquads: serviceOwner?.mySquads,
            });
          }
          setObjFieldValue('services', []);
          onChangeHandler(value);
        }}
        meAndMySqaudField={serviceOwner}
        setterFunction={setObjFieldValue}
        onRemove={(type: string, id: string) => {
          if (id === 'meAndMySquads') {
            setObjFieldValue('serviceOwner', {
              meAndMySquads: false,
              users: serviceOwner.users,
              squads: serviceOwner.squads,
              myUsers: [],
              mySquads: [],
            });
          } else if (type === 'user') {
            setObjFieldValue('serviceOwner', {
              meAndMySquads: serviceOwner?.meAndMySquads,
              users: serviceOwner.users.filter((val: filterDropdownOptions) => val.value != id),
              squads: serviceOwner.squads,
              myUsers: serviceOwner?.myUsers,
              mySquads: serviceOwner?.mySquads,
            });
          } else if (type === 'squad') {
            setObjFieldValue('serviceOwner', {
              meAndMySquads: serviceOwner?.meAndMySquads,
              squads: serviceOwner.squads.filter((val: filterDropdownOptions) => val.value != id),
              users: serviceOwner.users,
              myUsers: serviceOwner?.myUsers,
              mySquads: serviceOwner?.mySquads,
            });
          }
          setObjFieldValue('services', []);
        }}
      />
      {/* </FormControlWrapper> */}
      <FormControlWrapper
        label="Services"
        value={services}
        onClear={() => {
          setObjFieldValue('services', []);
        }}
      >
        <FormSelect
          options={useGetServiceListForMaintainer(
            getServiceOwner?.map((val: any) => {
              return val.value;
            }),
            serviceOwner,
          )}
          value={services}
          label="Services"
          hideLabel
          onChange={setObjFieldValue}
          onBlur={setFieldTouched}
          error={errors.services}
          isMulti={true}
          fieldName="services"
          isClearable={false}
        />
        <FilterFormTags values={services} setFieldValue={setObjFieldValue} type="services" />
      </FormControlWrapper>
      <FormControlWrapper
        label="Tags"
        value={tagsValue}
        onClear={() => {
          setObjFieldValue('tagsKey', '');
          setObjFieldValue('tagsValue', []);
          setObjFieldValue('keyValue', []);
          errors.tagsValue = '';
        }}
      >
        <FormSelect
          options={tagsData}
          value={tagsKey}
          label="Tags Key"
          hideLabel
          controlShouldRenderValue
          onChange={(fieldName, value) => {
            setObjFieldValue(fieldName, value);
            value &&
              Array.isArray(value) &&
              setValues({ ...values, keyValue: [...keyValue, ...value] });
          }}
          onBlur={setFieldTouched}
          error={errors.tagsKey}
          isMulti={false}
          fieldName="tagsKey"
          isClearable={false}
        />
        <Spacer mb={3} />
        <FormSelect
          options={
            tagsKey
              ? Array.isArray(tagsKey.child)
                ? tagsKey.child.map(child => {
                    return { label: `${tagsKey.label}:${child}`, value: child };
                  })
                : []
              : []
          }
          value={tagsValue}
          label="Tags Value"
          hideLabel
          onChange={(fieldName, value) => {
            setObjFieldValue(fieldName, value);
            setObjFieldValue('keyValue', value);
          }}
          onBlur={setFieldTouched}
          error={errors.tagsValue}
          isMulti={true}
          fieldName="tagsValue"
          isDisabled={!tagsKey || isEmpty(tagsKey)}
          isClearable={false}
        />
        <FilterFormTags
          values={keyValue}
          valueSecondary={tagsValue}
          typeSecondary={'tagsValue'}
          setFieldValue={setObjFieldValue}
          type="keyValue"
        />
      </FormControlWrapper>
      <FormControlWrapper
        label="Alert Sources"
        description="Shows valid Alert Sources for the selected date range"
        value={alert}
        onClear={() => {
          setObjFieldValue('alert', []);
        }}
      >
        <FormSelect
          options={alertArray}
          value={alert}
          onChange={setObjFieldValue}
          onBlur={setFieldTouched}
          error={errors.alert}
          isMulti={true}
          label="Alert Sources"
          hideLabel
          fieldName="alert"
          isClearable={false}
          variant="image"
        />
        <FilterFormTags values={alert} setFieldValue={setObjFieldValue} type="alert" />
      </FormControlWrapper>
      <FormControlWrapper
        label="Priority"
        value={priority}
        onClear={() => {
          setObjFieldValue('priority', []);
        }}
      >
        <FormSelect
          options={PRIORITY_OPTIONS}
          value={priority}
          onChange={setObjFieldValue}
          onBlur={setFieldTouched}
          error={errors.priority}
          isMulti={true}
          label="Priority"
          hideLabel
          fieldName="priority"
          isClearable={false}
          variant="priority"
        />
        <FilterFormTags values={priority} setFieldValue={setObjFieldValue} type="priority" />
      </FormControlWrapper>
      <FormControlWrapper
        label="SLO Affecting"
        value={isSlo}
        onClear={() => {
          setObjFieldValue('isSlo', '');
          setObjFieldValue('sloList', []);
        }}
      >
        <FormRadio
          value={isSlo}
          onChange={(field, value) => {
            value === 'no' && setObjFieldValue('sloList', []);
            setObjFieldValue(field, value);
          }}
          fieldName="isSlo"
          fields={RADIO_OPTIONS}
        />
        {isSlo === 'yes' && (
          <FormControlWrapper
            label="SLO(s)"
            value={sloList}
            onClear={() => {
              setObjFieldValue('sloList', []);
            }}
            isChildWrapper
          >
            <FormSelect
              options={sloArray}
              value={sloList}
              label="SLO"
              hideLabel
              onChange={setObjFieldValue}
              onBlur={setFieldTouched}
              error={errors.sloList}
              isMulti={true}
              fieldName="sloList"
              isClearable={false}
            />
            <FilterFormTags values={sloList} setFieldValue={setObjFieldValue} type="sloList" />
          </FormControlWrapper>
        )}
      </FormControlWrapper>

      <FormControlWrapper
        label="Show Incidents with Postmortem"
        value={withRetro}
        description="Yes shows all postmortem statuses by default. Use the drop-down to filter by specific status."
        onClear={() => {
          setObjFieldValue('withRetro', '');
          setObjFieldValue('retroStatus', []);
        }}
      >
        <FormRadio
          value={withRetro}
          onChange={(field, val) => {
            setObjFieldValue(field, val);
            if (val === 'no') {
              // clear out postmortem status fields
              setObjFieldValue('retroStatus', []);
            }
          }}
          fieldName="withRetro"
          fields={RADIO_OPTIONS}
        />
        {withRetro == 'yes' && (
          <>
            <FormSelect
              options={RETRO_STATUS_DROPDOWN_OPTIONS}
              value={retroStatus}
              onChange={setObjFieldValue}
              onBlur={setFieldTouched}
              error={errors.alert}
              isMulti={true}
              label="Postmortem Status"
              hideLabel
              fieldName="retroStatus"
              isClearable={false}
              variant="image"
            />
            <FilterFormTags
              values={retroStatus}
              setFieldValue={setObjFieldValue}
              type="retroStatus"
            />
          </>
        )}
      </FormControlWrapper>

      <FormControlWrapper
        label="Show Incidents with Notes"
        value={withNotes}
        onClear={() => {
          setObjFieldValue('withNotes', '');
        }}
      >
        <FormRadio
          value={withNotes}
          onChange={setObjFieldValue}
          fieldName="withNotes"
          fields={RADIO_OPTIONS}
        />
      </FormControlWrapper>
      <FormControlWrapper
        label="Show Starred Incidents"
        value={isStarred}
        onClear={() => {
          setObjFieldValue('isStarred', '');
        }}
      >
        <FormRadio
          value={isStarred}
          onChange={setObjFieldValue}
          fieldName="isStarred"
          fields={RADIO_OPTIONS}
        />
      </FormControlWrapper>
      <Spacer pb={10} />
      <Flex
        position="absolute"
        alignItems="flex-start"
        mt={3}
        bottom={0}
        w={'100%'}
        backgroundColor="brand.white"
        borderTop="1px"
        borderColor="gray.200"
        py="2"
      >
        {id ? (
          <HStack spacing={3} pl={4}>
            <FormButton
              title="Save Changes"
              onClick={() => {
                const err = validateFilterForm(values);
                if (isEmpty(err)) {
                  onUpdate(values);
                } else {
                  toast({
                    status: 'error',
                    text: Object.values(err)[0],
                  });
                }
              }}
              isDisabled={!hasValue}
              isLoading={isLoading}
            />
            <FormButton
              title="Cancel"
              variant="ghost"
              onClick={onCancelModel}
              isDisabled={isLoading}
            />
          </HStack>
        ) : (
          <HStack pl={4} spacing={3}>
            <FormButton type="submit" title="Apply" isDisabled={!hasValue} />
            <FormButton
              title="Save Filter"
              variant="secondary"
              onClick={() => {
                queryUpdate(query, null, 'filterId');
                history.replace({ search: query.toString() });
                filterManager.clearFilters(queryClient);
                onSave(values);
              }}
              isDisabled={!hasValue}
            />
            <FormButton
              title="Clear All"
              variant="ghost"
              onClick={() => {
                queryUpdate(query, null, 'filterId');
                history.replace({ search: query.toString() });
                filterManager.clearFilters(queryClient);
                onCancel();
              }}
            />
          </HStack>
        )}
      </Flex>
    </form>
  );
};
