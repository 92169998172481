import { Divider, Flex, Box } from '@chakra-ui/react';
import { LogicalOperatorOptions, WorkflowForm } from '../../../types';
import { LOGICAL_OPERATOR_OPTIONS } from '../../../constant';
import { useFormContext } from 'react-hook-form';
import FormDropdown from 'library/molecules/Form/FormDropdown/FormDropdown';

type IProps = {
  name: 'group.type' | `group.fields.children.${number}.type`;
};
const DropdownSeparator = ({ name }: IProps) => {
  const { setValue, watch } = useFormContext<WorkflowForm>();

  const activeOperator =
    watch(name) === 'and_filter' ? LOGICAL_OPERATOR_OPTIONS[0] : LOGICAL_OPERATOR_OPTIONS[1];
  return (
    <Flex justify={'center'} align={'center'} mt={4}>
      <Divider size="2px" flex={1} />
      <Box mr={5} ml={5} width={28} backgroundColor="white">
        <FormDropdown<LogicalOperatorOptions>
          options={LOGICAL_OPERATOR_OPTIONS}
          name={name}
          value={activeOperator}
          defaultValue={LOGICAL_OPERATOR_OPTIONS[0]}
          onChange={op => {
            if (op && 'value' in op && op.value === 'or') {
              setValue(name, 'or_filter');
            } else {
              setValue(name, 'and_filter');
            }
          }}
        />
      </Box>
      <Divider size="2px" flex={1} />
    </Flex>
  );
};

export default DropdownSeparator;
