import { AssigneeType } from 'library/enums';
import { ICountry, INumberType, IRoutingType } from './enums';

export const LCR_DETAILS_TABS = [
  {
    name: 'Details',
    queryKey: 'details',
    analytics_key: 'Details',
  },
  {
    name: 'Sessions',
    queryKey: 'sessions',
    analytics_key: 'Sessions',
  },
];

export const KeypadMappingDefaultValue = {
  alias: '',
  schedules: '',
  service_id: '',
  service_name: '',
};

export const LCRDefaultValue = {
  id: '',
  name: '',
  country: ICountry.US,
  number: '',
  routing_type: IRoutingType.oncallRouting,
  type: INumberType.local,
  enabled: false,
  message_structure: '',
  description: '',
  greeting_message: '',
  owner_id: '',
  owner_type: '',
  team_id: '',
  record_call: false,
  incident_count: 0,
  keypad_mappings: [],
  metadata: { acl: {} },
};

export const LCRSessionDefaultValue = {
  id: '',
  caller_number: '',
  incident_id: '',
  recording_url: '',
  assigned_to_id: '',
  duration_in_seconds: '',
  assigned_to_name: '',
  assigned_to_type: AssigneeType.User,
  service_alias: '',
  service_id: '',
  status: '',
  start_time: '',
  incident_count: 0,
  end_time: '',
  call_relations: [
    {
      direction: '',
      duration_in_seconds: '',
      from: '',
      session_id: '',
      timestamp: '',
      to: '',
      status: '',
    },
  ],
};
export const CountriesKey = {
  US: 'United States',
  CA: 'Canada',
  AUS: 'Australia',
  IN: 'India',
  BE: 'Belgium',
  GB: 'United Kingdom',
};

export const RoutingTypeKeys = {
  voicemail: 'Voicemail',
  oncall: 'On-Call',
};

export const NumberTypeKeys = {
  local: 'Local',
  'toll-free': 'Toll Free',
};
