import { Box, Circle, HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import { Popover, Tag } from 'library/atoms';
import React, { useCallback, useMemo } from 'react';
import { useAuditLogsListContext } from '../../context/auditLogsContext';
import { cleanObject, generateFilterLabels, truncate } from '../../helpers';

function FilterTags() {
  const context = useAuditLogsListContext();
  const appliedFilters: any = context.appliedFilters;

  delete appliedFilters['lastNumberOfDays'];
  delete appliedFilters['created'];

  const filterLabel = useMemo(() => {
    return generateFilterLabels(appliedFilters, context.showDateFilters).filter(
      (filter: any) => filter.value,
    );
  }, [appliedFilters, context.showDateFilters]);

  const transformTags = useCallback(
    (filterLabels: any) => {
      return {
        labels: filterLabels
          .map((filter: any) => ({
            label: filter.label,
            username: filter?.displayValue,
            value: filter.value,
          }))
          .slice(0, 5),
        overflowElements: filterLabels
          .map((filter: any) => ({
            label: filter.label,
            username: filter?.displayValue,
            value: filter.value,
          }))
          .slice(5),
      };
    },
    [filterLabel],
  );

  const onRemoveFilter = (index: number, item: any) => {
    const appliedFilter = Object.assign({}, appliedFilters);
    const filterKey = filterLabel[index].key as keyof typeof appliedFilters;
    if (Array.isArray(appliedFilter[filterKey])) {
      appliedFilter[filterKey] = appliedFilter[filterKey].filter((val: any) => {
        const checkValue = val?.value;
        return checkValue !== item.value;
      });
    } else {
      if (filterKey === 'Date Filter') {
        delete appliedFilter['startDate'];
        delete appliedFilter['endDate'];
      } else {
        delete appliedFilter[filterKey];
      }
    }
    if (!appliedFilter.startDate) delete appliedFilter['startDate'];
    if (!appliedFilter.endDate) delete appliedFilter['endDate'];
    context.applyFilters(cleanObject(appliedFilter), () => {});
    if (filterKey === 'Date Filter')
      context.setDateFilters({
        lastNumberOfDays: null,
        created: null,
      });
  };

  if (!filterLabel.length || !appliedFilters.isExplicitlySet) {
    return null;
  }

  return (
    <HStack alignSelf="start" sx={{ '& *': { fontSize: 10.5 } }}>
      {transformTags(filterLabel).labels.length ? (
        <Wrap mt={3}>
          {transformTags(filterLabel).labels.map(
            (
              {
                value,
                label,
                username,
              }: {
                value: string;
                label: string;
                username?: string | null;
              },
              index: number,
            ) => {
              return (
                <WrapItem key={index}>
                  <Tag
                    size="md"
                    variant="filter"
                    label={label}
                    tooltipText={username ? username : label}
                    title={
                      username ? (
                        <HStack>
                          <Text fontWeight={'bold'}>
                            {truncate(label)}:
                            <Text margin={1} as="span" fontWeight="normal">
                              {truncate(username)}
                            </Text>
                          </Text>
                        </HStack>
                      ) : (
                        <Text>{label}</Text>
                      )
                    }
                    onClose={() => {
                      onRemoveFilter(index, {
                        value,
                        label,
                        username,
                      });
                    }}
                  />
                </WrapItem>
              );
            },
          )}
        </Wrap>
      ) : null}
      {transformTags(filterLabel).overflowElements.length && (
        <Box style={{ marginTop: 10 }}>
          <Popover
            hasArrow={false}
            enableTriggerWrapper={true}
            disablePortal={true}
            trigger={
              <Circle cursor="pointer" size="19px" bg="primary.400">
                <Text variant="hint" color="secondary.1000">
                  +{transformTags(filterLabel).overflowElements.length}
                </Text>
              </Circle>
            }
          >
            <VStack p="2" spacing={2} maxH="60vh" overflowY="scroll" alignItems="flex-start">
              {transformTags(filterLabel).overflowElements.map(
                (
                  {
                    value,
                    label,
                    username,
                  }: {
                    value: string;
                    label: string;
                    username?: string;
                  },
                  index: number,
                ) => {
                  return (
                    <WrapItem key={value.toString()}>
                      <Tag
                        size="md"
                        variant="filter"
                        label={label}
                        tooltipText={value ? username : label}
                        title={
                          value ? (
                            <HStack>
                              <Text fontWeight={'bold'}>
                                {truncate(label)}:
                                <Text margin={1} as="span" fontWeight="normal">
                                  {truncate(username || '')}
                                </Text>
                              </Text>
                            </HStack>
                          ) : (
                            <Text>{label}</Text>
                          )
                        }
                        onClose={() => {
                          onRemoveFilter(index, {
                            value,
                            label,
                            username,
                          });
                        }}
                      />
                    </WrapItem>
                  );
                },
              )}
            </VStack>
          </Popover>
        </Box>
      )}
    </HStack>
  );
}

export default FilterTags;
