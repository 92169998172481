import { get } from 'lodash';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { LCRDefaultValue, LCRSessionDefaultValue } from '../common/constant';
import { ILCRData, LCRDataLogs, LCRDataResponse } from '../common/interface';
import { ILogsFilterDataTypes, LCRListState, MappingTableTypes } from '../types/LCR.types';

const intialState: LCRListState = {
  lcrData: { data: [], metadata: { total_count: 0, acl: {} } },
  lcrDataDetails: LCRDefaultValue,
  lcrSessionData: LCRSessionDefaultValue,
  enableModalIsOpen: false,

  lcrStatusData: {
    id: '',
    enabled: false,
  },

  mappingData: [
    {
      keypadNumber: 1,
      alias: '',
      service: [],
      schedule: [],
    },
  ],
  isLoading: false,
  hasError: false,
  formState: {
    name: '',
    description: '',
    country: undefined,
    routingType: undefined,
    routingBehavior: undefined,
    greetingMessage: '',
    keywordMapping: [],
    notifyUser: false,
    routingNumber: undefined,
    recordCall: true,
    messageStructure: '',
    callDuration: undefined,
    owner: undefined,
  },

  logsFilter: undefined,
  logsFilterApply: undefined,
  logsSearch: '',
};

export const useLCRStore = create(
  combine(intialState, set => ({
    setLCRData: (lcrData: LCRDataResponse) => set({ lcrData }),
    setLCRDataById: (lcrDataDetails: ILCRData) => set({ lcrDataDetails }),
    setLCRSessionDataById: (lcrSessionData: LCRDataLogs) => set({ lcrSessionData }),
    setLCRLoading: (isLoading: boolean) => set({ isLoading }),
    setMappingData: (mappingData: MappingTableTypes[]) => set({ mappingData }),
    setFormData: (formState: any) => set({ formState }),
    openEnableModal: () => set({ enableModalIsOpen: true }),
    closeEnableModal: () => set({ enableModalIsOpen: false }),
    setLCRStatus: (id: string, enabled: boolean) => set({ lcrStatusData: { id, enabled } }),
    setLCRLogsFilter: (logsFilter: ILogsFilterDataTypes[]) => set({ logsFilter }),
    setLCRLogsApply: (logsFilterApply: ILogsFilterDataTypes[]) => set({ logsFilterApply }),
    setLogsSearch: (logsSearch: string) => set({ logsSearch }),
  })),
);
