import * as React from 'react';
import './index.css';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { requestOrganizationPlan } from '../../../../../core/actions/organization/plan';
import { Row } from '@squadcast/alchemy-ui/carbon';
import { planFeatures } from '../../../../../store/billingPlans';
import { Grid, Heading, Para, Theme, DialogModalFrame } from 'uie/components';
import PageLoaderComponent from '../../../../../components/PageLoader';
import { Link } from 'react-router-dom';
import {
  IAppBillingPlan,
  IAppBillingFeatures,
  IBillingPlan,
} from '../../../../../core/interfaces/IBillingPlan';
import 'react-circular-progressbar/dist/styles.css';
import BillingService from '../../../../../core/services/service.billing';
import { API } from 'core/api';
import { CrownIcon, InfoIcon } from 'icons';
import TrailExpiredTooltip from 'components/trialexpired.tooltip';
import InfoToolTipOnUsageCalculation from 'components/infotoolTipOnUsageCalculation';
import ConfirmModal from 'components/confirm.modal';
import ProgressBar from 'components/ProgressBar';
import { isEnterprisePlan } from './helper';
import { ContactUs } from './contact-us';

interface IProps extends Pick<IAppState, 'organization' | 'userInfo' | 'APP_CONFIG'> {
  requestOrganizationPlan: () => void;
}

interface IState {
  plans: IAppBillingPlan[];
  showAnnual: boolean;
  showBoxShow: boolean;
  planData: any[];
  showConfirmFreePlan: boolean;
  currentPlanDetails: IBillingPlan | null;
  showDowngradeBlockModal: boolean;
  nextPlanSuggestions: string[];
}
class Billing extends React.Component<IProps, IState> {
  private $mainContainer = React.createRef<HTMLDivElement>();
  private _BillingService = new BillingService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      showAnnual: false,
      planData: [],
      currentPlanDetails: null,
      showBoxShow: false,
      plans: planFeatures.plans,
      showConfirmFreePlan: false,
      nextPlanSuggestions: [],
      showDowngradeBlockModal: false,
    };
  }

  public confirmAndTakeAction = async (planId: string) => {
    if (!BillingService.isUpgradeable(this.props, planId)) {
      await this.setState({ showDowngradeBlockModal: true });
      return;
    } else {
      await this.setState({ showDowngradeBlockModal: false });
    }
    if (planId === BillingService.DEFAULT_FREE_PLAN_SLUG) {
      await this.setState({ showConfirmFreePlan: true });
      return;
    }
    this.takeAction(planId);
  };

  public takeAction = async (planId: string) => {
    let whetherHostedPage = !(
      this.state.currentPlanDetails?.metadata?.chargebee?.card_status === 'valid'
    );

    if (planId == BillingService.DEFAULT_FREE_PLAN_SLUG) {
      whetherHostedPage = false;
    }
    try {
      const res = await this._BillingService.addSubscription({
        planId: planId,
        planQuantity: this.props.organization.users.u.length,
        whetherHostedPage: whetherHostedPage,
        reactivate: !this.state.currentPlanDetails?.active,
      });
      if (res.data.result) {
        if (res.data.type === 'page') {
          window.location.href = res.data.data;
        } else {
          this.getBillingPlan();
          window.location.reload();
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  public cancelSubscription = async () => {
    try {
      const res = await this._BillingService.cancelSub();
      if (res.data.result) {
        window.location.reload();
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  public generatePlans() {
    const plans = this.state.plans;

    if (this.state.planData.length === 0) return;

    for (let i = 0; i < plans.length; i++) {
      const planId = this.state.showAnnual ? plans[i].annualId : plans[i].monthlyId;
      plans[i].price =
        (this.state.planData as any)[
          window._.findIndex(this.state.planData, (data: any) => data.plan.id === planId)
        ].plan.price /
        ((this.state.showAnnual ? 12 : 1) * 100);
    }
    this.setState({
      plans,
    });
  }

  public getBillingPlan = async () => {
    try {
      const planResponse = await this._BillingService.billPlan();
      if (planResponse.data.data) {
        this.setState({
          currentPlanDetails: planResponse.data.data,
        });
      }
    } catch (_) {
      console.log('BILLING_PLAN_REQ_FAILED', _);
    }
  };

  public async componentDidMount() {
    if (this.props.organization.plan.p!.plan_slug.indexOf('annual') !== -1) {
      this.setState({
        showAnnual: true,
      });
    } else {
      this.setState({
        showAnnual: false,
      });
    }
    try {
      const publicPlan = await this._BillingService.plans();
      if (publicPlan.data.result) {
        this.setState(
          {
            planData: publicPlan.data.data,
          },
          () => {
            this.generatePlans();
          },
        );
      }
    } catch (err: any) {
      console.log('unable to get billing plans', err);
    }
    this.getBillingPlan();
    this.setState({
      nextPlanSuggestions: BillingService.getPlanSuggestionsToUpgrade(this.props),
    });
  }

  onScroll = () => {
    const offSet = this.$mainContainer.current?.scrollTop;
    if (offSet) {
      this.setState({
        showBoxShow: true,
      });
    } else {
      this.setState({
        showBoxShow: false,
      });
    }
  };

  onToggleYearlyPlans = () => {
    this.setState(
      {
        showAnnual: !this.state.showAnnual,
      },
      () => {
        this.generatePlans();
      },
    );
  };

  public render() {
    const { theme } = Theme;
    const notificationUsageDetails = this.state.currentPlanDetails?.notification_usage;
    const isOldProActive = BillingService.isOldProPlanActive(this.props);

    if (this.props.organization.plan.action !== 'REQUEST_ORG_PLAN_SUCCESS') {
      return (
        <Grid
          type="column"
          flexWidth={12}
          className="org_settings__container_padding global--soft-scroll"
          onScroll={this.onScroll}
          ref={this.$mainContainer}
        >
          <PageLoaderComponent />
        </Grid>
      );
    }

    const activePlanState = (plan: any) => {
      const planAsAny = plan as any;
      const id = this.state.showAnnual ? 'annualId' : 'monthlyId';
      const onExtendedTrial = BillingService.isOnExtendedProductTrialPlan(this.props);
      const onTrial = BillingService.isSomeProductTrialPlan(this.props);
      const isFree = plan[id] == BillingService.DEFAULT_FREE_PLAN_SLUG;
      const isEnterprise = isEnterprisePlan(plan.name);
      const isPrimaryCTA = this.state.nextPlanSuggestions.includes(planAsAny[id]);
      if (isFree && onExtendedTrial) {
        return <TrailExpiredTooltip />;
      }

      const planState =
        this.props.organization.plan.p!.active &&
        planAsAny[id] === this.props.organization.plan.p!.plan_slug;

      return planState ? (
        <div>
          <button
            onClick={this.cancelSubscription}
            className="main-button-inverse"
            style={{ border: 'none', background: 'none', height: 31, fontSize: 16 }}
          >
            Unsubscribe
          </button>
          {this.state.currentPlanDetails &&
            this.state.currentPlanDetails.metadata.chargebee.card_status === 'no_card' &&
            !(
              this.state.currentPlanDetails.plan_slug === BillingService.DEFAULT_FREE_PLAN_SLUG
            ) && (
              <button
                onClick={this.takeAction.bind(
                  null,
                  (plan as any)[(this.state.showAnnual ? 'annual' : 'monthly') + 'Id'],
                )}
                style={{
                  border: 'none',
                  background: 'none',
                  fontWeight: 600,
                  color: '#0f61dd',
                  cursor: 'pointer',
                  fontSize: '12px',
                }}
              >
                Add Card On File
              </button>
            )}
        </div>
      ) : (
        <>
          {isEnterprise ? (
            <ContactUs isPrimary={isPrimaryCTA} />
          ) : (
            <button
              className={
                'main-button' +
                (isPrimaryCTA ? ' btn-subscribe--main' : ' btn-subscribe--secondary')
              }
              onClick={this.confirmAndTakeAction.bind(
                null,
                (plan as any)[(this.state.showAnnual ? 'annual' : 'monthly') + 'Id'],
              )}
            >
              Subscribe {onTrial && isFree && <InfoToolTipOnUsageCalculation />}
            </button>
          )}
        </>
      );
    };

    const getLimit = (type: string) => {
      const limit = Number(
        notificationUsageDetails?.find(usage => usage.type === type && usage.unlimited != true)
          ?.limit,
      );
      const usageCount = Number(
        notificationUsageDetails?.find(usage => usage.type === type)?.count,
      );
      return {
        limit,
        usageCount,
      };
    };

    const progressBar = (type: string) => {
      const notificationLimit = getLimit(type);
      const usagePercentage = (notificationLimit.usageCount / notificationLimit.limit) * 100;
      return (
        <>
          <div className="progress-bar-box" style={{ width: '50%' }}>
            <ProgressBar usagePercentage={usagePercentage}></ProgressBar>
            <div className="progress-bar__text flex flex-spacebtwn">
              <p>Used: {notificationLimit.usageCount} </p>
              <p>Limit: {notificationLimit.limit}</p>
            </div>
          </div>
        </>
      );
    };

    const getUsageProgress = () => {
      if (BillingService.isSomeLimitedPlan(this.props) && this.state.currentPlanDetails?.active) {
        return <>{progressBar('phone-and-sms')}</>;
      }
      return '';
    };
    let billingEnd = null;
    billingEnd = this.state.currentPlanDetails?.active
      ? new Date(this.state.currentPlanDetails.billing_period_end)
      : null;

    return (
      <Grid
        type="column"
        flexWidth={12}
        className="org_settings__container_padding global--soft-scroll"
        onScroll={this.onScroll}
        ref={this.$mainContainer}
      >
        <Heading fontSize={20} height={32} fontWeight={500} color={theme.shades.black}>
          Billing
        </Heading>
        <div className="portal-header">
          <Para className="portal-header__text mb-8" style={{ marginTop: 18 }}>
            <Link
              to={{
                pathname: `${API.config.billingPortal}`,
              }}
              target="_blank"
              className="portal_link"
            >
              Login to the Billing portal
            </Link>{' '}
            to update your account info, update billing address, update payment information and
            download invoices
          </Para>
          <p className="portal-header__help_text" style={{ marginBottom: 24 }}>
            Any changes in billing plan might take a few seconds to reflect in the system
          </p>
          <div className="portal-current-plan flex flex-start" style={{ marginBottom: 24 }}>
            <p className="portal-current-plan__text" style={{ marginRight: 14 }}>
              {' '}
              Current Plan :{'  '}
              {this.state.currentPlanDetails?.active
                ? isOldProActive
                  ? 'Custom Plan'
                  : BillingService.getPlanDisplayName(this.state.currentPlanDetails?.plan_slug)
                : 'No Active Plan'}
            </p>
            <p className="portal-current-plan__renew-text">
              {billingEnd
                ? 'Renews on ' + billingEnd.toDateString().split(' ').slice(1).join(' ')
                : ''}
            </p>
          </div>
          {isOldProActive ? (
            <>
              <p className="portal-header__custom_help_text" style={{ marginBottom: 24 }}>
                You've been grandfathered into a custom plan until your next billing cycle
              </p>
            </>
          ) : (
            ''
          )}
          <div className="billing_divider"> </div>
        </div>

        <div className="data-display-container" style={{ marginTop: 30, top: 0 }}>
          <div className="text-center" style={{ marginBottom: 68 }}>
            <span
              className="plan-duration-text"
              style={{ marginRight: 10, position: 'relative', top: -4 }}
            >
              Annual
            </span>
            <label className="switch no-select">
              <input
                type="checkbox"
                id="togBtn"
                checked={!this.state.showAnnual}
                onChange={this.onToggleYearlyPlans}
              />
              <div className="slider-toggle round">
                <span className="on" />
                <span className="off" />
              </div>
            </label>
            <span
              className="plan-duration-text"
              style={{ marginLeft: 10, position: 'relative', top: -4 }}
            >
              Monthly
            </span>
          </div>

          <div className="plan-container" style={{ paddingBottom: 100, marginTop: 40 }}>
            <Row justifyContent="space-between">
              <div className="plan-features-column">
                <div className="plan-box plan-box--empty"> </div>
                {planFeatures.featureList.map((val, idx) => {
                  const isNotificationFeature = idx === 5 || idx === 6;
                  return (
                    <div
                      className={
                        'feature-box feature-box--list' +
                        (isNotificationFeature ? ' feature-box--big' : '')
                      }
                      style={
                        val.bold
                          ? { backgroundColor: '#E0EBFD', border: 0 }
                          : { backgroundColor: '#FFFFFF' }
                      }
                      key={idx}
                    >
                      <p
                        className={
                          (val.bold ? 'feature-box-heading' : 'feature-box-text') +
                          (val.description != null ? ' hastooltip' : '')
                        }
                      >
                        {val.text}
                        <span>{val.description}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
              {planFeatures.plans.map((plan: IAppBillingPlan, index: number) => {
                const planAsAny = plan as any;
                const planFromJSON =
                  planAsAny[(this.state.showAnnual ? 'annual' : 'monthly') + 'Id'];
                const isPlanActive =
                  this.props.organization.plan.p!.active &&
                  planFromJSON === this.props.organization.plan.p!.plan_slug;

                const isFree = planFromJSON == BillingService.DEFAULT_FREE_PLAN_SLUG;
                const isEnterprise = isEnterprisePlan(planAsAny.name);
                const isOldProActive = BillingService.isOldProPlanActive(this.props);

                return (
                  <div
                    className="plan-box"
                    key={index}
                    style={{
                      backgroundColor: isPlanActive ? '#edf4ff' : 'white',
                    }}
                  >
                    <div
                      className={
                        'plan-main-heading-box flex-column' +
                        (isPlanActive ? ' flex-start' : ' flex-center')
                      }
                      style={{
                        backgroundColor: isPlanActive ? '#edf4ff' : 'white',
                      }}
                    >
                      {isPlanActive ? (
                        <div className="plan-current-btn-box">
                          <button className="plan-current-btn plan-current-txt">Current</button>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="plan-main-heading text-center">
                        <div className="plan-name-box flex flex-center">
                          {!isFree && <CrownIcon />}
                          <h1 className="plan-name" style={{ marginLeft: 6 }}>
                            {plan.name}
                          </h1>
                        </div>
                        <h1
                          className="plan-price"
                          style={{
                            marginBottom: 10,
                            ...{ marginTop: isEnterprise ? '-18px' : 'inherit' },
                          }}
                        >
                          <p className="plan-info" style={{ margin: 0 }}>
                            {isEnterprise ? 'Starts from' : ''}
                          </p>
                          <span className="plan-dollar">$</span>
                          {plan.price}
                        </h1>
                        <p className="plan-info">per user/month</p>
                        <p
                          className="billing-info"
                          style={isFree ? { opacity: 0, visibility: 'hidden' } : {}}
                        >
                          {this.state.showAnnual ? 'Billed Annually' : 'Billed monthly'}
                          <br />
                          <br />
                          {!this.state.showAnnual && !isPlanActive
                            ? BillingService.getDiscountInfo(plan.name)
                            : ''}
                        </p>
                        <div style={{ marginTop: 30 }}>{activePlanState(plan)}</div>
                      </div>
                    </div>
                    <div className="features-box-container">
                      <div className="plan-features">
                        {plan.features.map((feature: IAppBillingFeatures, featureIndex: number) => {
                          const isNotificationFtBox = featureIndex == 5 || featureIndex == 6;
                          const isFreePlanBox = index == 0;
                          const isProBox = index == 1;
                          const isEnterprise = index == 3;

                          const isLCRActive = this.props?.organization?.plan?.p?.rules[
                            'lcr-numbers'
                          ]?.quantity
                            ? this.props?.organization?.plan?.p?.rules['lcr-numbers']?.quantity > 0
                            : false;

                          return (
                            <div
                              className={
                                'feature-box' +
                                (isNotificationFtBox ? ' feature-box--big' : '') +
                                (isFreePlanBox ? ' first-plan-features' : '')
                              }
                              style={
                                feature.text === ' '
                                  ? {
                                      backgroundColor: '#E0EBFD',
                                      border: 0,
                                    }
                                  : isPlanActive
                                  ? { backgroundColor: '#edf4ff' }
                                  : { backgroundColor: '#FFFFFF' }
                              }
                              key={featureIndex}
                            >
                              <p
                                className={
                                  (isFreePlanBox || isProBox) &&
                                  (isNotificationFtBox || featureIndex === 4)
                                    ? 'sms-phone-text text-center'
                                    : 'feature-box-text text-center'
                                }
                                style={{
                                  margin: 0,
                                }}
                              >
                                {isFreePlanBox || isProBox ? (
                                  isNotificationFtBox ? (
                                    <>
                                      <p
                                        style={{
                                          margin: 0,
                                          marginTop: -5,
                                        }}
                                      >
                                        {feature.text}
                                      </p>
                                      {isPlanActive && !isOldProActive ? getUsageProgress() : ''}
                                    </>
                                  ) : (
                                    <>
                                      <p style={{ marginBottom: 6 }}> {feature.text}</p>
                                    </>
                                  )
                                ) : isEnterprise && feature.description === 'addOn' ? (
                                  <>
                                    {isLCRActive ? (
                                      <p>Yes </p>
                                    ) : (
                                      <p style={{ marginBottom: 6 }}>
                                        [Add-on]
                                        <a
                                          href="mailto:support@squadcast.com?subject=Request to enable Live Call Routing"
                                          style={{
                                            color: '#0F61DD',
                                            fontFamily: 'Mulish',
                                            textDecoration: 'none',
                                            marginLeft: '2px',
                                            marginRight: '2px',
                                          }}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Contact Sales
                                        </a>
                                        for pricing
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  feature.text
                                )}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Row>
          </div>
        </div>

        <DialogModalFrame
          id="confirm_free_plan"
          width="400px"
          onClose={() => {
            this.setState({ showConfirmFreePlan: false });
          }}
        >
          {this.state.showConfirmFreePlan ? (
            <ConfirmModal
              hide={async (result: boolean) => {
                await this.setState({ showConfirmFreePlan: false });
                result ? this.takeAction(BillingService.DEFAULT_FREE_PLAN_SLUG) : '';
              }}
              buttonText={'Continue to Free Plan'}
              displayText={`This will change some of your configuration. You will lose access to the Paid features. Would you still like to continue?`}
            />
          ) : (
            ''
          )}
        </DialogModalFrame>
        <DialogModalFrame
          id="confirm_free_plan"
          width="400px"
          onClose={() => {
            this.setState({ showDowngradeBlockModal: false });
          }}
        >
          {this.state.showDowngradeBlockModal ? (
            <ConfirmModal
              hide={async (result: boolean) => {
                await this.setState({ showDowngradeBlockModal: false });
              }}
              buttonText={'Understood'}
              hideCancelBtn={true}
              header="Warning"
              displayText={`Doing this would change your configuration, functionality and limit your features. If you're sure about it, please reach out to sales@squadcast.com`}
            />
          ) : (
            ''
          )}
        </DialogModalFrame>
      </Grid>
    );
  }
}
export default connect(
  ({ userInfo, organization, APP_CONFIG }: IAppState) => ({
    userInfo,
    organization,
    APP_CONFIG,
  }),
  {
    requestOrganizationPlan,
  },
)(Billing);
