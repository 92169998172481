import { IUserInfo } from 'core/interfaces/IUserData';
import { matchSorter } from 'match-sorter';
import React, { ComponentProps, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';

import { Grid } from 'uie/components';

import { IAppState } from '../../../../core/interfaces/IAppState';
import { SecondaryFiltersKeys, SelectOption } from '../../types';
import Badge from './badge';
import { FilterItem, FilterItemList } from './dropdown_container';
import { useGetSqauds } from 'views/main/organization/analytics-v2/Filters/hooks';

interface Props {
  selected: string[];
  filteredTerm?: string;
  selectedTeam?: string;
  isMulti?: boolean;
  updateFilter: (type: SecondaryFiltersKeys, data: string[]) => void;
}

const useUsersSelector = (teamId?: string) => {
  const organization = useSelector((state: IAppState) => state.organization);
  const usersInTeam = organization.teams.t
    .find(team => team.id === teamId)
    ?.members.map(m => m.user_id);
  return organization.users.u.filter(user => usersInTeam?.includes(user.id)) as IUserInfo[];
};

const useSquadSelector = (teamId?: string) => {
  const organization = useSelector((state: IAppState) => state.organization);
  return organization.squads.s;
};

export const UserBadge = (
  props: ComponentProps<typeof Badge> & {
    selectedTeam?: string;
    onRemoveTag?: any;
    showAccumlatedTags?: boolean;
  },
) => {
  const users = useUsersSelector(props.selectedTeam).map(val => {
    return {
      ...val,
      type: 'user',
    };
  });
  const squads = useGetSqauds(props.selectedTeam ?? '').squads.map((val: any) => {
    return {
      ...val,
      type: 'squad',
    };
  });
  const corpusArray = [...users, ...squads];
  const itemList = props.items
    .map(it => {
      const user = corpusArray.find(s => s.id === it);
      if (user) {
        if (user.type === 'squad') {
          return `${user.name}`;
        }
        if (user.type === 'user') {
          const username = user?.username_for_display ? `(${user.username_for_display})` : '';
          const fullName = `${user?.first_name} ${user?.last_name}`;
          return `${fullName}${username}`;
        }
      }
    })
    .filter(val => val != undefined);

  const removeTag = (name: string) => {
    const itemList = props.items
      .map(it => {
        const user = corpusArray.find(s => s.id === it);
        if (user) {
          if (user.type === 'user') {
            return {
              ...user,
              filterTerm: `${user?.first_name} ${user?.last_name}${
                user?.username_for_display ? `(${user.username_for_display})` : ''
              }`,
            };
          } else {
            return {
              ...user,
              filterTerm: user?.name,
            };
          }
        }
      })
      .filter(val => val != undefined);
    props.onRemoveTag(itemList?.filter((val: any) => val.filterTerm === name)[0]);
  };
  if (props.showAccumlatedTags) return <Badge {...props} items={itemList} showTooltip />;
  else
    return (
      <>
        {itemList?.map((list: any) => (
          <Badge
            key={props.label}
            onRemove={val => {
              removeTag(val);
            }}
            {...props}
            items={[list]}
            showTooltip
          />
        ))}
      </>
    );
};

const noneItem = { label: 'None', value: '' };

function Users(props: Props): JSX.Element {
  const users = useUsersSelector(props.selectedTeam);

  const usersOptions = useMemo(
    () =>
      matchSorter(users, props.filteredTerm || '', { keys: ['first_name', 'last_name'] }).map(
        (u: IUserInfo) => {
          return {
            label: `${u.first_name} ${u.last_name}`,
            value: u.id,
            username: u.username_for_display,
          };
        },
      ),
    [users, props.filteredTerm],
  );

  const [selectedOptions, selectedNames] = useMemo(() => {
    const sel = users.filter(t => {
      return props.selected.some(f => {
        return f === t.id;
      });
    });

    return [
      sel.map(u => {
        return {
          label: `${u.first_name} ${u.last_name}`,
          value: u.id,
        };
      }),
      sel.map(u => `${u.first_name} ${u.last_name}`),
    ];
  }, [users, props.selected]);

  const handleChange = (newValue: OnChangeValue<SelectOption, boolean>) => {
    const arr: string[] = [];

    if (Array.isArray(newValue)) {
      const options = newValue as MultiValue<SelectOption>;
      options.map(o => {
        arr.push(o.value);
      });
    } else if (newValue) {
      const option = newValue as SingleValue<SelectOption>;
      if (option?.value) {
        arr.push(option.value);
      }
    }

    props.updateFilter('users', arr);
  };

  const checkUserSelected = (user: SelectOption) => {
    if (selectedOptions.length) {
      return !!selectedOptions.find(option => option.value === user.value);
    } else if (!user.value) {
      return true;
    }
    return false;
  };

  return (
    <Grid style={{ flexDirection: 'column', rowGap: '5px', overflowY: 'auto' }}>
      <FilterItem
        item={noneItem}
        checkItemSelected={checkUserSelected}
        onItemSelect={user => handleChange(user)}
      />
      <FilterItemList
        itemList={usersOptions}
        checkItemSelected={checkUserSelected}
        onItemSelect={user => handleChange(user)}
      />
    </Grid>
  );
}

export default Users;
