import moment from 'moment';
import * as Yup from 'yup';
import { GetStatusPageDetailsQuery } from '../graphql/query';
import { NewStatusPageOwnerType } from '../graphql/types';
import { IMessageTemplate, IOptionComponent, IStatusIssueState } from '../Interface';
import { COMPONENT_TYPE, SEND_EMAIL_ID, THEME_TYPE } from './status.constants';

export const PAGE_TYPES = {
  DETAILS: 'details',
  ADDCOMPONENT: 'addComponent',
  ADDGROUP: 'addGroup',
  THEME: 'theme',
  ISSUE: 'issues',
  MAINTENANCE: 'maintenance',
  STATUS_MESSAGE: 'status_message',
  MESSAGE_TEMPLATE: 'template',
  SUBSCRIBERS_SETTINGS: 'settings',
};

export const fileUploadViewConfig = {
  status: ['upload-image'],
  uploadImage: true,
  imageTexts: {
    sbOnDragEnter: 'Drop file to upload it.',
    sbOnDrop: 'Uploading file #images_names#...',
    sbProgress: 'Uploading #file_name#: #progress#%',
    sbOnUploaded: 'Uploaded #image_name#',
    sizeUnits: ' B, KB, MB',
  },
  renderImageUploadsAsLinks: false,
  multipleFileUploadLimit: 2,
};

export const fileUploadHelperText = 'Attach files via drag & drop or paste files from clipboard';

const FILE_SIZE = 500;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const PAGE_SCHEMA = {
  [PAGE_TYPES.DETAILS]: Yup.object().shape({
    statusPageName: Yup.string()
      .required('Name is required!')
      .trim()
      .max(250, 'Max characters allowed are 250'),

    contactEmail: Yup.string().required('Contact Email is required!').email('Enter a valid email'),
    description: Yup.string().max(15000, 'Max characters allowed are 15000'),
    squadcastDomainName: Yup.string().when('domainSettings', {
      is: '1',
      then: Yup.string()
        .required('Enter domain name')
        .trim()
        .matches(
          /^[a-zA-Z0-9.\-_]+$/,
          'Valid Characters include a-z, A-Z, 0-9, and "- ", "_" and "."',
        )
        .max(250, 'Max characters allowed are 250'),
    }),
    customPageHostName: Yup.string().when('domainSettings', {
      is: '2',
      then: Yup.string()
        .required('Enter domain name')
        .trim()
        .matches(
          /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
          'Enter valid domain name',
        )
        .max(250, 'Max characters allowed are 250'),
    }),
    owner: Yup.object().shape({
      id: Yup.string().trim().required('Owner is required'),
      type: Yup.string().trim().required('Owner type is required'),
    }),
  }),
  [PAGE_TYPES.ADDCOMPONENT]: Yup.object().shape({
    name: Yup.string()
      .required('Name of component is required')
      .trim()
      .max(250, 'Max characters allowed are 250'),
    componentToGroup: Yup.boolean(),
    groupId: Yup.string().when('componentToGroup', {
      is: true,
      then: Yup.string().required('Select a group'),
    }),
  }),
  [PAGE_TYPES.ADDGROUP]: Yup.object().shape({
    groupName: Yup.string()
      .required('Name of Group is required')
      .trim()
      .max(250, 'Max characters allowed are 250'),
  }),
  [PAGE_TYPES.THEME]: Yup.object().shape({
    primaryColor: Yup.string()
      .required('Primary color is required!')
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Enter valid color'),
    secondaryColor: Yup.string()
      .required('Secondary color is required!')
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Enter valid color'),
    logo: Yup.mixed().when('isLogoChanged', {
      is: true,
      then: Yup.mixed()
        .required('Logo is required')
        .test(
          'fileSize',
          `File size is too large. Please upload image of size less than ${FILE_SIZE}kB`,
          value => value && value.size / 1024 <= FILE_SIZE,
        )
        .test(
          'fileType',
          'Unsupported File Format',
          value => value && SUPPORTED_FORMATS.includes(value.type),
        ),
    }),
  }),
  [PAGE_TYPES.ISSUE]: Yup.object().shape({
    title: Yup.string()
      .matches(/^[a-zA-Z0-9 ]*$/, 'Input must not contain special characters')
      .required('Name is required!')
      .trim()
      .max(250, 'Max characters allowed are 250'),
    selectedComponents: Yup.array().test({
      message: 'Atleast one component is needed',
      test: arr => (arr || []).length > 0,
    }),
  }),
  [PAGE_TYPES.MAINTENANCE]: Yup.object().shape({
    title: Yup.string()
      .matches(/^[a-zA-Z0-9 ]*$/, 'Input must not contain special characters')
      .required('Name is required!')
      .trim()
      .max(250, 'Max characters allowed are 250'),
    startTime: Yup.string(),
    endTime: Yup.string()
      .test({
        message: 'End time and start time can not be same',
        test: function (value) {
          const { startTime, startDate, endDate } = this.parent;
          const start = moment(`${startDate}${startTime}`, 'YYYY-MM-DDHH:mm');
          const end = moment(`${endDate}${value}`, 'YYYY-MM-DDHH:mm');
          return !start.isSame(end);
        },
      })
      .test({
        message: 'End time should be greater than the start time',
        test: function (value) {
          const { startTime, startDate, endDate } = this.parent;
          const start = moment(`${startDate}${startTime}`, 'YYYY-MM-DDHH:mm');
          const end = moment(`${endDate}${value}`, 'YYYY-MM-DDHH:mm');
          return end.isAfter(start);
        },
      }),
    endAfterOccurences: Yup.string().when('repeatsSelection', {
      is: '3',
      then: Yup.string().required('Please mention the end '),
    }),
    selectedComponentsForMaintenance: Yup.array().test({
      message: 'Atleast one component is needed',
      test: arr => (arr || []).length > 0,
    }),
    note: Yup.string().when('selectedAddNote', {
      is: true,
      then: Yup.string().required('Note is required '),
    }),
  }),
  [PAGE_TYPES.MESSAGE_TEMPLATE]: Yup.object().shape({
    template: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required('Name is required')
          .trim()
          .max(250, 'Max characters allowed are 250'),
      }),
    ),
  }),
  [PAGE_TYPES.SUBSCRIBERS_SETTINGS]: Yup.object().shape({
    contactEmail: Yup.string()
      .required('Contact Email is required!')
      .trim()
      .email('Enter a valid email'),
  }),
};

export const INIT_VALUES = {
  [PAGE_TYPES.DETAILS]: {
    statusPageName: '',
    domainSettings: '1',
    description: '',
    privatePage: false,
    squadcastDomainName: '',
    customPageHostName: '',
    timezone: {
      label: 'IST\n    (GMT+5:30) \n    Asia/Calcutta',
      value: 'Asia/Calcutta',
    },
    sendOnlyEmailID: SEND_EMAIL_ID,
    contactEmail: '',
    aRecord: '',
    txtRecord: '',
    isCustomDomainEnabled: false,
    isCustomARecordVerified: false,
    isCustomTxtVerified: false,
    owner: {
      id: '',
      type: 'user' as NewStatusPageOwnerType,
    },
    hideFromSearchEngines: false,
  },
  [PAGE_TYPES.ADDCOMPONENT]: {
    name: '',
    type: COMPONENT_TYPE.COMPONENT,
    description: '',
    componentToGroup: false,
    groupId: undefined,
    serviceID: undefined,
  },
  [PAGE_TYPES.THEME]: {
    primaryColor: '',
    secondaryColor: '',
    logoUrl: '',
    isLogoChanged: true,
    schemaType: THEME_TYPE.DEFAULT,
  },
  [PAGE_TYPES.ISSUE]: {
    allComponents: [] as IOptionComponent[],
    selectedComponents: [],
    title: '',
    status: {},
    issueStates: [] as IStatusIssueState[],
    template: [] as IMessageTemplate[],
    statusCodes: [] as { value: number; label: string }[],
    pageName: '',
    stateMessageCount: '0',
    isMigrated: false,
    operationStatusCode: 0,
    currentIssueState: '',
    timezone: '',
  },
  [PAGE_TYPES.STATUS_MESSAGE]: {
    status: [],
  },
  [PAGE_TYPES.MAINTENANCE]: {
    title: '',
    startDate: moment().format('YYYY-MM-DD'),
    startTime: '00:00',
    endDate: moment().format('YYYY-MM-DD'),
    endTime: '00:00',
    // selectedRepeat: false,
    selectedAddNote: false,
    // repeatsSelection: '1',
    // repeats: {
    //   every: 1,
    //   range: '',
    //   end: {
    //     type: '',
    //     isForever: false,
    //     endDate: '',
    //     endAfterOccurences: 1,
    //   },
    // },
    components: [] as IOptionComponent[],
    statusPageID: '',
    note: '',
    selectedComponentsForMaintenance: [],
    pageName: '',
  },
  [PAGE_TYPES.SUBSCRIBERS_SETTINGS]: {
    emailFrom: '',
    contactEmail: '',
    allowWebhookSubscription: false,
    componentSubscriptionEnabled: false,
    componentSubscriptionCount: 0,
    maintenanceSubscriptionEnabled: false,
    maintenanceSubscriptionCount: 0,
    showMaintenanceMessage: false,
    showComponentMessage: false,
  },
};
