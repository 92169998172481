import { InputComboBlockWithMde } from 'uie/components';
import React, { Component } from 'react';
import styled from 'styled-components';

type InputBlockMDEWithHeight = React.ComponentProps<typeof InputComboBlockWithMde> & {
  maxHeight?: number;
};

const MDEWithMaxHeight = styled(InputComboBlockWithMde)`
  & .CodeMirror:not(.CodeMirror-fullscreen) {
    max-height: ${(props: InputBlockMDEWithHeight) => `${props.maxHeight ?? 144}px`};
  }
`;

class MDEWrapper extends Component<{ children: React.ReactNode; maxHeight: number }> {
  render() {
    const { children, maxHeight } = this.props;
    return React.Children.map(
      children,
      child =>
        React.isValidElement<{ maxHeight: number }>(child) &&
        React.cloneElement(child, {
          maxHeight: maxHeight,
        }),
    );
  }
}

export { MDEWithMaxHeight, MDEWrapper };
