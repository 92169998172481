import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputBox, TextButton } from '@squadcast/alchemy-ui/metal';
import { Row, ErrorText } from '@squadcast/alchemy-ui/carbon';
import { DialogModalFrame, Grid, IconButton, Para, Pill } from 'uie/components';
import { TwitterPicker } from 'react-color';
import { CalendarService } from '../../../../../core/services';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import ConfirmModal from '../../../../../components/confirm.modal';
import { IComponentErrorState } from '../../../../../core/interfaces/IComponentState';
import { objectIsEmpty, pick, deepCopy } from 'core/helpers';
import { exception } from '../../../../../core/exception';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import {
  T_WA_GS_SCHEDULE_CREATED,
  T_WA_GS_SCHEDULE_DELETED,
} from '../../../../../core/const/tracker';
import equal from 'fast-deep-equal/es6/react';
import { TeamIcon } from '../../../../../icons';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';

interface IProps extends Pick<IAppState, 'organization'> {
  id: string;
  hide: (reload: boolean) => () => void;
  checkAndSetDirty: () => void;
  userAccess: UserAccessContextValue;
}

interface IState {
  name: string;
  description: string;
  colour: string;
  showColorPicker: string;
  errors: IComponentErrorState;
  requestDeleteId: string;
  saving: boolean;
}

class CalenderModal extends Component<IProps, IState> {
  public calendarService = new CalendarService();
  private _initialState?: IState;

  constructor(props: IProps) {
    super(props);

    const { name, description, colour } = props.organization.calendar.c.find(
      c => c.id === props.id,
    ) || {
      name: '',
      description: '',
      colour: '#0f61dd',
    };

    this.state = {
      name,
      description,
      colour,
      showColorPicker: '',
      errors: {},
      saving: false,
      requestDeleteId: '',
    };

    this._initialState = deepCopy<IState>(this.state) as IState;
  }

  public onChange = (value: string, key: string) => {
    const newState: any = {};
    newState[key] = value;
    this.setState(newState);
  };

  public onDeleteRequest = () => {
    this.setState({
      requestDeleteId: this.props.id,
    });
  };

  public closeModal = () => {
    this.setState({ requestDeleteId: '' });
  };

  public confirmDelete = async (isTrue: boolean) => {
    if (!isTrue) {
      this.setState({ requestDeleteId: '' });
      return;
    }
    AppTracker.track(T_WA_GS_SCHEDULE_DELETED);

    try {
      await this.calendarService.deleteCalendar(this.props.id);
      this.props.hide(true)();
    } catch (err: any) {
      const errors: any = {};
      errors.network_error = err?.response?.data?.meta?.error_message ?? 'Network Error';
      this.setState({
        requestDeleteId: '',
        errors,
      });
    }
  };

  public verify = () => {
    const errors: any = {};

    if (this.state.name === '') {
      errors.schedule_name = 'Schedule Name is required';
    }

    this.setState({ errors });
    return errors;
  };

  public onSave = async () => {
    if (!objectIsEmpty(this.verify())) {
      return;
    }

    try {
      this.setState({ saving: true });
      const { colour, description, name } = this.state;

      if (this.props.id) {
        const calendar = {
          ...this.props.organization.calendar.c.find(c => c.id === this.props.id)!,
          ...{ name, description, colour },
        };
        await this.calendarService.updateCalendar(calendar);
        this.props.hide(true)();
      } else {
        await this.calendarService.createCalendar({
          name,
          description,
          colour,
        });
        this.props.hide(false)();
      }
    } catch (err: any) {
      exception.handle('E_NEW_CALENDAR_SAVE', err);
      const { response } = err;
      const errors: any = {};
      const networkError = response?.data?.meta?.error_message ?? 'Network Error';
      errors.network_error = networkError.includes('schedule with the same name already exists')
        ? 'Schedule with the same name already exists. Please choose a different name for easier identification'
        : networkError;
      this.setState({
        errors,
        saving: false,
      });
    }
    if (this.props.id.length === 0)
      AppTracker.track(T_WA_GS_SCHEDULE_CREATED, { 'Schedule Type': this.state.name });
  };

  componentDidUpdate() {
    if (
      this._initialState &&
      !equal(
        pick(this.state, 'colour', 'description', 'name'),
        pick(this._initialState, 'colour', 'description', 'name'),
      )
    )
      this.props.checkAndSetDirty();
  }

  public render() {
    return (
      <div
        onClick={event => {
          event.stopPropagation();
          this.setState({ showColorPicker: '' });
        }}
      >
        <div className="clearfix">
          <div className="float-left">
            <Grid alignItems="center">
              <h1 className="modal-container-heading">
                {!this.props.id ? 'Create new Schedule' : 'Edit Schedule'}
              </h1>
              <Pill className="ml-10">
                <Grid alignItems="center">
                  <TeamIcon height={16} width={16} />
                  <Para className="ml-5">{this.props.organization.selectedTeam.team?.name}</Para>
                </Grid>
              </Pill>
            </Grid>
          </div>
        </div>
        <div className="mt-20">
          <div>
            <Row alignItems="center">
              <Row flexWidth={11}>
                <div className="w-1-1">
                  <InputBox
                    id={'schedule_name'}
                    name="Schedule Name"
                    label="Schedule name"
                    placeHolder="On-call Schedules"
                    type="text"
                    value={this.state.name}
                    onChange={(event, value) => this.onChange(value, 'name')}
                    required={true}
                    error={this.state.errors.schedule_name ? this.state.errors.schedule_name : ''}
                  />
                </div>
              </Row>
              <div className="ml-20" style={{ position: 'relative' }}>
                <div
                  className="color-block cursor-pointer"
                  style={{
                    backgroundColor: this.state.colour as any,
                    border: '2px solid whitesmoke',
                  }}
                  onClick={event => {
                    this.setState({
                      showColorPicker: '1',
                    });
                    event.stopPropagation();
                  }}
                />

                {this.state.showColorPicker === '1' && (
                  <div
                    className="config-modal animated fadeIn no-pointer"
                    style={{ width: 'auto', borderRadius: 6 }}
                    onClick={event => {
                      event.stopPropagation();
                    }}
                  >
                    <TwitterPicker
                      color={this.state.colour as string}
                      onChange={({ hex }) => this.onChange(hex, 'colour')}
                      triangle="hide"
                    />
                  </div>
                )}
              </div>
            </Row>
          </div>

          <InputBox
            id={'schedule_description'}
            name="Schedule description"
            label="Schedule description"
            placeHolder="..."
            type="text"
            value={this.state.description}
            onChange={(_, value) => this.onChange(value, 'description')}
          />
        </div>
        <div className="mt-20">
          <div className="mb-20">
            {this.state.errors.network_error && (
              <ErrorText text={this.state.errors.network_error} />
            )}
          </div>
          <Row
            justifyContent={this.props.id.length > 0 ? 'space-between' : 'flex-start'}
            alignItems="center"
          >
            <TextButton
              id="schedule_save"
              role="button"
              disabled={this.state.name === ''}
              text={this.props.id.length > 0 ? 'Update' : 'Save'}
              onClick={this.onSave}
              loading={this.state.saving}
            />
            {this.props.id.length > 0 && this.props.userAccess.hasDeleteAccess('schedules') && (
              <IconButton
                id="delete_calendar"
                name="delete_calendar"
                borderType="rounded"
                base="40px"
                color="#FDF5F6"
                onClick={this.onDeleteRequest}
              >
                <Para fontSize={12} style={{ margin: '10px' }}>
                  <img src="/icons/delete.svg" alt="delete" />
                </Para>
              </IconButton>
            )}
          </Row>
        </div>
        <DialogModalFrame id="calendar_delete_confirm" width="400px" onClose={this.closeModal}>
          {this.state.requestDeleteId.length > 0 && (
            <ConfirmModal
              displayText={`Delete ${this.state.name} ?`}
              buttonText="Yes"
              hide={this.confirmDelete}
            />
          )}
        </DialogModalFrame>
      </div>
    );
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
}))(withUserAccess(CalenderModal));
