import {
  allOperators,
  booleanComparators,
  numberComparators,
  stringComparators,
} from 'core/const/comparators';
import { T_WA_GS_SUPPRESSION_RULES_MODIFIED } from 'core/const/tracker';
import { deepCopy, pick } from 'core/helpers';
import { IAppState } from 'core/interfaces/IAppState';
import { IComponentState } from 'core/interfaces/IComponentState';
import { IAlertSource } from 'core/interfaces/IIntegration';
import { BillingService, ServiceService, SuppressionRulesService } from 'core/services';
import equal from 'fast-deep-equal/es6/react';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppTracker } from 'shared/analytics/tracker';
import {
  generateExpression,
  getLHSType,
} from 'views/main/organization/service-catalog/helpers/helper.automation-rule';
import {
  IRuleExpressionOperation,
  ISuppressionRule,
  ITimeslot,
} from 'views/main/organization/service-catalog/Interfaces/automation-rule';
import { RoutingRules } from '..';
import { AutomationRuleContext, AutomationRuleContextProps } from '../context';

import render from './render.index';

interface IIndexedSuppressionRule extends ISuppressionRule {
  id: number;
  rule_id: string;
  existing: boolean;
}

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  serviceId: string;
  hide: () => void;
  checkAndSetDirty: () => void;
  setIsDirty: (isDirty: boolean) => void;
  refetchRule: () => void;
  editRuleId?: string;
  rules?: ISuppressionRule[];
}

interface IState {
  componentState: IComponentState;
  rules: IIndexedSuppressionRule[];
  saveState: 'idle' | 'saving' | 'saved' | 'error';
  networkError: '';
  eventState: 'loading' | 'noEvents' | 'idle' | 'default';
  event: any;
  errors: string[];
  alertSourceSearch: string;
  alertSource: IAlertSource | null;
  alertSourcesLoadState: 'loading' | 'success' | 'error';
  alertSourceErrorMessage: string;
  searchString: { [key: string]: string };
  warningIndex: number | null;
  globalSearch: string;
  showUpgradeModal: boolean;
  planSuprressionRulesLimitExceeded: boolean;
  orgSuppressionCount: number;
  existingDeleted: number;
  editRuleId: string | undefined;
}

export class SuppressionRulesModal extends Component<IProps, IState, AutomationRuleContextProps> {
  static contextType = AutomationRuleContext;
  declare context: React.ContextType<typeof AutomationRuleContext>;

  private ServiceService = new ServiceService(this.props.serviceId);
  private SuppressionRulesService = new SuppressionRulesService(this.props.serviceId);

  public _service = this.props.organization.services.s.find(s => s.id === this.props.serviceId);

  public alertSources: IAlertSource[] = this.props.integrations.i
    .filter(as => !as.hideAutomation && !as.deprecated)
    .map(as => ({
      isActive: false,
      ...as,
    }));

  public _comparators: any = {
    string: stringComparators,
    number: numberComparators,
    boolean: booleanComparators,
    all: allOperators,
  };

  private initialState?: IState;

  constructor(props: IProps) {
    super(props);
    this.state = {
      componentState: 'busy',
      rules: [],
      eventState: 'default',
      event: {},
      saveState: 'idle',
      errors: [],
      networkError: '',
      alertSourceSearch: '',
      alertSource: null,
      alertSourcesLoadState: 'loading',
      alertSourceErrorMessage: '',
      searchString: {},
      warningIndex: null,
      globalSearch: '',
      showUpgradeModal: false,
      planSuprressionRulesLimitExceeded: false,
      orgSuppressionCount: 0,
      existingDeleted: 0,
      editRuleId: props.editRuleId,
    };
  }

  public render = render;

  public async componentDidMount() {
    this.getActiveAlertSources();
    await this.getSuppressionRules();
    await this.updateOrgSuppressionCount();

    if (!this.props.editRuleId) {
      this.checkPlanLimitsAndAddNewRule();
    }
  }

  updatePlanLimitExceeded = () => {
    this.setState({
      planSuprressionRulesLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'suppression-rules',
        () =>
          this.state.rules.filter(r => r.existing === false).length +
          this.state.orgSuppressionCount -
          this.state.existingDeleted,
      ),
    });
  };

  public getActiveAlertSources = async () => {
    try {
      const { data } = await this.ServiceService.getActiveAlertSources();
      const activeAlertSources = data.data as string[];

      this.alertSources.forEach((as: IAlertSource) => {
        if (activeAlertSources.includes(as._id)) {
          as.isActive = true;
        }
      });

      // sorting according to shortName
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        const firstAlertSourceName = as1.type ?? '';
        const secondAlertSourceName = as2.type ?? '';
        if (!firstAlertSourceName || !secondAlertSourceName) return 0;
        if (firstAlertSourceName.toLowerCase() < secondAlertSourceName.toLowerCase()) {
          return -1;
        }
        if (firstAlertSourceName.toLowerCase() > secondAlertSourceName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      // sorting according to active status
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        if (as1.isActive && !as2.isActive) {
          return -1;
        }
        if (!as1.isActive && as2.isActive) {
          return 1;
        }
        return 0;
      });
      const as = this.alertSources.filter((a: IAlertSource) => a.isActive);
      if (as.length === 1) {
        this.setState({ alertSourcesLoadState: 'success' });
        this.getAlertSourceConfig(as[0]);
        return;
      }
      this.setState({ alertSourcesLoadState: 'success' });
    } catch (err: any) {
      this.setState({
        alertSourceErrorMessage: `Network Error: ${
          err?.esponse?.data?.meta?.error_message ?? 'Network Error'
        }`,
        alertSourcesLoadState: 'error',
      });
    }
  };

  public getAlertSourceConfig = async (alertSource: IAlertSource) => {
    this.setState({
      alertSource,
      eventState: 'loading',
    });
    try {
      const {
        data: {
          data: { payload, message, description, tags },
        },
      } = await this.ServiceService.getAlertSourceLatestEvent(alertSource._id);
      if (tags) Object.keys(tags).forEach(k => (tags[k] = tags[k].value));

      this.setState({
        eventState: 'idle',
        event: {
          payload,
          tags: tags || {},
          incident: {
            message,
            description:
              description.length < 100 ? description : `${description.substring(0, 100)}...`,
          },
          source: alertSource.shortName,
        },
      });
    } catch (err: any) {
      this.setState({
        eventState: 'noEvents',
        event: {},
      });
    }
  };

  public getSuppressionRules = async () => {
    this.setState({ componentState: 'busy' });
    try {
      this.setState(
        {
          rules:
            this.props.rules?.map((r: any, i) => {
              return {
                ...r,
                id: i,
                rule_id: r.rule_id,
                existing: true,
                basic_expression: r.is_basic ? r.basic_expression : [],
              };
            }) ?? [],
          componentState: 'idle',
        },
        () => (this.initialState = deepCopy(this.state)),
      );
    } catch (err: any) {
      this.setState({ componentState: 'error' });
    }
  };

  public updateOrgSuppressionCount = async () => {
    try {
      const {
        data: {
          data: { count: orgSuppressionCount },
        },
      } = await this.SuppressionRulesService.getOrgCount();
      await this.setState({ orgSuppressionCount: orgSuppressionCount });
      this.updatePlanLimitExceeded();
    } catch (err: any) {
      this.setState({ componentState: 'error' });
    }
  };

  public checkPlanLimitsAndAddNewRule = async () => {
    if (this.state.planSuprressionRulesLimitExceeded) {
      this.setState({ showUpgradeModal: true });
    } else {
      await this.addNewRule();
      this.updatePlanLimitExceeded();
    }
  };

  public addNewRule = () => {
    this.props.checkAndSetDirty();
    this.setState(({ rules }: { rules: any }) => {
      rules.push({
        id: rules.length,
        rule_id: '',
        description: '',
        expression: '',
        is_basic: true,
        is_timebased: false,
        timeslots: [],
        existing: false,
        basic_expression: [
          {
            lhs: '',
            op: IRuleExpressionOperation.EOpNoOp,
            rhs: '',
          },
        ],
      });

      return { rules };
    });
  };

  public addNewTimeSlot = (index: number) => {
    const day: any = new Date().getDay();
    const timezone = this.props.organization.currentUser.u?.time_zone || moment.tz.guess();
    this.props.checkAndSetDirty();
    this.setState(({ rules }) => {
      if (!rules[index].timeslots) rules[index].timeslots = [];
      const newRules = [...rules];
      newRules[index].timeslots.push({
        time_zone: timezone,
        start_time: new Date(
          moment(new Date())
            .tz(timezone)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
        ),
        end_time: new Date(
          moment(new Date())
            .tz(timezone)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
        ),
        custom: {
          repeats_count: 1,
          repeats: 'day',
          repeats_on_weekdays: [day],
          repeats_on_month: 'date-occurence',
        },
        is_allday: false,
        repetition: 'none',
        is_custom: false,
        ends_never: false,
        ends_on: new Date(
          moment(new Date())
            .tz(timezone)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
        ),
      });
      return { rules: newRules };
    });
  };

  public handleTimeSlotChange = (ruleIndex: number, timeIndex: number) => (newSlot: ITimeslot) => {
    this.setState(({ rules }) => {
      const newRules = [...rules];
      newRules[ruleIndex].timeslots[timeIndex] = newSlot;
      return { rules: newRules };
    });
  };

  public deleteTimeslot = (ruleIndex: number, index: number) => {
    this.setState(({ rules }) => {
      rules[ruleIndex].timeslots.splice(index, 1);
      return { rules };
    });
  };

  public verify = () => {
    const errors: string[] = [];
    const { rules } = this.state;
    let firstErrIndex = -1;

    rules
      .filter(rule =>
        this.props.editRuleId ? rule.rule_id === this.props.editRuleId : !rule.existing,
      )
      .forEach((rule, index) => {
        if (!rule.is_basic) {
          if (rule.expression === '') {
            errors.push(`rules[${index}].expression`);
          }
        } else {
          rule.basic_expression && rule.basic_expression.length > 0
            ? rule.basic_expression.forEach((be, beIndex) => {
                if (be.lhs === '') {
                  errors.push(`rules[${index}].basic_expression[${beIndex}].lhs`);
                }

                if (be.op === IRuleExpressionOperation.EOpNoOp) {
                  errors.push(`rules[${index}].basic_expression[${beIndex}].op`);
                }

                if (be.rhs === '') {
                  errors.push(`rules[${index}].basic_expression[${beIndex}].rhs`);
                }
              })
            : errors.push(`rules[${index}].basic_expression`);
        }

        if (errors.length !== 0 && firstErrIndex === -1) {
          firstErrIndex = index;
        }
      });

    this.setState({ errors });
    return errors;
  };

  public save = async () => {
    const { editRuleId } = this.props;

    this.setState({ errors: [], saveState: 'saving' });
    const errors = this.verify();
    if (errors.length > 0) {
      this.setState({ saveState: 'idle' });
      return;
    }

    const rule = this.state.rules.find((r, i, arr) =>
      editRuleId ? r.rule_id === editRuleId : i === arr.length - 1,
    );

    try {
      // await this.SuppressionRulesService.save(this.state.rules);

      if (!rule) {
        throw new Error('Rule not defined');
      }

      if (editRuleId) {
        await this.context?.updateRule.mutateAsync({
          ruleType: RoutingRules.suppression,
          ruleId: rule.rule_id,
          ruleDetail: { ...rule, timeslots: rule.timeslots ?? [] },
        });
      } else {
        await this.context?.createRule.mutateAsync({
          ruleType: RoutingRules.suppression,
          ruleDetail: { ...rule, timeslots: rule.timeslots ?? [] },
        });
      }

      this.setState({
        rules: this.state.rules.map(r => {
          return { ...r, existing: true };
        }),
        existingDeleted: 0,
      });
      this.setState({
        saveState: 'saved',
      });
      this.props.setIsDirty(false);

      this.props.refetchRule();
    } catch (err: any) {
      this.setState({
        saveState: 'error',
        networkError: err?.esponse?.data?.meta?.error_message ?? 'Network Error',
      });
    }

    AppTracker.track(T_WA_GS_SUPPRESSION_RULES_MODIFIED, {
      'Suppression Alert Source': this.state.alertSource?.type,
    });
  };

  public onRuleExpressionChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      this.setState(({ rules }) => {
        rules[index].expression = value;
        return { rules };
      });
    };

  public onDescriptionChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(({ rules }) => {
      rules[index].description = value;
      return { rules };
    });
  };

  onSelectChange = (_: any, alertSource: IAlertSource) => {
    this.getAlertSourceConfig(alertSource);
  };

  onTextChange = (type: 'alertSourceSearch') => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [type]: event.target.value });
  };

  changeConditionSelectBox =
    (ruleIndex: number, beIndex: number, field: 'lhs' | 'op' | 'rhs') => (_: any, value: any) => {
      this.setState(({ rules }) => {
        rules[ruleIndex].basic_expression[beIndex][field] = value;
        if (field === 'lhs') {
          rules[ruleIndex].basic_expression[beIndex].rhs = '';
          rules[ruleIndex].basic_expression[beIndex].op = IRuleExpressionOperation.EOpNoOp;
        }
        return { rules };
      });
    };

  changeConditionInputBox =
    (ruleIndex: number, beIndex: number, field: 'rhs') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value: any = event.target.value;

      this.setState(({ rules, event }) => {
        const lhs: string = rules[ruleIndex].basic_expression[beIndex].lhs;
        value = getLHSType(lhs, event) === 'number' ? parseFloat(value) || '' : value;
        rules[ruleIndex].basic_expression[beIndex][field] = value;
        return { rules };
      });
    };

  addCondition = (ruleIndex: number) => () => {
    this.setState(({ rules }) => {
      rules[ruleIndex].basic_expression
        ? rules[ruleIndex].basic_expression.push({
            lhs: '',
            op: IRuleExpressionOperation.EOpNoOp,
            rhs: '',
          })
        : (rules[ruleIndex].basic_expression = [
            {
              lhs: '',
              op: IRuleExpressionOperation.EOpNoOp,
              rhs: '',
            },
          ]);

      return { rules };
    });
  };

  removeCondition = (ruleIndex: number, beIndex: number) => () => {
    this.setState(({ rules }) => {
      rules[ruleIndex].basic_expression.splice(beIndex, 1);
      return { rules };
    });
  };

  openWarning = (ruleIndex: number) => () => this.setState({ warningIndex: ruleIndex });

  closeWarning = (flag: boolean) => () => {
    if (!flag) {
      this.setState({ warningIndex: null });
    } else {
      this.setState(({ rules, warningIndex }) => {
        const rule = rules[warningIndex as number];
        rule.is_basic = false;
        rule.expression = generateExpression(rule.basic_expression, this.state.event);
        rule.basic_expression = [];
        return { rules, warningIndex: null };
      });
    }
  };

  setEntitySearch =
    (entitiyType: string, ruleIndex: number, beIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      this.setState(({ searchString }) => {
        searchString[`${entitiyType}_${ruleIndex}_${beIndex}`] = value;
        return { searchString };
      });
    };

  public onClickTime = (ruleIndex: number) => () => {
    this.setState(({ rules }) => {
      rules[ruleIndex].is_timebased = !rules[ruleIndex].is_timebased;
      return { rules };
    });
  };

  componentDidUpdate() {
    if (
      this.initialState &&
      this.state.saveState !== 'saved' &&
      !equal(
        pick(this.state, 'rules', 'alertSource'),
        pick(this.initialState, 'rules', 'alertSource'),
      )
    )
      this.props.checkAndSetDirty();
  }
}

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(SuppressionRulesModal);
