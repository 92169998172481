import { Box, Center, HStack, Skeleton, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/table-core';

import { CircleIcon } from 'icons';
import { useMemo } from 'react';
import { ACTION_TITLE, STATUS_COLORS, STATUS_LABELS } from '../../../constant';
import { useWorkflowLogsContext } from '../../../context/workflowLogsContext';
import qs, { ParsedQuery } from 'query-string';

import { WorkflowLogDetail } from '../../../types/response';
import { useHistory } from 'react-router-dom';
import { JSONCodeBlock } from 'uie/components';
import { THEME_COLORS } from 'library/theme/colors';
import { ActionTypes } from '../../../types';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { IAppState } from 'core/interfaces/IAppState';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Table from 'library/molecules/Tablev2/Table';
import { CustomDrawerComponent } from 'library/molecules';

dayjs.extend(utc);
dayjs.extend(timezone);

const WorkflowActionLog = () => {
  const {
    showActionLog,
    onHideActionLog,
    actionLogs,
    actionLogLoading,
    showTriggerWebhook,
    workflowWebhook,
    workflowWebhookLoading,
  } = useWorkflowLogsContext();
  const timezone = useSelector((state: IAppState) => state.userInfo.d?.time_zone);
  const history = useHistory();
  const query = qs.parse(history.location.search, { arrayFormat: 'comma' }) as ParsedQuery<string>;
  const selectedAction = query['actionId'];
  const selectedDetail = query['detail'];
  const selectedDate = query['date'];
  const onViewTriggerWebhook = ({
    id,
    detail,
    executed_at,
  }: {
    id: string;
    detail: string;
    executed_at: string;
  }) => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          actionId: id,
          detail: detail,
          date: executed_at,
        },
      }),
    });
  };
  //return empty hstack because it only need hover style.
  const HoverComponent = () => {
    return <HStack gap="0.5rem"></HStack>;
  };

  const getDate = (date: string) => {
    return dayjs.tz(dayjs.utc(date.slice(0, -10)), timezone);
  };
  const columns = useMemo<ColumnDef<WorkflowLogDetail, any>[]>(
    () => [
      {
        header: 'Action',
        cell: ({ row }) => {
          return (
            <HStack>
              <Box
                sx={{
                  '& > svg': {
                    fill: STATUS_COLORS[row.original.status],
                    stroke: STATUS_COLORS[row.original.status],
                  },
                }}
              >
                <Tooltip label={STATUS_LABELS[row.original.status]}>
                  <CircleIcon width={10} />
                </Tooltip>
              </Box>
              <Text>{ACTION_TITLE[row.original.name as keyof typeof ACTION_TITLE]}</Text>
            </HStack>
          );
        },
      },
      {
        header: 'Detail',
        cell: ({ row }) => (
          <Text
            cursor={'pointer'}
            color="brand.blue"
            onClick={() => onViewTriggerWebhook(row.original)}
          >
            {row.original.detail !== '' ? row.original.detail : '-'}
          </Text>
        ),
      },
      {
        header: 'Executed At',
        cell: ({ row }) => {
          const date = getDate(row.original.executed_at).format('hh:mm:ss');
          const fullDate = getDate(row.original.executed_at).format('MMM DD, YYYY ,hh:mm:ss');
          return (
            <Tooltip label={fullDate}>
              <Text>{date}</Text>
            </Tooltip>
          );
        },
      },
    ],
    [],
  );

  return (
    <CustomDrawerComponent
      disableBodyPadding
      isOpen={showActionLog}
      //since title comes from BE need to ad empty string else previous title appears for a momment and bad UX
      title={
        selectedAction
          ? workflowWebhookLoading
            ? ''
            : ACTION_TITLE[workflowWebhook?.action_name as ActionTypes]
          : 'Log Detail'
      }
      showBackIcon={!!selectedAction}
      onClose={onHideActionLog}
    >
      {showTriggerWebhook ? (
        workflowWebhookLoading ? (
          <Center>
            <Box h={'60dvh'} display={'flex'} alignItems={'center'}>
              <Spinner color="brand.blue" size="lg" />
            </Box>
          </Center>
        ) : (
          <Box width="100%" pr={4} pl={6}>
            <HStack justifyContent={'space-between'} px={4} pb={6} pt={4}>
              <Text as={'h2'} variant={'h2'} fontWeight={'bold'}>
                {selectedDetail}
              </Text>
              <Text color={THEME_COLORS.secondary['1000']}>
                {getDate(selectedDate as string).format('hh:mm:ss')}
              </Text>
            </HStack>
            <Box>
              <JSONCodeBlock
                code={
                  workflowWebhook.data !== ''
                    ? JSON.parse(workflowWebhook?.data).Error === 'no error'
                      ? JSON.parse(JSON.parse(workflowWebhook?.data)?.Input)
                      : JSON.parse(workflowWebhook?.data)?.Error
                    : {}
                }
                enableSearch={true}
                shellProps={{
                  minHeight: '600px',
                  minWidth: 'calc(100% - 15px)',
                }}
              />
            </Box>
          </Box>
        )
      ) : (
        <Table
          isLoading={actionLogLoading}
          data={actionLogs}
          columns={columns}
          hidePagination
          hoverComponent={() => <HoverComponent />}
        />
      )}
    </CustomDrawerComponent>
  );
};

export default WorkflowActionLog;
