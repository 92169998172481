import { toSentenceCase } from 'core/helpers/stringUtils';
import { AssigneeType } from 'library/enums';
import moment from 'moment';
import { generatePath, Link } from 'react-router-dom';
import { USER_PROFILE_PATH } from 'views/main/routes/routes';
import {
  DateFilterChoice,
  DROPDOWN_TYPE_ENUM,
  EXPORT_TYPE,
  Filters,
} from '../types/index.auditLogs';

export const toUpperCase = (value: string) => {
  const targetArray = value.split('_').map(val => toSentenceCase(val));

  return targetArray.join(' ');
};

export const CREATED_OPTIONS = [
  { label: 'Last 24 Hours', value: DateFilterChoice.Days },
  { label: 'This Week', value: DateFilterChoice.Week },
  { label: 'This Month', value: DateFilterChoice.Month },
  { label: 'Last 7 Days', value: DateFilterChoice.Last7Days },
  { label: 'Last 14 Days', value: DateFilterChoice.Last14Days },
  { label: 'Last 30 Days', value: DateFilterChoice.Last30Days },
  { label: 'Date Range', value: DateFilterChoice.Range },
];

export const EXPORT_TYPE_DROPWDOWN = [
  { label: 'CSV', value: EXPORT_TYPE.CSV },
  { label: 'JSON', value: EXPORT_TYPE.JSON },
];

export const getDropdownValues = (key: string, values: any) => {
  if (values === DROPDOWN_TYPE_ENUM.DATE_RANGE) return CREATED_OPTIONS;
  const formattedArray = values?.map((val: any) => {
    return {
      label: key,
      value: val,
    };
  });
  return formattedArray;
};

export const getActionsValue = (
  accumulatorArray: {
    label: string;
    value: string;
  }[],
  contextValue: any,
) => {
  let values: any = [];
  if (accumulatorArray.length) {
    accumulatorArray.forEach((val: any) => {
      values = [...values, ...getDropdownValues('Actions', contextValue[val.value])];
    });
  } else values = getDropdownValues('Actions', Object.values(contextValue).flat());

  return values;
};

export const truncate = (input: string, maxLength = 25) =>
  input?.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const getDateFilterValues = (
  created: DateFilterChoice,
  startDate?: string | null,
  endDate?: string | null,
) => {
  if (created) {
    switch (created) {
      case DateFilterChoice.Days: {
        return {
          startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          endDate: moment(Date.now()).format('YYYY-MM-DD'),
        };
      }
      case DateFilterChoice.Month: {
        return {
          startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          endDate: moment(Date.now()).format('YYYY-MM-DD'),
        };
      }
      case DateFilterChoice.Week: {
        return {
          startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
          endDate: moment(Date.now()).format('YYYY-MM-DD'),
        };
      }
      case DateFilterChoice.Last7Days: {
        return {
          startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          endDate: moment(Date.now()).format('YYYY-MM-DD'),
        };
      }
      case DateFilterChoice.Last14Days: {
        return {
          startDate: moment().subtract(14, 'days').format('YYYY-MM-DD'),
          endDate: moment(Date.now()).format('YYYY-MM-DD'),
        };
      }
      case DateFilterChoice.Last30Days: {
        return {
          startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
          endDate: moment(Date.now()).format('YYYY-MM-DD'),
        };
      }
      case DateFilterChoice.Range: {
        return {
          startDate:
            startDate !== null
              ? moment(startDate).format('YYYY-MM-DD')
              : moment().subtract(1, 'days').format('YYYY-MM-DD'),
          endDate:
            endDate !== null
              ? moment(endDate).format('YYYY-MM-DD')
              : moment(Date.now()).format('YYYY-MM-DD'),
        };
      }
    }
  } else
    return {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };
};

export function cleanObject(obj: any) {
  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const clean = (obj: any) => {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      } else if (Array.isArray(obj[key])) {
        if (obj[key].length === 0) {
          delete obj[key];
        } else {
          obj[key] = obj[key]
            .map((item: any) => {
              if (typeof item === 'object' && item !== null) {
                return clean(item);
              }
              return item;
            })
            .filter((item: any) => !(Array.isArray(item) && item.length === 0));
        }
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        clean(obj[key]);
        if (isEmptyObject(obj[key])) {
          delete obj[key];
        }
      }
    }
    return obj;
  };

  return clean(obj);
}

export const checkIfValueExists = (
  arrayPool: { label: string; value: string }[],
  newMember: { label: string; value: string },
) => {
  const filteredArray = arrayPool.filter(item => item.value === newMember.value);
  if (filteredArray.length) return true;
  return false;
};

export const downloadJSON = (data: any, filename: string, cb: () => void) => {
  const jsonString = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
  cb();
};

export function replaceValues(
  obj: { [key: string]: any },
  replacements: { [key: string]: any },
): { [key: string]: any } {
  const updatedObj = { ...obj };

  for (const [key, value] of Object.entries(replacements)) {
    if (key in updatedObj) {
      updatedObj[key] = value;
    }
  }

  return updatedObj;
}

export const generateFilterLabels = (filters: any, showDateFilters: boolean) => {
  let mappedFilters: any = [];
  Object.keys(filters).map(val => {
    if (['pageNumber', 'pageSize', 'isExplicitlySet', 'endDate'].includes(val)) return;
    else if (Array.isArray(filters[val])) {
      filters[val].map((ele: any) => {
        mappedFilters = [
          ...mappedFilters,
          {
            key: val,
            label: toSentenceCase(val),
            value: ele?.value ? ele?.value : ele,
            displayValue: val === 'actor' || val === 'team' ? ele.label : ele.value,
          },
        ];
      });
    } else {
      if (val === 'startDate' && filters['startDate']) {
        mappedFilters = [
          ...mappedFilters,
          {
            key: 'Date Filter',
            label: `Date Range`,
            value: moment(filters[val]).format('YYYY-MM-DD'),
            displayValue: `${moment(filters['startDate']).format('MMM DD, YYYY')} - ${moment(
              filters['endDate'],
            ).format('MMM DD, YYYY')}`,
          },
        ];
      } else if (filters[val]) {
        mappedFilters = [
          ...mappedFilters,
          {
            key: val,
            label: toSentenceCase(val),
            value: filters[val],
            displayValue: filters[val],
          },
        ];
      }
    }
  });
  if (showDateFilters) return mappedFilters;
  else
    return mappedFilters.filter(
      (filter: any) => filter.key !== 'startDate' && filter.key !== 'endDate',
    );
};

export function onlyUnique(value: any, index: any, array: any) {
  return array.indexOf(value) === index;
}

export function capitalizeFirstLetter(str: string) {
  return toSentenceCase(str);
}

export const buildFilterObjectForTable = (filterObj: Filters) => {
  const filterArray: any = [];
  Object.keys(filterObj).forEach(key => {
    const typedKey = key as keyof Filters;
    const value = filterObj[typedKey];
    if (filterObj[typedKey] !== null) {
      if (Array.isArray(value)) {
        value.forEach((item: any) => {
          if (typedKey === 'actor') filterArray.push(`${typedKey}- ${item.fullName}`);
          else if (typedKey === 'team') filterArray.push(`${typedKey}- ${item.name}`);
          else filterArray.push(`${typedKey}- ${item}`);
        });
      } else {
        if (typedKey === 'startDate' || typedKey === 'endDate')
          filterArray.push(
            `${typedKey} - ${moment(filterObj[typedKey], 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format(
              'MMM DD, YYYY',
            )}`,
          );
        else filterArray.push(`${typedKey} - ${filterObj[typedKey]}`);
      }
    }
  });

  return {
    filterTags: filterArray.slice(0, 2),
    overflowElements: filterArray.slice(2),
  };
};

export const getErrorMessage = (message: string) => {
  if (message.length) return message;
  return 'Opps!! Something went wrong';
};
