import { Grid, Para, TextButton, Theme } from 'uie/components';
import { TAG_MENU_MAX_WIDTH } from 'core/const/immutables';
import { truncateTagLabel } from 'core/helpers/stringUtils';
import React, { ReactNode, useMemo, useState } from 'react';
import { ActionMeta, GroupBase, OnChangeValue, SingleValue, StylesConfig } from 'react-select';
import CreatableReactSelect, { CreatableProps } from 'react-select/creatable';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';

const { theme } = Theme;

const customStyles: StylesConfig<any, boolean> = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
  }),
  option: provided => ({
    ...provided,
    wordBreak: 'break-word',
  }),
  control: provided => ({
    ...provided,
    margin: 0,
    padding: 5,
    border: 0,
    minWidth: 200,
    boxShadow: 'none',
  }),

  menu: () => ({ boxShadow: `inset 0 1px 0 ${theme.shades.lightGrey}` }),
};

type IProps = {
  value: { key: string; value: string };
  keysOptions: string[];
  valuesMap: { [key: string]: string[] };
  handleChange?: (key: string, value: string) => void;
  errorMsg?: string;
};

const formatKeyOption: (
  data: any,
  formatOptionLabelMeta: FormatOptionLabelMeta<any>,
) => React.ReactNode = ({ label, valuesCount }, { context }) => (
  <Grid type="column">
    <Grid>{label}</Grid>
    {valuesCount && context !== 'value' && (
      <Grid style={{ color: theme.shades.grey, fontStyle: 'italic', fontSize: '12px' }}>
        {valuesCount} {valuesCount > 1 ? 'values' : 'value'}
      </Grid>
    )}
  </Grid>
);

const TagGroup: React.FC<IProps> = ({ keysOptions, valuesMap, value, ...props }) => {
  const keysOptionsFormat = useMemo(
    () =>
      keysOptions.map(key => ({
        value: key,
        label: key,
        valuesCount: valuesMap[key]?.length || 0,
      })),
    [keysOptions, valuesMap],
  );

  const valuesOptions = useMemo(
    () =>
      valuesMap[value.key]?.map(value => ({
        value: value,
        label: value,
      })) || [],
    [valuesMap, value],
  );

  const onUpdate = (type: 'key' | 'value') => (selected: any) => {
    const newKey = type === 'key' ? selected.value : value.key;
    let newValue = type === 'value' ? selected.value : value.value;

    if (type === 'key') {
      newValue = '';
    }

    props.handleChange && props.handleChange(newKey, newValue);
  };

  return (
    <Grid type="column" width="100%">
      <Grid width="100%" style={{ gap: '16px' }}>
        <DropdownContainer
          isMulti={false}
          type="key"
          selected={value.key}
          menuPlaceholder="Key"
          value={value.key ? [{ label: value.key, value: value.key }] : []}
          onChange={onUpdate('key')}
          formatOptionLabel={formatKeyOption}
          options={keysOptionsFormat}
          placeholder="Search Existing Keys"
        />

        <DropdownContainer
          isMulti={false}
          type="value"
          selected={value.value}
          menuPlaceholder="Value"
          value={value.value ? [{ label: value.value, value: value.value }] : []}
          onChange={onUpdate('value')}
          options={valuesOptions}
          placeholder="Search Existing Values"
        />
      </Grid>

      {props.errorMsg && (
        <Para fontSize={12} style={{ marginTop: '4px', color: theme.danger.default }}>
          {props.errorMsg}
        </Para>
      )}
    </Grid>
  );
};

type DropdownContainerProps = {
  selected: string;
  menuPlaceholder: string;
  onClose?: () => void;
  isInitializedOpen?: boolean;
  type: 'key' | 'value';
} & CreatableProps<ICreatableOption, false, GroupBase<ICreatableOption>>;

export interface ICreatableOption {
  value: string;
  label: string;
}

export const DropdownContainer: React.FC<DropdownContainerProps> = ({
  selected,
  menuPlaceholder,
  onClose,
  isInitializedOpen,
  onChange,
  type,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitializedOpen || false);
  const toggleOpen = () => {
    if (isOpen) onClose?.();

    setIsOpen(!isOpen);
  };

  const handleChange = (
    newValue: SingleValue<ICreatableOption>,
    actionMeta: ActionMeta<ICreatableOption>,
  ) => {
    toggleOpen();
    onChange?.(newValue, actionMeta);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <TextButton
          onClick={toggleOpen}
          style={{
            background: theme.shades.white,
            minWidth: 150,
            width: '100%',
            padding: '8px 10px',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Para fontSize={14}>
            {truncateTagLabel(selected) || (
              <span style={{ color: theme.shades.lightGrey }}>{menuPlaceholder}</span>
            )}
          </Para>

          <img className="ml-10" width={10} src="/icons/dropdown.svg" alt="down" />
        </TextButton>
      }
    >
      <CreatableReactSelect
        autoFocus
        backspaceRemovesValue={false}
        closeMenuOnSelect={false}
        noOptionsMessage={() => `No ${type}s found. Type and add a new one`}
        formatCreateLabel={(inputValue: string) => (
          <Grid type="column">
            {`+ ${inputValue}`}
            <Para fontSize={10} style={{ marginTop: '12px', fontWeight: 600 }}>
              Click + to add custom {type}
            </Para>
          </Grid>
        )}
        menuIsOpen
        controlShouldRenderValue={false}
        styles={customStyles}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={handleChange}
        {...props}
      />
    </Dropdown>
  );
};

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = theme.shades.lightGrey;
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        fontSize: 14,
        maxWidth: TAG_MENU_MAX_WIDTH,
      }}
      {...props}
    />
  );
};

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
interface DropdownProps {
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
  children: ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ children, isOpen, target, onClose }) => (
  <div
    style={{
      position: 'relative',
      border: `1px solid ${theme.shades.lightGrey}`,
      borderRadius: 4,
      width: '100%',
    }}
  >
    <Grid style={{ alignItems: 'center' }}>{target}</Grid>
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export default TagGroup;
