import { useOutsideClick } from '@chakra-ui/hooks';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/popover';
import { Portal } from '@chakra-ui/react';
import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/slider';
import { PauseBlue, PlayBlue, SpeakerBlue } from 'icons';
import { useAutoAnimate } from 'library/atoms/AutoAnimate/useAutoAnimate';
import React, { useState, useRef, useEffect } from 'react';
interface AudioPlayerProps {
  audioUrl: string;
  isTable: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl, isTable }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1); // Default volume is set to maximum
  const audioRef = useRef<HTMLAudioElement>(null);
  const { element } = useAutoAnimate();
  const speakerRef = useRef(null);
  const [showVolume, setShowVolume] = useState(false); // State to control the display of volume control

  useOutsideClick({
    ref: speakerRef,
    handler: () => setShowVolume(false),
  });

  const togglePlay = () => {
    const audioElements = document.querySelectorAll<HTMLAudioElement>('[id=table_audio]');

    audioElements.forEach(element => {
      if (element !== audioRef.current) {
        element.pause();
      } else {
        element.play();
      }
    });

    setShowVolume(false);
  };
  const pausePlay = () => {
    audioRef.current?.pause();
  };

  useEffect(() => {
    if (isPlaying)
      if (currentTime === duration) {
        setIsPlaying(false);
        setShowVolume(false);
      } else {
        setIsPlaying(true);
      }
  }, [currentTime, duration]);

  const toggleVolume = () => {
    setShowVolume(!showVolume);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleVolumeChange = (val: number) => {
    setVolume(val);
    if (audioRef.current) {
      audioRef.current.volume = val;
    }
  };

  const handleTimelineChange = (value: number) => {
    setCurrentTime(value);
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Flex alignItems="center" position="relative">
      <audio
        ref={audioRef}
        src={audioUrl}
        id="table_audio"
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        controls={false}
      />
      <Box>
        {audioRef.current?.paused ? (
          <PlayBlue cursor="pointer" onClick={togglePlay} />
        ) : (
          <PauseBlue cursor="pointer" onClick={pausePlay} />
        )}
      </Box>
      <Box ml={1}>
        <Flex alignItems="center" ref={element}>
          <Text>{formatTime(currentTime)}</Text>&nbsp;/&nbsp;<Text>{formatTime(duration)}</Text>
          {!audioRef.current?.paused && (
            <Slider
              min={0}
              max={duration}
              step={0.01}
              value={currentTime}
              onChange={handleTimelineChange}
              width="100px"
              ml={4}
            >
              <SliderTrack bg="lightgray">
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={2} />
            </Slider>
          )}
        </Flex>
      </Box>
      <Box ml="2">
        <Popover isOpen={showVolume}>
          <PopoverTrigger>
            <Box onClick={toggleVolume} cursor="pointer" ml={2} ref={speakerRef}>
              <SpeakerBlue />
            </Box>
          </PopoverTrigger>
          {isTable ? (
            <Portal>
              <PopoverContent p={2} w="fit-content">
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={volume}
                  onChange={val => handleVolumeChange(val)}
                  orientation="vertical"
                  minH="20"
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={2} />
                </Slider>
              </PopoverContent>
            </Portal>
          ) : (
            <PopoverContent p={2} w="fit-content">
              <Slider
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={val => handleVolumeChange(val)}
                orientation="vertical"
                minH="20"
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={2} />
              </Slider>
            </PopoverContent>
          )}
        </Popover>
      </Box>
    </Flex>
  );
};

export default AudioPlayer;
