import {
  ContainerLoad,
  Grid,
  Heading,
  Para,
  Theme,
  Checkbox,
  TextButton,
  SpinLoader,
  DialogModalFrame,
  Label,
  FocusedSearch,
} from 'uie/components';
import React from 'react';
import { OrganizationPermissionsTab } from './index';
import { userPermissionsLabels } from './const';
import ConfirmModal from '../../../../../components/confirm.modal';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_PERMISSIONS_PAGE_VIEWED } from '../../../../../core/const/tracker';
import RouteLeavingGuard from 'components/UnsavedChangeNavigation';
import { ProfileIcon, UsernameIcon, NotificationGreyEmail } from '../../../../../icons';
import { IOrgConfig } from 'core/interfaces/IOrgConfig';

export function render(this: OrganizationPermissionsTab) {
  const { theme } = Theme;
  const { organization, roles, userInfo } = this.props;
  const {
    userAbilityMap,
    userAbilityChangeIdMap,
    componentNetworkState,
    changesSaved,
    showRemoveUserAbilityAlert,
    searchQuery,
  } = this.state;

  let orgUsers = organization.users.u;

  const orgConfig = organization.currentOrg.o?.config || ({} as IOrgConfig);

  try {
    const searchRegex = new RegExp(searchQuery, 'gi');
    orgUsers = organization.users.u.filter(u =>
      searchRegex.test(`${u.first_name} ${u.last_name} ${u.role} ${u.email}`),
    );
  } catch (_) {}

  AppTracker.track(T_WA_GS_PERMISSIONS_PAGE_VIEWED);

  const permissions = userPermissionsLabels(orgConfig);

  return (
    <>
      <ContainerLoad isLoading={componentNetworkState !== 'idle'} />
      <Grid
        type="column"
        flexWidth={12}
        className="org_settings__container_padding global--soft-scroll"
      >
        <Grid height="40px" justifyContent="space-between" flexWidth={12} alignItems="baseline">
          <div>
            <Heading fontSize={20} height={32} fontWeight={500} color={theme.shades.black}>
              Organization Level Permissions
            </Heading>
            <Para fontSize={12} color={theme.shades.grey}>
              Manage organization-level access within your account. Learn more about permissions{' '}
              <a
                href="https://support.squadcast.com/manage-users/user-permissions-access-controls"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </Para>
          </div>
          <Grid alignItems="center">
            {changesSaved && (
              <Para fontSize={14} color={theme.shades.grey} className="mr-10">
                Changes are saved!
              </Para>
            )}
            <FocusedSearch
              value={searchQuery}
              onChange={this.onSearch}
              height="32px"
              width="150px"
              fontSize="14px"
              placeholder="search"
            />
            {userAbilityChangeIdMap.length > 0 && (
              <TextButton
                className="ml-10"
                height="32px"
                onClick={this.confirmUpdateUserPermission}
                disabled={componentNetworkState === 'update-user-permissions'}
              >
                <Grid alignItems="center">
                  <Para color={theme.shades.white}>Update permissions</Para>
                  {componentNetworkState === 'update-user-permissions' && (
                    <SpinLoader base="8px" color={theme.shades.white} />
                  )}
                </Grid>
              </TextButton>
            )}
          </Grid>
        </Grid>
        {orgUsers.length === 0 && (
          <Para color={theme.shades.grey}>Search did not match any users</Para>
        )}
        {orgUsers.length > 0 && (
          <Grid
            flexWidth={12}
            alignItems="baseline"
            className="mt-20 organizationPermissionsTab_label__container"
          >
            <Grid flexWidth={3}>
              <Label fontWeight={500}>Name</Label>
            </Grid>
            <Grid flexWidth={8} alignItems="flex-end" justifyContent="space-between">
              {permissions.map(p => (
                <Grid flexWidth={12} key={p.accessor}>
                  <Label fontWeight={500}>{p.label}</Label>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {orgUsers.map((user, i) => {
          const userRole = roles.r.find(r => r._id === user.role_id);

          if (userRole?.name === 'Stakeholder') return false;

          const isAccountOwner = userRole?.name === 'Account Owner';
          const userAbilities = userAbilityMap.find(u => u.user_id === user.id)!;

          return (
            <Grid key={i} flexWidth={12} className="organizationPermissionsTab_user__container">
              <Grid flexWidth={3}>
                <div>
                  <Para fontSize={14} fontWeight={400} style={{ maxWidth: '27ch' }}>
                    {user.first_name} {user.last_name} {user.id === userInfo.d?.id && '· you'}
                  </Para>
                  <Grid type="column" alignItems="flex-start" className="mt-5">
                    <Grid alignItems="flex-start">
                      <NotificationGreyEmail
                        height={12}
                        width={12}
                        style={{ marginRight: 5, marginTop: 4 }}
                      />
                      <Para fontSize={12} color={theme.font.label} style={{ maxWidth: '27ch' }}>
                        {user.email}
                      </Para>
                    </Grid>
                    {user.username_for_display && (
                      <Grid alignItems="flex-start">
                        <UsernameIcon
                          height={12}
                          width={12}
                          style={{ marginRight: 5, marginTop: 4 }}
                        />
                        <Para fontSize={12} color={theme.font.label} style={{ maxWidth: '27ch' }}>
                          {user.username_for_display ?? ''}
                        </Para>
                      </Grid>
                    )}
                    <Grid alignItems="flex-start">
                      <ProfileIcon
                        height={12}
                        width={12}
                        stroke={theme.shades.grey}
                        style={{ marginRight: 5, marginTop: 4 }}
                      />
                      <Para fontSize={12} color={theme.font.label}>
                        {userRole?.name || ''}
                      </Para>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid flexWidth={8} alignItems="center" justifyContent="space-between">
                {permissions.map(p => {
                  const isPermissionGranted = userAbilities.abilities.includes(p.accessor);
                  return (
                    <Grid flexWidth={12} key={p.accessor}>
                      <Checkbox
                        disabled={isAccountOwner}
                        checked={isAccountOwner || isPermissionGranted}
                        onChange={this.updateUserPermission(user.id, p.accessor)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <RouteLeavingGuard
        when={this.state.isModalDirty}
        shouldBlockNavigation={() => true}
        navigate={path => this.props.history.push(path)}
      />
      <DialogModalFrame
        id="user_permission__show_remove_user_ability_self"
        onClose={this.setShowRemoveUserAbilityAlert('agreed')}
        width="400px"
      >
        {showRemoveUserAbilityAlert === 'set' && (
          <ConfirmModal
            buttonText="Ok"
            displayText="On removing this permission you will no longer be able to manage user permissions / users tab in settings"
            hide={this.setShowRemoveUserAbilityAlert('unset')}
          />
        )}
      </DialogModalFrame>
    </>
  );
}
