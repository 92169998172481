import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { useParams } from 'react-router';
import LCRProviderWrapper from '../../components/LCRProviderWrapper';
import { LCRFormType } from '../../types/LCR.types';
import LcrForm from './components/formComponent/lcr.form';
import { LCRFormValidation } from './helper/validation';
import { LCRDefaultValue } from '../../common/constant';
const LCRUpdate = () => {
  return (
    <LCRProviderWrapper title="Edit New Routing Number">
      <FormWrapper<LCRFormType>
        // validationSchema={LCRFormValidation}
        defaultValues={LCRDefaultValue}
      >
        <LcrForm editMode={true} />
      </FormWrapper>
    </LCRProviderWrapper>
  );
};

const LCRCreate = () => {
  return (
    <LCRProviderWrapper title="Add New Routing Number">
      <FormWrapper<LCRFormType>
        // validationSchema={LCRFormValidation}
        defaultValues={LCRDefaultValue}
      >
        <LcrForm editMode={false} />
      </FormWrapper>
    </LCRProviderWrapper>
  );
};
function CreateOrUpdateLCR() {
  const params = useParams<{ id: string }>();
  return params.id ? <LCRUpdate /> : <LCRCreate />;
}

export default CreateOrUpdateLCR;
