import { Box, Flex } from '@chakra-ui/layout';
import { RepeatIcon } from '@chakra-ui/icons';

import { useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SlackOption, SlackMessageAction } from 'views/main/organization/workflow/types';
import { MdBlock } from 'uie/components';
import { useEffect, useMemo, useState } from 'react';
import { Radio, RadioGroup, IconButton, Stack, Text, Input } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { useGetSlackChannels } from 'views/main/organization/settings/integrations/slack-v2/hooks/getSlackChannels';
import FormField from 'library/molecules/FormField/FormField';

const MessageChannelForm = () => {
  const [channelType, setChannelType] = useState<string>('channel_selection');
  const slackChannelRes = useGetSlackChannels();

  const {
    formState: { isDirty, errors },
    setValue,
    getValues,
    watch,
    trigger,
  } = useFormContext<SlackMessageAction>();
  const { setIsDirty } = useWorkflowFormContext();

  const slackChannel = watch('data.channel_id');

  const channelOptions = useMemo(() => {
    return slackChannelRes.data?.channels.map(channel => {
      const option: SlackOption = {
        label: '#' + channel.name,
        value: channel.id,
      };
      return option;
    });
  }, [slackChannelRes.isSuccess, slackChannelRes.isRefetching]);
  const selectedChannel = channelOptions?.find(channel => channel.value === slackChannel);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  return (
    <Flex px={6} pt={4} flexDirection="column">
      <Box mb={3} style={{ position: 'relative', zIndex: 2 }}>
        <FormField
          label="Slack Channel"
          error={errors?.data?.channel_id && errors.data.channel_id.message}
        >
          <RadioGroup
            mt={1}
            onChange={e => {
              setChannelType(e);
            }}
            value={channelType}
            w="20rem"
          >
            <Stack direction={'column'}>
              <Radio size="md" value={'channel_id'} isChecked={'channel_id' === channelType}>
                <Text fontSize="sm">Channel ID</Text>
              </Radio>
              <Radio
                size="md"
                value={'channel_selection'}
                isChecked={'channel_selection' === channelType}
                fontSize="sm"
              >
                <Text fontSize="sm">Select a channel</Text>
              </Radio>
            </Stack>
          </RadioGroup>

          {channelType === 'channel_selection' ? (
            <Flex alignItems={'center'} mt={5}>
              <Box w="20rem" mr={2}>
                <ReactSelect
                  onChange={o => {
                    setValue('data.channel_id', o!.value, { shouldDirty: true });
                    trigger(`data.channel_id`);
                  }}
                  value={selectedChannel}
                  options={channelOptions}
                  placeholder="Select Channel"
                  isLoading={slackChannelRes.isLoading}
                />
              </Box>
              <IconButton
                icon={<RepeatIcon />}
                aria-label={'refresh channels'}
                onClick={() => {
                  slackChannelRes.refetch();
                }}
              />
            </Flex>
          ) : (
            <Box w="20rem" mt={5}>
              <Input
                placeholder="Enter channel id"
                value={getValues('data.channel_id')}
                onChange={e => {
                  setValue('data.channel_id', e.target.value, { shouldDirty: true });
                }}
              />
            </Box>
          )}
        </FormField>
      </Box>
      <Box mb={2}>
        <FormField
          label="Message"
          error={
            typeof errors?.data?.message === 'object' && 'message' in errors.data.message
              ? errors.data.message.message
              : ''
          }
        >
          <MdBlock
            value={getValues('data.message')}
            onChange={content => setValue('data.message', content, { shouldDirty: true })}
            options={{
              hideIcons: ['upload-image', 'upload-file'],
              maxHeight: '130px',
              spellChecker: false,
              placeholder: 'Eg: {{event.incident_id}} triggered',
            }}
          />
        </FormField>
      </Box>
    </Flex>
  );
};

export default MessageChannelForm;
