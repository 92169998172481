import { Box, Flex } from '@chakra-ui/layout';
import { useFormContext } from 'react-hook-form';
import { MSTeamMessageUserAction, MsTeamOption } from 'views/main/organization/workflow/types';
import ReactSelect from 'react-select';
import { MdBlock } from 'uie/components';
import { useGetMsTeamUserList } from 'views/main/organization/workflow/details/hooks/useCheckMsTeamStatus';
import { useMemo } from 'react';
import FormField from 'library/molecules/FormField/FormField';
const MessageTeamUserForm = () => {
  const {
    formState: { errors },
    setValue,
    trigger,
    watch,
    getValues,
  } = useFormContext<MSTeamMessageUserAction>();

  const teamUser = watch('data.member_id');

  const teamUsersList = useGetMsTeamUserList();

  const userOptions = useMemo(() => {
    return teamUsersList?.data?.map(user => {
      const option: MsTeamOption = {
        label: user.name + ' ' + `(${user.email})`,
        value: user.id,
      };
      return option;
    });
  }, [teamUsersList]);

  const selectedUser = userOptions?.find(user => user?.value === teamUser);
  return (
    <Flex px={6} pt={4} flexDirection="column">
      <Box mb={3} style={{ position: 'relative', zIndex: 2 }}>
        <FormField
          label="Select Microsoft Teams User"
          error={errors?.data?.member_id && errors.data.member_id.message}
        >
          <Box w="20rem" mr={2}>
            <ReactSelect
              onChange={o => {
                setValue('data.member_id', o!.value, { shouldDirty: true });
                trigger(`data.member_id`);
              }}
              value={selectedUser}
              options={userOptions}
              placeholder="Select User"
            />
          </Box>
        </FormField>
      </Box>
      <Box mb={2}>
        <FormField
          label="Message"
          error={
            typeof errors?.data?.message === 'object' && 'message' in errors.data.message
              ? errors.data.message.message
              : ''
          }
        >
          <MdBlock
            value={getValues('data.message')}
            onChange={content => setValue('data.message', content, { shouldDirty: true })}
            options={{
              hideIcons: ['upload-image', 'upload-file'],
              maxHeight: '130px',
              spellChecker: false,
              placeholder: 'Eg: {{event.incident_id}} triggered',
            }}
          />
        </FormField>
      </Box>
    </Flex>
  );
};

export default MessageTeamUserForm;
