import { useDisclosure } from '@chakra-ui/react';
import { useToast } from 'library/atoms';
import { FormikForm } from 'library/molecules';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import { truncate, getTimeZone } from '../../common/util';
import { useUpdateIncidentGroupMutation } from '../../graphql/mutation';
import { filterManager } from '../manager';
import { INITIAL_FILTERS_STATE } from '../manager/constant';
import { invalSavedFilters } from '../manager/util';
import { FormTemplate } from './FormTemplate';
import { SaveFilter } from './SaveFilter';
import { getIncidentFilterObj, setIncidentFilterCompleteObj } from './temp-store';
import { validateFilterForm } from './validator';

import type { Filters, SavedFilter } from '../../interfaces/filters';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_FILTER_APPLIED } from 'core/const/tracker';
import { connect } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { cleanObject } from './options';

type Props = {
  onClose: () => void;
  onSuccess: (savedFilter?: SavedFilter) => void;
  filterObj?: SavedFilter | null;
  timeZone: string;
  teamId: string;
};

export const FilterForm = memo(({ onClose, onSuccess, filterObj, teamId, timeZone }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [filterFormData, setFiltersFormData] = useState<Filters | undefined>();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const [saveFilterData, setSaveFilterData] = useState<Filters>();

  const onCancelfn = useCallback(() => {
    filterManager.clearFilters(queryClient);
    onClose();
  }, []);

  const { mutateAsync: updateIncidentGroup, isLoading: isUpdateLoading } =
    useUpdateIncidentGroupMutation();

  const getFilterFormObj = (): Filters => {
    let tempObj = getIncidentFilterObj();
    if (!tempObj) {
      tempObj = getFilter();
      setIncidentFilterCompleteObj(tempObj);
    }
    (tempObj as Filters).tagsKey = null;
    return tempObj;
  };

  const getFilter = (): Filters => {
    if (filterFormData) {
      return filterFormData;
    }
    if (filterObj) {
      return {
        ...filterObj.filters,
        id: filterObj.id,
        name: filterObj.name,
      } as Filters;
    }
    const appliedFilter = filterManager.getUIFilters();
    // If saved filter is applied then form shouldn't render filters value by default
    return appliedFilter.id ? INITIAL_FILTERS_STATE : appliedFilter;
  };

  const onUpdate = useCallback((filters: Filters) => {
    setFiltersFormData({ ...filters });
    const apiFilters = filterManager.getAPIFilterObj(filters, timeZone);
    const name = filters.name ?? '';

    const payload = {
      incidentGroupID: filterObj?.id ?? 0,
      name,
      filters: apiFilters,
    };
    updateIncidentGroup(payload)
      .then(() => {
        toast({
          status: 'success',
          text: `Success: '${truncate(name, 25)}' filter updated`,
        });
        invalSavedFilters(queryClient);
        onSuccess({
          filters,
          id: payload.incidentGroupID,
          name: payload.name,
        });
      })
      .catch(error => {
        toast({
          status: 'error',
          text: `${error}` ?? 'Error: Unable to update filter',
        });
      });
  }, []);

  const Filters = memo((props: any) => (
    <FormTemplate
      onClose={onClose}
      onCancel={onCancelfn}
      onSave={onSave}
      onCancelModel={onClose}
      teamId={teamId}
      onUpdate={onUpdate}
      isLoading={isUpdateLoading}
      timeZone={timeZone}
      {...props}
    />
  ));

  // Apply button handler
  const handleSubmit = (filters: Filters) => {
    setFiltersFormData({ ...filters });
    filterManager.applyFilters(filters, queryClient);
    const apiFilters = filterManager.buildAPIFilterModel(filters);
    const tags = {};
    if (apiFilters.tags) {
      apiFilters.tags.forEach(pair => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tags[pair?.key.toString()] = pair?.value;
      });
    }
    const getAnalyticsForm = (field: any) => {
      let obj: any = {};
      if (field?.users.length)
        obj = {
          ...obj,
          Users: field?.users.map((user: any) => user?.value),
        };
      if (field?.squads.length)
        obj = {
          ...obj,
          Squads: field?.squads.map((user: any) => user?.value),
        };
      if (field?.meAndMySquads) {
        obj = {
          ...obj,
          'Me and My Squads': field?.meAndMySquads,
        };
      }
      return obj;
    };

    const trackedAppliedFilter = {
      Responders: getAnalyticsForm(filters?.assignedTo),
      'Service Owner': getAnalyticsForm(filters?.serviceOwner),
      'Affected Service': apiFilters.serviceIDs,
      Tags: tags,
      'Alert Sources': filters.alert.map(alertSourceData => alertSourceData.label),
      Priority: filters.priority.map(priorityData => priorityData.value),
      'SLO Affecting': filters.isSlo ? (apiFilters.isSLO == 'no' ? 'No' : 'Yes') : null,
      'Inc with Postmortem': filters.withRetro
        ? apiFilters.hasRetrospectives == 'no'
          ? 'No'
          : 'Yes'
        : null,
      'Inc with Notes': filters.withNotes ? (apiFilters.hasNotes == 'no' ? 'No' : 'Yes') : null,
      'Starred Inc': filters.isStarred ? (apiFilters.isStarred == 'no' ? 'No' : 'Yes') : null,
    };

    AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_FILTER_APPLIED, {
      'Applied Filter': cleanObject(trackedAppliedFilter),
    });
    onClose();
  };

  // Save filter handler
  const onSave = (filters: Filters) => {
    setFiltersFormData({ ...filters });
    setSaveFilterData(filters);
    toggleModal();
  };

  return (
    <>
      <FormikForm
        validate={validateFilterForm}
        handleSubmit={handleSubmit}
        mapPropsToValues={getFilterFormObj}
        formTemplate={Filters}
      />
      <SaveFilter
        isOpen={isOpen}
        onClose={toggleModal}
        onFilterClose={onClose}
        onSuccess={() => {
          toggleModal();
          onSuccess();
        }}
        filters={saveFilterData ?? INITIAL_FILTERS_STATE}
        teamID={teamId}
        timeZone={timeZone}
      />
    </>
  );
});

export const FilterFormConnected = connect((state: IAppState) => ({
  timeZone: getTimeZone(state.organization),
  teamId: state.organization?.selectedTeam?.teamId,
}))(FilterForm);
