import { Box } from '@chakra-ui/layout';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler, ReactNode } from 'react';
import { FormProvider, useForm, UseFormProps, FieldValues, SubmitHandler } from 'react-hook-form';
import { AnySchema, ObjectSchema } from 'yup';

type Props<T extends FieldValues> = UseFormProps<T> & {
  onSubmit?: SubmitHandler<T>;
  children: ReactNode;
  validationSchema?: ObjectSchema<Record<keyof T, AnySchema>>;
};

export function FormWrapper<T extends FieldValues>({
  onSubmit,
  children,
  validationSchema,
  defaultValues,
}: Props<T>) {
  const formMethods = useForm<T>({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const { handleSubmit } = formMethods;

  const onSubmitWrapper: FormEventHandler<HTMLDivElement> & FormEventHandler<HTMLFormElement> =
    e => {
      e?.stopPropagation();
      e?.preventDefault();
      if (onSubmit) handleSubmit(onSubmit)();
    };

  return (
    <FormProvider {...formMethods}>
      <Box h="full" as="form" onSubmit={onSubmitWrapper}>
        {children}
      </Box>
    </FormProvider>
  );
}
