import { FormikProvider, useFormik } from 'formik';
import React, { useMemo, useRef, useCallback, useEffect } from 'react';
import { ScaleFade, HStack, Text } from '@chakra-ui/react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import Loader from 'components/chakra/Loader';
import { errorMessages } from '../../constants/errormessage';
import { INewSchedule } from '../../interface/schedule';
import EditRotations from './editRotations';
import { useGetScheduleForEditActionQuery } from 'views/main/organization/schedules/graphql/query';
import { useScheduleActionsContext } from '../../schedules.actions/context';
import { useUpdateScheduleMutation } from 'views/main/organization/schedules/graphql/mutation';
import {
  formatScheduleRequest,
  mapFetchScheduleToNewSchedule,
} from '../../helpers/helpers.schedule';
import { scheduleProperties } from '../../constants/schedules.properties';
import { rotationProperties } from '../../constants/rotations.properties';
import {
  reactQueryConfig,
  defaultReactQueryConfig,
  reactQueryConfigError,
} from '../../helpers/helpers.schedule';
import { OwnerType, UpdateSchedule } from 'views/main/organization/schedules/graphql/types';
import { useGetAllPatternParticipants } from '../../helpers/helpers.customrotations';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_SCHEDULES_V2_ROTATION_EDITED } from 'core/const/tracker';
import { participantGroupsValidationSchema } from '../../validations/participantGroupsSchema';

const initialScheduleValue = {
  name: '',
  timeZone: '',
  owner: { ID: '', type: OwnerType.User },
  description: '',
  rotations: [],
};

interface Props {
  scheduleId: string;
}

const EditSchedule = ({ scheduleId }: Props) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const allParticipants = useGetAllPatternParticipants();
  const {
    closeEditSchedule,
    resetParticipantsGroupToEdit,
    reFetchSchedulesData,
    reFetchOncallUsersData,
  } = useScheduleActionsContext();

  const { mutateAsync: updateSchedule } = useUpdateScheduleMutation({
    ...reactQueryConfig.mutation.update,
    onSuccess: () => {
      reactQueryConfig.mutation.update.onSuccess();
      reFetchSchedulesData();
      reFetchOncallUsersData();
    },
  });

  const {
    data: { schedule } = {},
    isLoading,
    isSuccess,
  } = useGetScheduleForEditActionQuery(
    {
      ID: Number.parseInt(scheduleId),
    },
    {
      ...defaultReactQueryConfig,
      onError: reactQueryConfigError('Get Schedule for Edit'),
      cacheTime: 0,
    },
  );

  const formikSchedule = mapFetchScheduleToNewSchedule(schedule, allParticipants);

  const validationSchema = {
    name: Yup.string()
      .required(errorMessages.schedule.name)
      .test({
        test: value => (value?.length ? value.length < scheduleProperties.name.maxLength : true),
        message: errorMessages.schedule.nameLength.replace(
          'NUM',
          `${scheduleProperties.name.maxLength}`,
        ),
      }),
    timeZone: Yup.string().required(errorMessages.schedule.timezone),
    description: Yup.string().test({
      test: value =>
        value?.length ? value.length < scheduleProperties.description.maxLength : true,
      message: errorMessages.schedule.descriptionLength.replace(
        'NUM',
        `${scheduleProperties.description.maxLength}`,
      ),
    }),
    rotations: Yup.array()
      .test({
        test: arr => {
          const isValidEndDate = arr?.every(rotation =>
            !rotation.endsAfterIterations && rotation.endDate
              ? rotation.startDate < rotation.endDate
              : true,
          );
          return isValidEndDate ? isValidEndDate : false;
        },
        message: errorMessages.rotations.startEndDate,
      })
      .test({
        test: arr => {
          const rotationNames = arr?.map(r => r.name);
          const uniqueRotationNames = Array.from(new Set(rotationNames));
          return rotationNames?.length === uniqueRotationNames.length;
        },
        message: errorMessages.rotations.duplicateNames,
      })
      .of(
        Yup.object().shape({
          name: Yup.string()
            .required(errorMessages.rotations.name)
            .test({
              test: value =>
                value?.length ? value.length < rotationProperties.name.maxLength : true,
              message: errorMessages.rotations.nameLength.replace(
                'NUM',
                `${rotationProperties.name.maxLength}`,
              ),
            }),
          color: Yup.string().required(errorMessages.schedule.colorScheme),
          participantGroups: participantGroupsValidationSchema,
          startDate: Yup.date().required(),
        }),
      ),
  };

  const formik = useFormik<INewSchedule>({
    enableReinitialize: true,
    initialValues: formikSchedule ?? initialScheduleValue,
    validateOnChange: false,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true);
        await updateSchedule({
          ID: Number.parseInt(scheduleId),
          input: formatScheduleRequest(values, false) as UpdateSchedule,
        });

        setSubmitting(false);
        resetForm({ values });
        queryClient.invalidateQueries(['getScheduleForEditAction']);
        closeEditSchedule();
        resetParticipantsGroupToEdit();
        AppTracker.track(T_WA_UP_SCHEDULES_V2_ROTATION_EDITED);
      } catch (error) {
        setSubmitting(false);
        console.log(error);
      }
    },
    validationSchema: Yup.object().shape(validationSchema),
  });

  if (!isLoading && !isSuccess) {
    return (
      <HStack justifyContent="center" mt={5}>
        <Text>The schedule information could not be displayed for editing!</Text>
      </HStack>
    );
  }

  return (
    <Loader.Spinner
      isLoading={isLoading}
      loadingMessage="Initializing Schedules ..."
      centered
      spinnerProps={{ size: 'lg' }}
    >
      <ScaleFade initialScale={0.9} in={isSuccess}>
        <FormikProvider value={formik}>
          <EditRotations />
        </FormikProvider>
      </ScaleFade>
    </Loader.Spinner>
  );
};

export default EditSchedule;
