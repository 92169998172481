import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { UseMutationOptions } from 'react-query';

import { PAGE_TYPES } from '../constants/schema';
import {
  UpdateStatusPageStatusMessageMutation,
  UpdateStatusPageStatusMessageMutationVariables,
  useUpdateStatusPageStatusMessageMutation,
} from '../graphql/mutation';
import { getPageInputDetails } from '../helpers/helper.service';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { usePageDetails } from './usePageDetails';

type onSubmit = () => void;

const useUpdatePageStatus = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    UpdateStatusPageStatusMessageMutation,
    TError,
    UpdateStatusPageStatusMessageMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageStatusMessageMutation({
    ...options,
    onSuccess: onSuccess,
    onError: reactQueryConfigError('Update status message'),
  });
};

const { initValues } = getPageInputDetails(PAGE_TYPES.STATUS_MESSAGE);

export function useStausTypeList(pageId: number, onSuccussHandler: onSubmit) {
  const { details, isSuccess } = usePageDetails(pageId);

  useEffect(() => {
    if (isSuccess && details) {
      initValues.status = details.statusCodes;
    }
  }, [isSuccess]);

  const { mutateAsync: updateStatusMessage } = useUpdatePageStatus(() => {
    onSuccussHandler();
  });

  const onSubmit = (values: any) => {
    const data = values.status.map((d: any) => {
      return { id: d.id, status: d.message };
    });
    updateStatusMessage({
      input: {
        pageID: pageId,
        data: data,
      },
    });
  };

  const formik = useFormik({
    initialValues: initValues,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  return {
    isSuccess: isSuccess,
    formik,
  };
}
