import { Box, HStack } from '@chakra-ui/react';
import { T_WA_AUDIT_LOGS_FILTERED } from 'core/const/tracker';
import { FormButton } from 'library/atoms';
import { CustomDrawerComponent } from 'library/molecules';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { useOrganizationConfig } from 'views/main/organization/schedules';
import { useAuditLogsListContext } from '../../context/auditLogsContext';
import { cleanObject, getDateFilterValues, onlyUnique } from '../../helpers';
import { DateFilterChoice, GetAuditLogsParamsType } from '../../types/index.auditLogs';
import FilterForm from './FilterForm';

interface AuditLogsProps {
  open: boolean;
  onClose: () => void;
}
interface Option {
  label: string;
  value: string;
}

function AuditLogsFilter(props: AuditLogsProps) {
  const { open, onClose } = props;
  const context = useAuditLogsListContext();

  const [resources, setResources] = useState<Array<Option>>([]);
  const [actions, setActions] = useState<Array<Option>>([]);
  const [team, setTeam] = useState<Array<Option>>([]);
  const [client, setClient] = useState<Array<Option>>([]);
  const [dateFilterValue, setDateFilterValue] = useState('');
  const [actor, setActor] = useState<Array<Option>>([]);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [lastNumberOfDays, setLastNumberOfDays] = useState(null);
  const [created, setCreated] = useState<any>(null);
  const [showDateFilterToggle, setShowDateFilterToggle] = useState(false);
  const [showDateError, setShowDateError] = useState(false);
  const org = useOrganizationConfig();

  const closeFilters = () => {
    setResources([]);
    setActions([]);
    setTeam([]);
    setClient([]);
    setDateFilterValue('');
    setActor([]);
    setStartDate(null);
    setEndDate(null);
    setLastNumberOfDays(null);
    setCreated(null);
    onClose();
  };

  useEffect(() => {
    setResources(context.appliedFilters.resource);
    setActions(context.appliedFilters.action);
    setActor(context.appliedFilters.actor);
    setActions(context.appliedFilters.action);
    setTeam(context.appliedFilters.team);
    setClient(context.appliedFilters.client);
    setStartDate(context.appliedFilters.startDate);
    setEndDate(context.appliedFilters.endDate);
  }, [context.appliedFilters]);

  useEffect(() => {
    setLastNumberOfDays(context.dateFilters?.lastNumberOfDays);
    setCreated(context.dateFilters?.created);
  }, [context.dateFilters]);

  const applyFilter = () => {
    const APIFilter: GetAuditLogsParamsType = {
      pageNumber: 1,
      pageSize: 10,
      resource: [...resources].filter(onlyUnique),
      actor: [...actor].filter(onlyUnique),
      team: [...team].filter(onlyUnique),
      client: [...client].filter(onlyUnique),
      action: [...actions].filter(onlyUnique),
      ...getDateFilterValues(created?.value, startDate, endDate),
    };
    const trackerObject: any = {
      resource: [...resources].filter(onlyUnique).map(resource => resource.value),
      actor: [...actor].filter(onlyUnique).map(actor => actor?.value),
      team: [...team].filter(onlyUnique).map(team => team?.value),
      client: [...client].filter(onlyUnique).map(client => client?.value),
      action: [...actions].filter(onlyUnique).map(action => action?.value),
      ...getDateFilterValues(created?.value, startDate, endDate),
    };
    if (
      created &&
      created?.value === DateFilterChoice.Range &&
      (startDate === null || endDate === null)
    ) {
      setShowDateError(true);
    } else {
      closeFilters();
      setShowDateError(false);
      context.applyFilters(cleanObject(APIFilter), closeFilters);
      AppTracker.track(T_WA_AUDIT_LOGS_FILTERED, cleanObject(trackerObject));
      context.setShowDateFilters(showDateFilterToggle);
      if (created !== null) {
        context.setDateFilters({
          lastNumberOfDays: lastNumberOfDays,
          created: created,
        });
      }
    }
  };

  const clearFilter = () => {
    const emptyStateValues = {
      ...context.appliedFilters,
      resource: [],
      actor: [],
      team: [],
      client: [],
      action: [],
      startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      endDate: moment(Date.now()).format('YYYY-MM-DD'),
    };
    context.setAppliedFilters({
      ...emptyStateValues,
      isExplicitlySet: false,
    });
    context.setDateFilters({
      lastNumberOfDays: null,
      created: null,
    });
    context.setShowDateFilters(false);
    context.clearAllFilters(emptyStateValues, closeFilters);
  };

  const isApplyButtonDisabled =
    !resources.length &&
    !actions.length &&
    !team.length &&
    !client.length &&
    !actor.length &&
    created === null;

  const isEditMode =
    Boolean(context.appliedFilters.resource.length) ||
    Boolean(context.appliedFilters.action.length) ||
    Boolean(context.appliedFilters.team.length) ||
    Boolean(context.appliedFilters.client.length) ||
    Boolean(context.appliedFilters.actor.length) ||
    context.dateFilters.created !== null;

  return (
    <CustomDrawerComponent
      size="md"
      title="Filters"
      isOpen={open}
      onClose={onClose}
      disableBodyPadding
      footer={
        <HStack spacing={3}>
          <FormButton
            disabled={isApplyButtonDisabled && !isEditMode}
            onClick={() => applyFilter()}
            type="submit"
            title="Apply"
          />
          <FormButton title="Cancel" variant="secondary" onClick={() => closeFilters()} />
          <FormButton title="Clear All" variant="ghost" onClick={() => clearFilter()} />
        </HStack>
      }
    >
      <Box sx={{ '& > form': { mb: 20 } }}>
        <Box>
          <FilterForm
            resources={[...resources].filter(onlyUnique)}
            setResources={setResources}
            actions={[...actions].filter(onlyUnique)}
            setActions={setActions}
            actor={[...actor].filter(onlyUnique)}
            setActor={setActor}
            team={[...team].filter(onlyUnique)}
            setTeam={setTeam}
            client={[...client].filter(onlyUnique)}
            setClient={setClient}
            dateFilterValue={dateFilterValue}
            setDateFilterValue={setDateFilterValue}
            userList={org?.organization?.users?.u}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            lastNumberOfDays={lastNumberOfDays}
            setLastNumberOfDays={setLastNumberOfDays}
            created={created}
            setCreated={setCreated}
            setShowDateFilterToggle={setShowDateFilterToggle}
            showDateError={showDateError}
            setShowDateError={setShowDateError}
          />
        </Box>
      </Box>
    </CustomDrawerComponent>
  );
}

export default AuditLogsFilter;
