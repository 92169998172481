import { DateTime } from 'luxon';
import { Grid, Heading, Para, TextButton, Theme } from 'uie/components';
import CalendarRangePicker from 'components/CalendarRangePicker';
import { Locale } from 'core/helpers/dateUtils';
import { IAppState } from 'core/interfaces/IAppState';
import { IAppBillingPlan } from 'core/interfaces/IBillingPlan';
import React, { useEffect, useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { shallowEqual, useSelector } from 'react-redux';
import { planFeatures } from 'store/billingPlans';

import { useLastUpdatedQuery } from '..';
import { useFilters } from '../Filters/Filters.context';
import { CalendarProvider } from '../Panel/CalendarPanel.context';
import Panel from '../Panel/Panel';
import PanelContainer from '../Panel/PanelContainer';
import { PanelObject } from '../types';
import FilterDrawer from '../Filters/FilterDrawer';
import { useSecondaryFilters } from 'components/SecondaryFilter/context';
import { SecondaryFiltersKeys, SecondaryFiltersType } from 'components/SecondaryFilter/types';
import { FilterOptions } from 'components/SecondaryFilter/const';
import { ServiceBadge } from 'components/SecondaryFilter/DialogFilter/filters/services';
import { UserBadge } from 'components/SecondaryFilter/DialogFilter/filters/users';
import useGetAllServices from 'core/hooks/useGetAllServices';
import { filterDropdownOptions } from '../../incident-list/interfaces/common';
import Badge from 'components/SecondaryFilter/DialogFilter/filters/badge';
import { ServiceOwnerBadge } from 'components/SecondaryFilter/DialogFilter/filters/serviceOwner';
import { filterObjectValues } from '../../incident-list/filters/tags/helper';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useServices } from '../hooks';

const { theme } = Theme;

const ResponsiveGridLayout = WidthProvider(Responsive);

type BillingPlan = 'Free' | 'Pro' | 'Enterprise' | IAppBillingPlan['name'];

type Props = {
  title: string;
  isTeamAnalytics?: boolean;
  panels: PanelObject[];
  hideFilters?: boolean;
};

const getMinDatePerPlan = (planType: BillingPlan) => {
  switch (planType) {
    case 'Pro':
      return new Date(new Date().setMonth(new Date().getMonth() - 12));
    case 'Enterprise':
      // TODO: use organization onboard date when available (currently using date when Squadcast started its operation)
      return new Date('2018-01-01');
    case 'Free':
    default:
      return new Date(new Date().setMonth(new Date().getMonth() - 3));
  }
};

function isJson(str: string) {
  let value = typeof str !== 'string' ? JSON.stringify(str) : str;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === 'object' && value !== null;
}

const Page: React.FC<Props> = ({ title, panels, hideFilters, isTeamAnalytics }) => {
  const { updateDateFilter, filters } = useFilters();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
  const queryParams = useLocation().search;
  const { data } = useLastUpdatedQuery();

  const { fetchServices } = useServices();
  const parseparams = qs.parse(queryParams);
  const urlFilters = useMemo(() => {
    const parsefilters = parseparams.filters;

    let urlfilters: SecondaryFiltersType = {};

    try {
      if (Array.isArray(parsefilters)) {
        urlfilters = JSON.parse(parsefilters[0]);
      } else {
        urlfilters = JSON.parse(parsefilters!);
      }
    } catch (error) {
      console.error(error);
    }

    return urlfilters;
  }, [queryParams]);
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const { filters: appliedSecondaryFilters, defaultFilters, updateFilter } = useSecondaryFilters();
  const selectedTeam = urlFilters?.teams?.length ? `${urlFilters?.teams[0]}` : `[]`;
  const servicesList = useGetAllServices(selectedTeam);

  const planType = useMemo(
    () =>
      (organization.plan.p && organization.plan.p.active
        ? planFeatures.plans.find(plan =>
            Object.values(plan).includes(organization.plan.p?.plan_slug),
          )?.name
        : 'Free') as BillingPlan,
    [organization],
  );

  const [selectedAssigne, setSelectedAssigne] = useState({
    myUsers: [],
    mySquads: [],
    users: [],
    squads: [],
    meAndMySquads: false,
  });
  const [tagsKey, setTagsKey] = useState<any>(null);
  const [tagsValue, setTagsValue] = useState<any>([]);
  const [keyValue, setKeysValue] = useState<any>([]);
  const [priority, setPriority] = useState<any>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [serviceOwner, setServiceOwner] = useState<{
    users: filterDropdownOptions[];
    squads: filterDropdownOptions[];
    myUsers: filterDropdownOptions[];
    mySquads: filterDropdownOptions[];
    meAndMySquads: boolean;
  }>({
    myUsers: [],
    mySquads: [],
    users: [],
    squads: [],
    meAndMySquads: false,
  });

  let filterQuery: string[] = [];
  if (typeof parseparams.filters === 'string' && isJson(parseparams.filters)) {
    filterQuery = JSON.parse(parseparams.filters).services ?? [];
  }

  const serviceFromQuery = servicesList
    .filter(service => filterQuery.includes(service.id))
    .map(service => ({ label: service.name, value: service.id }));

  const [services, setServices] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(() => {
    setServices(serviceFromQuery);
  }, [servicesList]);

  const layout = panels.map(panel => ({
    i: panel.id,
    x: panel.gridPos.x,
    y: panel.gridPos.y,
    w: panel.gridPos.w,
    h: panel.gridPos.h,
  }));

  const toggleFilterDrawer = () => {
    setEditMode(true);
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };
  const showService =
    appliedSecondaryFilters?.showServices && appliedSecondaryFilters?.showServices[0] === 'true'
      ? true
      : false;

  const activeFilteredListFiltered = useMemo(() => {
    const filtersWithData = Object.entries(appliedSecondaryFilters)
      .filter(
        ([filterKey]) => defaultFilters.hasOwnProperty(filterKey) && filterKey !== 'showServices',
      )
      .filter(([_, filterValue]) => !!filterValue.length);
    return filtersWithData as [SecondaryFiltersKeys, string[]][];
  }, [appliedSecondaryFilters]);

  const removeFilter = async (filter: any, key: string) => {
    if (key === 'services') {
      const updatedServices = services.filter((service: any) => service.value !== filter.id);
      setServices(updatedServices);
      updateFilter(
        key,
        updatedServices.map((val: any) => {
          return val.value;
        }),
      );
    }
    if (key === 'serviceOwner') {
      const filteredObj = filterObjectValues(serviceOwner, filter?.value);
      const checkArr = [
        ...filteredObj.mySquads,
        ...filteredObj.myUsers,
        ...filteredObj.squads,
        ...filteredObj?.users,
      ];
      setServiceOwner(filteredObj);
      updateFilter(
        key,
        checkArr.map((val: any) => {
          return val.value;
        }),
      );
      if (!showService) {
        const services = await fetchServices(
          checkArr.map((val: any) => {
            return val.value;
          }),
          selectedTeam,
        );
        setServices([]);
        if (checkArr.length > 0) {
          updateFilter(
            'services',
            services.map((val: any) => {
              return val.serviceID;
            }),
          );
        } else {
          updateFilter('services', []);
        }
      }
    }
    if (key === 'users') {
      const filteredObj = filterObjectValues(selectedAssigne, filter?.id);
      const checkArr = [
        ...filteredObj.mySquads,
        ...filteredObj.myUsers,
        ...filteredObj.squads,
        ...filteredObj?.users,
      ];
      setSelectedAssigne(filteredObj);
      updateFilter(
        key,
        checkArr.map((val: any) => {
          return val.value;
        }),
      );
    }
    if (key === 'squads') {
      const filteredObj = filterObjectValues(selectedAssigne, filter?.id);
      const checkArr = [
        ...filteredObj.mySquads,
        ...filteredObj.myUsers,
        ...filteredObj.squads,
        ...filteredObj?.users,
      ];
      setSelectedAssigne(filteredObj);
      updateFilter(
        key,
        checkArr.map((val: any) => {
          return val.value;
        }),
      );
    }
    if (key === 'priority') {
      const updatedPriority = priority.filter((val: any) => val.value !== filter);
      setPriority(updatedPriority);
      updateFilter(
        key,
        updatedPriority.map((val: any) => {
          return val.value;
        }),
      );
    }
    if (key === 'tags') {
      const updateTags = tagsValue.filter((val: any) => val.label !== filter);
      updateFilter(
        key,
        updateTags.map((val: any) => {
          return val.label;
        }),
      );
      setTagsValue(updateTags);
      setTagsKey([]);
      setKeysValue(updateTags);
    }
  };

  const secondaryFilters = hideFilters ? null : (
    <Grid className="mt-10" style={{ marginLeft: 20, rowGap: 12 }} flexWrap="wrap">
      {activeFilteredListFiltered.map(([filterKey, filterValue]) => {
        let label = `${FilterOptions[filterKey]}`;
        if (filterKey !== FilterOptions.priority.toLowerCase()) {
          label = label.slice(0, -1);
        }
        if (filterKey === 'squads') {
          label = 'Assignee';
        }
        if (filterKey === 'serviceOwner') {
          label = `Service Owner `;
        }
        const badgeProps = {
          label,
          items: filterValue,
          key: filterKey,
        };
        if (filterKey === 'services') {
          if (showService) {
            return (
              <ServiceBadge
                onRemoveTag={(val: any) => {
                  removeFilter(val, filterKey);
                }}
                showAccumlatedTag={false}
                {...badgeProps}
                services={servicesList}
              />
            );
          }
        } else if (filterKey === 'users' || filterKey === 'squads') {
          return (
            <UserBadge
              onRemoveTag={(val: any) => {
                removeFilter(val, filterKey);
              }}
              showAccumlatedTags={false}
              {...badgeProps}
              selectedTeam={selectedTeam}
            />
          );
        } else if (filterKey === 'serviceOwner') {
          return (
            <ServiceOwnerBadge
              onRemoveTag={(val: any) => {
                removeFilter(val, filterKey);
              }}
              {...badgeProps}
              showAccumlatedTags={false}
              selectedTeam={selectedTeam}
            />
          );
        } else if (filterKey === 'priority' || filterKey === 'tags') {
          return filterValue?.map(item => {
            return (
              <Badge
                {...badgeProps}
                key={item}
                items={[item]}
                onRemove={(val: any) => {
                  removeFilter(val, filterKey);
                }}
              />
            );
          });
        } else {
          return (
            <Badge
              {...badgeProps}
              onRemove={(val: any) => {
                removeFilter(val, filterKey);
              }}
            />
          );
        }
      })}
      <TextButton
        onClick={toggleFilterDrawer}
        style={{
          background: theme.shades.white,
          border: `1px solid ${theme.primary.default}`,
          boxShadow: 'none',
          minWidth: 100,
          padding: '6px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Para fontSize={14} color={theme.primary.default}>
          <span style={{ fontWeight: 600 }}>
            {activeFilteredListFiltered.length ? 'Update' : '+ Add Filter'}
          </span>
        </Para>
      </TextButton>
    </Grid>
  );

  return (
    <Grid type="column" style={{ background: theme.sidebar.open }}>
      <Grid
        justifyContent="space-between"
        flexWrap="wrap"
        className="mt-10"
        style={{ marginLeft: 20, marginRight: 20 }}
      >
        <Heading fontSize={27} color="#1D426B" style={{ marginTop: 20, marginBottom: 20 }}>
          {title}
        </Heading>
        <Grid alignItems="center">
          {data?.last_updated && (
            <Para fontSize={14} color={theme.shades.grey} style={{ marginRight: 12 }}>
              Data was last updated on{' '}
              <strong>{Locale.toShortDateTimeWithOffset(data.last_updated)}</strong>.
            </Para>
          )}
          <CalendarRangePicker
            startDate={filters.date.startDate.toJSDate()}
            endDate={filters.date.endDate.toJSDate()}
            onUpdateRange={updateDateFilter}
            minDate={
              //If data retention limit is fetched from BE, use it
              // If not, use the fallback date according to the org plan
              filters.date.dataRetentionLimit
                ? DateTime.fromSQL(filters.date.dataRetentionLimit).toJSDate()
                : getMinDatePerPlan(planType)
            }
            maxDate={new Date()}
          />
        </Grid>
      </Grid>
      <Heading
        fontSize={14}
        height={28}
        style={{
          fontWeight: 100,
          paddingBottom: '10px',
          marginBottom: '10px',
          marginLeft: '20px',
          fontSize: '12px',
          float: 'left',
          color: 'var(--shades-grey)',
        }}
      >
        {title === 'Organization Analytics' ? (
          <>
            View and download reports of historical data on your organization's performance. Learn
            more about organization analytics{' '}
            <a
              href="https://support.squadcast.com/analytics/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </>
        ) : null}
      </Heading>
      {secondaryFilters}
      <CalendarProvider>
        <ResponsiveGridLayout
          isDraggable={false}
          isResizable={false}
          isDroppable={false}
          containerPadding={[0, 0]}
          margin={[20, 20]}
          layouts={{ lg: layout }}
          cols={{ lg: 12, md: 12, sm: 1, xs: 1, xxs: 1 }}
          style={{
            margin: 20,
          }}
        >
          {panels.map(panel => {
            return (
              <div key={panel.id}>
                {/* This div is required for react-grid-layout */}
                <PanelContainer title={panel.title} helpText={panel.helpText}>
                  <Panel panel={panel} isTeamAnalytics={isTeamAnalytics} />
                </PanelContainer>
              </div>
            );
          })}
        </ResponsiveGridLayout>
      </CalendarProvider>
      {isFilterDrawerOpen && (
        <FilterDrawer
          isOpen={isFilterDrawerOpen}
          onClose={toggleFilterDrawer}
          selectedAssigne={selectedAssigne}
          setSelectedAssigne={val => setSelectedAssigne(val)}
          tagsKey={tagsKey}
          setTagsKey={val => setTagsKey(val)}
          tagsValue={tagsValue}
          setTagsValue={val => setTagsValue(val)}
          keyValue={keyValue}
          setKeysValue={val => setKeysValue(val)}
          priority={priority}
          setPriority={val => setPriority(val)}
          serviceOwner={serviceOwner}
          setServiceOwner={val => setServiceOwner(val)}
          services={services}
          setServices={val => setServices(val ?? [])}
          selectedTeamId={selectedTeam}
          editMode={editMode}
        />
      )}
    </Grid>
  );
};

export default Page;
