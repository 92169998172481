import { url } from 'inspector';

export const supportUrls = {
  headerUrl: {
    API: {
      name: 'API Docs',
      url: 'https://apidocs.squadcast.com/',
    },
    support: {
      name: 'Support Docs',
      url: 'https://support.squadcast.com/quickstart-guide/readme',
    },
    community: {
      name: 'Community',
      url: 'https://community.squadcast.com/view/home',
    },
    tour: {
      name: 'Take a Product Tour',
      url: 'userflow',
    },
    FAQ: {
      name: 'FAQ',
      url: 'https://support.squadcast.com/docs/squadcast-faq',
    },
    contact: {
      name: 'Contact Us',
      url: 'https://www.squadcast.com/support-ticket-form',
    },
    rp: {
      name: 'Referral Program',
      url: 'referral',
      hidden: true,
    },
    fr: {
      name: 'Have a Feature Request?',
      url: 'https://form.typeform.com/to/JrsE8n?typeform-source=squadcast.typeform.com',
    },
  },
};
