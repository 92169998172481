import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { Fragment } from 'react';

import { reactQueryConfigSuccess } from '../../helpers/helper.toast';
import { useStausUpdate } from '../../hooks/useStatusUpdate';
import { IPageStatus } from '../../Interface';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_PAGE_STATUS_UPDATED_MANUALLY } from 'core/const/tracker';

interface Props {
  pageId: number;
  onCancel: () => void;
  refetch: () => void;
}

const UpdatePageStatus = (props: Props) => {
  const { setStatusId, statusId, onFormSubmit, statusCodes, isSuccess } = useStausUpdate(
    props.pageId,
    () => {
      props.refetch();
      reactQueryConfigSuccess('Page status updated');

      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_PAGE_STATUS_UPDATED_MANUALLY);

      props.onCancel();
    },
  );

  return (
    <Fragment>
      <Box bgColor="white" pt={2}>
        <Text mb={4}>STATUS PAGE STATUS</Text>
        {isSuccess ? (
          <RadioGroup onChange={val => setStatusId(parseInt(val))} value={statusId}>
            <Stack direction="column">
              {statusCodes.map(status => {
                return (
                  <Flex
                    key={status?.id}
                    py={4}
                    borderBottomWidth={1}
                    align="center"
                    data-testid="statuses"
                  >
                    <Radio value={status?.id} key={status?.id}>
                      <Flex flex={1} direction="column" ml={2}>
                        <Flex flex={1} direction="row" align={'center'}>
                          <Box bgColor={status?.color} w={3} h={3} borderRadius={6} mr={2} />
                          <Text>{status?.message}</Text>
                        </Flex>
                      </Flex>
                    </Radio>
                  </Flex>
                );
              })}
            </Stack>
          </RadioGroup>
        ) : (
          <Center h="60vh">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        )}

        <Box w="100%" bottom={0} position="fixed" bgColor={'white'} zIndex="2">
          <Divider />
          <HStack px={2} py={2} gap={4}>
            <Button type="submit" variant="default" onClick={onFormSubmit}>
              Update
            </Button>
            <Button variant="invertedDefault" onClick={() => props.onCancel()}>
              Cancel
            </Button>
          </HStack>
        </Box>
      </Box>
    </Fragment>
  );
};

export default UpdatePageStatus;
