import { useFormikContext, Field, FieldArray } from 'formik';
import React, { Fragment, useEffect } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import { Delete2Icon } from 'icons';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  VStack,
  IconButton,
  ButtonGroup,
  Button,
  HStack,
  Center,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import Select from 'components/chakra/Select';
import ParticipantOptionLabel from '../schedules.customizerotations/participantOptionLabel';
import TagGroup from './tagGroup';
import { timeZones } from '../../constants/schedules.timezone';
import { INewSchedule } from '../../interface/schedule';
import { schedulesTextCopy } from '../../constants/schedules.copy';
import { useGetAllPatternParticipants } from '../../helpers/helpers.customrotations';
import { schedulesColorPalette } from '../../constants/schedules.rotation-template';
import { useScheduleHeaderContext } from '../../schedules.view/schedules.header/context';
import { ParticipantValue } from '../schedules.customizerotations/participantMultiValue';
import { checkAndUpdateTags } from '../../helpers/helpers.schedule';
import { useSelector, shallowEqual } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { OwnerType } from '../../graphql/types';

function SchedulesForm() {
  const {
    values: { name, description, timeZone, owner, tags, tagsSource },
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormikContext<INewSchedule>();

  const { updateCurrentTimezone } = useScheduleHeaderContext();

  useEffect(() => {
    updateCurrentTimezone(timeZone);
  }, []);

  const currentuser = useSelector(
    (state: IAppState) => state.organization.currentUser.u,
    shallowEqual,
  );

  useEffect(() => {
    if (tags && tagsSource) {
      const { newTagAdded, updatedTags } = checkAndUpdateTags(tagsSource, tags);
      if (newTagAdded) {
        handleChange({ target: { name: 'tagsSource', value: updatedTags } });
      }
    }
  }, [tags, tagsSource]);

  const ownerOptions = useGetAllPatternParticipants();
  let ownerValue = ownerOptions.find(
    option => option.id === owner?.ID && option.type === owner?.type,
  );

  if (!owner) {
    if (currentuser) {
      ownerValue = {
        id: currentuser.id,
        type: OwnerType.User,
        label: `${currentuser.first_name} ${currentuser.last_name}`,
        value: currentuser.id,
      };
    }
  }

  const inputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const inputValue = event.currentTarget.value.trim();
    if (inputValue.length > 0) {
      handleChange({
        target: { name: event.currentTarget.name, value: event.currentTarget.value },
      });
    } else {
      handleChange({ target: { name: event.currentTarget.name, value: '' } });
    }
  };

  const SingleValue = ParticipantValue({
    componentType: 'single',
    showTooltip: owner?.type === 'user',
  });

  return (
    <VStack gap={8}>
      <FormControl isInvalid={!!(errors.name && touched.name)}>
        <FormLabel htmlFor="name">{schedulesTextCopy.creation.form.nameLabel} *</FormLabel>
        <Input
          isInvalid={false}
          value={name}
          id="name"
          name="name"
          onChange={inputChangeHandler}
          onBlur={handleBlur}
          placeholder={schedulesTextCopy.creation.form.nameLabel}
        />
        <FormErrorMessage>{errors.name}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!(errors.timeZone && touched.timeZone)}>
        <FormLabel htmlFor="timeZone" sx={{ '&:after': { content: "'*'" } }}>
          {schedulesTextCopy.creation.form.timeZoneLabel}
        </FormLabel>
        <Select
          name="timeZone"
          inputId="timeZone"
          value={timeZones.find(tz => tz.value === timeZone) ?? null}
          options={timeZones}
          onChange={(newValue: any) => {
            handleChange({ target: { name: 'timeZone', value: newValue?.value } });
            updateCurrentTimezone(newValue?.value);
          }}
          onBlur={handleBlur}
          placeholder={schedulesTextCopy.creation.form.timeZonePlaceholder}
          closeMenuOnSelect
        />
        <FormErrorMessage>{errors.timeZone}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!(errors.owner?.ID && touched.owner)}>
        <FormLabel htmlFor="owner" sx={{ '&:after': { content: "'*'" } }}>
          {schedulesTextCopy.creation.form.owner}
        </FormLabel>
        <Select
          name="owner"
          inputId="owner"
          options={ownerOptions}
          value={ownerValue}
          defaultValue={ownerValue}
          onChange={(newValue: any) =>
            handleChange({
              target: { name: 'owner', value: { ID: newValue.id, type: newValue.type } },
            })
          }
          formatOptionLabel={ParticipantOptionLabel}
          components={{ SingleValue }}
          onBlur={handleBlur}
          placeholder={schedulesTextCopy.creation.form.ownerPlaceholder}
          closeMenuOnSelect
          hideDropdownArrow
        />
        <FormErrorMessage>{errors.owner?.ID}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!tags?.length && !!(errors.tags && touched.tags)}>
        <FormLabel>{schedulesTextCopy.creation.form.tagsLabel}</FormLabel>
        <VStack alignItems="flex-start" w="100%">
          <FieldArray
            name="tags"
            render={() =>
              tags ? (
                <Grid
                  templateColumns="1fr 1fr 1fr"
                  templateRows={`repeat(${tags.length}, 1fr)`}
                  gap={1}
                  marginTop={6}
                  alignItems="center"
                >
                  {tags.map((tag, index) => {
                    return (
                      <Fragment key={index}>
                        <GridItem>
                          <TagGroup
                            value={tag}
                            keysOptions={tagsSource ? Object.keys(tagsSource) : []}
                            valuesMap={tagsSource ? tagsSource : {}}
                            handleChange={(key: string, value: string) => {
                              const updatedTags = [...tags];
                              updatedTags[index].key = key;
                              updatedTags[index].value = value;
                              handleChange({
                                target: {
                                  name: 'tags',
                                  value: updatedTags,
                                },
                              });
                            }}
                          />
                        </GridItem>
                        <GridItem>
                          <ButtonGroup alignSelf="flex-start" justifySelf="auto">
                            {schedulesColorPalette.map((colorObject, colorIndex) => (
                              <Button
                                m={1.5}
                                key={colorIndex}
                                background={colorObject.value}
                                value={colorObject.value}
                                onClick={e => {
                                  const updatedTags = [...tags];
                                  updatedTags[index].color = colorObject.value;
                                  handleChange({
                                    target: {
                                      name: 'tags',
                                      value: updatedTags,
                                    },
                                  });
                                }}
                              >
                                {tag.color === colorObject.value ? (
                                  <CheckIcon color="gray.900" h={2} w={2} />
                                ) : (
                                  ''
                                )}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </GridItem>
                        <GridItem>
                          <IconButton
                            aria-label="delete-tag"
                            variant="outline"
                            icon={<Delete2Icon />}
                            ml={2}
                            onClick={() => {
                              const updatedTags = tags.filter((t, i) => i !== index);
                              handleChange({
                                target: {
                                  name: 'tags',
                                  value: updatedTags,
                                },
                              });
                            }}
                            _hover={{ cursor: 'pointer' }}
                          />
                        </GridItem>
                      </Fragment>
                    );
                  })}
                </Grid>
              ) : (
                <></>
              )
            }
          />

          <Button
            variant="invertedDefault"
            onClick={() => {
              const updatedTags = [
                ...(tags ?? []),
                { key: '', value: '', color: schedulesColorPalette[0].value },
              ];
              handleChange({
                target: {
                  name: 'tags',
                  value: updatedTags,
                },
              });
            }}
            leftIcon={<>+</>}
          >
            Add Tag
          </Button>
        </VStack>
        <FormErrorMessage>{errors.tags}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!(errors.description && touched.description)}>
        <FormLabel htmlFor="description">
          {schedulesTextCopy.creation.form.descriptionLabel}
        </FormLabel>
        <Textarea
          name="description"
          id="description"
          value={description ?? ''}
          rows={4}
          onChange={inputChangeHandler}
          placeholder={schedulesTextCopy.creation.form.descriptionLabel}
          onBlur={handleBlur}
        />
        <FormErrorMessage>{errors.description}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
}

export default SchedulesForm;
