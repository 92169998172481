import { Box, Flex, VStack } from '@chakra-ui/layout';
import { getAssigneeComponent } from 'views/main/organization/lcr/common/assignee';
import { DownloadBlue } from 'icons';
import AudioPlayer from 'views/main/organization/lcr/components/AudioPlayer';
import { useGetSessionsDetailsById } from 'views/main/organization/lcr/hooks/lcrDetails';
import { useParams, useLocation, Link } from 'react-router-dom';
import qs from 'query-string';
import { useLCRRelationTableAction } from './LCRRelationTableAction';
import { HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { getIncidentDetailsPath } from 'views/main/organization/incident-list/common/util';
import { getServiceDetailsPath } from 'views/main/organization/lcr/common/utils';
import { AssigneeAvatar, FormButton, Tooltip, usernameTooltipLabel } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import moment from 'moment';
import Table from 'library/molecules/Tablev2/Table';
import { Text } from 'library/atoms/Text';
import { CustomDrawerComponent } from 'library/molecules';
import DrawerFooterWrapper from 'library/molecules/Drawer';
type IProps = {
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
};
const LCRLogsDetails = ({ isDrawerOpen, onDrawerClose }: IProps) => {
  const params = useParams<{ id: string; sessionID: string }>();
  const { search } = useLocation();
  const searchData = qs.parse(search);
  const sessionId = typeof searchData.sessionId === 'string' ? searchData.sessionId : '';
  const { data } = useGetSessionsDetailsById(params.id, params.sessionID);

  const columns = useLCRRelationTableAction();
  const duration = [
    {
      key: 'Start time',
      value: moment.utc(data?.start_time).add(1, 'hour').format('HH:mm [UTC+1]'),
    },
    {
      key: 'End time',
      value: moment.utc(data?.end_time).add(1, 'hour').format('HH:mm [UTC+1]'),
    },
    {
      key: 'Total duration',
      value: `${data?.duration_in_seconds} sec`,
    },
  ];

  return (
    <CustomDrawerComponent
      title="Log Details"
      size="lg"
      isOpen={isDrawerOpen}
      onClose={onDrawerClose}
      disableBodyPadding
    >
      <VStack align="stretch">
        <KeyValueComponent label="FROM">
          <Text variant="h4" color="secondary.1000">
            {data?.caller_number}
          </Text>
        </KeyValueComponent>
        {data?.incident_id && (
          <KeyValueComponent label="INCIDENT ID">
            <Link to={getIncidentDetailsPath(data?.incident_id)}>
              <Text variant="h4" color="brand.blue">
                {data?.incident_id}
              </Text>
            </Link>
          </KeyValueComponent>
        )}
        {data?.assigned_to_id && (
          <KeyValueComponent label="ASSIGNED TO">
            {data?.assigned_to_type !== AssigneeType.User ? (
              <HStack columnGap="4px">
                <AssigneeAvatar
                  name={data?.assigned_to_name}
                  type={AssigneeType.Escalationpolicy}
                />
                {getAssigneeComponent(
                  data?.assigned_to_id,
                  AssigneeType.Escalationpolicy,
                  data?.assigned_to_name,
                )}
              </HStack>
            ) : (
              <Tooltip label={usernameTooltipLabel(data?.assigned_to_name)} placement="bottom">
                <HStack columnGap="4px">
                  <AssigneeAvatar name={data?.assigned_to_name} type={AssigneeType.User} />
                  {getAssigneeComponent(
                    data?.assigned_to_id,
                    AssigneeType.User,
                    data?.assigned_to_name,
                  )}
                </HStack>
              </Tooltip>
            )}
          </KeyValueComponent>
        )}
        <KeyValueComponent label="RECORDING">
          {data?.recording_url !== '' ? (
            <Flex alignItems="center" gap={1}>
              <a target="_blank" href={data?.recording_url} rel="noreferrer">
                <DownloadBlue cursor="pointer" />
              </a>
              <AudioPlayer audioUrl={data?.recording_url ?? ''} isTable={false} />
            </Flex>
          ) : (
            <Text variant="h4" color="secondary.1000">
              No recording available
            </Text>
          )}
        </KeyValueComponent>

        {data?.service_id && (
          <KeyValueComponent label="SELECTED SERVICE">
            <Link to={getServiceDetailsPath(data?.service_id)}>
              <Text textTransform="capitalize" variant="h4">
                {data?.service_alias}
              </Text>
            </Link>
          </KeyValueComponent>
        )}

        <KeyValueComponent label="DURATION" noBorder>
          {duration.map((data, i) => (
            <Flex key={i} justifyContent="space-between" my={3} w="100%">
              <Text color="secondary.1000" variant="h4">
                {data.key}
              </Text>
              <Text color="secondary.1000" variant="h4">
                {' '}
                {data.value}{' '}
              </Text>
            </Flex>
          ))}
        </KeyValueComponent>
        <Box>
          <Table data={data?.call_relations ?? []} columns={columns} hidePagination />
        </Box>
      </VStack>
      <DrawerFooterWrapper>
        <FormButton variant="secondary" onClick={onDrawerClose} title="Cancel" />
      </DrawerFooterWrapper>
    </CustomDrawerComponent>
  );
};

export default LCRLogsDetails;

const KeyValueComponent = ({
  label,
  children,
  noBorder = false,
}: {
  label: string;
  children: ReactNode;
  noBorder?: boolean;
}) => {
  return (
    <Flex
      py={3}
      px={4}
      flexDirection="column"
      alignItems="self-start"
      borderBottom={noBorder ? '0px' : '1px'}
      borderBottomColor="secondary.200"
      borderBottomStyle="solid"
    >
      <Text variant="body_800" color="secondary.700" mb={2}>
        {label}
      </Text>
      {children}
    </Flex>
  );
};
